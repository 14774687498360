import { getDataset, getDatasetAccuracyData, listDatasets } from "../services/corpus"

export default async function (options) {
    let children = await listDatasets({ parent_id: options.dataset_id })
        .then(data => data.rows)
    let thisDataset = await getDataset(options.dataset_id)
    let accuracies = await Promise.all([...children.map(c => c.id), options.dataset_id].map(id => getDatasetAccuracyData(id, options.filter)))

    let plot = [
        ...accuracies
            .map((accuracy, i) => accuracy.map(a => ({ x: a.date, y: a.accuracy * 100, group: i === children.length ? thisDataset.name : children[i].name })))
            .reduce((acc, pts) => [...acc, ...pts])
    ]
        .sort((a, b) => 1 - 2 * Number(a.x < b.x))

    let workspaces = [...children.map(c => c.name), thisDataset.name]
    let dates = [...new Set(plot.map(d => d.x))].sort()

    let dateAvgs = dates
        .map(d => plot
            .filter(p => p.x === d && p.group !== thisDataset.name)
            .reduce((avg, p, i, ps) => avg + p.y / ps.length, 0))
    let avgPerDate = dateAvgs
        .reduce((acc, el, i) => ({ ...acc, [dates[i]]: el.toFixed(2).replace('.', ',') }), {})

    let exportData = []
    for (let w of workspaces) {
        let workspaceAverage = 0
        let workspaceRow = { dataset: w, ...dates.reduce((acc, d) => ({ ...acc, [d]: undefined }), {}) }
        let workspaceExperiments = accuracies[workspaces.indexOf(w)]
        for (let e of workspaceExperiments) {
            workspaceRow[e.date] = Number(100 * e.accuracy).toFixed(2).replace('.', ',')
            workspaceAverage += 100 * e.accuracy / workspaceExperiments.length
        }
        workspaceRow[window.translate("AVERAGE")] = workspaceAverage.toFixed(2).replace('.', ',')
        exportData.push(workspaceRow)
    }
    if (thisDataset.router)
        exportData.push({ dataset: window.translate("AVERAGE"), ...avgPerDate, [window.translate("AVERAGE")]: dateAvgs.reduce((avg, el) => avg + (el || 0) / dateAvgs.length, 0).toFixed(2).replace('.', ',') })

    return { dataset: thisDataset, plot, data: exportData }
}