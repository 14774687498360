import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Loading, Tabs, Tab, UnorderedList, ListItem, Modal, Accordion, AccordionItem, Icon} from 'carbon-components-react';
import { iconCheckmarkSolid, iconWarningSolid } from 'carbon-icons'
import { getDataset, getDatasetClassDistibution, exportDataset,  importIntent, importExample, importEntity, importDatasetConfig, auditDatasetImport } from '../../../services/corpus'
import { Form } from '../../../components/carbon-react-crud';
import xlsx from 'xlsx';
import { saveAs } from 'file-saver';
import { Promise } from "bluebird";

class DatasetHeader extends Component {

    state = {
        dataset: {},    

        distributionData: [],
        distributionReport: [[0], ''],
        loading: false,
        hidden: true,
        export: false,

        classes: {
            items: [],
            actual: 0,
            total: 0,
            errors: 0,
            icon: {
                i: iconWarningSolid,
                style: { marginLeft: "0.5rem", fill: "white" },
                description: window.translate("UPLOADING")
            }
        },
        examples: {
            items: [],
            actual: 0,
            total: 0,
            errors: 0,
            icon: {
                i: iconWarningSolid,
                style: { marginLeft: "0.5rem", fill: "white" },
                description: window.translate("UPLOADING")
            }
        },
        entities: {
            items: [],
            actual: 0,
            total: 0,
            errors: 0,
            icon: {
                i: iconWarningSolid,
                style: { marginLeft: "0.5rem", fill: "white" },
                description: window.translate("UPLOADING")
            }
        },
        config: {
            items: [],
            actual: 0,
            total: 0,
            errors: 0,
            icon: {
                i: iconWarningSolid,
                style: { marginLeft: "0.5rem", fill: "white" },
                description: window.translate("UPLOADING")
            }
        }
    }

    render() {
        return (<>
            <Loading active={this.state.loading} />
            <div className="bx--grid">
                <div className="bx--row">
                    <div className="bx--col-lg-12 bx--col-md-12 bx--col-sm-12 bx--col-xs-12">
                        <h4> {window.translate("DATASET")}: {this.state.dataset.name} </h4>
                        <br />
                        <strong> {window.translate("NUMBER_OF_EXAMPLES")}: </strong> {this.state.distributionData.reduce((acc, c) => acc + Number(c.count), 0)}
                        <br /><br />
                        <strong> {window.translate("NUMBER_OF_CLASSES")}: </strong> {this.state.distributionData.length}
                        <br /><br />
                    </div>
                    <div className="bx--row">
                        <div className="bx--col-lg-12 bx--col-md-12 bx--col-sm-12 bx--col-xs-12">
                            <Link to={`/datasets/${this.props.match.params.dataset_id}`}><Button> {window.translate("OVERVIEW")} </Button></Link>
                            <Link to={`/datasets/${this.props.match.params.dataset_id}/examples_class_distribution`}><Button> {window.translate("EXAMPLES")} </Button></Link>
                            <Link to={`/datasets/${this.props.match.params.dataset_id}/review_examples`}><Button> {window.translate("REVIEW_EXAMPLES")} </Button></Link>
                            <Link to={`/datasets/${this.props.match.params.dataset_id}/review`}><Button> {window.translate("REVIEW")} </Button></Link>
                            <Link to={`/datasets/${this.props.match.params.dataset_id}/mapping`}><Button> {window.translate("MAPPING")} </Button></Link>
                            <Link to={`/datasets/${this.props.match.params.dataset_id}/merger`}><Button> {window.translate("MERGER")} </Button></Link>
                            <Link to={`/datasets/${this.props.match.params.dataset_id}/intents`}><Button> {window.translate("INTENTS")} </Button></Link>
                            <Link to={`/datasets/${this.props.match.params.dataset_id}/entity_transfer`}><Button> {window.translate("ENTITY_TRANSFER")} </Button></Link>
                            <Link to={`/datasets/${this.props.match.params.dataset_id}/entities`}><Button> {window.translate("ENTITIES")} </Button></Link>
                            <Link to={`/datasets/${this.props.match.params.dataset_id}/experiments`}><Button> {window.translate("EXPERIMENTS")} </Button></Link>
                            <Link to={`/datasets/${this.props.match.params.dataset_id}/evaluations`}><Button> {window.translate("EVALUATIONS")} </Button></Link>
                            <Link to={`/datasets/${this.props.match.params.dataset_id}/permissions`}><Button> {window.translate("PERMISSIONS")} </Button></Link>
                            <Button onClick={ev => exportDataset(this.props.match.params.dataset_id, `${this.state.dataset.name}-${(new Date()).toISOString().replace(/-|:/g, '').replace('T', '-').slice(0, 15)}.xlsx`)}> {window.translate("DOWNLOAD")} </Button>
                            <Button onClick={ev => exportDataset(this.props.match.params.dataset_id, `${this.state.dataset.name}-${(new Date()).toISOString().replace(/-|:/g, '').replace('T', '-').slice(0, 15)}.xlsx`, { clean: true })}> {window.translate("DOWNLOAD_CLEAN")} </Button>
                            <Button onClick={ev => this.setState({ uploadModalOpen: true })}> {window.translate("UPLOAD")} </Button>
                            <Modal
                                className='carbon--custom-form'
                                open={this.state.uploadModalOpen}
                                primaryButtonText={window.translate("SEND")}
                                primaryButtonDisabled={!this.state.hidden}
                                secondaryButtonText={window.translate("CLOSE")}
                                onRequestSubmit={async () => this.handleUpload(this.state.formData)}
                                onRequestClose={ev => this.setState({ uploadModalOpen: false, ...this.resetImportState() })}
                            >
                                <Form
                                    fields={[
                                        { key: "instructions", type: "readonly", component: props => <UploadInstructions {...props} /> },
                                        { key: "dataset", label: window.translate("DATASET"), type: "file", props: { accept: ["xlsx"] } }
                                    ]}
                                    value={this.state.formData}
                                    onChange={formData => this.setState({ formData })}
                                />
                                <br />
                                <br />
                                <div className="bx--row">
                                    <div className="bx--col-lg-12 bx--col-md-12 bx--col-sm-12 bx--col-xs-12">
                                        <h2 hidden={this.state.hidden}> {window.translate("UPLOAD_DETAILS")} <Button disabled={!this.state.export} onClick={async (ev) => await this.exportUploadDetails(`${this.state.dataset.name}-upload-details-${(new Date()).toISOString().replace(/-|:/g, '').replace('T', '-').slice(0, 15)}.xlsx`)}> {window.translate("EXPORT")} </Button> </h2>
                                        <br />
                                        <Accordion className="accordion-config" hidden={this.state.hidden}>
                                            <h3> {window.translate("CONFIG")} ({this.state.config.actual}/{this.state.config.total}) {window.translate("MISSES")}: {this.state.config.errors} <Icon description={this.state.config.icon.description} style={this.state.config.icon.style} icon={this.state.config.icon.i} /></h3>
                                            {this.state.config.items}
                                        </Accordion>
                                        <br />
                                        <br />
                                        <Accordion className="accordion-classes" hidden={this.state.hidden}>
                                            <h3> {window.translate("CLASSES")} ({this.state.classes.actual}/{this.state.classes.total}) {window.translate("MISSES")}: {this.state.classes.errors} <Icon description={this.state.classes.icon.description} style={this.state.classes.icon.style} icon={this.state.classes.icon.i} /></h3>
                                            {this.state.classes.items}
                                        </Accordion>
                                        <br />
                                        <br />
                                        <Accordion className="accordion-examples" hidden={this.state.hidden}>
                                            <h3> {window.translate("EXAMPLES")} ({this.state.examples.actual}/{this.state.examples.total}) {window.translate("MISSES")}: {this.state.examples.errors} <Icon description={this.state.examples.icon.description} style={this.state.examples.icon.style} icon={this.state.examples.icon.i} /></h3>
                                            {this.state.examples.items}
                                        </Accordion>
                                        <br />
                                        <br />
                                        <Accordion className="accordion-entities" hidden={this.state.hidden}>
                                            <h3> {window.translate("ENTITIES")} ({this.state.entities.actual}/{this.state.entities.total}) {window.translate("MISSES")}: {this.state.entities.errors} <Icon description={this.state.entities.icon.description} style={this.state.entities.icon.style} icon={this.state.entities.icon.i} /></h3>
                                            {this.state.entities.items}
                                        </Accordion>
                                        <br />
                                        <br />
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
            <br /><br />
        </>)
    }

    componentDidMount() {
        getDatasetClassDistibution(this.props.match.params.dataset_id)
            .then(data => {
                this.setState({
                    distributionData: data,
                })
            })
        getDataset(this.props.match.params.dataset_id)
            .then(dataset => this.setState({ dataset }))
    }

    async exportUploadDetails(filename) {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let wb = xlsx.utils.book_new()

        if (this.state.classes.items.length > 0) {
            let classes_data = [["Name", "Messages"], ...this.errorsToArray("classes")]
            let classes_ws = xlsx.utils.aoa_to_sheet(classes_data)
            xlsx.utils.book_append_sheet(wb, classes_ws, "Classes")
        }

        if (this.state.examples.items.length > 0) {
            let examples_data = [["Name", "Messages"], ...this.errorsToArray("examples")]
            let examples_ws = xlsx.utils.aoa_to_sheet(examples_data)
            xlsx.utils.book_append_sheet(wb, examples_ws, "Examples")
        }

        if (this.state.entities.items.length > 0) {
            let entities_data = [["Name", "Messages"], ...this.errorsToArray("entities")]
            let entities_ws = xlsx.utils.aoa_to_sheet(entities_data)
            xlsx.utils.book_append_sheet(wb, entities_ws, "Entities")
        }

        if (this.state.config.items.length > 0) {
            let config_data = [["Name", "Messages"], ...this.errorsToArray("config")]
            let config_ws = xlsx.utils.aoa_to_sheet(config_data)
            xlsx.utils.book_append_sheet(wb, config_ws, "Config")
        }

        try {
            const excelBuffer = xlsx.write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], {type: fileType})
            saveAs(data, filename)
        } catch (error) {}
    }

    

    async handleUpload(data) {
        try {
            this.setState({ ...this.resetImportState() })
            // read file and get workbook sheets
            let result = await this.readFileAsync(data.get('dataset'))
            let dataArr = new Uint8Array(result)
            let workbook = xlsx.read(dataArr, {type: 'array'})
            let classes = xlsx.utils.sheet_to_json(workbook.Sheets["Classes"])
            let examples = xlsx.utils.sheet_to_json(workbook.Sheets["Examples"])
            let entities = xlsx.utils.sheet_to_json(workbook.Sheets["Entities"])
            let config = xlsx.utils.sheet_to_json(workbook.Sheets["Config"])

            // update total items
            this.state.config.total = config.length;
            this.state.classes.total = classes.length;
            this.state.examples.total = examples.length;
            this.state.entities.total = entities.length;

            // validate workbook sheets
            if (!this.validateConfigSheetErrors(config)) config = []
            if (!this.validateIntentsSheetErrors(classes)) classes = []
            if (!this.validateExamplesSheetErrors(examples)) examples = []
            if (!this.validateEntitiesSheetErrors(entities)) entities = []

            // update UI
            this.setState({ 
                hidden: false, 
                config: this.state.config,
                classes: this.state.classes,
                examples: this.state.examples,
                entities: this.state.entities 
            })

            // generate calls
            let promises = [...config.map(row => { return { row: row, import: (row) => this.handleConfigImport(row) } }),
            ...classes.map(row => { return { row: row, import: (row) => this.handleClassesImport(row) } }),
            ...examples.map(row => { return { row: row, import: (row) => this.handleExamplesImport(row) } }),
            ...entities.map(row => { return { row: row, import: (row) => this.handleEntitiesImport(row) } })]
            
            // sequence calling
            if (promises.length > 0) {
                await Promise.each(promises,
                    i => i.import(i.row) 
                )
                .then(result => {})
                .catch(rejection => {
                    console.error("Catch: " + rejection);
                })
                this.handleAuditDatasetImport()
            }

        } catch(err) {
            let message = this.getErrorMessage(err)
            this.props.handleNotification("error", window.translate("ERROR_UPLOADING_FILE"), message)
        }
    }

    getIntentPayload(row) {
        return {
            name: row.class_name,
            canonical: row.canonical,
            content: row.answer,
            out_of_context: Boolean(row.is_out_of_context),
            out_of_training: Boolean(row.is_out_of_training),
            final: Boolean(row.final),
            type: String(row.class_type),
            dataset_id: String(this.state.dataset.id),
            output_variables: row.output_variables,
            context_variables: row.context_variables,
            owner: row.owner,
            metadata: row.metadata,
        }
    }

    getExamplePayload(row) {
        return {
            text: row.example,
            intent_name: row.class_name,
            status: row.fixed,
            metadata: row.metadata,
        }
    }

    getEntityPayload(row) {
        return {
            name: String(row.entity),
            fuzzy_match: true,
            dataset_id: String(this.state.dataset.id),
            value: String(row.value),
            type: row.type,
            synonyms: row.synonyms
        }
    }

    validateConfigSheetErrors(config) {
        let errors = this.validateConfigSheet(config)
        if (errors.length > 0) {
            this.setState({
                config: {
                    ...this.state.config,
                    errors: this.state.config.errors + 1,
                    actual: this.state.config.total > 0 ? this.state.config.actual + 1 : this.state.config.actual,
                    items: [...this.state.config.items,
                        <AccordionItem title={window.translate("CONFIG")} style={{ color: "red" }}>{this.getAllErrorsMessage(errors)}</AccordionItem>
                    ],
                    icon: {
                        i: iconWarningSolid,
                        style: { marginLeft: "0.5rem", fill: "yellow" },
                        description: window.translate("UPLOADING_WARNING")
                    } 
                }
            })
            return false
        }
        return true
    }

    validateIntentsSheetErrors(intent) {
        let errors = this.validateIntentsSheet(intent)
        if (errors.length > 0) {
            this.setState({
                classes: {
                    ...this.state.classes,
                    errors: this.state.classes.errors + 1,
                    actual: this.state.classes.total > 0 ? this.state.classes.actual + 1 : this.state.classes.actual,
                    items: [...this.state.classes.items,
                        <AccordionItem title={window.translate("INTENTS")} style={{ color: "red" }}>{this.getAllErrorsMessage(errors)}</AccordionItem>
                    ],
                    icon: {
                        i: iconWarningSolid,
                        style: { marginLeft: "0.5rem", fill: "yellow" },
                        description: window.translate("UPLOADING_WARNING")
                    }
                }
            })
            return false
        }
        return true
    }

    validateExamplesSheetErrors(example) {
        let errors = this.validateExamplesSheet(example)
        if (errors.length > 0) {
            this.setState({
                examples: {
                    ...this.state.examples,
                    errors: this.state.examples.errors + 1,
                    actual: this.state.examples.total > 0 ? this.state.examples.actual + 1 : this.state.examples.actual,
                    items: [...this.state.examples.items,
                        <AccordionItem title={window.translate("EXAMPLES")} style={{ color: "red" }}>{this.getAllErrorsMessage(errors)}</AccordionItem>
                    ],
                    icon: {
                        i: iconWarningSolid,
                        style: { marginLeft: "0.5rem", fill: "yellow" },
                        description: window.translate("UPLOADING_WARNING")
                    }
                }
            })
            return false
        }
        return true
    }

    validateEntitiesSheetErrors(entities) {
        let errors = this.validateEntitiesSheet(entities)
        if (errors.length > 0) {
            this.setState({
                entities: {
                    ...this.state.entities,
                    errors: this.state.entities.errors + 1,
                    actual: this.state.entities.total > 0 ? this.state.entities.actual + 1 : this.state.entities.actual,
                    items: [...this.state.entities.items,
                        <AccordionItem title={window.translate("ENTITIES")} style={{ color: "red" }}>{this.getAllErrorsMessage(errors)}</AccordionItem>
                    ],
                    icon: {
                        i: iconWarningSolid,
                        style: { marginLeft: "0.5rem", fill: "yellow" },
                        description: window.translate("UPLOADING_WARNING")
                    }
                }
            })
            return false
        }
        return true
    }

    validateConfigSheet(config) {
        let configSheet = config.length > 0 ? this.cleanWorkbookData(config[0]) : undefined
        let errors = []
        if (!configSheet) return []
        if (config.length !== 1) errors.push({ message: "Config spreadsheet should have 2 rows" })
        if (Object.keys(configSheet).length !== 4) errors.push({ message: "Config spreadsheet should have 4 columns" })
        try { JSON.parse(configSheet.welcome_text) } catch (err) { errors.push({ message: window.translate(this.invalidParameterResponse('welcome_text').message) }) }
        try { JSON.parse(configSheet.anything_else_text) } catch (err) { errors.push({ message: window.translate(this.invalidParameterResponse('anything_else_text').message) }) }
        if (!["sequential", "random", "multiline"].includes(String(configSheet.response_variation))) errors.push({ message: window.translate(this.invalidParameterResponse('response_variation').message) })
        if (!(Number(configSheet.disambiguation_question_limit) > 0)) errors.push({ message: window.translate(this.invalidParameterResponse('disambiguation_question_limit').message) })
        return errors
    }
    
    validateIntentsSheet(intent) {
        let intentsSheet = intent.length > 0 ? this.cleanWorkbookData(intent[0]) : undefined
        let errors = []
        if (!intentsSheet) return []
        if (Object.keys(intentsSheet).length < 7) errors.push({ message: "Classes spreadsheet should have at least 7 columns"})
        return errors
    }

    validateExamplesSheet(example) {
        let examplesSheet = example.length > 0 ? this.cleanWorkbookData(example[0]) : undefined
        let errors = []
        if (!examplesSheet) return []
        if (Object.keys(examplesSheet).length < 2) errors.push({ message: "Examples spreadsheet should have at least 2 columns" })
        return errors
    }

    validateEntitiesSheet(entities) {
        let entitiesSheet = entities.length > 0 ? this.cleanWorkbookData(entities[0]) : undefined
        let errors = []
        if (!entitiesSheet) return []
        if (Object.keys(entitiesSheet).length > 4 ) errors.push({ message: "Entities spreadsheet should have at least 3 columns and no more than 4 columns" })
        return errors
    }

    handleConfigImport(row) {
        return importDatasetConfig(this.state.dataset.id, this.cleanWorkbookData(row))
            .catch(errors => {
                return this.setState({
                    config: {
                        ...this.state.config,
                        errors: this.state.config.errors + 1,
                        items: [...this.state.config.items,
                            <AccordionItem title={window.translate("CONFIG")} style={{ color: "red" }}>{this.getAllErrorsMessage(errors.messages)}</AccordionItem>
                        ],
                        icon: {
                            i: iconWarningSolid,
                            style: { marginLeft: "0.5rem", fill: "yellow" },
                            description: window.translate("UPLOADING_WARNING")
                        }
                    }
                })
            })
            .then(updatedDataset => {
                return this.setState({
                    config: {
                        ...this.state.config,
                        actual: this.state.config.actual + 1
                    }
                })
            })
    }

    handleClassesImport(row) {
        let intent = this.getIntentPayload(this.cleanWorkbookData(row))
        return importIntent(this.state.dataset.id, intent)
            .catch(err => {
                return this.setState({
                    classes: {
                        ...this.state.classes,
                        errors: this.state.classes.errors + 1,
                        items: [...this.state.classes.items,
                            <AccordionItem title={window.translate(err.resource_name)} style={{ color: "red" }}>{this.getAllErrorsMessage(err.messages)}</AccordionItem>
                        ],
                        icon: {
                            i: iconWarningSolid,
                            style: { marginLeft: "0.5rem", fill: "yellow" },
                            description: window.translate("UPLOADING_WARNING")
                        }
                    }
                })
            })
            .then(() => {
                return this.setState({
                    classes: {
                        ...this.state.classes,
                        actual: this.state.classes.actual + 1
                    }
                })
            })
    }

    handleExamplesImport(row) {
        let example = this.getExamplePayload(this.cleanWorkbookData(row))
        return importExample(this.state.dataset.id, example)
            .catch(err => {
                return this.setState({
                    examples: {
                        ...this.state.examples,
                        errors: this.state.examples.errors + 1,
                        items: [...this.state.examples.items,
                            <AccordionItem title={window.translate(err.resource_name)} style={{ color: "red" }}>{this.getAllErrorsMessage(err.messages)}</AccordionItem>
                        ],
                        icon: {
                            i: iconWarningSolid,
                            style: { marginLeft: "0.5rem", fill: "yellow" },
                            description: window.translate("UPLOADING_WARNING")
                        }
                    }
                })
            })
            .then(importedExample => {
                return this.setState({
                    examples: {
                        ...this.state.examples,
                        actual: this.state.examples.actual + 1
                    }
                })
            })
    }

    handleEntitiesImport(row) {
        let entity = this.getEntityPayload(this.cleanWorkbookData(row))
        return importEntity(this.state.dataset.id, entity)
            .catch(err => {
                return this.setState({
                    entities: {
                        ...this.state.entities,
                        errors: this.state.entities.errors + 1,
                        items: [...this.state.entities.items,
                            <AccordionItem title={window.translate(err.resource_name)} style={{ color: "red" }}>{this.getAllErrorsMessage(err.messages)}</AccordionItem>
                        ],
                        icon: {
                            i: iconWarningSolid,
                            style: { marginLeft: "0.5rem", fill: "yellow" },
                            description: window.translate("UPLOADING_WARNING")
                        }
                    }
                })
            })
            .then(importedEntity => {
                return this.setState({
                    entities: {
                        ...this.state.entities,
                        actual: this.state.entities.actual + 1
                    }
                })
            })
    }

    handleAuditDatasetImport() {
        return auditDatasetImport(this.state.dataset.id, this.state.dataset)
            .catch(err => {
                let message = err.message ? window.translate(err.message) : ""
                this.props.handleNotification("error", window.translate("ERROR_UPLOADING_FILE"), message)
            })
            .then(() => {
                this.setState({
                export: true,
                config: {
                    ...this.state.config,
                    icon: this.state.config.items.length > 0 ? {
                        i: iconWarningSolid,
                        style: this.state.config.errors === 0 ? { marginLeft: "0.5rem", fill: "green" } : { marginLeft: "0.5rem", fill: "yellow" },
                        description: window.translate("UPLOAD_WARNING")
                    } : {
                        i: iconCheckmarkSolid,
                        style: { marginLeft: "0.5rem", fill: "green" },
                        description: window.translate("UPLOAD_SUCCESS")
                    }
                },
                classes: {
                    ...this.state.classes,
                    icon: this.state.classes.items.length > 0 ? {
                        i: iconWarningSolid,
                        style: this.state.classes.errors === 0 ? { marginLeft: "0.5rem", fill: "green" } : { marginLeft: "0.5rem", fill: "yellow" },
                        description: window.translate("UPLOAD_WARNING")
                    } : {
                        i: iconCheckmarkSolid,
                        style: { marginLeft: "0.5rem", fill: "green" },
                        description: window.translate("UPLOAD_SUCCESS")
                    }
                },
                examples: {
                    ...this.state.examples,
                    icon: this.state.examples.items.length > 0 ? {
                        i: iconWarningSolid,
                        style: this.state.examples.errors === 0 ? { marginLeft: "0.5rem", fill: "green" } : { marginLeft: "0.5rem", fill: "yellow" },
                        description: window.translate("UPLOAD_WARNING")
                    } : {
                        i: iconCheckmarkSolid,
                        style: { marginLeft: "0.5rem", fill: "green" },
                        description: window.translate("UPLOAD_SUCCESS")
                    }
                },
                entities: {
                    ...this.state.entities,
                    icon: this.state.entities.items.length > 0 ? {
                        i: iconWarningSolid,
                        style: this.state.entities.errors === 0 ? { marginLeft: "0.5rem", fill: "green" } : { marginLeft: "0.5rem", fill: "yellow" },
                        description: window.translate("UPLOAD_WARNING")
                    } : {
                        i: iconCheckmarkSolid,
                        style: { marginLeft: "0.5rem", fill: "green" },
                        description: window.translate("UPLOAD_SUCCESS")
                    }
                }
                })
            })
    }

    readFileAsync(file) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader()
          reader.onload = () => { resolve(reader.result) }
          reader.onerror = reject
          reader.readAsArrayBuffer(file)
        })
    }

    errorsToArray(type) {
        return this.state[type].items.map(i => [i.props.title, i.props.children.map(p => p.props.children).join(", ")])
    }

    invalidParameterResponse(paramName) {
        return { message: `Provided parameter '${paramName}' is invalid` }
    }

    getAllErrorsMessage(errors) {
        return errors.length > 0 ? errors.map(e => <p>{window.translate(e.message)}</p>) : [<p>{window.translate(errors.message)}</p>]
    }

    getErrorMessage(err){
        return err.message ? window.translate(err.message) + (err.row ? " " + window.translate("AT_ROW") + " " + err.row : "") : ""
    }

    cleanWorkbookData(row) {
        return Object.keys(row).reduce((c, k) => (c[k.toLowerCase().trim().replace(/[\W]+/g, "_")] = row[k], c), {})
    }

    resetImportState() {
       return {
            hidden: true,
            loading: false,
            export: false, 
            classes: {
                items: [],
                actual: 0,
                total: 0,
                errors: 0,
                icon: {
                    i: iconWarningSolid,
                    style: { marginLeft: "0.5rem", fill: "white" },
                    description: window.translate("UPLOADING")
                }
            },
            examples: {
                items: [],
                actual: 0,
                total: 0,
                errors: 0,
                icon: {
                    i: iconWarningSolid,
                    style: { marginLeft: "0.5rem", fill: "white" },
                    description: window.translate("UPLOADING")
                }
            },
            entities: {
                items: [],
                actual: 0,
                total: 0,
                errors: 0,
                icon: {
                    i: iconWarningSolid,
                    style: { marginLeft: "0.5rem", fill: "white" },
                    description: window.translate("UPLOADING")
                }
            },
            config: {
                items: [],
                actual: 0,
                total: 0,
                errors: 0,
                icon: {
                    i: iconWarningSolid,
                    style: { marginLeft: "0.5rem", fill: "white" },
                    description: window.translate("UPLOADING")
                }
            }
        }
    }
}

class UploadInstructions extends Component {
    render() {
        return (<>
            <h1>{window.translate("UPLOAD_INSTRUCTIONS")}</h1>
            <Tabs>
                <Tab label={window.translate("OVERVIEW")}>
                    <p>{window.translate("UPLOAD_INSTRUCTIONS_0")}</p>
                    <img alt={window.translate("UPLOAD_INSTRUCTIONS_0")} style={{ width: "100%" }} src={`${process.env.PUBLIC_URL}/images/dataset-upload/sheets.png`} />
                    <a href={`${process.env.PUBLIC_URL}/dataset-template.xlsx`} download="dataset-template.xlsx">{window.translate("DOWNLOAD")}</a>
                    <p>{window.translate("UPLOAD_INSTRUCTIONS_1")}</p>
                    <p>{window.translate("UPLOAD_INSTRUCTIONS_2")}</p>
                </Tab>
                <Tab label={window.translate("INTENTS")}>
                    <img alt={window.translate("INTENTS_UPLOAD_INSTRUCTIONS_0")} style={{ width: "100%" }} src={`${process.env.PUBLIC_URL}/images/dataset-upload/intents.png`} />
                    <p>{window.translate("INTENTS_UPLOAD_INSTRUCTIONS_0")}</p>
                    <UnorderedList>
                        <ListItem>Class Name: {window.translate("INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_0")}</ListItem>
                        <ListItem>Canonical: {window.translate("INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_1")}</ListItem>
                        <ListItem>Answer: {window.translate("INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_2")}</ListItem>
                        <ListItem>Is out of context: {window.translate("INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_3")}</ListItem>
                        <ListItem>Is out of training: {window.translate("INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_4")}</ListItem>
                        <ListItem>Final: {window.translate("INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_5")}</ListItem>
                        <ListItem>Class type: {window.translate("INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_6")}</ListItem>
                    </UnorderedList>
                </Tab>
                <Tab label={window.translate("EXAMPLES")}>
                    <img alt={window.translate("EXAMPLES_UPLOAD_INSTRUCTIONS_0")} style={{ width: "100%" }} src={`${process.env.PUBLIC_URL}/images/dataset-upload/examples.png`} />
                    <p>{window.translate("EXAMPLES_UPLOAD_INSTRUCTIONS_0")}</p>
                    <UnorderedList>
                        <ListItem>Example: {window.translate("EXAMPLES_UPLOAD_INSTRUCTIONS_COLUMN_0")}</ListItem>
                        <ListItem>Class Name: {window.translate("EXAMPLES_UPLOAD_INSTRUCTIONS_COLUMN_1")}</ListItem>
                    </UnorderedList>
                </Tab>
                <Tab label={window.translate("ENTITIES")}>
                    <img alt={window.translate("ENTITIES_UPLOAD_INSTRUCTIONS_0")} style={{ width: "100%" }} src={`${process.env.PUBLIC_URL}/images/dataset-upload/entities.png`} />
                    <p>{window.translate("ENTITIES_UPLOAD_INSTRUCTIONS_0")}</p>
                    <UnorderedList>
                        <ListItem>Entity: {window.translate("ENTITIES_UPLOAD_INSTRUCTIONS_COLUMN_0")}</ListItem>
                        <ListItem>Value: {window.translate("ENTITIES_UPLOAD_INSTRUCTIONS_COLUMN_1")}</ListItem>
                        <ListItem>Type: {window.translate("ENTITIES_UPLOAD_INSTRUCTIONS_COLUMN_3")}</ListItem>
                        <ListItem>Synonyms: {window.translate("ENTITIES_UPLOAD_INSTRUCTIONS_COLUMN_2")}</ListItem>
                    </UnorderedList>
                </Tab>
                <Tab label={window.translate("CONFIG")}>
                    <img alt={window.translate("CONFIG_UPLOAD_INSTRUCTIONS_0")} style={{ width: "100%" }} src={`${process.env.PUBLIC_URL}/images/dataset-upload/config.png`} />
                    <p>{window.translate("CONFIG_UPLOAD_INSTRUCTIONS_0")}</p>
                    <UnorderedList>
                        <ListItem>Welcome text: {window.translate("CONFIG_UPLOAD_INSTRUCTIONS_COLUMN_0")}</ListItem>
                        <ListItem>Anything else text: {window.translate("CONFIG_UPLOAD_INSTRUCTIONS_COLUMN_1")}</ListItem>
                        <ListItem>Response variation: {window.translate("CONFIG_UPLOAD_INSTRUCTIONS_COLUMN_2")}</ListItem>
                        <ListItem>Disambiguation question limit: {window.translate("CONFIG_UPLOAD_INSTRUCTIONS_COLUMN_3")}</ListItem>
                    </UnorderedList>
                </Tab>
            </Tabs>
        </>)
    }
}

export default withRouter(DatasetHeader)