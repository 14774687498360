import React, { Component } from 'react';
import { Tabs, Tab, CodeSnippet } from 'carbon-components-react';

class APISnippet extends Component {
    render() {
        return <>
            <h3>{this.props.title}</h3>
            <Tabs>
                <Tab label="cURL">
                    <CodeSnippet type="multi">{this.renderCurlCode()}</CodeSnippet>
                </Tab>
                <Tab label="NodeJS">
                    <CodeSnippet type="multi">{this.renderJSCode()}</CodeSnippet>
                </Tab>
                <Tab label="Python">
                    <CodeSnippet type="multi">{this.renderPythonCode()}</CodeSnippet>
                </Tab>
                <Tab label="Cobol">
                    <CodeSnippet type="multi">{this.renderCobolCode()}</CodeSnippet>
                </Tab>
            </Tabs>
        </>
    }

    renderCurlCode() {
        return `curl -X ${this.props.method} ${this.props.url} -H 'Content-type:application/json' ${["POST", "PUT"].includes(this.props.method) ? `-d '${JSON.stringify(this.props.body)}'` : ""}`
    }

    renderJSCode() {
        return `Coming soon!`
    }

    renderPythonCode() {
        return `Coming soon!`
    }

    renderCobolCode() {
        return `HAH, GOT'EM!`
    }
}

export default APISnippet
