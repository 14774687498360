import React, { Component } from 'react';
import renderHTML from 'react-render-html'
import { UnorderedList, ListItem, Accordion, AccordionItem, Loading } from 'carbon-components-react';
import { listAllIntents, getRelatedIntents } from '../../../services/corpus'
import AssistantEditor from './assistant-editor'
import { CrudTable } from '../../../components/carbon-react-crud';
import OutputVariablesEditor from './output-variables-editor';

class IntentDetail extends Component {

    state = {
        intent: {},
        intentNames: {}
    }

    render() {
        return (<>
            <Loading active={this.state.loading} />
            <p><strong>{window.translate("NAME")}: </strong>{(this.state.intent.intent ? this.state.intent.intent.name : "")}</p>
            <p><strong>{window.translate("CANONICAL")}: </strong>{(this.state.intent.intent ? this.state.intent.intent.canonical : "")}</p>
            {(this.state.intent.intent && this.state.intent.intent.type !== 4) && <p><strong>{window.translate("OWNER")}: </strong>{(this.state.intent.intent ? this.state.intent.intent.owner : "")}</p>}
            <p><strong>{window.translate("OUT_OF_TRAINING")}: </strong>{(this.state.intent.intent ? this.state.intent.intent.out_of_training ? window.translate("YES") : window.translate("NO") : "")}</p>
            <p><strong>{window.translate("OUT_OF_CONTEXT")}: </strong>{(this.state.intent.intent ? this.state.intent.intent.out_of_context ? window.translate("YES") : window.translate("NO") : "")}</p>
            <p><strong>{window.translate("FINAL")}: </strong>{(this.state.intent.intent ? this.state.intent.intent.final ? window.translate("YES") : window.translate("NO") : "")}</p>
            <p><strong>{window.translate("TYPE")}: </strong>{this.state.intent.intent && [window.translate("NORMAL"), window.translate("DISAMBIGUATION"), window.translate("PROCFLOW"), window.translate("ASSISTANT"), window.translate("TEMPLATE")][this.state.intent.intent.type] }</p>

            <br /><br />
            <Accordion>
                <AccordionItem open={false} title={window.translate("ANSWER")}>
                    <UnorderedList>
                        {this.state.intent.intent ? <>
                            {this.state.intent.intent.type <= 1 && renderHTML(this.state.intent.intent.content[0] || "")}
                            {this.state.intent.intent.type === 2 && <ProcflowVisualization value={this.state.intent.intent.content} loadIntentNames={this.loadIntentNames.bind(this)} intentNames={this.state.intentNames} />}
                            {(this.state.intent.intent.type === 3 || this.state.intent.intent.type === 4) && !console.log(this.state.intent.intent) && <div style={{ maxHeight: "80vh", overflow: "scroll" }}><AssistantEditor {...this.props} value={Array.isArray(this.state.intent.intent.content) && this.state.intent.intent.content.map(node => ({ ...node, expanded: true }))} disabled onChange={console.log} /></div>}
                        </> :
                            ""
                        }
                    </UnorderedList>
                </AccordionItem>
                {this.state.intent.intent && this.state.intent.intent.type !== 4 && <> 
                    <AccordionItem open={false} title={window.translate("EXAMPLES")}>
                        {this.state.intent.intent && <CrudTable
                            url={`/corpus/datasets/${this.state.intent.intent.dataset_id}/intents/${this.props.intentID || this.props.intent.id}/examples`}
                            headers={[{ key: "text", header: window.translate("TEXT"), sortable: true, parse: this.props.exampleParser }]}
                            searchable disableCreate disableUpdate={row => true} disableDelete={row => true}
                        />}
                    </AccordionItem>
                    <AccordionItem open={false} title={window.translate("RELATED_CLASSES")}>
                        <UnorderedList>
                            {(this.state.intent.intent ? this.state.intent.relatedIntents : []).map(intent => <ListItem>{intent.serial}: {intent.name}</ListItem>)}
                        </UnorderedList>
                    </AccordionItem>
                    {this.state.intent.intent && <OutputVariablesEditor title={window.translate("CONTEXT_VARIABLES")} value={this.state.intent.intent.context_variables} editionMode={false} />}
                    {this.state.intent.intent && <OutputVariablesEditor value={this.state.intent.intent.output_variables} editionMode={false} />}
                    {this.state.intent.intent && <OutputVariablesEditor title={window.translate("METADATA")} keyLabel="Flag" addLabel={window.translate("ADD_FLAG")} value={this.state.intent.intent.metadata} editionMode={false} />}
                </>}
            </Accordion>
        </>)
    }

    componentDidMount() {
        this.componentWillReceiveProps(this.props)
    }

    componentWillReceiveProps(props) {
        if (!this.state.intent.intent || (this.state.intent.intent.id !== (props.intentID || props.intent.id))) {
            this.loadIntentInfo(props.intentID)
        }
    }

    async loadIntentInfo(intentID) {
        try {
            this.setState({ loading: true })
            await (this.props.intent ? Promise.resolve({ rows: [this.props.intent] }) : listAllIntents({ id: intentID }))
                .then(data => data.rows[0])
                .then(intent => Promise.all([
                    getRelatedIntents(intent.dataset_id, intent.id).catch(err => ({ rows: [] })),
                    intent,
                ]))
                .then(data => ({
                    relatedIntents: data[0].rows,
                    intent: data[1]
                }))
                .then(data => {
                    this.setState({
                        intent: data,
                        loading: false
                    })
                })
        } catch (err) {
            this.setState({ loading: false })
        }
    }

    loadIntentNames(intentIDs) {
        if (!intentIDs.reduce((allLoaded, intentID) => allLoaded && intentID in this.state.intentNames, true)) {
            this.setState({ loading: true })
            Promise.all(intentIDs
                .map(intentID => listAllIntents({ id: intentID })
                    .then(data => data.rows[0])
                    .catch(err => ({ name: window.translate("INTENT_NOT_FOUND") }))
                ))
                .then(intents => {
                    let newIntentNames = intents.reduce((newIntentNames, intent) => {
                        if (!intent) return newIntentNames
                        return {
                            ...newIntentNames,
                            [intent.id]: intent.name
                        }
                    }, {})
                    this.setState({
                        intentNames: {
                            ...this.state.intentNames,
                            ...newIntentNames
                        },
                        loading: false
                    })
                })
        }

    }

}

class ProcflowVisualization extends Component {

    render() {
        return (
            <Accordion>
                <AccordionItem style={{ backgroundColor: this.props.value.color || "white" }} title={renderHTML((this.props.value.value.text && this.props.value.value.text[0]) || "")} open>
                    <Accordion>
                        {this.props.value.childs.map(option => <AccordionItem style={{ backgroundColor: option.color || "white" }} title={option.value.text && option.value.text[0]} open>
                            {option.childs[0].subtype === 'straight' && renderHTML(option.childs[0].value.text[0] || "")}
                            {option.childs[0].subtype === 'goTo' && option.childs[0].value.goTo.value in this.props.intentNames && this.props.intentNames[option.childs[0].value.goTo.value]}
                            {option.childs[0].subtype === 'disambiguation' && <ProcflowVisualization {...this.props} value={option.childs[0]} />}
                            <OutputVariablesEditor title={window.translate("CONTEXT_VARIABLES")} value={option.childs[0].value.context_variables} />
                            <OutputVariablesEditor value={option.childs[0].value.output_variables} />
                        </AccordionItem>)}
                    </Accordion>
                </AccordionItem>
            </Accordion>
        )
    }

    componentDidMount() {
        let jumpTargets = this.props.value.childs
            .filter(option => option.childs[0].subtype === 'goTo')
            .map(goToOption => goToOption.childs[0].value.goTo.value)
        this.props.loadIntentNames(jumpTargets)
    }
}

IntentDetail.defaultProps = {
    exampleParser: text => text
}

export default IntentDetail