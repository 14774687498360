import React, { Component } from 'react';
import { Tile} from 'carbon-components-react';
import MultiConditionEditor from '../../../multi-condition-editor';

class NodeConditionEditor extends Component {

    render() {
        return (<>
            <Tile>
                <h3>{window.translate("IF_ASSISTANT_RECOGNIZES")}:</h3>
                <br />
                <MultiConditionEditor
                    value={this.props.selectedNode.conditions}
                    onChange={conditions => this.props.onChange(this.props.selectedNode, { conditions })}
                    dataset_id={this.props.dataset_id}
                />
            </Tile>
        </>)
    }

}

export default NodeConditionEditor