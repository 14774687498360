const { basicServiceRequest } = require('./_basic')

let auditRequest = basicServiceRequest('/audit')

let getAuditConfig = (params) => auditRequest({ method: 'GET', url: '/audit_facts/config', params })
let getPivotDimensions = (params) => auditRequest({ method: 'GET', url: '/audit_facts/pivot_dimensions', params })
let queryAuditFacts = (params) => auditRequest({ method: 'GET', url: '/audit_facts', params })
let reportAuditFacts = (params) => auditRequest({ method: 'GET', url: '/audit_facts/report', params })
let groupAuditFactsByDate = (params) => auditRequest({ method: 'GET', url: '/audit_facts/group_by_date', params })
let groupAuditFactsByPivot = (params) => auditRequest({ method: 'GET', url: '/audit_facts/report', params })

module.exports = {
    getAuditConfig,
    getPivotDimensions,
    queryAuditFacts,
    reportAuditFacts,
    groupAuditFactsByDate,
    groupAuditFactsByPivot,
}