import React, { Component } from 'react';
import { Button } from 'carbon-components-react';
import { RecordPicker } from '../../../../components/carbon-react-crud';
import { listAllAssistantSkills } from '../../../../services/orchestrator';
import { listAllIntents } from '../../../../services/corpus';

class DialogToolbar extends Component {

    render() {
        return (<div className="DialogToolbar">
            {this.props.nodePickerOn ?
                <>
                    <Button kind="danger" onClick={() => this.props.onCancel()}>{window.translate("CANCEL")}</Button>
                    <Button kind="secondary" onClick={() => this.props.onChooseIntent()}>Outra intenção</Button>
                </> :
                <>
                    <Button onClick={() => this.props.onAddNode(this.props.selectedNode || {}, "below")}>{window.translate("ADD_NODE")}</Button>
                    <Button disabled={!this.props.selectedNode} kind="secondary" onClick={() => this.props.onAddNode(this.props.selectedNode, "child")}>{window.translate("ADD_CHILD_NODE")}</Button>
                    <Button onClick={() => this.props.onAddNode(this.props.selectedNode || {}, "below", { type: "folder", output: null })} kind="secondary">{window.translate("ADD_FOLDER")}</Button>
                    <div style={{ marginLeft: "1rem" }}>
                        <RecordPicker label="Copy from" disabled={!this.props.intent_id} fetchOptions={(url, params) => listAllAssistantSkills({ ...params, dataset_id: this.props.dataset_id })} onChange={id => id && this.props.onCopyFromWA(id)} />
                    </div>
                    {(this.props.intent_type === "3" || this.props.intent_type === 3) && 
                        <div style={{ marginLeft: "1rem" }}>
                            <RecordPicker label={window.translate("LOAD_TEMPLATE")} fetchOptions={(url, params) => listAllIntents({ ...params, type: 4 })} onChange={id => id && this.props.onCopyFromTemplate(id)} />
                        </div>                        
                    }
                </>}
        </div>)
    }
}

export default DialogToolbar