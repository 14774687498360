import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { DataTable, Loading, Modal, Button } from 'carbon-components-react'
import { iconArrowRight } from 'carbon-icons'
import CommonPage from './common-page'
import { RecordPicker } from '../components/carbon-react-crud'
import axios from 'axios'

class DualPage extends Component {

    routeMap = {
        "assistant_instances": "orchestrator",
        "assistant_skills": "orchestrator",
        "discovery_instances": "orchestrator",
        "discovery_projects": "orchestrator",
        "discovery_collections": "orchestrator",
        "orchestrators": "orchestrator",
        "datasets": "corpus",
        "knowledge_bases": "documents",
        "users": "auth",
        "audit_facts": "audit",
    }

    state = {
        selection: this.props.initialSelection(this.props.match.params),
        targetRow: null,
        dangerModalOpen: false,
        loading: false,
    }

    ref1 = React.createRef()
    ref2 = React.createRef()

    render() {
        return <>
            <Loading active={this.state.loading} />
            {this.props.header && <this.props.header {...this.props} />}
            {this.props.radio && this.props.actions.map(action =>
                <Button
                    style={{ marginLeft: "1rem" }}
                    disabled={!this.state.sourceRow || !this.state.targetRow}
                    onClick={ev => {
                        this.setState({
                            dangerModalOpen: true,
                            dangerFunc: () => {
                                this.setState({ loading: true })
                                action.func(this.props.match.params, this.state.sourceRow, this.state.targetRow)
                                    .then(() => { this.ref1.current.handleRefresh(); this.ref2.current.handleRefresh() })
                                    .catch(err => this.props.handleNotification("error", window.translate("ERROR_CREATING_RESOURCE"), (this.props.customMessage && this.props.customMessage[err.message] ? this.props.customMessage[err.message] : (err.message && window.translate(err.message)))))
                                    .then(() => this.setState({ loading: false, dangerModalOpen: false }))
                            }
                        })
                    }}
                >
                    {window.translate(action.label)}
                </Button>
            )}
            <div style={{ display: "flex" }}>
                <div style={{ width: "50%", margin: "10px" }}>
                    <CommonPage
                        ref={this.ref1}
                        {...this.props}
                        url={this.props.model1.url}
                        header={null}
                        radio={this.props.radio}
                        pathname={this.props.path1(this.props.match.params)}
                        model={this.props.model1}
                        onSelectRow={sourceRow => this.setState({ sourceRow })}
                        batchActions={(selectedRows) => <>
                            {this.state.targetRow && this.props.actions.map(action => <DataTable.TableBatchAction
                                onClick={ev => {
                                    this.setState({
                                        dangerModalOpen: true,
                                        selectedRows,
                                        dangerFunc: () => {
                                            this.setState({ loading: true })
                                            Promise.all(selectedRows.map(row => action.func(this.props.match.params, row, this.state.targetRow)))
                                                .then(() => { this.ref1.current.handleRefresh(); this.ref2.current.handleRefresh() })
                                                .catch(err => this.props.handleNotification("error", window.translate("ERROR_CREATING_RESOURCE"), (this.props.customMessage && this.props.customMessage[err.message] ? this.props.customMessage[err.message] : (err.message && window.translate(err.message)))))
                                                .then(() => this.setState({ loading: false, dangerModalOpen: false }))
                                        }
                                    })
                                }}
                                icon={iconArrowRight}
                            >
                                {window.translate(action.label)}
                            </DataTable.TableBatchAction>)}
                        </>}
                    />
                </div>
                <div style={{ width: "50%", margin: "10px" }}>
                    <RecordPicker
                        label={window.translate("DATASET")}
                        id="select-1"
                        value={this.state.targetRow}
                        onChange={value => this.setState({ selection: value }, () => value && this.ref2.current.handleRefresh())}
                        fetchOptions={this.fetchOptions.bind(this)}
                    />
                    <br /><br />
                    <CommonPage ref={this.ref2} onSelectRow={targetRow => this.setState({ targetRow })} {...this.props} header={null} radio={true} pathname={this.props.path2(this.props.match.params, this.state.selection)} model={this.props.model2} />
                </div >
            </div >
            {this.state.dangerModalOpen && setTimeout(() => window.document.body.scrollTo(0, 0), 10) && setTimeout(() => window.scrollTo(0, 0), 20) && <style dangerouslySetInnerHTML={{ __html: `html, body {margin: 0; height: 100%; overflow: hidden}` }}></style>}
            <Modal
                open={this.state.dangerModalOpen}
                primaryButtonText={window.translate("YES")}
                secondaryButtonText={window.translate("NO")}
                danger={true}
                modalHeading={window.translate("ARE_YOU_SURE")}
                onRequestSubmit={ev => this.state.dangerFunc()}
                onRequestClose={ev => this.setState({ dangerModalOpen: false })}
            >
                {this.props.dangerModalContent && this.props.dangerModalContent(this)}
            </Modal>
        </>
    }

    componentDidMount() {
        this.setState({ selection: this.props.initialSelection(this.props.match.params) })
    }

    fetchOptions(url, params) {
        let parts = this.props.path2(this.props.match.params, this.state.selection).split('/').slice(0, -2)
        let path = `${process.env.REACT_APP_PATH || ""}/${this.routeMap[parts[1]]}${parts.join('/')}`
        return axios({ url: path, params }).then(response => response.data)
    }
}

export default withRouter(DualPage)