import React, { Component } from 'react';
import { TextArea, Button, Icon, Select, SelectItem, Tile, OverflowMenu, OverflowMenuItem, Link } from 'carbon-components-react';
import { iconAddOutline, iconDelete, iconChevronUp, iconChevronDown, iconSubtractGlyph } from 'carbon-icons'
import ContextEditor from '../../../context-editor';
import { Editor } from '@tinymce/tinymce-react'
import { JSONEditor } from '../../../../../../components/carbon-react-crud';
import validateDialogNodeJSON from '../../../../../../utils/validate-dialog-node-json';
import tinymce from '../../../../../../utils/tinymce';

class OutputEditor extends Component {

    state = {
        outputEditor: false,
        jsonEditor: false,
        contextEditor: false,
        htmlEditor: false
    }

    render() {
        return (<Tile>
            {!this.props.outputOnly && <>
                <div style={{ display: "flex" }}>
                    <h3 style={{ flex: "1 1", margin: "10px" }}>{this.state.contextEditor ? window.translate("THEN_SET_CONTEXT") : window.translate("ASSISTANT_RESPONDS")}</h3>
                    <OverflowMenu flipped={true} >
                        {!this.state.jsonEditor && <OverflowMenuItem itemText="Open JSON editor" onClick={ev => this.setState({ jsonEditor: true, contextEditor: false })} />}
                        {this.state.jsonEditor && <OverflowMenuItem itemText="Close JSON editor" onClick={ev => this.setState({ jsonEditor: false, contextEditor: true })} />}
                        {!this.state.contextEditor && <OverflowMenuItem itemText="Open context editor" onClick={ev => this.setState({ jsonEditor: false, contextEditor: true })} />}
                        {this.state.contextEditor && <OverflowMenuItem itemText="Close context editor" onClick={ev => this.setState({ contextEditor: false })} />}
                    </OverflowMenu>
                </div>
                {this.state.contextEditor && <ContextEditor value={this.props.selectedNode.context} onChange={context => this.props.onChange(this.props.selectedNode, { context })} />}
                {this.state.contextEditor && <h3>{window.translate("ASSISTANT_RESPONDS")}</h3>}
                {this.state.jsonEditor && <JSONEditor validate={validateDialogNodeJSON} value={{ context: this.props.selectedNode.context, output: this.props.selectedNode.output, actions: this.props.selectedNode.actions }} onChange={payload => this.props.onChange(this.props.selectedNode, { context: undefined, output: {}, actions: undefined, ...payload })} />}
            </>}
            {!this.state.jsonEditor && <>
                {this.getGeneric().map((output, i) => <div key={`text-editor-${i}`}>
                    <hr />
                    <div style={{ float: "left" }}>
                        <Select style={{ float: "right" }} labelText="" value={output.response_type} onChange={ev => this.handleEditResponseType(ev.target.value, i)}>
                            <SelectItem label={window.translate("TEXT")} value="text" />
                            {/* <SelectItem label={window.translate("OPTION")} value="option" />
                            <SelectItem label={window.translate("PAUSE")} value="pause" />
                            <SelectItem label={window.translate("IMAGE")} value="image" />
                            <SelectItem label={window.translate("SEARCH_SKILL")} value="search" />
                            <SelectItem label={window.translate("CONNECT_TO_HUMAN_AGENT")} value="agent" /> */}
                        </Select>
                    </div>
                    <br />
                    <div style={{ position: "absolute", right: 0 }}>
                        <Button kind="secondary" className="btn-icon-only" onClick={(ev) => this.handleMoveResponseUp(i)} ><Icon icon={iconChevronUp} /></Button>
                        <Button kind="secondary" className="btn-icon-only" onClick={(ev) => this.handleMoveResponseDown(i)} ><Icon icon={iconChevronDown} /></Button>
                        <Button kind="secondary" className="btn-icon-only" onClick={(ev) => this.handleRemoveResponse(i)} ><Icon icon={iconDelete} /></Button>
                    </div>
                    <br /><br /><br />
                    {output.response_type === "text" && [...output.values, { text: "" }].map((v, j) => <>
                        <div style={{ display: "flex" }}>
                            {this.state.htmlEditor ? <Editor
                                apiKey={tinymce.apiKey}
                                init={{
                                    plugins: 'link image code emoticons wordcount table',
                                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code | emoticons',
                                    language: navigator.language.replace('-', '_'),
                                    language_url: `${(process.env.REACT_APP_PATH || "")}/languages/${navigator.language.replace('-', '_')}.js`,
                                }}
                                value={v.text}
                                onEditorChange={value => this.handleEditTextResponse(value, i, j)}
                            /> :
                                <TextArea rows={1} value={v.text} onChange={ev => this.handleEditTextResponse(ev.target.value, i, j)} placeholder={window.translate("ENTER_RESPONSE_TEXT")}
                                    onKeyPress={ev => ev.key === 'Enter' ? ev.target.blur() : null}
                                />
                            }
                            {<Button disabled={j === output.values.length} kind="secondary" className="btn-icon-only" onClick={(ev) => this.handleRemoveTextResponse(i, j)} ><Icon icon={iconSubtractGlyph} /></Button>}
                        </div>
                        <br />
                    </>)}
                    <Link style={{ cursor: "pointer" }} onClick={ev => this.setState({ htmlEditor: !this.state.htmlEditor })}>{this.state.htmlEditor ? "Text editor" : "HTML editor"}</Link>
                    <br /><br />
                    {window.translate("RESPONSE_VARIATIONS_SET_TO")} {window.translate(output.selection_policy)}. {window.translate("SET_TO")}
                    {output.selection_policy !== "sequential" && <><Link style={{ cursor: "pointer" }} onClick={ev => this.handleEditResponse({ ...output, selection_policy: "sequential" }, i)}> {window.translate("SEQUENTIAL")}</Link> |</>}
                    {output.selection_policy !== "random" && <><Link style={{ cursor: "pointer" }} onClick={ev => this.handleEditResponse({ ...output, selection_policy: "random" }, i)}> {window.translate("RANDOM")}</Link> |</>}
                    {output.selection_policy !== "multiline" && <><Link style={{ cursor: "pointer" }} onClick={ev => this.handleEditResponse({ ...output, selection_policy: "multiline" }, i)}> {window.translate("MULTILINE")}</Link></>}
                    <br />
                    <Link style={{ cursor: "pointer" }} target="_blank" href="https://assistant-us-south.watsonplatform.net/docs?topic=assistant-dialog-overview#dialog-overview-variety">Learn more</Link>
                </div>)}
                <br />
                <Button kind="secondary" style={{ borderWidth: 0 }} onClick={() => this.handleAddResponse()}>Add response type <Icon icon={iconAddOutline} /></Button>
            </>}
        </Tile>)
    }

    getGeneric() {
        return (this.props.selectedNode.output && this.props.selectedNode.output.generic) || []
    }

    handleAddResponse() {
        let generic = [
            ...this.getGeneric(),
            { response_type: "text", values: [], selection_policy: "sequential" }
        ]
        this._updateOutputGeneric(generic)
    }

    handleEditResponse(response, index) {
        let generic = this.getGeneric().map((g, i) => i === index ? response : g)
        this._updateOutputGeneric(generic)
    }

    handleMoveResponseUp(index) {
        if (index === 0) return
        let generic = this.getGeneric().map((g, i) => i === index ? this.getGeneric()[i - 1] : i === index - 1 ? this.getGeneric()[index] : g)
        this._updateOutputGeneric(generic)
    }

    handleMoveResponseDown(index) {
        if (index === this.getGeneric().length - 1) return
        let generic = this.getGeneric().map((g, i) => i === index ? this.getGeneric()[i + 1] : i === index + 1 ? this.getGeneric()[index] : g)
        this._updateOutputGeneric(generic)
    }

    handleRemoveResponse(index) {
        let generic = this.getGeneric().filter((_, i) => index !== i)
        this._updateOutputGeneric(generic)
    }

    handleEditResponseType(type, index) {
        let generic = this.getGeneric().map((g, i) => index === i ? { ...g, response_type: type } : g)
        this._updateOutputGeneric(generic)
    }

    handleEditTextResponse(value, index, textIndex) {
        if (textIndex === this.getGeneric()[index].values.length)
            this.getGeneric()[index].values.push({ text: "" })
        let generic = this.getGeneric()
            .map((g, i) => index === i ? {
                ...g,
                values: this.getGeneric()[index].values
                    .map((t, j) => textIndex === j ? { text: value } : t)
            } : g)
        this._updateOutputGeneric(generic)
    }

    handleRemoveTextResponse(index, textIndex) {
        this.getGeneric()[index].values = [
            ...this.getGeneric()[index].values.slice(0, textIndex),
            ...this.getGeneric()[index].values.slice(textIndex + 1)
        ]
        this._updateOutputGeneric(this.getGeneric())
    }

    _updateOutputGeneric(generic) {
        let newValue = { ...this.props.selectedNode, output: { ...this.props.selectedNode.output, generic } }
        this.props.onChange(this.props.selectedNode, newValue)
    }

}

export default OutputEditor