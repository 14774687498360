module.exports = {
    "ACCURACY_VS_COVERAGE": "Accuracy VS coverage",
    "COVERAGE": "Coverage",
    "DIGRESSION_RETURN_TEXT": "Digression return text",
    "SEARCH_ALL_FIELDS": "Search all fields",
    "AFTER": "After",
    "BEFORE": "Before",
    "FLAGS": "Flags",
    "PROCFLOW REQUIRES AT LEAST ONE CHILD": "Procflow requires at least one child",
    "PROCFLOW OPTIONS SHOULD HAVE A SINGLE CHILD": "Procflow options should have a single child",
    "RESPONSE SHOULD BE A NON-EMPTY LIST": "Response should be a non-empty list",
    "INVALID GO-TO DETECTED": "Invalid go-to detected",
    "PROCFLOW QUESTION SHOULD BE A NON-EMPTY LIST": "Procflow question should be a non-empty list",
    "DIGRESSION_RETURN": "Digression return",
    "NO_QUESTION": "No disambiguation question",
    "WORD_CLOUD": "Word cloud",
    "AVERAGE": "Average",
    "REORDER": "Reorder",
    "SNAPSHOT": "Snapshot",
    "AUDIT_MAX_RANGE_MESSAGE": "Please select an interval of up to 90 days to export and search",
    "TRAINING": "Training",
    "FINISHED": "Finished",
    "FAILED": "Failed",
    "INDEXED": "Indexed",
    "DEINDEXED": "Deindexed",
    "ORCHESTRATOR_ID": "Orchestrator",
    "CREATED_BETWEEN": "Created between",
    "UPDATED_BETWEEN": "Updated between",
    "CONFIRM": "Confirm",
    "TRANSFER": "Transfer",
    "COPY": "Copy",
    "EXPERIMENT_ID": "Experiment ID",
    "PREDICTION_ID": "Prediction ID",
    "INCLUDE_INTENTS": "Include intents",
    "INCLUDE_ENTITIES": "Include entities",
    "INCLUDE_DIALOG": "Include dialog",
    "TOTAL_BY": "Total by",
    "DATE": "Date",
    "COMPARISON": "Comparison",
    "RAW": "Raw",
    "WHEN_USER_RESPONDS_IF": "When user responds, if intent or entity is",
    "FOUND": "Found",
    "NOT_FOUND": "Not found",
    "IMPORT": "Import",
    "EXAMPLE_ID": "Example ID",
    "SHOW_ALL_FIELDS": "Show all fields",
    "RESOURCE_MISSING": "Resource missing",
    "REVIEW_EXAMPLE_ID": "Review example ID",
    "ALL_EVENTS": "All events",
    "VIEW_ALL_EVENTS": "View all events",
    "EVENTS_IN": "Events in",
    "EVENTS_FOR": "Events for",
    "VIEW_EVENTS_IN_THIS_RESOURCE": "View events in this resource",
    "VIEW_EVENTS_TARGETING": "View events targeting this resource",
    "VIEW_EVENTS_IN": "View events in this",
    "RESOURCE": "Resource",
    "RESOURCE_BEFORE": "Resource before",
    "RESOURCE_AFTER": "Resource after",
    " FACT": " Fact",
    " DATE": " Date",
    "ASSISTANT_INSTANCE_ID": "Assistant instance ID",
    "ASSISTANT_SKILL_ID": "Assistant Skill ID",
    "RECOMMENDATION_ID": "Recommendation ID",
    "EVALUATION_ID": "Evaluation ID",
    "ENTITY_VALUE_ID": "Entity value ID",
    "ENTITY_ID": "Entity ID",
    "DATASET_ID": "Dataset ID",
    "START_DATE": "Start date",
    "END_DATE": "End date",
    "ACTION_TYPE": "Action type",
    "ENABLE_FILTERS": "Enable filters",
    "RETRAIN_DIALOG": "Retrain dialog",
    "NODES_IN_THIS_FOLDER": "Nodes in this folder",
    "CHILDREN": "Children",
    "TARGET": "Target",
    "DIGRESSIONS_CAN_GO_AWAY": "Digressions can go away from this node",
    "DIGRESSIONS_CAN_COME_IN": "Digressions can come into this node",
    "DIGRESSIONS_CANNOT_GO_AWAY": "Digressions cannot go away from this node",
    "DIGRESSIONS_CANNOT_COME_IN": "Digressions cannot come into this node",
    "DIGRESSION_NO_CHILDREN_TITLE": "This node has no children.",
    "DIGRESSION_NO_CHILDREN_TEXT": "Digressions are not possible if a node has no children as the flow has already ended.",
    "DIGRESSION_JUMP_TITLE": "Jump to blocks digressions after this node’s response.",
    "DIGRESSION_JUMP_TEXT": "This node is configured to jump to another node or skip user input after it is processed. This will always trigger before digression occurs.",
    "DIGRESSION_OUT_SLOTS_TITLE": "Allow digressions away while slot filling.",
    "DIGRESSION_OUT_SLOTS_TEXT": "Users can divert the conversation away from this node in the middle of processing slots.",
    "DIGRESSION_OUT_SLOTS_RETURN_LABEL": "Only digress from slots to nodes that allow returns",
    "DIGRESSION_OUT_SLOTS_RETURN_TEXT": "If a user goes off topic, only nodes with digressions that allow returns will be considered.",
    "DIGRESSION_OUT_RETURN_TEXT": "Allow return from digressions triggered after this node’s response.",
    "DIGRESSION_IN_TITLE": "This node cannot be digressed directly into.",
    "DIGRESSION_IN_TEXT": "Digressions into a node are only allowed for nodes at the root level. This node can still be reached as part of a digression it will just have to go through a parent node at the root level that has been digressed into.",
    "INVALID_TARGET": "Alvo inválido",
    "RECOMMENDATION": "Recommendation",
    "EVALUATION": "Evaluation",
    "DUPLICATE_CONTEXT_VARIABLE_NOT_SAVED": "Duplicate context variable not saved",
    "MANDATORY_DISAMBIGUATION": "Mandatory disambiguation",
    "IF_NO_SLOTS_ARE_PREFILLED_ASK": "If no slots are pre-filled, ask this first:",
    "ADD_SLOT": "Add slot",
    "EVALUATION_HELP_0": "Evaluations are tools for analyzing datasets that allow optimization of their content.",
    "EVALUATION_HELP_1": "When an evaluation is executed, recommendations are generated to delete examples, based on statistical analysis of the data.",
    "EVALUATION_HELP_2": "The following types of evaluation are available:",
    "REDUNDANCY_DETECTION_DESCRIPTION": "Detects and suggests deletion of similar examples.",
    "UNDERSAMPLING_DESCRIPTION": "Detects classes with a high number of examples, and suggests deletions.",
    "OUTLIER_DETECTION_DESCRIPTION": "Detects and suggests deletion of examples which are much larger than average.",
    "RECOMMENDATION_HELP_0": "Recommendations are the result of an evaluation, and represent actions which could be taken based on the analysis.",
    "RECOMMENDATION_HELP_1": "Open the details tab of each recommendations to view and edit the proposed actions.",
    "RECOMMENDATION_HELP_2": "When a recommendation is deemed reasonable, click \"Execute\" to apply it to the dataset.",
    "RECOMMENDATION_HELP_3": "Otherwise, click \"Delete\" to remove it from the list.",
    "EVALUATION_NAME_DESCRIPTION": "The evaluation's name.",
    "EVALUATION_LANGUAGE_DESCRIPTION": "The language to use in this evaluation.",
    "EVALUATION_USE_REVIEW_DESCRIPTION": "Whether to analyze review examples instead of mapped examples.",
    "EVALUATION_MIN_DISTANCE_DESCRIPTION": "Minimum distance for examples to be considered different. Higher values will consider more examples redundant.",
    "EVALUATION_MAX_SAMPLES_DESCRIPTION": "Maximum class size deemed reasonable for this dataset.",
    "EVALUATION_DEVIATIONS_DESCRIPTION": "Number of standard deviations from the mean for an example to be considered an outlier. Higher values will select larger examples.",
    "HELP": "Help",
    "USER_NAME_DESCRIPTION": "The user's name.",
    "USER_USERNAME_DESCRIPTION": "The username to be used to log in.",
    "USER_PASSWORD_DESCRIPTION": "The user's password.",
    "USER_ACTIVE_DESCRIPTION": "Whether the user is active. Unactive users cannot log in.",
    "USER_ADMIN_DESCRIPTION": "Whether the user is a platform-wide administrador. Administrators have full access to all resources.",
    "USER_AUDITOR_DESCRIPTION": "Whether the user is a platform-wide auditor. Auditors can generate usage reports about all resources they have viewer access to.",
    "USER_LOGIN_ATTEMPTS_DESCRIPTION": "How many failed login attempts this user has. If higher than 3, user is blocked.",
    "USER_CONFIRM_PASSWORD_DESCRIPTION": "Please confirm your own password to perform this action, for security purposes.",
    "REMAINING_EXAMPLES_TO_DELETE": "Remaining examples to delete",
    "DELETE_REVIEW_EXAMPLES": "Delete review examples",
    "USE_REVIEW": "Use review examples",
    "OUTLIER_DETECTION": "Outlier detection",
    "DEVIATIONS": "Standard deviations",
    "MAX_SAMPLES": "Maximum samples",
    "DELETE_EXAMPLES": "Delete examples",
    "UNDERSAMPLING": "Undersampling",
    "KEEP_ONE_EXAMPLE": "Keep one example",
    "EXECUTE": "Execute",
    "EVALUATIONS": "Evaluations",
    "NEW_EVALUATIONS": "New evaluation",
    "RECOMMENDATIONS": "Recommendations",
    "MIN_DISTANCE": "Minimum distance",
    "REDUNDANCY_DETECTION": "Redundancy detection",
    "RESPONSE_VARIATIONS_SET_TO": "Response variations are set to",
    "SET_TO": "Set to",
    "MULTILINE": "Multiline",
    "MANAGE_CONTEXT": "Manage context",
    "VIEW_OUTPUT": "View output",
    "MANAGE_HANDLERS_FOR": "Manage handlers for",
    "MANAGE_HANDLERS": "Manage handlers",
    "HANDLERS_DESCRIPTION_0": "Handlers are how your bot will respond when the users answer to a prompt is not found. These handlers will be checked before trying the \"Not found\" responses in a slot.",
    "HANDLERS_DESCRIPTION_1": "If answer to any prompt is not found and",
    "WELCOME_CONDITION_DESCRIPTION": "triggers when a conversation is started by the system",
    "ANYTHING_ELSE_CONDITION_DESCRIPTION": "triggers when the user input does not match",
    "CUSTOMIZE": "Customize",
    "PROMPT_FOR_EVERYTHING": "Prompt for everything",
    "CUSTOMIZE_NODE": "Customize node",
    "SLOTS": "Slots",
    "MULTI_CONDITIONED_RESPONSES": "Multiple conditioned responses",
    "DIGRESSIONS": "Digressions",
    "SLOTS_ENABLE_DESCRIPTION": "Enable this to gather the information your bot needs to respond to a user within a single node.",
    "PROMPT_EVERYTHING_DESCRIPTION": "Enable this to ask for multiple pieces of information in a single prompt, so your user can provide them all at once and not be prompted for them one at a time.",
    "MULTI_RESPONSE_DESCRIPTION": "Enable multiple responses so that your bot can provide different responses to the same input, based on other conditions.",
    "DEFAULT": "Default",
    "PROMPT_AGAIN": "Prompt again",
    "SKIP_CURRENT_SLOT": "Skip current slot",
    "SKIP_TO_RESPONSE": "Skip to response",
    "CLEAR_SLOT_AND_PROMPT_AGAIN": "Clear slot and prompt again",
    "MOVE_ON": "Move on",
    "ENABLE_THIS_SLOT_IF": "Enable slot if",
    "CONFIGURE_SLOT": "Configure slot",
    "CHECK_FOR": "Check for",
    "SAVE_IT_AS": "Save it as",
    "IF_NOT_PRESENT_ASK": "If not present, ask",
    "THEN_CHECK_FOR": "Then check for",
    "CONFIGURE_RESPONSE": "Configure response",
    "EVALUATE_CONDITION": "Evaluate condition",
    "EVALUATE_RESPONSES": "Evaluate responses",
    "SKIP_USER_INPUT": "Skip user input",
    "ADMIN_DESCRIPTION": "Admins may execute any action on the resource, including deleting it and issuing new permissions.",
    "AUDITOR_DESCRIPTION": "Auditors, in addition to editor actions, may generate and view the resource's performance and usage reports.",
    "EDITOR_DESCRIPTION": "Editors may edit details of the resource, as well as create, edit and delete its dependent resources.",
    "VIEWER_DESCRIPTION": "Viewers may only view the resource.",
    "NO_ROLE_DESCRIPTION": "Select a role to view its attributes.",
    "MEANING_OF_ROLES": "What does this role mean?",
    "MAPPING_EXAMPLES": "Mapping examples",
    "INTO_INTENT": "Into intent",
    "INTENT_NAME": "Intent name",
    "INTENT_NOT_FOUND": "Intent not found",
    "EXPERIMENT": "Experiment",
    "INTENT_TO_KEEP": "Intent to keep",
    "ALL": "All",
    "CORRECT": "Correct",
    "INCORRECT": "Incorrect",
    "NONE": "None",
    "FEEDBACK_NOT_FOUND": "Feedback not found",
    "EXAMPLE ALREADY EXISTS IN THIS DATASET": "Example already exists in this dataset",
    "DISCOVERY_COLLECTION_ID": "Discovery collection ID",
    "DISCOVERY_PROJECT_ID": "Discovery project ID",
    "TRAINING_DATA_ID": "Training data ID",
    "HISTORY_DISAMBIGUATION_FLOW": "History disambiguation flow",
    "ASSISTANTSKILL": "Assistant Skill",
    "ASSISTANTINSTANCE": "Assistant Instance",
    "NO_EVENTS_FOR_RESOURCE_TYPE": "No events found for this resource and resource subtype combination.",
    "NO_EVENTS_FOR_RESOURCE": "No events found for this resource.",
    "GROUP_BY": "Group results by",
    "IN_DATE_RANGE": "In date range",
    "EVENTS_OF_TYPE": "Limited to events of type",
    "AUDIT_INSTRUCTIONS": "Select query paramenters. The text below will reflect its meaning.",
    "SEARCH_EVENTS_RELATED_TO": "Search events related to",
    "INPUT": "Input",
    "CONVERSATION_ID": "Conversation ID",
    "MESSAGE": "Message",
    "GENERATE": "Generate",
    "CONFIG": "Configuration",
    "ACTIVE": "Active",
    "DISAMBIGUATION_OR_PROCFLOW_INTENT": "(Disambiguation or procflow intent)",
    "NO_AUDIT_FACTS_FOUND": "Sorry! No audit facts found for this query.",
    "WELCOME": "Welcome",
    "LOGOUT": "Log out",
    "TRAIN_SIZE": "Train size",
    "THEN_SET_CONTEXT": "Then set context",
    "UPLOAD_INSTRUCTIONS": "Upload instructions",
    "UPLOAD_INSTRUCTIONS_0": "The workbook is composed of four sheets: \"Classes\" (intents), \"Examples\", \"Entities\" and \"Config\". Check ou the correct format by donwloading a template in the link below.",
    "UPLOAD_INSTRUCTIONS_1": "Sheets are identified by name, and columns by order. For that reason, do not edit this information. The absence of a sheet will not impact the process.",
    "UPLOAD_INSTRUCTIONS_2": "Check out the next tabs for specific information on the format of each sheet.",
    "INTENTS_UPLOAD_INSTRUCTIONS_0": "The intents sheet should contain the following columns:",
    "INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_0": "A descriptive name.",
    "INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_1": "The canonical question",
    "INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_2": "The answer. For normal and disambiguation answers, this should be an array of strings. Other class types should be of type JSON, but direct edition of those is not supported.",
    "INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_3": "Whether the class is out of context. Should be either 0 or 1.",
    "INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_4": "Whether the class is out of training. Should be either 0 or 1.",
    "INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_5": "Whether the class is answer-only. Should be either 0 or 1.",
    "INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_6": "The class type. Should be 0 (normal), 1 (disambiguation) or 2 (procflow)",
    "EXAMPLES_UPLOAD_INSTRUCTIONS_0": "The examples sheet should contain the following columns:",
    "EXAMPLES_UPLOAD_INSTRUCTIONS_COLUMN_0": "The example to be registered.",
    "EXAMPLES_UPLOAD_INSTRUCTIONS_COLUMN_1": "The name of the class the example belongs to",
    "ENTITIES_UPLOAD_INSTRUCTIONS_0": "The entities sheet should contain the following columns:",
    "ENTITIES_UPLOAD_INSTRUCTIONS_COLUMN_0": "The entitie's name.",
    "ENTITIES_UPLOAD_INSTRUCTIONS_COLUMN_1": "The entity value's name.",
    "ENTITIES_UPLOAD_INSTRUCTIONS_COLUMN_2": "The value's synonyms. Should be an array of strings.",
    "ENTITIES_UPLOAD_INSTRUCTIONS_COLUMN_3": "The value's type (Synonyms or Patterns).",
    "CONFIG_UPLOAD_INSTRUCTIONS_0": "The config sheet should contain the following columns:",
    "CONFIG_UPLOAD_INSTRUCTIONS_COLUMN_0": "Text to be sent in the beginning of the conversation.",
    "CONFIG_UPLOAD_INSTRUCTIONS_COLUMN_1": "Text to be sent when the assistant does not understand a question.",
    "CONFIG_UPLOAD_INSTRUCTIONS_COLUMN_2": "The type of response variation. Should be \"sequential\" or \"random\".",
    "CONFIG_UPLOAD_INSTRUCTIONS_COLUMN_3": "Maximum number of disambiguation questions the assistant may attempt before saying it did not understand a question.",
    "CANCEL": "Cancel",
    "SAVE": "Save",
    "CLOSE": "Close",
    "INTENTS SPREADSHEET SHOULD HAVE 7 COLUMNS": "Examples spreadsheet should have 2 columns",
    "EXAMPLES SPREADSHEET SHOULD HAVE 2 COLUMNS": "Examples spreadsheet should have 2 columns",
    "ENTITIES SPREADSHEET SHOULD HAVE 3 COLUMNS": "Examples spreadsheet should have 2 columns",
    "CONFIG SPREADSHEET SHOULD HAVE 4 COLUMNS": "Examples spreadsheet should have 2 columns",
    "CONFIG SPREADSHEET SHOULD HAVE 2 ROWS": "Examples spreadsheet should have 2 columns",
    "AT_ROW": "at row",
    "ERROR_UPLOADING_FILE": "Error uploading file",
    "TOP_INTENTS": "Top intents",
    "MERGE_LEFT": "Merge left",
    "MERGE_RIGHT": "Merge right",
    "SERIAL": "ID",
    "PROVIDED PARAMETER 'NAME' IS INVALID": "Provided parameter 'name' is invalid",
    "REQUIRED PARAMETER 'NAME' IS MISSING": "Required parameter 'name' is missing",
    "PROVIDED PARAMETER 'OWNER' IS INVALID": "Provided parameter 'owner' is invalid",
    "REQUIRED PARAMETER 'OWNER' IS MISSING": "Required parameter 'owner' is missing",
    "PROVIDED PARAMETER 'USERNAME' IS INVALID": "Provided parameter 'username' is invalid",
    "REQUIRED PARAMETER 'USERNAME' IS MISSING": "Required parameter 'username' is missing",
    "PROVIDED PARAMETER 'PASSWORD' IS INVALID": "Provided parameter 'password' is invalid",
    "REQUIRED PARAMETER 'PASSWORD' IS MISSING": "Required parameter 'password' is missing",
    "PROVIDED PARAMETER 'NEW_PASSWORD' IS INVALID": "Provided parameter 'new password' is invalid",
    "REQUIRED PARAMETER 'NEW_PASSWORD' IS MISSING": "Required parameter 'new password' is missing",
    "PROVIDED PARAMETER 'URL' IS INVALID": "Provided parameter 'url' is invalid",
    "REQUIRED PARAMETER 'URL' IS MISSING": "Required parameter 'url' is missing",
    "ASSISTANT INSTANCE IS NOT VALID, CHECK YOUR CREDENTIALS": "Assistant instance is not valid, check your credentials",
    "DISCOVERY INSTANCE IS NOT VALID, CHECK YOUR CREDENTIALS": "Discovery instance is not valid, check your credentials",
    "PROVIDED PARAMETER 'ENVIRONMENT_ID' IS INVALID": "Provided parameter 'environment ID' is invalid",
    "REQUIRED PARAMETER 'ENVIRONMENT_ID' IS MISSING": "Required parameter 'environment ID' is missing",
    "PROVIDED PARAMETER 'CONTEXT_VARIABLES' IS INVALID": "Provided parameter 'context variables' is invalid",
    "PROVIDED PARAMETER 'OUTPUT_VARIABLES' IS INVALID": "Provided parameter 'output variables' is invalid",
    "PROVIDED PARAMETER 'LANGUAGE' IS INVALID": "Provided parameter 'language' is invalid",
    "REQUIRED PARAMETER 'LANGUAGE' IS MISSING": "Required parameter 'language' is missing",
    "PROVIDED PARAMETER 'KNOWLEDGE_BASE_ID' IS INVALID": "Provided parameter 'knowledge base' is invalid",
    "REQUIRED PARAMETER 'KNOWLEDGE_BASE_ID' IS MISSING": "Required parameter 'knowledge base' is missing",
    "PROVIDED PARAMETER 'DATASET_ID' IS INVALID": "Provided parameter 'dataset' is invalid",
    "REQUIRED PARAMETER 'DATASET_ID' IS MISSING": "Required parameter 'dataset' is missing",
    "PROVIDED PARAMETER 'WORKSPACE_ID' IS INVALID": "Provided parameter 'workspace ID' is invalid",
    "REQUIRED PARAMETER 'WORKSPACE_ID' IS MISSING": "Required parameter 'workspace ID' is missing",
    "PROVIDED PARAMETER 'CANONICAL' IS INVALID": "Provided parameter 'canonical' is invalid",
    "REQUIRED PARAMETER 'CANONICAL' IS MISSING": "Required parameter 'canonical' is missing",
    "PROVIDED PARAMETER 'CONTENT' IS INVALID": "Provided parameter 'content' is invalid",
    "REQUIRED PARAMETER 'CONTENT' IS MISSING": "Required parameter 'content' is missing",
    "PROVIDED PARAMETER 'OUT_OF_TRAINING' IS INVALID": "Provided parameter 'out of training' is invalid",
    "REQUIRED PARAMETER 'OUT_OF_TRAINING' IS MISSING": "Required parameter 'out of training' is missing",
    "PROVIDED PARAMETER 'OUT_OF_CONTEXT' IS INVALID": "Provided parameter 'out of context' is invalid",
    "REQUIRED PARAMETER 'OUT_OF_CONTEXT' IS MISSING": "Required parameter 'out of context' is missing",
    "PROVIDED PARAMETER 'FINAL' IS INVALID": "Provided parameter 'final' is invalid",
    "REQUIRED PARAMETER 'FINAL' IS MISSING": "Required parameter 'final' is missing",
    "PROVIDED PARAMETER 'TYPE' IS INVALID": "Provided parameter 'type' is invalid",
    "REQUIRED PARAMETER 'TYPE' IS MISSING": "Required parameter 'type' is missing",
    "PROVIDED PARAMETER 'INTENT' IS INVALID": "Provided parameter 'intent' is invalid",
    "REQUIRED PARAMETER 'INTENT' IS MISSING": "Required parameter 'intent' is missing",
    "PROVIDED PARAMETER 'SYNONYMS' IS INVALID": "Provided parameter 'synonyms' is invalid",
    "REQUIRED PARAMETER 'SYNONYMS' IS MISSING": "Required parameter 'synonyms' is missing",
    "PROVIDED PARAMETER 'TEXT' IS INVALID": "Provided parameter 'text' is invalid",
    "REQUIRED PARAMETER 'TEXT' IS MISSING": "Required parameter 'text' is missing",
    "PROVIDED PARAMETER 'ASSISTANT_INSTANCE_ID' IS INVALID": "Provided parameter 'assistant instance' is invalid",
    "REQUIRED PARAMETER 'ASSISTANT_INSTANCE_ID' IS MISSING": "Required parameter 'assistant instance' is missing",
    "PROVIDED PARAMETER 'USER_ID' IS INVALID": "Provided parameter 'user' is invalid",
    "REQUIRED PARAMETER 'USER_ID' IS MISSING": "Required parameter 'user' is missing",
    "PROVIDED PARAMETER 'ROLE' IS INVALID": "Provided parameter 'role' is invalid",
    "REQUIRED PARAMETER 'ROLE' IS MISSING": "Required parameter 'role' is missing",
    "PROVIDED PARAMETER 'CREATED_AFTER' IS INVALID": "Provided parameter 'created after' is invalid",
    "REQUIRED PARAMETER 'CREATED_AFTER' IS MISSING": "Required parameter 'created after' is missing",
    "PROVIDED PARAMETER 'CREATED_BEFORE' IS INVALID": "Provided parameter 'created before' is invalid",
    "REQUIRED PARAMETER 'CREATED_BEFORE' IS MISSING": "Required parameter 'created before' is missing",
    "PROVIDED PARAMETER 'RESOURCE_ID' IS INVALID": "Provided parameter 'resource ID' is invalid",
    "REQUIRED PARAMETER 'RESOURCE_ID' IS MISSING": "Required parameter 'resource ID' is missing",
    "PROVIDED PARAMETER 'WELCOME_TEXT' IS INVALID": "Provided parameter 'welcome text' is invalid",
    "REQUIRED PARAMETER 'WELCOME_TEXT' IS MISSING": "Required parameter 'welcome text' is missing",
    "PROVIDED PARAMETER 'ANYTHING_ELSE_TEXT' IS INVALID": "Provided parameter 'anything else text' is invalid",
    "REQUIRED PARAMETER 'ANYTHING_ELSE_TEXT' IS MISSING": "Required parameter 'anything else text' is missing",
    "PROVIDED PARAMETER 'ENTITY' IS INVALID": "Provided parameter 'entity' is invalid",
    "INVALID_SYNONYMS_DETECTED": "Invalid synonym detected",
    "INVALID PATTERNS DETECTED": "Invalid patterns detected",
    "PATTERN TYPE LIMIT EXCEDED": "Pattern type can't have more than 5 synonyms",
    "UNAUTHORIZED": "Unauthorized",
    "FORBIDDEN. YOU DO NOT HAVE THE REQUIRED PRIVILEGES FOR THIS ACTION": "Forbidden. You do not have the required privileges for this action",
    "FORBIDDEN. YOU ARE NOT AN ADMIN": "Forbidden. You are not an admin",
    "FORBIDDEN. YOU ARE NOT AN AUDITOR": "Forbidden. You are not an auditor",
    "MOVE_ENTITY": "Move entity",
    "COPY_ENTITY": "Copy entity",
    "ENTITY_TRANSFER": "Entity transfer",
    "CREATIONS": "Creations",
    "DELETIONS": "Deletions",
    "EDITIONS": "Editions",
    "MAPPINGS": "Mappings",
    "REMAPPINGS": "Remappings",
    "INCOMING_DISTRIBUTION": "Incoming distribution",
    "LATEST_EXPERIMENT_RESULTS": "Latest experiment results",
    "CLEAR": "Clear",
    "SERVICES": "Services",
    "OVERVIEW": "Overview",
    "MERGE": "Merge",
    "COUNT": "Count",
    "PAIRWISE_CLASS_ERRORS": "Pairwise class errors",
    "AUDIT_FACTS": "Audit facts",
    "SUBJECT": "Subject",
    "ENTITY_VALUE": "Entity value",
    "EXAMPLE": "Example",
    "REVIEW_EXAMPLE": "Review example",
    "REVIEWEXAMPLE": "Review example",
    "TYPE": "Type",
    "INTENT_ID": "Intent ID",
    "ENTITY": "Entity",
    "SYNONYMS": "Synonyms",
    "PATTERNS": "Patterns",
    "PATTERNS_TOOLTIP": "Patterns are complex and can impact retrain. Use patterns validated on Watson Assistant",
    "FUZZY_MATCH": "Fuzzy match",
    "PARENT_ID": "Parent ID",
    "AUTHOR": "Author",
    "PT": "Portuguese",
    "PT-BR": "Portuguese (Brazil)",
    "EN": "English",
    "EN-US": "English (US)",
    "ES": "Spanish",
    "FR": "French",
    "RESTORE": "Restore",
    "NEW_SNAPSHOTS": "New snapshot",
    "SNAPSHOTS": "Snapshots",
    "API_USAGE": "API usage",
    "SEND": "Send",
    "ACCEPT": "Accept",
    "ACCURACY": "Accuracy",
    "ACCURACY_VS_CLASS_SIZE": "Accuracy VS class size",
    "ADD_CHILD": "Add child",
    "ADD_CHILD_NODE": "Add child node",
    "ADD_FILE": "Add file",
    "ADD_FOLDER": "Add folder",
    "ADD_NODE": "Add node",
    "ADD_NODE_ABOVE": "Add node above",
    "ADD_NODE_BELOW": "Add node below",
    "ADD_RESPONSE": "Add response",
    "ADD_VARIABLE": "Add variable",
    "ADD_FLAG": "Add flag",
    "METADATA": "Metadata",
    "ADMIN": "Admin",
    "ADVANCED_SEARCH": "Advanced search",
    "ALL_EXAMPLES_REVIEW_MAPPING": "All examples review/mapping",
    "THEN_ASSISTANT_SHOULD": "Then assistant should",
    "ANSWER": "Answer",
    "ANSWER_UNITS": "Answer units",
    "ANYTHING_ELSE_TEXT": "Fallback text",
    "API_KEYS": "API keys",
    "APIKEY": "API Key",
    "APIKEYS": "API Keys",
    "APP_TITLE": "Q&A Manager 2.0",
    "ARE_YOU_SURE": "Are you sure?",
    "ASSISTANT": "Assistant",
    "TEMPLATE": "Template",
    "LOAD_TEMPLATE": "Load Template",
    "ASSISTANT_INSTANCE": "Assistant instance",
    "ASSISTANT_INSTANCE_PERMISSIONS": "Assistant instance permissions",
    "ASSISTANT_INSTANCES": "Assistant instances",
    "ASSISTANT_INSTANCES_DESCRIPTION": "Associate Watson Assistant service instances to answer questions through dialog.",
    "ASSISTANT_SKILL": "Assistant skill",
    "ASSISTANT_SKILL_PERMISSIONS": "Assistant skill permissions",
    "ASSISTANT_SKILLS": "Assistant skills",
    "ASSISTANT_MISSING_NODES_MSG": "Node {dialog_node} jumps to missing node {jump_dialog_node}",
    "AUDIT": "Audit",
    "AUDIT_DESCRIPTION": "View service actions.",
    "AUDITOR": "Auditor",
    "AUTO_RETRAIN": "Automatic retrain",
    "BOUND_WORKSPACE": "Bound workspace",
    "CANONICAL": "Canonical",
    "OWNER": "Owner",
    "CHANNEL": "Channel",
    "CHANNELS": "Channels",
    "CLASS": "Class",
    "CLASS_DISTRIBUTION": "Class distribution",
    "CLASS_SIZE": "Class size",
    "CLASSES": "Classes",
    "COMPLETE": "Complete",
    "COMPLETE_RETRAIN": "Complete retrain",
    "CONDITION": "Condition",
    "CONFIDENCE": "Confidence",
    "CONFIDENCE_VS_CLASS_SIZE": "Confidence VS class size",
    "CONFIRM_PASSWORD": "Please confirm your current password",
    "CONNECT_TO_HUMAN_AGENT": "Connect to human agent",
    "CONTENT": "Content",
    "CONTEXT_SET": "Context set",
    "CONVERSATION_CHAT": "Conversation Chat",
    "CORPUS": "Corpus",
    "CREATE": "Create",
    "CREATED_AFTER": "Created after",
    "CREATED_AT": "Created at",
    "CREATED_BEFORE": "Created before",
    "DATASET": "Dataset",
    "DATASET_PERMISSIONS": "Dataset permissions",
    "DATASETS": "Datasets",
    "DATASETS_DESCRIPTION": "Configure your chatbot's short tail answers.",
    "DEINDEX": "Deindex",
    "DELETE": "Delete",
    "DIALOG_CONFIGS": "Dialog configurations",
    "DISAMBIGUATION": "Disambiguation",
    "DISAMBIGUATION_QUESTION_LIMIT": "Disambiguation question limit",
    "DISCOVERY_COLLECTION": "Discovery collection",
    "DISCOVERY_COLLECTION_PERMISSIONS": "Discovery collection permissions",
    "DISCOVERY_COLLECTIONS": "Discovery collections",
    "DISCOVERY_PROJECT": "Discovery Project",
    "DISCOVERY_PROJECT_PERMISSIONS": "Discovery project permissions",
    "DISCOVERY_PROJECTS": "Discovery Projects",
    "DISCOVERY_INSTANCE": "Discovery instance",
    "DISCOVERY_INSTANCE_PERMISSIONS": "Discovery instance permissions",
    "DISCOVERY_INSTANCES": "Discovery instances",
    "DISCOVERY_INSTANCES_DESCRIPTION": "Bind Watson Discovery service instances to infuse your chatbot with document retrieval.",
    "CUSTOM": "Custom",
    "DOCUMENT_RETRIEVAL": "Document Retrieval",
    "CONVERSATIONAL_SEARCH": "Conversational Search",
    "DISTRIBUTION": "Distribution",
    "DOCUMENTS": "Documents",
    "DOCUMENTS_DESCRIPTION": "Index documents for natural language search.",
    "DOES_NOT_RETURN": "Does not return",
    "DOWNLOAD": "Download",
    "DOWNLOAD_CLEAN": "Download (Clean)",
    "DUPLICATE": "Duplicate",
    "EDIT": "Edit",
    "EDITOR": "Editor",
    "ENTER_EXTERNAL_NODE_NAME": "Enter external node name",
    "ENTER_RESPONSE_TEXT": "Enter response text",
    "ENTITIES": "Entities",
    "ENTITY_VALUES": "Entity values",
    "ENVIRONMENT_ID": "Environment ID",
    "ERROR_CREATING_RESOURCE": "Error creating resource",
    "ERROR_DELETING_RESOURCE": "Error deleting resource",
    "ERROR_LOADING_RESOURCES": "Error loading resources",
    "EXAMPLES": "Examples",
    "EXAMPLES_CLASS_DISTRIBUTION": "Examples and class distribution",
    "EXPERIMENTS": "Experiments",
    "EXPORT": "Export",
    "EXPORT_CLEAN": "Export (clean)",
    "EXPORT_JSON": "Export JSON",
    "FEEDBACK": "Feedback",
    "FINAL": "Final",
    "FINISHED_AT": "Finished at",
    "GO_TO": "Go to",
    "HITS": "Hits",
    "HIT_INDEX": "Hit index",
    "HOME": "Home",
    "HTML": "HTML",
    "IF_ASSISTANT_NEEDS_TO_REPRESENT_NODE_USE": "If virtual-assistant needs to represent node to users, then use",
    "IF_ASSISTANT_RECOGNIZES": "If assistant recognizes",
    "IMAGE": "Image",
    "INCORRECT_PASSWORD": "Incorrect password",
    "INDEX": "Index",
    "INDEXED_AT": "Indexed at",
    "INSTANCE": "Instance",
    "INTENT": "Intent",
    "INTENTS": "Intents",
    "INVITE": "Invite",
    "JSON_EDITOR": "JSON editor",
    "JUMP_TO": "Jump to",
    "KEY": "Key",
    "KNOWLEDGE_BASE": "Knowledge Base",
    "KNOWLEDGE_BASE_PERMISSIONS": "Knowledge base permissions",
    "KNOWLEDGE_BASES": "Knowledge Bases",
    "LANGUAGE": "Language",
    "LOGIN": "Login",
    "LOGIN_ATTEMPTS": "Login attempts",
    "LOGIN_FAILED": "Login failed. Username or password is incorrect.",
    "MAP_TO_SELECTED": "Map to selected class",
    "MAPPING": "Mapping",
    "MERGE_SELECTED_CLASSES": "Merge selected classes",
    "MERGER": "Merger",
    "MISSES": "Misses",
    "MOVE": "Move",
    "NAME": "Name",
    "DESCRPTION": "Description",
    "NEW_ANSWER_UNITS": "New answer unit",
    "NEW_APIKEYS": "New API Key",
    "NEW_ASSISTANT_INSTANCES": "New Assistant instance",
    "NEW_ASSISTANT_SKILLS": "New Assistant skill",
    "NEW_CHANNELS": "New channel",
    "NEW_DATASETS": "New dataset",
    "NEW_DISCOVERY_COLLECTIONS": "New Discovery collection",
    "NEW_DISCOVERY_PROJECTS": "New Discovery project",
    "NEW_DISCOVERY_INSTANCES": "New Discovery instance",
    "NEW_DOCUMENTS": "New document",
    "NEW_ENTITIES": "New entity",
    "NEW_ENTITY_VALUES": "New entity value",
    "NEW_EXAMPLES": "New example",
    "NEW_EXPERIMENTS": "New experiment",
    "NEW_INTENTS": "New intent",
    "NEW_KNOWLEDGE_BASES": "New knowledge base",
    "NEW_NAME": "New name",
    "NEW_ORCHESTRATORS": "New Orchestrator",
    "NEW_PASSWORD": "New password",
    "NEW_PERMISSIONS": "New Permission",
    "NEW_REPORTS": "New report",
    "NEW_REVIEW_EXAMPLES": "New mapping example",
    "NEW_USERS": "New User",
    "NO": "No",
    "NO_CONDITION_SET": "No condition set",
    "NORMAL": "Normal",
    "NUMBER_OF_CLASSES": "Number of classes",
    "NUMBER_OF_EXAMPLES": "Number of examples",
    "OPTION": "Option",
    "OPTIONS": "Options",
    "ORCHESTRATOR": "Orchestrator",
    "ORCHESTRATOR_PERMISSIONS": "Orchestrator permissions",
    "ORCHESTRATORS": "Orchestrators",
    "ORCHESTRATORS_DESCRIPTION": "Join together Dialog and Search skills to build a chatbot.",
    "OUT_OF_CONTEXT": "Out of context",
    "OUT_OF_TRAINING": "Out of training",
    "OUTPUT_VARIABLES": "Output variables",
    "CONTEXT_VARIABLES": "Context variables",
    "PAGE_NOT_FOUND": "Page not found",
    "PARENT": "Parent",
    "PASSWORD": "Password",
    "PAUSE": "Pause",
    "PERMISSIONS": "Permissions",
    "PIVOT_DIMENSION": "Pivot dimension",
    "PRECISION": "Precision",
    "PRECISION_AT_K": "Precision at K",
    "PREDICTED_INTENT": "Predicted intent",
    "PREDICTED_INTENT_NAME": "Predicted intent name",
    "PREDICTIONS": "Predictions",
    "PROCFLOW": "Procflow",
    "QUERY": "Query",
    "RANDOM": "Random",
    "REJECT": "Reject",
    "RELATED_CLASSES": "Related classes",
    "REMAP": "Remap",
    "MAP": "Map",
    "REMAP_EXAMPLES": "Remap questions",
    "REMOVE_CHILD": "Remove child",
    "REMOVE_RESPONSE": "Remove response",
    "REPLICATION": "Replication",
    "REPLICATOR": "Replicator",
    "REPORT": "Report",
    "RESOURCE_SUBTYPE": "Resource subtype",
    "RESOURCE_TYPE": "Resource type",
    "RESPONSE": "Response",
    "RESPONSE_VARIATION": "Response variation",
    "RESPONSES": "Responses",
    "RETRAIN": "Retrain",
    "REVIEW": "Review",
    "REVIEW_EXAMPLES": "Examples to Review",
    "ROLE": "Role",
    "ROUTER": "Router",
    "SEARCH": "Search",
    "SEARCH_SKILL": "Search skill",
    "SEARCHERS": "Searchers",
    "SEQUENTIAL": "Sequential",
    "SIGN_IN": "Sign in",
    "SIMPLE_RETRAIN": "Simple retrain",
    "SIMPLIFIED_EDITOR": "Simplified editor",
    "SOURCE_DATASET": "Source dataset",
    "STATUS": "Status",
    "SUBMIT": "Submit",
    "SUCCESS_CREATING_RESOURCE": "Resource created successfully",
    "SUCCESS_DELETING_RESOURCE": "Resource deleted successfully",
    "TARGET_DATASET": "Target dataset",
    "TARGET_FACT": "Target fact",
    "TEST": "Test",
    "TEXT": "Text",
    "ASSISTANT_RESPONDS": "Assistant responds",
    "TRAINED_AT": "Trained at",
    "TRAINING_DATA": "Training Data",
    "TRUE_INTENT": "True intent",
    "TRUE_INTENT_NAME": "True intent name",
    "UNBIND": "Unbind",
    "UPDATE": "Update",
    "UPDATED_AFTER": "Updated after",
    "UPDATED_AT": "Updated at",
    "UPDATED_BEFORE": "Updated before",
    "UPLOAD": "Upload",
    "URL": "URL",
    "USER": "User",
    "USER_IS_BLOCKED": "User is blocked",
    "USER_NOT_FOUND": "User not found",
    "USERNAME": "Username",
    "USERS": "Users",
    "USERS_DESCRIPTION": "Manager Q&A Manager users.",
    "VALUE": "Value",
    "VARIABLE": "Variable",
    "VIEW": "View",
    "VIEWER": "Viewer",
    "WAIT_FOR_USER_INPUT": "Wait for user input",
    "WELCOME_TEXT": "Welcome text",
    "WORKSPACE_ID": "Workspace ID",
    "YES": "Yes",
    "FIXED": "Fixed",
    "NOT_FIXED": "Not fixed",
    "INFERENCE": "Inference",
    "INFERENCE_OPT": "Inference Opt-in",
    "NEGATIVE": "Negative",
    "POSITIVE": "Positive",
    "ACTION": "Action",
    "INTENT CREATE": "Create Intent",
    "INTENT UPDATE": "Update Intent",
    "CREATE ENTITY_VALUE": "Create Entity Value",
    "EXAMPLE_STATUS": "Example Status",
    "MAP REVIEW_EXAMPLE": "Map Review Example",
    "ACCEPT REVIEW_EXAMPLE": "Accept Review Example",
    "ID_INTENT_BEFORE": "Intent ID before",
    "ID_INTENT_ACTUAL": "Intent ID actual",
    "ID_INTENT": "Intent ID",
    "NAME_AFTER": "Name after",
    "EXAMPLE_STATUS_AFTER": "Example Status after",
    "ANSWER_AFTER": "Answer after",
    "EXAMPLE_AFTER": "Example after",
    "TAXONOMY_AFTER": "Taxonomy after",
    "TAXONOMY_MERGED": "Merged Intent Taxonomy",
    "TAXONOMY_KEEPED": "Keeped Intent Taxonomy",
    "FINAL_AFTER": "Final after",
    "OUT_OF_CONTEXT_AFTER": "Out of Context after",
    "OUT_OF_TRAINING_AFTER": "Out of Training after",
    "CANONICAL_AFTER": "Canonical after",
    "TYPE_AFTER": "Type after",
    "CONTEXT_VARIABLES_AFTER": "Context Variables after",
    "OUTPUT_VARIABLES_AFTER": "Output Variables after",
    "TAXONOMY": "Taxonomy",
    "TAXONOMY_ACTUAL": "Taxonomy actual",
    "TAXONOMY_BEFORE": "Taxonomy before",
    "DIALOG_RETRAIN": "Dialog Retrain",
    "DELETE EVALUATION": "Delete Evaluation",
    "CREATE EXPERIMENT": "Create Experiment",
    "ID_MERGED": "Merged Intent ID",
    "ID_KEEPED": "Keeped Intent ID",
    "EXAMPLE_KEEPED": "Example excluded",
    "TOTAL_SUGGESTED_EXAMPLES": "Total Suggested Examples",
    "TOTAL_DELETED_EXAMPLES": "Total Deleted Examples",
    "SYSTEM": "System",
    "MERGE INTENT": "Merge Intent",
    "EXAMPLE_HISTORY_DISAMBIGUATION": "Example - Flow selected Options",
    "TYPE_MERGED": "Type Merged Intent",
    "TYPE_KEEPED": "Type Keeped Intent",
    "ENTITY_VALUE_NAME": "Value Name",
    "ENTITY_VALUE_NAME_AFTER": "Value Name after",
    "UPDATE ENTITY_VALUE": "Update Entity Name",
    "SYNONYMS_AFTER": "Synonyms after",
    "ANSWER_MERGED": "Answer Merged Intent",
    "ANSWER_KEEPED": "Answer Keeped Intent",
    "CANONICAL_MERGED": "Canonical Merged Intent",
    "CANONICAL_KEEPED": "Canonical Keeped Intent",
    "INPUT_CHANNEL": "Input Channel",
    "DATASET_MERGED": "Dataset Merged Intent",
    "DATASET_KEEPED": "Dataset Keeped Intent",
    "DELETE EXPERIMENT": "Delete Experiment",
    "DELETE ENTITY_VALUE": "Delete Entity Value",
    "DELETE EXAMPLE": "Delete Example",
    "FINAL_MERGED": "Final Merged Intent",
    "FINAL_KEEPED": "Final Keeped Intent",
    "OUT_OF_CONTEXT_MERGED": "Out of Context Merged Intent",
    "OUT_OF_CONTEXT_KEEPED": "Out of Context Keeped Intent",
    "OUT_OF_TRAINING_MERGED": "Out of Training Merged Intent",
    "OUT_OF_TRAINING_KEEPED": "Out of Training Keeped Intent",
    "OUTPUT_VARIABLES_MERGED": "Output Variables Merged Intent",
    "OUTPUT_VARIABLES_KEEPED": "Output Variables Keeped Intent",
    "CONTEXT_VARIABLES_MERGED": "Context Variables Merged Intent",
    "CONTEXT_VARIABLES_KEEPED": "Context Variables Keeped Intent",
    "RETRAIN ASSISTANT_SKILL": "Retrain Assistant Skill",
    "FEEDBACK ORCHESTRATOR": "Orchestrator Feedback",
    "FEEDBACK_TYPE": "Feedback Type",
    "SKILL_RETRAINED_EVENT_MESSAGE": "Skill {name} was updated with status {status}"
}
