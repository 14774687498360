import { TextInput } from 'carbon-components-react';
import React, { Component } from 'react';

class NumberInput extends Component {

    state = {
        value: null,
    }

    render() {
        let value = (this.state.value || this.props.value).toString()
        return <>
            <TextInput
                {...this.props}
                style={{ maxWidth: "15rem", ...this.props.style }}
                value={this.isNumber(value) ? value : ""}
                onChange={ev => this.handleChange(ev.target.value)}
                onBlur={ev => this.setState({ value: this.props.value.toString() })}
            />
        </>
    }

    isNumber(value) {
        return ![undefined, null].includes(value) && (value === '-' || !Number.isNaN(this.getNumber(value)))
    }

    getNumber(value) {
        return Number(value.replace(',', '.'))
    }

    componentWillReceiveProps(props) {
        if ([null, undefined].includes(this.state.value))
            this.handleChange(props.value)
    }

    handleChange(value) {
        let stringValue = value.toString()
        if (value === '-') return this.setState({ value: '-' })
        if (!this.isNumber(stringValue)) return
        this.setState({ value: stringValue })
        let numberValue = this.getNumber(stringValue)
        if (this.props.max !== undefined && numberValue > this.props.max) numberValue = this.props.max
        if (this.props.min !== undefined && numberValue < this.props.min) numberValue = this.props.min
        this.props.onChange(stringValue === "" ? undefined : numberValue)
    }

}

NumberInput.defaultProps = {
    value: "",
    style: {}
}

export default NumberInput