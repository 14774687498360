import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Tile, OverflowMenu, OverflowMenuItem, Link } from 'carbon-components-react';
import { listAllIntents } from '../../../../services/corpus'
import renderText from '../../../../utils/render-text'

let visibleNodes = ['standard', 'frame', 'folder']

class DialogNode extends Component {

    state = {
        jumpTargetName: null
    }

    render() {
        if (!this.props.dialogNodes) return <></>
        if (!this.props.showVirtualNodes && this.props.node && !visibleNodes.includes(this.props.node.type)) return <></>
        let children = this.orderChildren(this.props.dialogNodes, this.props.node)
        return (
            <div className={"node-container " + (this.props.isLastChild ? "" : "not-last-node")} {...this.props}>
                {this.props.node && <>
                    {this.props.node.type === 'folder' && !(this.props.node.expanded && this.props.hasChildren) && <div className="node-folder__stack"></div>}
                    <Tile style={{ opacity: visibleNodes.includes(this.props.node.type) ? 1 : 0.5, backgroundColor: this.props.node.color || "white" }}
                        className={this.getNodeClass()}>
                        <div className="node__expander">
                            {(this.props.hasChildren || this.props.node.type === 'folder') &&
                                <button onClick={ev => this.props.onExpand(this.props.node)}>
                                    {this.props.node.type === 'folder' ?
                                        <svg style={{ fill: this.props.hasChildren ? "#3d70b2" : "#000000", transform: "rotate(0deg)" }} width="16" height="12" viewBox="0 0 16 12" fill-rule="evenodd"><path d="M0 12h16V2H8V0H0z"></path></svg>
                                        :
                                        <svg style={{ fill: "#3d70b2" }} width="20" height="12" viewBox="0 0 8 12" fillRule="evenodd"><path d="M0 10.6L4.7 6 0 1.4 1.4 0l6.1 6-6.1 6z"></path></svg>
                                    }
                                </button>}
                        </div>
                        <div className="node__contents" onClick={ev => !this.isDisabled() && this.props.onSelect(this.props.node, ev)}>
                            <p>{renderText(visibleNodes.includes(this.props.node.type) ? this.props.node.title : this.props.node.type.toUpperCase())}</p>
                            <p style={{ fontSize: "0.8rem" }}>{renderText(this.props.node.conditions || window.translate("NO_CONDITION_SET"))}</p>
                            <p style={{ position: "absolute", bottom: "0.5rem", fontSize: "0.8rem" }}>{renderText(`X ${window.translate("RESPONSES")} / ${Object.keys(this.props.node.context || {}).length} ${window.translate("CONTEXT_SET")} / ${window.translate("DOES_NOT_RETURN")}`)}</p>
                        </div>
                        <div className="node__menu">
                            {!this.props.nodePickerOn && <OverflowMenu flipped style={{ position: "relative", top: 0, right: 0 }}>
                                <OverflowMenuItem floating onClick={() => this.props.onAdd(this.props.node, "child")} itemText={window.translate("ADD_CHILD_NODE")} />
                                <OverflowMenuItem floating onClick={() => this.props.onAdd(this.props.node, "above")} itemText={window.translate("ADD_NODE_ABOVE")} />
                                <OverflowMenuItem floating onClick={() => this.props.onAdd(this.props.node, "below")} itemText={window.translate("ADD_NODE_BELOW")} />
                                <OverflowMenuItem floating onClick={() => this.props.onAdd(this.props.node, "below", { type: "folder", output: null })} itemText={window.translate("ADD_FOLDER")} />
                                <OverflowMenuItem floating onClick={() => this.props.onBind(this.props.node, "move")} itemText={window.translate("MOVE")} hasDivider />
                                <OverflowMenuItem floating onClick={() => this.props.onDuplicate(this.props.node, "child")} itemText={window.translate("DUPLICATE")} />
                                {this.props.node.type !== 'folder' && <OverflowMenuItem floating onClick={() => this.props.onBind(this.props.node, "jump")} itemText={window.translate("JUMP_TO")} />}
                                <OverflowMenuItem floating onClick={() => this.props.onDelete(this.props.node)} itemText={window.translate("DELETE")} hasDivider isDelete />
                            </OverflowMenu>}
                        </div>
                    </Tile>
                </>}
                <br />
                {(!this.props.node || this.props.node.expanded) &&
                    <div className="node-children" style={{ marginLeft: this.props.node ? "30px" : "0" }}>
                        {this.props.node && this.props.node.next_step && this.props.node.next_step.behavior === "jump_to" &&
                            <div className={"node-container " + (!children.length ? "" : "not-last-node")}>
                                <Tile className="node node-jump">
                                    <Link>{window.translate("JUMP_TO")} {this.getJumpTarget()}<br />({{
                                        "user_input": window.translate("WAIT_FOR_USER_INPUT"),
                                        "condition": window.translate("EVALUATE_CONDITION"),
                                        "body": window.translate("EVALUATE_RESPONSES"),
                                    }[this.props.node.next_step.selector]})</Link>
                                </Tile>
                                <br />
                            </div>}
                        {this.props.node && this.props.node.next_step && this.props.node.next_step.behavior === "skip_user_input" &&
                            <div className={"node-container " + (!children.length ? "" : "not-last-node")}>
                                <Tile className="node node-jump">
                                    <Link>{window.translate("SKIP_USER_INPUT")}</Link>
                                </Tile>
                                <br />
                            </div>}
                        {children.map((child, index) => <>
                            <DialogNode
                                {...this.props}
                                key={`child-${index}`}
                                node={child}
                                hasChildren={this.props.dialogNodes.find(otherNode => otherNode.parent === child.dialog_node && visibleNodes.includes(otherNode.type)) || (child.next_step && ["jump_to", "skip_user_input"].includes(child.next_step.behavior))}
                                isLastChild={!children.slice(index + 1).length > 0}
                            />
                        </>)}
                    </div>
                }
            </div>)
    }

    componentDidUpdate(props) {
        if (
            props.node && props.node.next_step && props.node.next_step.dialog_node &&
            ((this.props.node.next_step && props.node.next_step.dialog_node !== this.props.node.next_step.dialog_node) || !this.state.jumpTargetName))
            this.loadJumpToTargetName(props.node.next_step.dialog_node)
    }

    orderChildren(children, parent) {
        return children
            .filter(node => node.parent === (parent ? parent.dialog_node : undefined))
            .reduce((orderedChildren, _1, _2, allChildren) => {
                if (orderedChildren.length === 0) return [allChildren.find(child => !child.previous_sibling)]
                let nextChild = allChildren.find(child => child.previous_sibling === orderedChildren.slice(-1)[0].dialog_node)
                if (!nextChild) return orderedChildren
                return [...orderedChildren, nextChild]
            }, [])
    }

    getNodeClass() {
        let className = "node"
        if (this.props.node.expanded) className += " node--expanded"
        if (this.isSelected()) className += " node-selected"
        if (this.isDisabled()) className += " node-source"
        return className
    }

    isSelected() { return this.props.selectedNode && this.props.selectedNode.dialog_node === this.props.node.dialog_node }

    isDisabled() {
        return this.props.sourceNode && (
            this.props.sourceNode.dialog_node === this.props.node.dialog_node ||
            this.orderChildren(this.props.dialogNodes, this.props.node).find(child => child.dialog_node === this.props.sourceNode.dialog_node && child.type === "response_condition")
        )
    }

    async loadJumpToTargetName(nodeID) {
        if (!this.props.match) return
        let nodeInThisDialog = this.props.dialogNodes.find(node => node.dialog_node === nodeID)
        if (nodeInThisDialog) {
            if (this.state.jumpTargetName !== nodeInThisDialog.title) this.setState({ jumpTargetName: nodeInThisDialog.title })
            return
        }
        let nodeName = nodeID
        let [intentsWithThisID, intentsThatReferenceThisID] = await Promise.all([
            listAllIntents({ id: nodeID, siblings_of: this.props.match.params.dataset_id }),
            listAllIntents({ search: nodeID, siblings_of: this.props.match.params.dataset_id, type: 3 })
        ])
        if (intentsWithThisID.count) {
            nodeName = intentsWithThisID.rows[0].name
        }
        else if (intentsThatReferenceThisID.count) {
            nodeName = intentsThatReferenceThisID
                .rows
                .filter(intent => intent.content.find(node => node.dialog_node === nodeID))
                .reduce((name, intent) => `${intent.content.find(node => node.dialog_node === nodeID).title} (${intent.name})`, "")
        }
        if (nodeName != this.state.jumpTargetName)
            this.setState({ jumpTargetName: nodeName })
    }

    getJumpTarget() {
        let nodeInThisDialog = this.props.dialogNodes.find(node => node.dialog_node === this.props.node.next_step.dialog_node)
        return nodeInThisDialog ? nodeInThisDialog.title : this.state.jumpTargetName
    }

}

DialogNode.defaultProps = {
    onExpand: () => { },
    onSelect: () => { }
}

export default withRouter(DialogNode)