import React, { Component } from 'react';
import { Button, Link, Accordion, AccordionItem, Toggle, InlineLoading } from 'carbon-components-react';
import { queryAuditFacts, groupAuditFactsByDate, groupAuditFactsByPivot } from '../../../services/audit'
import axios from 'axios';
import moment from 'moment';
import { getDataset, listAllIntents } from '../../../services/corpus';
import ResourceCompare from './resource-compare';
import { ExportButton } from '../../../components/carbon-react-crud';
import BarGraph from '../../../components/bar-graph';
import auditOptions from '../../../utils/audit-options'
import auditExportHelper from '../../../utils/audit-export'
import { CrudTable } from '../../../components/carbon-react-crud';

class AuditExplorer extends Component {

    state = {
        params: {},
        action_types: Object.keys(auditOptions).reduce((acc, resource_type) => [...new Set([...Object.keys(auditOptions[resource_type]), ...acc])], []),
        resource_types: Object.keys(auditOptions),
        pivot_dimensions: [],
        auditFactReport: [],
        auditDimensionReport: [],
        loading: true
    }

    crudTableRef = React.createRef()

    render() {
        return (<>
            {!this.state.params.resource_id && !this.state.params.resource_type && !this.state.params.filter_dimension && !this.state.params.filter_value && <>
                <h5>{window.translate("ALL_EVENTS")}</h5>
            </>}
            {this.state.params.resource_id && this.state.params.resource_type && <>
                <h5>{window.translate("EVENTS_FOR")} {window.translate(this.state.params.resource_type)} {this.state.filter_name || this.state.params.resource_id}</h5>
                <Link
                    href="#"
                    onClick={ev => this.handleSubmit({ ...this.state.params, filter_dimension: this.state.params.resource_type + '_ID', filter_value: this.state.params.resource_id, resource_id: null, resource_type: null })}
                >
                    {window.translate("VIEW_EVENTS_IN_THIS_RESOURCE")}
                </Link>
            </>}
            {this.state.params.filter_dimension && this.state.params.filter_value && <>
                <h5>{window.translate("EVENTS_IN")} {window.translate(this.state.params.filter_dimension.replace('_ID', ''))} {this.state.filter_name || this.state.params.filter_value}</h5>
                {this.state.params.filter_dimension !== 'AUTHOR' && <Link
                    href="#"
                    onClick={ev => this.handleSubmit({ ...this.state.params, filter_dimension: null, filter_value: null, resource_id: this.state.params.filter_value, resource_type: this.state.params.filter_dimension.replace('_ID', '') })}
                >
                    {window.translate("VIEW_EVENTS_TARGETING")}
                </Link>}
            </>}
            {(this.state.params.resource_id || this.state.params.resource_type || this.state.params.filter_dimension || this.state.params.filter_value) && <>
                <br />
                <Link
                    href="#"
                    onClick={ev => this.handleSubmit({ ...this.state.params, filter_dimension: null, filter_value: null, resource_id: null, action_type: null, resource_type: null, created_after: null, created_before: null })}
                >
                    {window.translate("VIEW_ALL_EVENTS")}
                </Link>
            </>}
            <br /><br />
            <Toggle labelText={window.translate("SHOW_ALL_FIELDS")} onToggle={state => this.setState({ showAllFields: state })} />
            {!this.state.loading && <CrudTable
                url="/audit/audit_facts"
                listResources={(url, params) => queryAuditFacts({ ...this.state.params, ...params })}
                createResource={async (url, params) => this.handleSubmit(params)}
                addButtonText={window.translate("NEW_REPORTS")}
                headers={[{ key: "id", header: "ID" }, { key: "author", header: window.translate("AUTHOR") }, { key: "action_type", header: window.translate("SUBJECT") }, { key: "created_at", header: window.translate("CREATED_AT") }]}
                advancedSearchOptions={{
                    filters: [
                        {
                            key: 'filter_dimension', type: 'select', label: window.translate('RESOURCE_TYPE'), options: [
                                {},
                                { label: window.translate("ASSISTANT_INSTANCE"), value: "ASSISTANT_INSTANCE_ID" },
                                { label: window.translate("ASSISTANT_SKILL"), value: "ASSISTANT_SKILL_ID" },
                                { label: window.translate("DISCOVERY_INSTANCE"), value: "DISCOVERY_INSTANCE_ID" },
                                { label: window.translate("DISCOVERY_PROJECT"), value: "DISCOVERY_PROJECT_ID" },
                                { label: window.translate("DISCOVERY_COLLECTION"), value: "DISCOVERY_COLLECTION_ID" },
                                { label: window.translate("ORCHESTRATOR"), value: "ORCHESTRATOR_ID" },
                                { label: window.translate("DATASET"), value: "DATASET_ID" },
                                { label: window.translate("USER"), value: "USER_ID" }]
                        },

                        { key: 'filter_value', label: window.translate("ASSISTANT_INSTANCE"), type: 'recordpicker', fetchOptions: params => axios('/orchestrator/assistant_instances', { params }).then(response => response.data), visible: params => params.filter_dimension === 'ASSISTANT_INSTANCE_ID' },
                        { key: 'filter_value', label: window.translate("ASSISTANT_SKILL"), type: 'recordpicker', fetchOptions: params => axios('/orchestrator/assistant_skills', { params }).then(response => response.data), visible: params => params.filter_dimension === 'ASSISTANT_SKILL_ID' },
                        { key: 'filter_value', label: window.translate("DISCOVERY_INSTANCE"), type: 'recordpicker', fetchOptions: params => axios('/orchestrator/discovery_instances', { params }).then(response => response.data), visible: params => params.filter_dimension === 'DISCOVERY_INSTANCE_ID' },
                        { key: 'filter_value', label: window.translate("DISCOVERY_PROJECT"), type: 'recordpicker', fetchOptions: params => axios('/orchestrator/discovery_projects', { params }).then(response => response.data), visible: params => params.filter_dimension === 'DISCOVERY_PROJECT_ID' },
                        { key: 'filter_value', label: window.translate("DISCOVERY_COLLECTION"), type: 'recordpicker', fetchOptions: params => axios('/orchestrator/discovery_collections', { params }).then(response => response.data), visible: params => params.filter_dimension === 'DISCOVERY_COLLECTION_ID' },
                        { key: 'filter_value', label: window.translate("ORCHESTRATOR"), type: 'recordpicker', fetchOptions: params => axios('/orchestrator/orchestrators', { params }).then(response => response.data), visible: params => params.filter_dimension === 'ORCHESTRATOR_ID' },
                        { key: 'filter_value', label: window.translate("DATASET"), type: 'recordpicker', fetchOptions: params => axios('/corpus/datasets', { params }).then(response => response.data), visible: params => params.filter_dimension === 'DATASET_ID' },
                        { key: 'filter_value', label: window.translate("USER"), type: 'recordpicker', fetchOptions: params => axios('/auth/users', { params }).then(response => response.data), visible: params => params.filter_dimension === 'USER_ID' },

                        { key: "action_type", label: window.translate("action_type"), type: "select", options: [{ value: "", label: " " }, ...this.state.action_types.map(t => ({ value: t, label: window.translate(t) }))] },
                        { key: "resource_type", label: window.translate("resource_type"), type: "select", options: [{ value: "", label: " " }, ...this.state.resource_types.map(t => ({ value: t, label: window.translate(t) }))] },
                        { key: "pivot_dimension", label: window.translate("pivot_dimension"), type: "select", options: [{ value: "", label: " " }, ...this.state.pivot_dimensions.map(t => ({ value: t, label: window.translate(t) }))] },
                        { type: "daterange", label: window.translate("created_between"), key1: "created_after", key2: "created_before" },
                    ],
                    value: this.state.params,
                    onChange: params => this.handleSubmit(params, false)
                }}
                mapFn={fact => ({
                    ...fact,
                    ...this.getFactDimensions(fact),
                    action_type: window.translate(fact.action_type) + " " + window.translate(fact.resource_type),
                    created_at: moment(fact.created_at).format('LLL'),
                })}
                renderDetail={row => {
                    let dimensions = this.getFactDimensions(row)
                    let before = dimensions.resource_before ? JSON.parse(dimensions.resource_before) : dimensions.resource_before
                    let after = dimensions.resource_after ? JSON.parse(dimensions.resource_after) : dimensions.resource_after
                    return <>
                        <div style={{ maxHeight: "25rem", overflow: "scroll" }}>
                            <ResourceCompare {...this.props} value={dimensions} before={before} after={after} dimensions={dimensions} resource_type={row.resource_type} showAllFields={this.state.showAllFields} />
                        </div>
                        <br /><br />
                        {window.translate("VIEW_EVENTS_IN")}:
                        <br /><br />
                        {Object.keys(dimensions)
                            .filter(key => key.endsWith('_id') || key === 'author')
                            .map(key =>
                                <Button disabled={this.state.params.filter_dimension === key.toUpperCase() && this.state.params.filter_value === dimensions[key]}
                                    onClick={ev => this.setState({ params: { ...this.state.params, filter_dimension: key.toUpperCase(), filter_value: dimensions[key], resource_id: null, resource_type: null } }, () => { this.handleSubmit(this.state.params); this.loadFilterName() })}>
                                    {window.translate(key.replace('_id', ''))}
                                </Button>
                            )}
                    </>
                }}
                toolbarContent={() => <>
                    {this.reportsEnabled() ? <ExportButton
                        sortHeader={(auditExportHelper.exportAudit[this.state.params.action_type] && auditExportHelper.exportAudit[this.state.params.action_type][this.state.params.resource_type]) ? false : true}
                        translate={window.translate}
                        listResources={async params => queryAuditFacts({ ...this.state.params, ...params, export: true })
                            .then(async data => await this.exportAudit(data, this.state.params.action_type, this.state.params.resource_type))} /> : <h5 style={{ margin: '1rem' }}>{window.translate("AUDIT_MAX_RANGE_MESSAGE")}</h5>}
                        </>}
                searchable={this.reportsEnabled()} disableCreate disableUpdate={() => true} disableDelete={() => true}
                ref={this.crudTableRef}
            />}
            <br /><br />
            <Accordion>
                <AccordionItem title={window.translate("REPORT")}>
                    {!this.state.loadingAuditFactReport ? <>
                        <BarGraph
                            id='facts-graph'
                            data={this.state.auditFactReport.map(point => ({ x: point.date, y: Number(point.count) }))}
                            xLabel={window.translate("DATE")}
                            yLabel={`${window.translate("TOTAL_BY")} ${window.translate("DATE")}`}
                        />
                        <ExportButton {...this.props} listResources={() => ({ rows: this.state.auditFactReport })} />
                        <br /><br />
                    </> : <InlineLoading />}
                    {!this.state.loadingAuditDimensionReport ? <>
                        <BarGraph
                            id='pivot-graph'
                            data={this.state.auditDimensionReport.map(point => ({ x: point.value, y: Number(point.count) }))}
                            xLabel={window.translate(this.state.params.pivot_dimension) || window.translate("AUTHOR")}
                            yLabel={`${window.translate("TOTAL_BY")} ${window.translate(this.state.params.pivot_dimension) || window.translate("AUTHOR")}`}
                        />
                        <ExportButton {...this.props} listResources={() => ({ rows: this.state.auditDimensionReport })} />
                    </> : <InlineLoading />}
                </AccordionItem>
            </Accordion>
        </>)
    }

    async exportAudit(data, action_type, resource_type) {
        if (auditExportHelper.exportAudit[action_type] && auditExportHelper.exportAudit[action_type][resource_type]) {
            return auditExportHelper.exportAudit[action_type][resource_type](data)
        }
        else {
            return {
                rows: data.rows.map(row => ({
                    " date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                    "action": window.translate(row.action_type)+ " " + window.translate(row.resource_type),
                    ...this.getFactDimensions(row)
                }))
            }
        }
    }

    reportsEnabled() {
        return this.state.params.created_after && this.state.params.created_before && moment(this.state.params.created_before).diff(moment(this.state.params.created_after)) <= 90 * 24 * 60 * 60 * 1000
    }

    handleSubmit(params, reload = true) {
        Object.keys(params).forEach(key => !params[key] && delete params[key])
        this.setState({
            pivot_dimensions: params.resource_type && params.action_type && auditOptions[params.resource_type] && auditOptions[params.resource_type][params.action_type] ? auditOptions[params.resource_type][params.action_type] : this.state.pivot_dimensions,
            params,
            loading: false
        })
        if (params.action_type && params.resource_type) {
            this.setState({
                loadingAuditFactReport: true, loadingAuditDimensionReport: true,
                auditFactReport: [], auditDimensionReport: [],
            })
            groupAuditFactsByDate(params)
                .then(data => {
                    this.setState({ auditFactReport: data, loadingAuditFactReport: false })
                })
                .catch(err => this.props.handleNotification("error", window.translate("ERROR_LOADING_RESOURCES"), (err.message && window.translate(err.message))))
            groupAuditFactsByPivot(params)
                .then(data => {
                    this.setState({ auditDimensionReport: data, loadingAuditDimensionReport: false })
                })
                .catch(err => this.props.handleNotification("error", window.translate("ERROR_LOADING_RESOURCES"), (err.message && window.translate(err.message))))
        }
        setTimeout(() => { this.loadFilterName(); reload && this.crudTableRef.current.loadResources() }, 10);
    }

    handlePaginationChange(limit, offset) {
        this.handleSubmit({ ...this.state.params, limit, offset }, false)
    }

    handleSearchChange(search) {
        clearTimeout(this.searchTimeout)
        this.setState({ params: search })
        this.searchTimeout = setTimeout(() => this.handleSubmit(search), 1000);
    }

    loadFilterName() {
        let request
        switch (this.state.params.resource_type) {
            case "DATASET": request = getDataset(this.state.params.resource_id); break;
            case "INTENT": request = listAllIntents({ id: this.state.params.resource_id }).then(data => data.rows[0]); break;
            default: break;
        }
        switch (this.state.params.filter_dimension) {
            case "DATASET_ID": request = getDataset(this.state.params.filter_value); break;
            case "INTENT_ID": request = listAllIntents({ id: this.state.params.filter_value }).then(data => data.rows[0]); break;
            default: break;
        }
        request ? request.then(resource => this.setState({ filter_name: (resource && resource.name) || "" })) : this.setState({ filter_name: "" })
    }

    componentDidMount() {
        this.handleSubmit({
            resource_id: this.props.resource_id,
            resource_type: this.props.resource_type,
            ...this.state.params,
        }, false)
    }

    getFactDimensions(fact) {
        return fact.dimensions
            .sort((d1, d2) => d1.field > d2.field)
            .reduce((dimensions, dim) => ({ ...dimensions, [dim.field.toLowerCase()]: dim.value }), {})
    }
}


export default AuditExplorer