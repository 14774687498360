import React, { Component } from 'react';
import { Tile } from 'carbon-components-react';
import NodeChildrenEditor from './node-children-editor';

class MultiOutputEditor extends Component {

    state = {
        selectedNode: null
    }

    modalRef = React.createRef()

    render() {

        return (<>
            <Tile>
                <h3 style={{ flex: "1 1", margin: "10px" }}>{window.translate("ASSISTANT_RESPONDS")}</h3>
                <NodeChildrenEditor {...this.props} template={{ type: "response_condition" }} />
            </Tile>
        </>)
    }

}

export default MultiOutputEditor