import moment from 'moment';
import htmlToText from 'html-to-text'


export const exportAudit = {
    CREATE: {
        INTENT: function (data) { return getCreateIntent(data) },
        EXAMPLE: function (data) { return getCreateExample(data) },
        ENTITY: function (data) { return getCreateEntity(data) },
        ENTITY_VALUE: function (data) { return getCreateEntityValue(data) },
        EXPERIMENT: function (data) { return getCreateExperiment(data) },
        EVALUATION: function (data) { return getCreateEvaluation(data) }
    },
    UPDATE: {
        INTENT: function (data) { return getUpdateIntent(data) },
        REVIEW_EXAMPLE: function (data) { return getUpdateReviewExample(data) },
        EXAMPLE: function (data) { return getUpdateExample(data) },
        ENTITY: function (data) { return getUpdateEntity(data) },
        ENTITY_VALUE: function (data) { return getUpdateEntityValue(data) }
    },
    MAP: {
        REVIEW_EXAMPLE: function (data) { return getMapReviewExample(data) }
    },
    REMAP: {
        EXAMPLE: function (data) { return getRemapExample(data) }
    },
    ACCEPT: {
        REVIEW_EXAMPLE: function (data) { return getAcceptReviewExample(data) }
    },
    REJECT: {
        REVIEW_EXAMPLE: function (data) { return getRejectReviewExample(data) }
    },
    DELETE: {
        REVIEW_EXAMPLE: function (data) { return getDeleteReviewExample(data) },
        EXAMPLE: function (data) { return getDeleteExample(data) },
        INTENT: function (data) { return getDeleteIntent(data) },
        ENTITY: function (data) { return getDeleteEntity(data) },
        ENTITY_VALUE: function (data) { return getDeleteEntityValue(data) },
        EXPERIMENT: function (data) { return getDeleteExperiment(data) },
        EVALUATION: function (data) { return getDeleteEvaluation(data) },
        RECOMMENDATION: function (data) { return getDeleteRecommendation(data) }
    },
    MERGE: {
        INTENT: function (data) { return getMergeIntent(data) }
    },
    RETRAIN: {
        ASSISTANT_SKILL: function (data) { return getRetrainAssistantSkill(data) }
    },
    FEEDBACK: {
        ORCHESTRATOR: function (data) { return getFeedbackOrchestrator(data) }
    },
    EXECUTE: {
        RECOMMENDATION: function (data) { return getExecuteRecommendation(data) }
    },
}

function getExecuteRecommendation(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                "before": getResource(row, "RESOURCE_BEFORE"),
                ...getFactDimensions(row)
            }))
            .map(row => ({
                "date": row.date,
                "author": window.translate(row.author),
                "action": row.action,
                "dataset": row.dataset,
                "examples": row.before.metadata ? translateRecommendationExamples(row.before.metadata) : "",
                "name": row.evaluation,
                "type": window.translate(row.before.evaluation_type ? row.before.evaluation_type : ""),
                "total_deleted_examples": row.before.metadata ? getTotalDeletedExamples(row.before.metadata) : ""
            }))
    }
}

function getFeedbackOrchestrator(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                "before": getResource(row, "RESOURCE_BEFORE"),
                ...getFactDimensions(row)
            }))
            .map(row => ({
                "date": row.date,
                "author": window.translate(row.author),
                "action": row.action,
                "dataset": row.before.dataset_name ? row.before.dataset_name : "",
                "input_channel": getChannel(row.metadata ? row.metadata : {}),
                "example": (row.history_disambiguation_flow && JSON.parse(row.history_disambiguation_flow).length) ? JSON.parse(row.history_disambiguation_flow)[0].input : row.input,
                "example_history_disambiguation": (row.history_disambiguation_flow && JSON.parse(row.history_disambiguation_flow).length) ? [...JSON.parse(row.history_disambiguation_flow).slice(1).map(h => h.input), row.input] : "",
                "ID": row.before.intent_serial ? row.before.intent_serial : "",
                "orchestrator": row.orchestrator,
                "taxonomy": row.before.intent_name ? row.before.intent_name : "",
                "feedback_type": getFeedbackType(row.type)
            }))
    }
}

function getRetrainAssistantSkill(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row)
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "assistant_skill": row.assistant_skill,
                "include_dialog": getYesOrNo(row.include_dialog),
                "include_entities": getYesOrNo(row.include_entities),
                "include_intents": getYesOrNo(row.include_intents),
                "assistant_instance": row.assistant_instance,
                "type": window.translate(getRetrainType(row))
            }))
    }
}

function getMergeIntent(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row),
                "before": getResource(row, "RESOURCE_BEFORE"),
                "after": getResource(row, "RESOURCE_AFTER")
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset_merged": row.before.dataset_name ? row.before.dataset_name : "",
                "dataset_keeped": row.after.dataset_name ? row.after.dataset_name : "",
                "final_merged": getYesOrNo(row.before.final),
                "final_keeped": getYesOrNo(row.after.final),
                "out_of_context_merged": getYesOrNo(row.before.out_of_context),
                "out_of_context_keeped": getYesOrNo(row.after.out_of_context),
                "out_of_training_merged": getYesOrNo(row.before.out_of_training),
                "out_of_training_keeped": getYesOrNo(row.after.out_of_training),
                "ID_merged": row.before.serial,
                "ID_keeped": row.serial,
                "canonical_merged": row.before.canonical,
                "canonical_keeped": row.after.canonical,
                "answer_merged": getIntentAnswer(row.before.content, row.before.type),
                "answer_keeped": getIntentAnswer(row.after.content, row.after.type),
                "taxonomy_merged": row.before.name,
                "taxonomy_keeped": row.intent,
                "type_merged": window.translate(getIntentType(row.before.type)),
                "type_keeped": window.translate(getIntentType(row.after.type)),
                "context_variables_merged": row.before.context_variables ? getContextVariables(row.before) : {},
                "context_variables_keeped": getContextVariables(row.after),
                "output_variables_merged": getOutputVariables(row.before),
                "output_variables_keeped": getOutputVariables(row.after)
            }))
    }
}

function getDeleteRecommendation(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                "before": getResource(row, "RESOURCE_BEFORE"),
                ...getFactDimensions(row)
            }))
            .map(row => ({
                "date": row.date,
                "author": window.translate(row.author),
                "action": row.action,
                "dataset": row.dataset,
                "examples": row.before.metadata ? translateRecommendationExamples(row.before.metadata) : "",
                "name": row.evaluation,
                "type": window.translate(row.before.evaluation_type ? row.before.evaluation_type : ""),
                "total_suggested_examples": row.before.metadata ? row.before.metadata.delete_count : ""
            }))
    }
}

function getDeleteEvaluation(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                "before": getResource(row, "RESOURCE_BEFORE"),
                ...getFactDimensions(row)
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "deviations": row.before.type === "outlier_detection" ? row.before.deviations : "",
                "min_distance": row.before.type === "redundancy_detection" ? row.before.min_distance : "",
                "language": window.translate(row.before.language ? row.before.language : ""),
                "name": row.evaluation,
                "max_samples": row.before.type === "undersampling" ? row.before.max_samples : "",
                "type": window.translate(row.type)
            }))
    }
}

function getDeleteExperiment(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row),
                "before": getResource(row, "RESOURCE_BEFORE")
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "complete": getYesOrNo(row.before.complete),
                "language": window.translate(row.before.language ? row.before.language : ""),
                "name": row.before.name ? row.before.name : "",
                "train_size": row.before.train_size ? row.before.train_size : ""
            }))
    }
}

function getDeleteEntityValue(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row),
                "before": getResource(row, "RESOURCE_BEFORE")
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "entity": row.entity,
                "entity_value_name": row.entity_value,
                "synonyms": getCleanSynonyms(row.before.synonyms)
            }))
    }
}

function getDeleteEntity(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row),
                "before": getResource(row, "RESOURCE_BEFORE")
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "name": row.before.name,
                "entity_values": row.before.values && row.before.values.map(v => Object.keys(v).reduce((acc, k) => ({ ...acc, [window.translate(k)]: v[k] }), {})),
            }))
    }
}

function getDeleteIntent(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row),
                "before": getResource(row, "RESOURCE_BEFORE")
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "final": getYesOrNo(row.before.final),
                "out_of_context": getYesOrNo(row.before.out_of_context),
                "out_of_training": getYesOrNo(row.before.out_of_training),
                "ID_intent": row.serial,
                "canonical": row.before.canonical,
                "answer": getIntentAnswer(row.before.content, row.before.type),
                "taxonomy": row.before.name,
                "type": window.translate(getIntentType(row.before.type)),
                "context_variables": getContextVariables(row.before),
                "output_variables": getOutputVariables(row.before)
            }))
    }
}

function getDeleteExample(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row),
                "before": getResource(row, "RESOURCE_BEFORE")
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "input_channel": getChannel(row.before.metadata ? row.before.metadata : {}),
                "example": row.before.text,
                "example_status": window.translate(getExampleType(row.before.status)),
                "ID_intent": row.serial,
                "taxonomy": row.intent,
            }))
    }
}

function getDeleteReviewExample(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row),
                "before": getResource(row, "RESOURCE_BEFORE")
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "input_channel": getChannel(row.before.metadata ? row.before.metadata : {}),
                "example": row.before.text,
            }))
    }
}

function getRejectReviewExample(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row),
                "before": getResource(row, "RESOURCE_BEFORE"),
                "after": getResource(row, "RESOURCE_AFTER")
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "input_channel": getChannel(row.before.metadata ? row.before.metadata : {}),
                "example": row.before.text,
                "ID_intent": row.after.intent_serial ? row.after.intent_serial : "",
                "taxonomy": row.after.intent_name ? row.after.intent_name : ""
            }))
    }
}

function getAcceptReviewExample(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row),
                "before": getResource(row, "RESOURCE_BEFORE"),
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "input_channel": getChannel(row.before.metadata ? row.before.metadata : {}),
                "example": row.before.text,
                "ID_intent": row.before.intent_serial ? row.before.intent_serial : "",
                "taxonomy": row.before.intent_name ? row.before.intent_name : ""
            }))
    }
}

function getMapReviewExample(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row),
                "before": getResource(row, "RESOURCE_BEFORE")
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "input_channel": getChannel(row.before.metadata ? row.before.metadata : {}),
                "example": row.before.text ? row.before.text : "",
                "ID_intent": row.before.intent_serial ? row.before.intent_serial : "",
                "taxonomy": row.before.intent_name ? row.before.intent_name : "",
            }))
    }
}

function getUpdateEntityValue(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row),
                "before": getResource(row, "RESOURCE_BEFORE")
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "entity": row.entity,
                "entity_value_name": row.before.name,
                "entity_value_name_after": row.name,
                "synonyms": getCleanSynonyms(row.before.synonyms),
                "synonyms_after": getCleanSynonyms(JSON.parse(row.synonyms))
            }))
    }
}

function getUpdateEntity(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row),
                "before": getResource(row, "RESOURCE_BEFORE")
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "name": row.before.name,
                "name_after": row.name
            }))
    }
}

function getUpdateExample(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row),
                "before": getResource(row, "RESOURCE_BEFORE"),
                "after": getResource(row, "RESOURCE_AFTER"),
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "input_channel": getChannel(row.before.metadata ? row.before.metadata : {}),
                "example": row.before.text ? row.before.text : "",
                "example_after": row.text,
                "example_status": window.translate(getExampleType(row.before.status ? row.before.status : "")),
                "example_status_after": window.translate(getExampleType(row.status)),
                "ID_intent": row.after.intent_serial ? row.after.intent_serial : "",
                "taxonomy": row.intent,
            }))
    }
}

function getUpdateReviewExample(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row),
                "before": getResource(row, "RESOURCE_BEFORE"),
                "after": getResource(row, "RESOURCE_AFTER")
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "input_channel": getChannel(row.before.metadata ? row.before.metadata : {}),
                "example": row.before.text,
                "example_after": row.after.text,
            }))
    }
}

function getCreateEvaluation(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row)
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "deviations": row.type === "outlier_detection" ? row.deviations : "",
                "min_distance": row.type === "redundancy_detection" ? row.min_distance : "",
                "language": window.translate(row.language),
                "name": row.name,
                "max_samples": row.type === "undersampling" ? row.max_samples : "",
                "type": window.translate(row.type)
            }))
    }
}

function getCreateExperiment(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row)
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "complete": getYesOrNo(row.complete),
                "language": window.translate(row.language),
                "name": row.name,
                "train_size": row.train_size
            }))
    }
}

function getCreateEntityValue(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row),
                "after": getResource(row, "RESOURCE_AFTER"),
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "name": row.name,
                "entity": row.after.entity && row.after.entity.name,
                "synonyms": getCleanSynonyms(row.after.synonyms)
            }))
    }
}

function getCreateEntity(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row)
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "name": row.name,
            }))
    }
}

function getCreateExample(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row),
                "after": getResource(row, "RESOURCE_AFTER")
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "example": row.text,
                "example_status": window.translate(getExampleType(row.status)),
                "ID_intent": row.after.intent_serial ? row.after.intent_serial : "",
                "taxonomy": row.intent,
            }))
    }
}

function getCreateIntent(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row)
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "final": getYesOrNo(row.final),
                "out_of_context": getYesOrNo(row.out_of_context),
                "out_of_training": getYesOrNo(row.out_of_training),
                "ID_intent": row.serial,
                "canonical": row.canonical,
                "answer": getIntentAnswer(row.content, row.type),
                "taxonomy": row.name,
                "type": window.translate(getIntentType(row.type)),
                "context_variables": getContextVariables(row),
                "output_variables": getOutputVariables(row)
            }))
    }
}

function getRemapExample(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row),
                "before": getResource(row, "RESOURCE_BEFORE"),
                "after": getResource(row, "RESOURCE_AFTER")
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "input_channel": getChannel(row.after.metadata ? row.after.metadata : {}),
                "example": row.after.text ? row.after.text : "",
                "example_status": window.translate(getExampleType(row.after.status ? row.after.status : "")),
                "ID_intent_before": row.before.intent_serial ? row.before.intent_serial : "",
                "ID_intent_actual": row.after.intent_serial ? row.after.intent_serial : "",
                "taxonomy_before": row.before.intent_name ? row.before.intent_name : "",
                "taxonomy_actual": row.after.intent_name ? row.after.intent_name : ""
            }))
    }
}

function getUpdateIntent(data) {
    return {
        rows: data.rows
            .map(row => ({
                "date": moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
                "action": window.translate(row.action_type) + " " + window.translate(row.resource_type),
                ...getFactDimensions(row),
                "before": getResource(row, "RESOURCE_BEFORE")
            }))
            .map(row => ({
                "date": row.date,
                "author": row.author,
                "action": row.action,
                "dataset": row.dataset,
                "final": getYesOrNo(row.before.final),
                "final_after": getYesOrNo(row.final),
                "out_of_context": getYesOrNo(row.before.out_of_context),
                "out_of_context_after": getYesOrNo(row.out_of_context),
                "out_of_training": getYesOrNo(row.before.out_of_training),
                "out_of_training_after": getYesOrNo(row.out_of_training),
                "ID_intent": row.serial,
                "canonical": row.before.canonical,
                "canonical_after": row.canonical,
                "answer": getIntentAnswer(row.before.content, row.before.type),
                "answer_after": getIntentAnswer(row.content, row.type),
                "taxonomy": row.before.name,
                "taxonomy_after": row.name,
                "type": window.translate(getIntentType(row.before.type)),
                "type_after": window.translate(getIntentType(row.type)),
                "context_variables": getContextVariables(row.before),
                "context_variables_after": getContextVariables(row),
                "output_variables": getOutputVariables(row.before),
                "output_variables_after": getOutputVariables(row)
            }))
    }
}

function isJsonStructure(str) {
    try {
        const result = typeof str !== 'string' ? JSON.parse(JSON.stringify(str)) : JSON.parse(str)
        const type = Object.prototype.toString.call(result)
        return type === '[object Object]' || type === '[object Array]'
    } catch (err) {
        return false;
    }
}

function getContextVariables(row) {
    return isJsonStructure(row.context_variables) ? row.context_variables : {}
}

function getOutputVariables(row) {
    return isJsonStructure(row.output_variables) ? row.output_variables : {}
}

function translateRecommendationExamples(metadata) {
    return metadata.examples ? metadata.examples
        .map(example => ({
            [window.translate("EXAMPLE")]: example.text,
            [window.translate("TAXONOMY")]: example.intent_name,
            [window.translate("STATUS")]: window.translate(getExampleType(example.status)),
            [window.translate("EXAMPLE_KEEPED")]: getYesOrNo(example.keep)
        })) : ""
}

function getTotalDeletedExamples(metadata) {
    return metadata.examples.reduce((a, example) => (example.keep === false ? a + 1 : a), 0)
}

function getFeedbackType(type) {
    if (["Incorrect", "incorrect"].includes(type)) return window.translate("NEGATIVE")
    if (["Correct", "correct"].includes(type)) return window.translate("POSITIVE")
    if (["None", "none"].includes(type)) return window.translate("NONE")
    return ""
}

function getCleanSynonyms(synonyms) {
    return synonyms.map(syn => syn).join(';')
}

function getChannel(metadata) {
    return metadata.canal_cliente ? metadata.canal_cliente : "N/A"
}

function getResource(row, field) {
    let filtered = row.dimensions.filter(dim => dim.field === field)
    return (filtered.length > 0 && JSON.parse(filtered[0].value)) ? JSON.parse(filtered[0].value) : {}
}

function getYesOrNo(text) {
    return window.translate(["true", true].includes(text) ? "YES" : (["false", false].includes(text) ? "NO" : ""))
}

function getIntentAnswer(content, type) {
    return Number(type) < 2 ? getIntentCleanAnswer(content) : (Number(type) > 2 ? getAssistantIntentCleanAnswer(content) : getProcflowIntentCleanAnswer(content))
}

function getAssistantIntentCleanAnswer(content) {
    let contentStr = ""
    const lineToken = '\n'
    try {
        let answer = typeof(content) === "string" ? JSON.parse(content) : content
        if (Object.keys(answer).length > 0) {
            let orderedNodes = orderNodes(answer)
            for (var node of orderedNodes) {
                contentStr += lineToken + (window.translate("CONDITION")+": "+(node.conditions ? node.conditions + lineToken : lineToken)) +
                                (window.translate("ANSWER")+": "+(node.output.generic && node.output.generic.length > 0 ? 
                                    (node.output.generic[0].values && node.output.generic[0].values.length > 0 ? 
                                    (node.output.generic[0].values[0].text ? htmlToText.fromString(node.output.generic[0].values[0].text) + lineToken : lineToken ) : lineToken) : lineToken)) +
                                (window.translate("TYPE")+": "+(node.type ? window.translate(node.type) + lineToken : lineToken)) +
                                (window.translate("TITLE")+": "+(node.title ? node.title + lineToken : lineToken)) 
            }
            contentStr = contentStr.substring(0 + lineToken.length, contentStr.length - lineToken.length)
        }
    }
    catch (e) {}
    return contentStr
}

function getProcflowIntentCleanAnswer(content) {
    let contentStr = ""
    const lineToken = '\n'
    try {
        let node = typeof(content) === "string" ? JSON.parse(content) : content
        if (Object.keys(node).length > 0) {
            for (var child of node.childs) {
                if (child.subtype === "disambiguation") {
                    contentStr += getProcflowIntentCleanAnswer(child)
                }
                if (child.subtype === "options") {
                    contentStr += lineToken + (window.translate("CONDITION")+": "+(child.value.text.length > 0 ? child.value.text[0] + lineToken : lineToken) +
                                (window.translate("ANSWER")+": "+((child.childs.length > 0) && (child.childs[0].subtype === "straight") ? 
                                        (htmlToText.fromString(child.childs[0].value.text[0]) + lineToken) : (child.childs[0].subtype === "goTo" ? 
                                        window.translate("ID") + " " + child.childs[0].value.goTo.value + lineToken : lineToken))) +
                                (window.translate("TYPE")+": "+(child.childs[0].subtype === "goTo" ? window.translate("GO_TO") + lineToken : 
                                    (child.childs[0].subtype === "straight" ? window.translate("NORMAL") + lineToken : window.translate(node.subtype) + lineToken))) +
                                (window.translate("TITLE")+": "+ lineToken))
    
                    if (Object.keys(child.childs).length > 0) {
                        for (var grandchild of child.childs) {
                            if (grandchild.subtype === "disambiguation") contentStr += getProcflowIntentCleanAnswer(grandchild)
                        }
                    }
                }
            }
            contentStr = contentStr.substring(0 + lineToken.length, contentStr.length - lineToken.length)
        }
    }
    catch (e) {}
    return contentStr
}

function getIntentCleanAnswer(content) {
    return htmlToText.fromString(String(content).replace(/[-"[\]]/g, ''))
}

function getRetrainType(row) {
    return row.complete === "true" ? "COMPLETE_RETRAIN" : (
        row.complete === "false" && row.include_intents === "true" ? "SIMPLE_RETRAIN" : (
            row.complete ? "DIALOG_RETRAIN" : ""))
}

function getIntentType(type) {
    const types = { 0: "NORMAL", 1: "DISAMBIGUATION", 2: "PROCFLOW", 3: "ASSISTANT" }
    return types[Number(type)] ? types[Number(type)] : ""
}

function getExampleType(type) {
    const types = { 0: "NOT_FIXED", 1: "FIXED" }
    return types[Number(type)] ? types[Number(type)] : ""
}

function getFactDimensions(fact) {
    return fact.dimensions
        .sort((d1, d2) => d1.field > d2.field)
        .reduce((dimensions, dim) => ({ ...dimensions, [dim.field.toLowerCase()]: dim.value }), {})
}

function orderChildren(children, parent) {
    return children
        .filter(node => node.parent === (parent ? parent.dialog_node : undefined))
        .reduce((orderedChildren, _1, _2, allChildren) => {
            if (orderedChildren.length === 0) return [allChildren.find(child => !child.previous_sibling)]
            let nextChild = allChildren.find(child => child.previous_sibling === orderedChildren.slice(-1)[0].dialog_node)
            if (!nextChild) return orderedChildren
            return [...orderedChildren, nextChild]
        }, [])
}

function orderNodes(nodes, parent, current = []) {
    let children = orderChildren(nodes, parent)
    let childrensChildren = children.map(node => [node, ...orderNodes(nodes, node, current)])
    let result = childrensChildren.reduce((acc, children) => [...acc, ...children], current)
    return result
}

export default {
    exportAudit
}