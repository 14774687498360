import React, { Component } from 'react';
import { RecordPicker } from '../../../../components/carbon-react-crud';
import { listDatasets, listIntents, listEvaluations } from '../../../../services/corpus';
import { listAssistantInstances, listAllAssistantSkills } from '../../../../services/orchestrator';

class ResourceFinder extends Component {

    render() {
        return <>
            <br />
            {this.props.value.dataset_id &&
                <RecordPicker
                    label={window.translate("DATASET")}
                    id="select-1"
                    value={this.props.value.dataset_id}
                    onChange={() => { }}
                    fetchOptions={(url, params) => listDatasets(params)}
                    disabled
                />}
            {this.props.value.evaluation_id &&
                <RecordPicker
                    label={window.translate("EVALUATION")}
                    id="select-2"
                    value={this.props.value.evaluation_id}
                    onChange={() => { }}
                    fetchOptions={(url, params) => listEvaluations(this.props.dimensions.dataset_id, params)}
                    disabled
                />}
            {this.props.value.intent_id &&
                <RecordPicker
                    label={window.translate("INTENT")}
                    id="select-3"
                    value={this.props.value.intent_id}
                    onChange={() => { }}
                    fetchOptions={(url, params) => listIntents(this.props.dimensions.dataset_id, params)}
                    disabled
                />}
            {this.props.value.assistant_instance_id &&
                <RecordPicker
                    label={window.translate("ASSISTANT_INSTANCE")}
                    id="select-4"
                    value={this.props.value.assistant_instance_id}
                    onChange={() => { }}
                    fetchOptions={(url, params) => listAssistantInstances(params)}
                    disabled
                />}
            {this.props.value.assistant_skill_id &&
                <RecordPicker
                    label={window.translate("ASSISTANT_SKILL")}
                    id="select-5"
                    value={this.props.value.assistant_skill_id}
                    onChange={() => { }}
                    fetchOptions={(url, params) => listAllAssistantSkills(params)}
                    disabled
                />}
            <br /><br />
        </>
    }

}

export default ResourceFinder