import React, { Component } from 'react';
import renderHTML from 'react-render-html'
import { Tile, TextInput, Button, Accordion, AccordionItem, Loading } from 'carbon-components-react';
import { messageOrchestrator, messageAssistantSkill } from '../../../services/orchestrator'
import { JSONEditor } from '../../../components/carbon-react-crud'


class QueryInterface extends Component {

    state = {
        input: { text: "", include_all_intents: true },
        messageResponse: null,
        loading: false
    }


    render() {
        return (<>
            <Loading active={this.state.loading} />
            <h3>{window.translate("QUERY")}</h3>
            <div style={{ display: "flex", margin: "auto" }}>
                <TextInput
                    onChange={ev => this.setState({ input: { text: ev.target.value, include_all_intents: true } })}
                    onKeyPress={ev => ev.key === "Enter" ? this.sendMessage(this.state.input) : undefined}
                    value={this.state.input.text}
                />
                <Button onClick={ev => this.sendMessage(this.state.input)}>{window.translate("SEND")}</Button>
            </div>
            <br />
            {this.state.messageResponse && !this.state.loading && <>
                <Tile style={{ height: "80vh", margin: "auto", overflow: "scroll" }}>
                    <h3>{window.translate("RESPONSE")}:</h3>
                    {this.state.messageResponse.intents.length > 0 && <h4>{this.state.messageResponse.intents[0].intent} ({this.state.messageResponse.intents[0].confidence})</h4>}
                    {this.state.messageResponse.output.text.map(txt => <Tile style={{ margin: "10px" }}>
                        {renderHTML(txt)}
                    </Tile>)}
                    <Accordion>
                        <AccordionItem title={window.translate("TOP_INTENTS")}>
                            <Accordion>
                                {this.state.messageResponse.intents.slice(1).map(intent =>
                                    <AccordionItem title={`${intent.intent} (${(intent.confidence * 100).toFixed(2)})`}>
                                        {renderHTML(intent.response || window.translate("DISAMBIGUATION_OR_PROCFLOW_INTENT"))}
                                    </AccordionItem>
                                )}
                            </Accordion>
                        </AccordionItem>
                        {this.state.messageResponse.relevant_documents && <AccordionItem title={window.translate("DOCUMENTS")}>
                            {this.state.messageResponse.relevant_documents.map(document =>
                                <AccordionItem title={document.name}>
                                    {renderHTML(document.content || "")}
                                </AccordionItem>
                            )}
                        </AccordionItem>}
                    </Accordion>
                    <br /><br />
                    <JSONEditor value={this.state.messageResponse} />
                </Tile>
            </>}
        </>)
    }

    sendMessage(input) {
        this.setState({ loading: true })
        return (this.props.orchestrator_id ?
            messageOrchestrator(this.props.orchestrator_id, { input }) :
            messageAssistantSkill(this.props.assistant_skill_id, { input })
        )
            .then(data => this.setState({ messageResponse: data }))
            .catch(err => this.setState({ messageResponse: { output: { text: ["Error"] } } }))
            .then(() => this.setState({ loading: false }))
    }
}

export default QueryInterface