import React, { Component } from 'react';
import { Button, Icon, TextInput, StructuredListWrapper, StructuredListHead, StructuredListRow, StructuredListCell, StructuredListBody } from 'carbon-components-react';
import { iconAddOutline, iconDelete, iconChevronUp, iconChevronDown, iconSettings } from 'carbon-icons'
import Modal from '../../../../../../components/modal';
import DialogNodeForm from '../';
import ConditionEditor from '../../../condition-editor';

class NodeChildrenEditor extends Component {

    state = {
        selectedChild: null
    }

    modalRef = React.createRef()

    render() {

        // Target nodes that fit into the template
        let targetNodes = this.props.dialogNodes.filter(node => {
            return this.props.selectedNode && node.parent === this.props.selectedNode.dialog_node &&
                Object.keys(this.props.template).reduce((fitsTemplate, key) => fitsTemplate && this.props.template[key] === node[key], true)
        })

        return (<>
            <StructuredListWrapper style={{ marginBottom: 0 }}>
                <StructuredListHead>
                    <StructuredListRow head>
                        <StructuredListCell head></StructuredListCell>
                        <StructuredListCell head>{window.translate("IF_ASSISTANT_RECOGNIZES")}</StructuredListCell>
                        <StructuredListCell head>{window.translate("ASSISTANT_RESPONDS")}</StructuredListCell>
                        <StructuredListCell head>{window.translate("AND_FINALLY")}</StructuredListCell>
                        <StructuredListCell head></StructuredListCell>
                        <StructuredListCell head></StructuredListCell>
                    </StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                    {targetNodes.map((child, index) => <StructuredListRow>
                        <StructuredListCell style={{ textAlign: "center" }}>
                            {<Button disabled={index === 0} kind="secondary" className="btn-icon-only" onClick={() => this.props.onMove(child, child.parent, targetNodes[index - 1].previous_sibling, "multiResponse")}><Icon icon={iconChevronUp} /></Button>}
                            <br />{index + 1}<br />
                            {<Button disabled={index === targetNodes.length - 1} kind="secondary" className="btn-icon-only" onClick={() => this.props.onMove(child, child.parent, targetNodes[index + 1].dialog_node, "multiResponse")}><Icon icon={iconChevronDown} /></Button>}
                        </StructuredListCell>
                        <StructuredListCell style={{ position: "static" }}>
                            <br /><ConditionEditor dataset_id={this.props.dataset_id} onChange={conditions => this.props.onChange(child, { conditions })} value={child.conditions} />
                        </StructuredListCell>
                        <StructuredListCell>
                            <br />
                            {child.output.generic && child.output.generic[0] ?
                                <TextInput
                                    onChange={ev => this.props.onChange(child, { output: { ...child.output, generic: [{ response_type: "text", selection_policy: "sequential", values: [{ text: ev.target.value }] }] } })}
                                    onKeyPress={ev => ev.key === 'Enter' ? ev.target.blur() : null}
                                    value={child.output.generic[0].values[0] ? child.output.generic[0].values[0].text : ""} /> :
                                <TextInput disabled value="" />
                            }
                        </StructuredListCell>
                        <StructuredListCell>
                            <br />{child.next_step && child.next_step.behavior === "jump_to" ? "Jump" : "-"}
                        </StructuredListCell>
                        <StructuredListCell>
                            <br />
                            <Button kind="secondary" className="btn-icon-only" onClick={() => { this.modalRef.current.handleOpen(); this.setState({ selectedChild: child }) }}><Icon icon={iconSettings} /></Button>
                        </StructuredListCell>
                        <StructuredListCell>
                            <br />
                            <Button kind="secondary" className="btn-icon-only" onClick={() => this.props.onDelete(child)}><Icon icon={iconDelete} /></Button>
                        </StructuredListCell>
                    </StructuredListRow>)}
                </StructuredListBody>
            </StructuredListWrapper>
            <Button kind="secondary" style={{ borderWidth: 0 }} onClick={() => {
                let lastChild = targetNodes.slice(-1)[0]
                if (lastChild) this.props.onAdd(lastChild, "below", this.props.template)
                else this.props.onAdd(this.props.selectedNode, "child", this.props.template)
            }}>{window.translate("ADD_RESPONSE")}<Icon icon={iconAddOutline} /></Button>
            {<Modal
                hideTrigger
                ref={this.modalRef}
                title={`${window.translate("CONFIGURE_RESPONSE")} ${targetNodes.map(n => n.dialog_node).indexOf(this.state.selectedChild && this.state.selectedChild.dialog_node) + 1}`}
                handleSubmit={() => this.modalRef.current.handleClose()}
            >
                {this.state.selectedChild && <DialogNodeForm
                    {...this.props}
                    onChange={this.handleChange.bind(this)}
                    selectedNode={this.state.selectedChild}
                />}
            </Modal>}
        </>)
    }

    handleChange(node, change) {
        if (change.next_step && change.next_step.behavior === "jump_to" && (!node.next_step || change.next_step.behavior !== node.next_step.behavior)) {
            this.modalRef.current.handleClose()
            return this.props.onBind(node, "jump")
        }
        this.setState({ selectedChild: { ...node, ...change } })
        this.props.onChange(node, change)
    }

}

export default NodeChildrenEditor