import React, { Component } from 'react';
import ConditionEditor from './condition-editor';
import { Select, Button, SelectItem } from 'carbon-components-react';

class MultiConditionEditor extends Component {

    render() {
        return (<>
            <div className="bx--grid">
                <div className="bx--row" style={{ position: "relative" }}>
                    {this.splitCondition(this.props.value).map((part, i, parts) => i % 2 === 0 ?
                        <div className="bx--col-xs-10" style={{ position: "static" }}>
                            <ConditionEditor
                                {...this.props}
                                onChange={(condition, destroy) => this.handlePartChange(condition, i, destroy)}
                                value={part}
                            />
                        </div>
                        :
                        <div className="bx--col-xs-2">
                            <Select hideLabel value={part} onChange={ev => this.handlePartChange(ev.target.value, i)}>
                                <SelectItem value={'&&'} text={'and'} />
                                <SelectItem value={'||'} text={'or'} />
                            </Select>
                        </div>
                    )}
                    <Button kind="secondary" className="btn-icon-only" style={{ height: "2.5rem" }} onMouseDown={() => this.props.onChange(this.props.value + ' && ')}>+</Button>
                </div>
            </div>
        </>)
    }

    splitCondition(condition) {
        return condition
            .split(/ && (?![^(]*\))/)
            .reduce((parts, andPart) => [
                ...parts,
                '&&',
                ...andPart
                    .split(/ \|\| (?![^(]*\))/)
                    .reduce((orParts, orPart) => [
                        ...orParts,
                        '||',
                        orPart
                    ], [])
                    .slice(1)
            ], [])
            .slice(1)
    }

    handlePartChange(condition, i, destroy) {
        let parts = this.splitCondition(this.props.value)

        return this.props.onChange(destroy ?
            i === 0 ?
                parts.slice(2).join(' ')
                :
                [
                    ...parts.slice(0, i - 1),
                    ...parts.slice(i + 1)
                ].join(' ')
            :
            [
                ...parts.slice(0, i),
                condition,
                ...parts.slice(i + 1)
            ].join(' '))
    }

}

export default MultiConditionEditor