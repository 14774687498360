import React, { Component } from 'react';

class Page404 extends Component {
    render() {
        return (
            <>
                <br />
                <h3> {window.translate("PAGE_NOT_FOUND")} </h3>
                <br />
            </>
        )
    }
}

export default Page404