module.exports = ({
    DATASET: {
        CREATE: ['AUTHOR', 'DATASET'], UPDATE: ['AUTHOR', 'DATASET'], DELETE: ['AUTHOR'], IMPORT: ['AUTHOR', 'DATASET'], EXPORT: ['AUTHOR','DATASET']
    },
    INTENT: {
        CREATE: ['AUTHOR', 'DATASET'], UPDATE: ['AUTHOR', 'DATASET', 'INTENT'], DELETE: ['AUTHOR', 'DATASET'], MERGE: ['AUTHOR', 'DATASET', 'INTENT']
    },
    EXAMPLE: {
        CREATE: ['AUTHOR', 'DATASET', 'INTENT'], UPDATE: ['AUTHOR', 'DATASET', 'INTENT'], DELETE: ['AUTHOR', 'DATASET', 'INTENT'], REMAP: ['AUTHOR', 'DATASET', 'INTENT']
    },
    ENTITY: {
        CREATE: ['AUTHOR', 'DATASET'], UPDATE: ['AUTHOR', 'DATASET', 'ENTITY'], DELETE: ['AUTHOR', 'DATASET']
    },
    ENTITY_VALUE: {
        CREATE: ['AUTHOR', 'DATASET', 'ENTITY'], UPDATE: ['AUTHOR', 'DATASET', 'ENTITY', 'ENTITY_VALUE'], DELETE: ['AUTHOR', 'DATASET', 'ENTITY']
    },
    REVIEW_EXAMPLE: {
        CREATE: ['AUTHOR', 'DATASET'], UPDATE: ['AUTHOR', 'DATASET'], DELETE: ['AUTHOR', 'DATASET'], MAP: ['AUTHOR', 'DATASET'], ACCEPT: ['AUTHOR', 'DATASET'], REJECT: ['AUTHOR', 'DATASET']
    },
    EXPERIMENT: {
        CREATE: ['AUTHOR', 'DATASET'], UPDATE: ['AUTHOR', 'DATASET'], DELETE: ['AUTHOR', 'DATASET']
    },
    EVALUATION: {
        CREATE: ['AUTHOR', 'DATASET'], UPDATE: ['AUTHOR', 'DATASET'], DELETE: ['AUTHOR', 'DATASET']
    },
    RECOMMENDATION: {
        UPDATE: ['AUTHOR', 'DATASET', 'EVALUATION'], DELETE: ['AUTHOR', 'DATASET', 'EVALUATION'], EXECUTE: ['AUTHOR', 'DATASET', 'EVALUATION']
    },
    ASSISTANT_INSTANCE: {
        CREATE: ['AUTHOR'], UPDATE: ['AUTHOR', 'ASSISTANT_INSTANCE'], DELETE: ['AUTHOR']
    },
    ASSISTANT_SKILL: {
        CREATE: ['AUTHOR', 'ASSISTANT_INSTANCE'], UPDATE: ['AUTHOR', 'ASSISTANT_INSTANCE', 'ASSISTANT_SKILL'], DELETE: ['AUTHOR', 'ASSISTANT_INSTANCE'], RETRAIN: ['AUTHOR', 'ASSISTANT_INSTANCE', 'ASSISTANT_SKILL']
    },
    DISCOVERY_INSTANCE: {
        CREATE: ['AUTHOR'], UPDATE: ['AUTHOR', 'DISCOVERY_INSTANCE'], DELETE: ['AUTHOR']
    },
    DISCOVERY_PROJECT: {
        CREATE: ['AUTHOR', 'DISCOVERY_INSTANCE'], UPDATE: ['AUTHOR', 'DISCOVERY_INSTANCE', 'DISCOVERY_PROJECT'], DELETE: ['AUTHOR', 'DISCOVERY_INSTANCE'], INDEX: ['AUTHOR', 'DISCOVERY_INSTANCE', 'DISCOVERY_PROJECT'], DEINDEX: ['AUTHOR', 'DISCOVERY_INSTANCE', 'DISCOVERY_PROJECT']
    },
    DISCOVERY_COLLECTION: {
        CREATE: ['AUTHOR', 'DISCOVERY_INSTANCE'], UPDATE: ['AUTHOR', 'DISCOVERY_INSTANCE', 'DISCOVERY_COLLECTION'], DELETE: ['AUTHOR', 'DISCOVERY_INSTANCE'], INDEX: ['AUTHOR', 'DISCOVERY_INSTANCE', 'DISCOVERY_COLLECTION'], DEINDEX: ['AUTHOR', 'DISCOVERY_INSTANCE', 'DISCOVERY_COLLECTION']
    },
    ORCHESTRATOR: {
        CREATE: ['AUTHOR'], UPDATE: ['AUTHOR', 'ORCHESTRATOR'], DELETE: ['AUTHOR'], FEEDBACK: ['AUTHOR', 'ORCHESTRATOR', 'TYPE']
    },
    USER: {
        CREATE: ['AUTHOR'], UPDATE: ['AUTHOR', 'USER'], DELETE: ['AUTHOR']
    },
})