let React = require('react')
let { ListItem } = require('carbon-components-react')
let moment = require('moment')
let h = require('../handlers/orchestrator.js')
try { require('moment/locale/' + navigator.language.slice(0, 2)) } catch { }

module.exports = {
    AssistantInstance: {
        title: window.translate("Assistant_Instances"),
        fields: [
            { key: "name", type: "text", header: true },
            { key: "username", type: "text", },
            { key: "password", type: "password", },
            { key: "url", type: "text", },
            { key: "created_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
            { key: "updated_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
        ],

        actions: [
            { path: "assistant_skills", text: "ASSISTANT_SKILLS", link: true },
            { path: "permissions", text: "PERMISSIONS", link: true, roles: ["admin"] },
        ],

        permissionsPath: id => `/orchestrator/assistant_instances/${id}/permissions/me`,
        audit: "ASSISTANT_INSTANCE",

    },

    AssistantSkill: {
        title: window.translate("Assistant_Skills"),
        fields: [
            { key: "serial", type: "id", header: false },
            { key: "name", type: "text", header: true },
            { key: "language", type: "select", header: false, options: [{ label: "", value: "" }, { label: window.translate("en"), value: "en" }, { label: window.translate("pt-br"), value: "pt-br" }, { label: window.translate("es"), value: "es" }, { label: window.translate("fr"), value: "fr" }] },
            { key: "trained_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
            { key: "status", type: "none", header: true, parse: status => window.translate(status.split(' -')[0]) },
            { key: "router", type: "toggle" },
            { key: "parent_id", type: "fkey", path: "/orchestrator/assistant_skills?router=true" },
            { key: "replication_id", type: "fkey", path: "/orchestrator/assistant_skills" },
            { key: "dataset_id", type: "fkey", path: "/corpus/datasets" },
            { key: "workspace_id", type: "text" },
            { key: "auto_retrain", type: "toggle" },
        ],

        actions: [
            { path: "test", text: "TEST", link: true },
            { path: "query", text: "QUERY", link: true },
            { path: "intents", text: "INTENTS", link: true },
            { path: "export", text: "EXPORT", batch: true, link: false, method: "get", download: ".json" },
            { onClick: (skill) => h.actions.retrain(skill), text: "RETRAIN", batch: true, link: false, roles: ["admin", "auditor", "editor"] },
            { path: "retrain?complete=true", text: "COMPLETE_RETRAIN", condition: skill => skill.router, batch: true, link: false, roles: ["admin", "auditor", "editor"] },
            { path: "retrain?include_intents=false&include_entities=false", batch: true, text: "RETRAIN_DIALOG", link: false, roles: ["admin", "auditor", "editor"] },
            { path: "snapshots", text: "SNAPSHOTS", link: true, roles: ["admin", "auditor", "editor"] },
            { path: "permissions", text: "PERMISSIONS", link: true, roles: ["admin"] },
        ],

        permissionsPath: id => `/orchestrator/assistant_skills/${id}/permissions/me`,

        liveReload: true,
        audit: "ASSISTANT_SKILL",

        renderDetail: (skill => {
            return <>
                <div style={{ paddingTop: "1rem"}}>
                    Status: {window.translate(skill.status)}
                    <br /><br />
                    {skill.warnings ? skill.warnings.map((warn, i) => <ListItem style={{ color: "red", fontWeight: "normal" }} key={`row-${i}`}>
                        {window.translate(warn.type).replace('{dialog_node}', warn.fields[0]).replace('{jump_dialog_node}', warn.fields[1])}
                    </ListItem>) : ""}
                </div>            
            </>
        })
    },

    Snapshot: {
        title: window.translate("Snapshots"),
        fields: [
            { key: "name", type: "text", header: true },
            { key: "status", type: "none", header: true, parse: status => window.translate(status) },
            { key: "created_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
        ],

        actions: [
            { path: "restore", text: "RESTORE", link: false },
        ],

        liveReload: true
    },

    DiscoveryInstance: {
        title: window.translate("Discovery_Instances"),
        fields: [
            { key: "name", type: "text", header: true },
            { key: "username", type: "text", },
            { key: "password", type: "password", },
            { key: "url", type: "text", },
            { key: "created_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
            { key: "updated_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
        ],

        actions: [
            { path: "discovery_projects", text: "DISCOVERY_PROJECTS", link: true },
            { path: "permissions", text: "PERMISSIONS", link: true },
        ],

        permissionsPath: id => `/orchestrator/discovery_instances/${id}/permissions/me`,
        audit: "SNAPSHOT",
    },

    DiscoveryProject: {
        title: window.translate("Discovery_Projects"),
        fields: [
            { key: "name", type: "text", header: true },
            {
                key: "type", type: "select", header: true, options: [
                    { label: "", value: "" }, 
                    { label: window.translate("CUSTOM"), value: "other" }, 
                    { label: window.translate("DOCUMENT_RETRIEVAL"), value: "document_retrieval" }, 
                    { label: window.translate("CONVERSATIONAL_SEARCH"), value: "conversational_search" }
                ]
            },
            { key: "created_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
            { key: "updated_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
        ],

        actions: [
            { path: "discovery_collections", text: "DISCOVERY_COLLECTIONS", link: true },
            { path: "permissions", text: "PERMISSIONS", link: true },
        ],

        permissionsPath: id => `/orchestrator/discovery_instances/${id}/permissions/me`,
    },

    DiscoveryCollection: {
        title: window.translate("Discovery_Collections"),
        fields: [
            { key: "name", type: "text", header: true },
            { key: "language", type: "select", header: true, options: [{ label: "", value: "" }, { label: window.translate("en"), value: "en" }, { label: window.translate("pt"), value: "pt" }, { label: window.translate("es"), value: "es" }] },
            { key: "indexed_at", type: "none", header: true },
            { key: "status", type: "none", header: true, parse: status => window.translate(status) },
            { key: "knowledge_base_id", type: "fkey", path: "/documents/knowledge_bases" },
        ],


        actions: [
            { path: "test", text: "TEST", link: true },
            { path: "index", text: "INDEX", link: false, roles: ["admin", "auditor", "editor"] },
            { path: "deindex", text: "DEINDEX", link: false, roles: ["admin", "auditor", "editor"] },
            { path: "permissions", text: "PERMISSIONS", link: true },
        ],

        permissionsPath: id => `/orchestrator/discovery_collections/${id}/permissions/me`,

        liveReload: true
    },


    Orchestrator: {
        title: window.translate("Orchestrators"),
        fields: [
            { key: "name", type: "text", header: true },
            { key: "assistant_skill_id", type: "fkey", path: "/orchestrator/assistant_skills" },
            { key: "discovery_collection_id", type: "fkey", path: "/orchestrator/discovery_collections" },
            { key: "created_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
            { key: "updated_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
        ],

        actions: [
            { path: "test", text: "TEST", link: true },
            { path: "query", text: "QUERY", link: true },
            { path: "permissions", text: "PERMISSIONS", link: true },
        ],

        permissionsPath: id => `/orchestrator/orchestrators/${id}/permissions/me`,
        audit: "ORCHESTRATOR",
    }
}