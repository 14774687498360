module.exports = {
    "ADD": "Novo",
    "CANCEL": "Cancelar",
    "CONFIRM": "Confirmar",
    "SUBMIT": "Enviar",
    "EDIT": "Editar",
    "DELETE": "Apagar",
    "ARE_YOU_SURE": "Você tem certeza?",
    "ERROR_LOADING_RESOURCES": "Erro ao carregar recursos",
    "ERROR_CREATING_RESOURCE": "Erro ao criar recurso",
    "SUCCESS_CREATING_RESOURCE": "Recurso criado com sucesso",
    "ERROR_DELETING_RESOURCE": "Erro ao apagar recurso",
    "SUCCESS_DELETING_RESOURCE": "Recurso apagado com sucesso",
    "SEARCH": "Buscar",
    "ADVANCED_SEARCH": "Busca avançada",
    "CLEAR": "Limpar",
    "EXPORT": "Exportar",
}