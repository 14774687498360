import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Select, SelectItem, Tile, Link, Button, Icon } from 'carbon-components-react';
import { iconCloseOutline } from 'carbon-icons';
import { listAllIntents } from '../../../../../../services/corpus'

class NextStepEditor extends Component {

    state = {
        jumpTargetName: ""
    }

    render() {

        let options = this.getOptions()

        return (
            <Tile>
                <h3>{window.translate("THEN_ASSISTANT_SHOULD")}:</h3>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: 0.6 }}>
                        <Select labelText="" value={this.props.selectedNode.next_step ? this.props.selectedNode.next_step.behavior : "undefined"} onChange={ev => this.props.onChange(this.props.selectedNode, { next_step: ev.target.value ? { behavior: ev.target.value } : undefined })}>
                            {options.map(option => <SelectItem key={option.value} label={option.label} value={option.value} />)}
                        </Select>
                    </div>
                    {this.props.selectedNode.next_step && this.props.selectedNode.next_step.behavior === "jump_to" && <div>
                        <Link href="#">{`${this.state.jumpTargetName} (${this.props.selectedNode.next_step.selector})`}</Link>
                        <Button className="btn-icon-only" kind='secondary' onClick={ev => this.props.onChange(this.props.selectedNode, { next_step: null })}><Icon icon={iconCloseOutline} /></Button>
                    </div>}
                </div>
            </Tile>
        )
    }

    componentDidUpdate(props) {
        if (
            props.selectedNode && props.selectedNode.next_step && props.selectedNode.next_step.dialog_node &&
            ((this.props.selectedNode.next_step && props.selectedNode.next_step.dialog_node !== this.props.selectedNode.next_step.dialog_node) || !this.state.jumpTargetName))
            this.loadJumpToTargetName(props.selectedNode.next_step.dialog_node)
    }

    componentDidMount() {
        this.forceUpdate()
    }

    // Get select menu options for the selected dialog node
    getOptions() {
        return [
            { value: undefined, label: window.translate("DEFAULT") },
            { value: "get_user_input", label: window.translate("WAIT_FOR_USER_INPUT") },
            { value: "jump_to", label: window.translate("JUMP_TO") },
            { value: "skip_user_input", label: window.translate("SKIP_USER_INPUT") },
            { value: "reprompt", label: window.translate("PROMPT_AGAIN") },
            { value: "skip_slot", label: window.translate("SKIP_CURRENT_SLOT") },
            { value: "skip_all_slots", label: window.translate("SKIP_TO_RESPONSE") },
            { value: "call_subdialog", label: window.translate("CLEAR_SLOT_AND_PROMPT_AGAIN") },
        ]
            .filter(option => this.getCondition(option.value))
    }

    // Whether this behavior is allowed for the selected dialog node
    getCondition(behavior) {
        return {
            [undefined]: this.props.selectedNode.type === "response_condition" || this.props.selectedNode.event_name === "filled",
            get_user_input:
                ["standard", "frame"].includes(this.props.selectedNode.type) ||
                this.props.selectedNode.event_name === "nomatch",
            jump_to: ["standard", "frame", "response_condition"].includes(this.props.selectedNode.type),
            skip_user_input:
                ["standard", "frame"].includes(this.props.selectedNode.type) &&
                this.props.dialogNodes.find(child =>
                    child.parent === this.props.selectedNode.dialog_node &&
                    ["standard", "frame"].includes(child.type)
                ),
            reprompt: ["generic", "filled", "nomatch"].includes(this.props.selectedNode.event_name),
            skip_slot: ["generic", "nomatch"].includes(this.props.selectedNode.event_name),
            skip_all_slots: ["generic", "filled", "nomatch"].includes(this.props.selectedNode.event_name),
            call_subdialog: false,
            move_on: this.props.selectedNode.event_name === "filled",
        }[behavior]
    }

    async loadJumpToTargetName(nodeID) {
        if (!this.props.match) return
        let nodeName = nodeID
        let [intentsWithThisID, intentsThatReferenceThisID] = await Promise.all([
            listAllIntents({ id: nodeID, siblings_of: this.props.match.params.dataset_id }),
            listAllIntents({ search: nodeID, siblings_of: this.props.match.params.dataset_id, type: 3 })
        ])
        if (intentsWithThisID.count) {
            nodeName = intentsWithThisID.rows[0].name
        }
        else if (intentsThatReferenceThisID.count) {
            nodeName = intentsThatReferenceThisID
                .rows
                .filter(intent => intent.content.find(node => node.dialog_node === nodeID))
                .reduce((name, intent) => `${intent.content.find(node => node.dialog_node === nodeID).title || "???"} (${intent.name})`, "")
        }
        if (nodeName != this.state.jumpTargetName)
            this.setState({ jumpTargetName: nodeName })
    }
}

NextStepEditor.defaultProps = {
    selectedNode: { next_step: {} },
    children: []
}

export default withRouter(NextStepEditor)