const { basicServiceRequest } = require('./_basic')

let orchestratorRequest = basicServiceRequest('/orchestrator')

let listOrchestrators = (params) => orchestratorRequest({ method: 'GET', url: '/orchestrators', params })
let createOrchestrator = (data) => orchestratorRequest({ method: 'POST', url: '/orchestrators', data })
let getOrchestrator = (id) => orchestratorRequest({ method: 'GET', url: `/orchestrators/${id}` })
let updateOrchestrator = (id, data) => orchestratorRequest({ method: 'PUT', url: `/orchestrators/${id}`, data })
let deleteOrchestrator = (id) => orchestratorRequest({ method: 'DELETE', url: `/orchestrators/${id}` })

let listOrchestratorPermissions = (orchestrator_id, params) => orchestratorRequest({ method: 'GET', url: `/orchestrators/${orchestrator_id}/permissions`, params })
let createOrchestratorPermission = (orchestrator_id, data) => orchestratorRequest({ method: 'POST', url: `/orchestrators/${orchestrator_id}/permissions`, data })
let updateOrchestratorPermission = (orchestrator_id, id, data) => orchestratorRequest({ method: 'PUT', url: `/orchestrators/${orchestrator_id}/permissions/${id}`, data })
let deleteOrchestratorPermission = (orchestrator_id, id) => orchestratorRequest({ method: 'DELETE', url: `/orchestrators/${orchestrator_id}/permissions/${id}` })

let messageOrchestrator = (orchestrator_id, data) => orchestratorRequest({ method: 'POST', url: `/orchestrators/${orchestrator_id}/message`, data })
let feedbackOrchestrator = (orchestrator_id, data) => orchestratorRequest({ method: 'POST', url: `/orchestrators/${orchestrator_id}/feedback`, data })
let getOrchestratorFeedback = (orchestrator_id, params) => orchestratorRequest({ method: 'GET', url: `/orchestrators/${orchestrator_id}/feedback`, params })

let listAssistantInstances = (params) => orchestratorRequest({ method: 'GET', url: '/assistant_instances', params })
let createAssistantInstance = (data) => orchestratorRequest({ method: 'POST', url: '/assistant_instances', data })
let getAssistantInstance = (id) => orchestratorRequest({ method: 'GET', url: `/assistant_instances/${id}` })
let updateAssistantInstance = (id, data) => orchestratorRequest({ method: 'PUT', url: `/assistant_instances/${id}`, data })
let deleteAssistantInstance = (id) => orchestratorRequest({ method: 'DELETE', url: `/assistant_instances/${id}` })
let listAssistantInstancePermissions = (instance_id, params) => orchestratorRequest({ method: 'GET', url: `/assistant_instances/${instance_id}/permissions`, params })
let createAssistantInstancePermission = (instance_id, data) => orchestratorRequest({ method: 'POST', url: `/assistant_instances/${instance_id}/permissions`, data })
let updateAssistantInstancePermission = (instance_id, id, data) => orchestratorRequest({ method: 'PUT', url: `/assistant_instances/${instance_id}/permissions/${id}`, data })
let deleteAssistantInstancePermission = (instance_id, id) => orchestratorRequest({ method: 'DELETE', url: `/assistant_instances/${instance_id}/permissions/${id}` })
let listAllAssistantSkills = (params) => orchestratorRequest({ method: 'GET', url: `/assistant_skills`, params })
let listAssistantSkills = (instance_id, params) => orchestratorRequest({ method: 'GET', url: `/assistant_instances/${instance_id}/assistant_skills`, params })
let createAssistantSkill = (instance_id, data) => orchestratorRequest({ method: 'POST', url: `/assistant_instances/${instance_id}/assistant_skills`, data })
let getAssistantSkill = (instance_id, id) => orchestratorRequest({ method: 'GET', url: `/assistant_instances/${instance_id}/assistant_skills/${id}` })
let updateAssistantSkill = (instance_id, id, data) => orchestratorRequest({ method: 'PUT', url: `/assistant_instances/${instance_id}/assistant_skills/${id}`, data })
let deleteAssistantSkill = (instance_id, id) => orchestratorRequest({ method: 'DELETE', url: `/assistant_instances/${instance_id}/assistant_skills/${id}` })
let listAssistantSkillPermissions = (instance_id, skill_id, params) => orchestratorRequest({ method: 'GET', url: `/assistant_instances/${instance_id}/assistant_skills/${skill_id}/permissions`, params })
let createAssistantSkillPermission = (instance_id, skill_id, data) => orchestratorRequest({ method: 'POST', url: `/assistant_instances/${instance_id}/assistant_skills/${skill_id}/permissions`, data })
let updateAssistantSkillPermission = (instance_id, skill_id, id, data) => orchestratorRequest({ method: 'PUT', url: `/assistant_instances/${instance_id}/assistant_skills/${skill_id}/permissions/${id}`, data })
let deleteAssistantSkillPermission = (instance_id, skill_id, id) => orchestratorRequest({ method: 'DELETE', url: `/assistant_instances/${instance_id}/assistant_skills/${skill_id}/permissions/${id}` })
let retrainAssistantSkill = (instance_id, skill_id, complete = false) => orchestratorRequest({ method: 'POST', url: `/assistant_instances/${instance_id}/assistant_skills/${skill_id}/retrain`, params: { complete } })
let exportAssistantSkill = (instance_id, skill_id) => {
    var link = document.createElement("a");
    link.download = "workspace.json";
    link.href = `${process.env.REACT_APP_PATH || ""}/orchestrator/assistant_instances/${instance_id}/assistant_skills/${skill_id}/export`
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
let messageAssistantSkill = (skill_id, data) => orchestratorRequest({ method: 'POST', url: `/assistant_skills/${skill_id}/message`, data })
let listAllAssistantSkillIntents = (skill_id, params) => orchestratorRequest({ method: 'GET', url: `/assistant_skills/${skill_id}/intents`, params })
let listAllAssistantSkillIntentExamples = (skill_id, intentName, params) => orchestratorRequest({ method: 'GET', url: `/assistant_skills/${skill_id}/intents/${intentName}/examples`, params })
let listAllAssistantSkillDialogNodes = (skill_id, params) => orchestratorRequest({ method: 'GET', url: `/assistant_skills/${skill_id}/dialog_nodes`, params })

let listDiscoveryInstances = (params) => orchestratorRequest({ method: 'GET', url: '/discovery_instances', params })
let createDiscoveryInstance = (data) => orchestratorRequest({ method: 'POST', url: '/discovery_instances', data })
let getDiscoveryInstance = (id) => orchestratorRequest({ method: 'GEt', url: `/discovery_instances/${id}` })
let updateDiscoveryInstance = (id, data) => orchestratorRequest({ method: 'PUT', url: `/discovery_instances/${id}`, data })
let deleteDiscoveryInstance = (id) => orchestratorRequest({ method: 'DELETE', url: `/discovery_instances/${id}` })
let listDiscoveryInstancePermissions = (instance_id, params) => orchestratorRequest({ method: 'GET', url: `/discovery_instances/${instance_id}/permissions`, params })
let createDiscoveryInstancePermission = (instance_id, data) => orchestratorRequest({ method: 'POST', url: `/discovery_instances/${instance_id}/permissions`, data })
let updateDiscoveryInstancePermission = (instance_id, id, data) => orchestratorRequest({ method: 'PUT', url: `/discovery_instances/${instance_id}/permissions/${id}`, data })
let deleteDiscoveryInstancePermission = (instance_id, id) => orchestratorRequest({ method: 'DELETE', url: `/discovery_instances/${instance_id}/permissions/${id}` })
let listAllDiscoveryCollections = (params) => orchestratorRequest({ method: 'GET', url: `/discovery_collections`, params })
let listDiscoveryCollections = (instance_id, params) => orchestratorRequest({ method: 'GET', url: `/discovery_instances/${instance_id}/discovery_collections`, params })
let createDiscoveryCollection = (instance_id, data) => orchestratorRequest({ method: 'POST', url: `/discovery_instances/${instance_id}/discovery_collections`, data })
let updateDiscoveryCollection = (instance_id, id, data) => orchestratorRequest({ method: 'PUT', url: `/discovery_instances/${instance_id}/discovery_collections/${id}`, data })
let deleteDiscoveryCollection = (instance_id, id) => orchestratorRequest({ method: 'DELETE', url: `/discovery_instances/${instance_id}/discovery_collections/${id}` })
let indexDiscoveryCollection = (instance_id, id) => orchestratorRequest({ method: 'POST', url: `/discovery_instances/${instance_id}/discovery_collections/${id}/index` })
let deindexDiscoveryCollection = (instance_id, id) => orchestratorRequest({ method: 'POST', url: `/discovery_instances/${instance_id}/discovery_collections/${id}/deindex` })
let listDiscoveryCollectionPermissions = (instance_id, collection_id, params) => orchestratorRequest({ method: 'GET', url: `/discovery_instances/${instance_id}/discovery_collections/${collection_id}/permissions`, params })
let createDiscoveryCollectionPermission = (instance_id, collection_id, data) => orchestratorRequest({ method: 'POST', url: `/discovery_instances/${instance_id}/discovery_collections/${collection_id}/permissions`, data })
let updateDiscoveryCollectionPermission = (instance_id, collection_id, id, data) => orchestratorRequest({ method: 'PUT', url: `/discovery_instances/${instance_id}/discovery_collections/${collection_id}/permissions/${id}`, data })
let deleteDiscoveryCollectionPermission = (instance_id, collection_id, id) => orchestratorRequest({ method: 'DELETE', url: `/discovery_instances/${instance_id}/discovery_collections/${collection_id}/permissions/${id}` })

module.exports = {
    listOrchestrators,
    createOrchestrator,
    getOrchestrator,
    updateOrchestrator,
    deleteOrchestrator,
    listOrchestratorPermissions,
    createOrchestratorPermission,
    updateOrchestratorPermission,
    deleteOrchestratorPermission,
    messageOrchestrator,
    feedbackOrchestrator,
    getOrchestratorFeedback,

    listAssistantInstances,
    createAssistantInstance,
    getAssistantInstance,
    updateAssistantInstance,
    deleteAssistantInstance,
    listAssistantInstancePermissions,
    createAssistantInstancePermission,
    updateAssistantInstancePermission,
    deleteAssistantInstancePermission,
    listAllAssistantSkills,
    listAssistantSkills,
    createAssistantSkill,
    getAssistantSkill,
    updateAssistantSkill,
    deleteAssistantSkill,
    listAssistantSkillPermissions,
    createAssistantSkillPermission,
    updateAssistantSkillPermission,
    deleteAssistantSkillPermission,
    retrainAssistantSkill,
    exportAssistantSkill,
    messageAssistantSkill,
    listAllAssistantSkillIntents,
    listAllAssistantSkillIntentExamples,
    listAllAssistantSkillDialogNodes,

    listDiscoveryInstances,
    createDiscoveryInstance,
    getDiscoveryInstance,
    updateDiscoveryInstance,
    deleteDiscoveryInstance,
    listDiscoveryInstancePermissions,
    createDiscoveryInstancePermission,
    updateDiscoveryInstancePermission,
    deleteDiscoveryInstancePermission,
    listAllDiscoveryCollections,
    listDiscoveryCollections,
    createDiscoveryCollection,
    updateDiscoveryCollection,
    deleteDiscoveryCollection,
    indexDiscoveryCollection,
    deindexDiscoveryCollection,
    listDiscoveryCollectionPermissions,
    createDiscoveryCollectionPermission,
    updateDiscoveryCollectionPermission,
    deleteDiscoveryCollectionPermission,
}