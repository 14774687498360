import React, { Component } from 'react';
import { LineChart } from '@carbon/charts-react';
import "@carbon/charts/styles.css";
import { ExportButton, Form } from '../../../components/carbon-react-crud';
import { InlineLoading } from 'carbon-components-react';
import accuracyReport from '../../../utils/accuracy-report';
let moment = require('moment')
try { require('moment/locale/' + navigator.language.slice(0, 2)) } catch { }

class AccuracyGraph extends Component {

    state = {
        filter: {
            created_after: moment(Date.now() - 180 * 24 * 60 * 60 * 1000).format('YYYY-MM-DD'),
            created_before: moment(Date.now() + 1 * 24 * 60 * 60 * 1000).format('YYYY-MM-DD'),
        },
        plot: []
    }

    render() {
        return <>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, margin: 'auto' }}>
                    <Form
                        fields={[{ key1: 'created_after', key2: 'created_before', type: 'daterange' }]}
                        value={this.state.filter}
                        onChange={filter => this.handleUpdate(filter)}
                    />
                </div>
                <div style={{ flex: 1, margin: 'auto', textAlign: 'end' }}>
                    <ExportButton csvOptions={{ delimiter: { field: ';' }, emptyFieldValue: "", sortHeader: false, excelBOM: true }} listResources={async (url, params) => ({ rows: this.state.data })}
                        translate={window.translate}
                    />
                </div>
            </div>
            {this.state.loading ?
                <InlineLoading /> :
                <LineChart
                    data={this.state.plot.map(d => ({ ...d, x: moment(d.x).format('DD/MM/YYYY') }))}
                    options={{
                        animations: false,
                        height: "300px",
                        axes: {
                            left: { mapsTo: "y" },
                            bottom: { mapsTo: "x", scaleType: "labels", truncation: false }
                        },
                        tooltip: {
                            showTotal: false,
                            truncation: { numCharacter: 1024 },
                            customHTML: (values, html) => {
                                return values && html
                                    .replace('x-value', window.translate("DATE"))
                                    .replace('y-value', window.translate("VALUE"))
                                    .replace('Group', '')
                                    .replace('</li></ul>', values.length > 1 ? `</li><li>
                                    <div class="datapoint-tooltip ">
                                        <a style="background-color: #ffffff" class="tooltip-color"></a>
                                        <p class="label">${window.translate("AVERAGE")}</p>
                                        <p class="value">${values.filter(v => v.group !== this.state.dataset.name).reduce((avg, el, i, a) => avg + el.y / a.length, 0).toFixed(2).replace('.', ',')}</p>
                                    </div>
                                </li></ul>` : '</li></ul>')
                            }
                        },
                        legend: {
                            truncation: { numCharacter: 1024 },
                        }
                    }}
                />}
            <br /><br />
        </>
    }

    componentDidMount() {
        this.handleUpdate(this.state.filter)
    }

    async handleUpdate(filter) {
        this.setState({ filter })
        let report = await accuracyReport({ dataset_id: this.props.dataset_id, filter })
        this.setState({
            loading: false,
            ...report
        })
    }
}

AccuracyGraph.defaultProps = {
    data: [],
}

export default AccuracyGraph