import React, { Component } from 'react';
import { RecordPicker } from './carbon-react-crud';
import { Button, FormLabel, Icon } from 'carbon-components-react';
import { iconAddGlyph, iconSubtractGlyph } from 'carbon-icons';
import { withRouter } from 'react-router';
import { getDatasetTaxonomies, getDatasetOwners } from '../services/corpus';

class TextBuilder extends Component {

    rpRefs = []

    render() {
        this.rpRefs = this.splitText(this.props.value).map(part => React.createRef())
        return (<>
            <FormLabel>{this.props.labelText}</FormLabel>
            <div className="bx--grid">
                <div className="bx--row" style={{ position: "relative" }}>
                    {this.splitText(this.props.value).map((part, i, parts) => <>
                        <div ref={this.rpRefs} className={`bx--col-xs-${(this.props.type === "owner" ? 1 : Math.floor(parts.length / 3) > i / 3 && part.length < 30) ? 3 : part.length < 60 ? 5 : 11}`} style={{ position: "static", padding: "1rem" }}>
                            <RecordPicker
                                onChange={newPart => this.handlePartChange(newPart, i)}
                                fetchOptions={(url, options) => 
                                    this.props.type === "owner" 
                                    ? getDatasetOwners(this.props.match.params.dataset_id, { ...options, values: this.props.value })
                                    : getDatasetTaxonomies(this.props.match.params.dataset_id, { ...options, start: i !== 2 ? this.props.value.split(this.props.separator).slice(0, i).join(this.props.separator) + (i > 0 ? this.props.separator : '') : '%/%/' })
                                }
                                value={part}
                                allowCreate
                            />
                        </div>
                        <span style={{ marginTop: "1.5rem" }}>{this.props.separator}</span>
                    </>)}
                    <Button kind="secondary" className="btn-icon-only" style={{ height: "2.5rem", marginTop: "1rem" }} onMouseDown={() => this.props.onChange(this.props.value.split(this.props.separator).slice(0, -1).join(this.props.separator))}><Icon icon={iconSubtractGlyph} /></Button>
                    <Button kind="secondary" className="btn-icon-only" style={{ height: "2.5rem", marginTop: "1rem" }} onMouseDown={() => this.props.onChange(this.props.value + this.props.separator)}><Icon icon={iconAddGlyph} /></Button>
                </div>
            </div>
        </>)
    }

    splitText(text) {
        return text.split(this.props.separator)
    }

    handlePartChange(text, i, destroy) {
        if (text && text.includes(this.props.separator)) return
        if (text) console.log(text.includes(this.props.separator))
        let parts = this.splitText(this.props.value)

        return this.props.onChange(destroy ?
            i === 0 ?
                parts.slice(2).join(this.props.separator)
                :
                [
                    ...parts.slice(0, i - 1),
                    ...parts.slice(i + 1)
                ].join(this.props.separator)
            :
            [
                ...parts.slice(0, i),
                text,
                ...parts.slice(i + 1)
            ].join(this.props.separator))
    }
}

TextBuilder.defaultProps = {
    separator: "/",
    value: "",
    type: ""
}

export default withRouter(TextBuilder)