import React, { Component } from 'react';
import { withRouter } from 'react-router'
import { Form, TextInput, Button } from 'carbon-components-react';
import axios from 'axios'
import queryString from 'query-string'

class LoginPage extends Component {

    state = {
        username: "",
        password: "",
        error: false,
    }

    render() {
        return (
            <Form className="login-form" onSubmit={this.onSubmit.bind(this)}>
                <h3>{window.translate("SIGN_IN")}</h3>
                <br />
                <TextInput
                    id="username"
                    name="username"
                    value={this.state.username}
                    onChange={ev => this.setState({ username: ev.target.value })}
                    labelText={window.translate("USERNAME")}
                    required
                />
                <br />
                <TextInput
                    id="password"
                    name="password"
                    value={this.state.password}
                    onChange={ev => this.setState({ password: ev.target.value })}
                    labelText={window.translate("PASSWORD")}
                    type="password"
                    required
                    invalid={!!this.state.error}
                    invalidText={this.state.error}
                />
                <br />

                <Button type="submit" className="some-class">
                    {window.translate("SUBMIT")}
                </Button>
            </Form>
        )
    }

    onSubmit(ev) {
        ev.preventDefault()

        axios({
            url: (process.env.REACT_APP_PATH || "") + '/auth/login',
            method: 'post',
            data: {
                username: this.state.username,
                password: this.state.password
            },
        })
            .then(response => {
                let nextURL = queryString.parse(this.props.location.search).next_url || ""
                if (!nextURL.startsWith('/')) nextURL = "/"
                window.location.replace(`${(process.env.REACT_APP_PATH || "")}${nextURL}`)
            })
            .catch(err => {
                console.log(err)
                if (err.response && err.response.status === 403)
                    this.setState({
                        error: window.translate("USER_IS_BLOCKED")
                    })
                else
                    this.setState({
                        error: window.translate("LOGIN_FAILED")
                    })
            })
    }
}

export default withRouter(LoginPage)