function _validateOutput(output) {
    if (typeof output !== "object" || Array.isArray(output)) throw new Error("output -> Output must be of type \"object\"")
    if (output.generic && !Array.isArray(output.generic)) throw new Error("output.generic -> Output generic must be of type \"array\"")
    if (output.generic) output.generic.map(_validateOutputGenericItem)
}

function _validateOutputGenericItem(genericItem, index) {
    if (!['text', 'image', 'pause', 'option', 'connect_to_agent', 'search_skill'].includes(genericItem.response_type)) throw new Error(`output.generic[${index}] -> Response Type must be one of 'text', 'image', 'pause', 'option', 'connect_to_agent', 'search_skill'`)
    if (genericItem.response_type === 'text') return _validateTextGenericItem(genericItem, index)
}

function _validateTextGenericItem(genericItem, index) {
    genericItem.values.forEach((value, index2) => {
        if (value.text === undefined) throw new Error(`output.generic[${index}].values[${index2}].text -> Text is required`)
        if (typeof value.text !== 'string') throw new Error(`output.generic[${index}].values[${index2}].text -> Text must be of type "string"`)
        if (value.text.length > 4096) throw new Error(`output.generic[${index}].values[${index2}].text -> Text is too long`)
    })
}

function _validateContext(context) {
    if (context && (typeof context !== "object" || Array.isArray(context))) throw new Error("context -> Context must be of type \"object\"")
}

function _validateActions(actions) {
    if (!actions) return true
    if (typeof actions !== "object" || !Array.isArray(actions)) throw new Error("actions -> Actions must be of type \"array\"")
    actions.forEach((action, index) => {
        if (action.name === undefined) throw new Error(`actions[${index}].name -> Name cannot be empty`)
        if (typeof action.name !== 'string') throw new Error(`actions[${index}].name -> Name must be of type "string"`)
        if (action.result_variable === undefined) throw new Error(`actions[${index}].result_variable -> Result variable cannot be empty`)
        if (typeof action.result_variable !== 'string') throw new Error(`actions[${index}].result_variable -> Result variable must be of type "string"`)
        if (action.result_variable.length === 0) throw new Error(`actions[${index}].result_variable -> Result variable should have a minimum length of 1`)
        if (action.parameters !== undefined && (typeof action.parameters !== 'object' || Array.isArray(action.parameters))) throw new Error(`actions[${index}].parameters -> Parameters must be of type "object"`)
    })
}

function validateDialogNodeJSON(json) {
    if (Object.keys(json).find(key => !["output", "context", "actions"].includes(key))) throw new Error("root -> Response can only contain properties: output,context,actions")
    _validateOutput(json.output)
    _validateContext(json.context)
    _validateActions(json.actions)
}

module.exports = validateDialogNodeJSON