import React, { Component } from 'react';
import { Button } from 'carbon-components-react'

class Modal extends Component {

    state = {
        open: false
    }

    render() {
        return (<>
            {this.state.open && setTimeout(() => window.document.body.scrollTo(0, 0), 10) && setTimeout(() => window.scrollTo(0, 0), 20) && <style dangerouslySetInnerHTML={{ __html: `html, body {margin: 0; height: 100%; overflow: hidden}` }}></style>}
            {!this.props.hideTrigger && <Button disabled={this.props.disabled} onClick={ev => this.handleOpen()}>{this.props.buttonTriggerText}</Button>}
            <div className={`bx--modal bx--modal-tall ${this.state.open && "is-visible"}`} {...this.props} onClick={ev => setTimeout(() => { window.scrollTo(0, 0); window.document.body.scrollTo(0, 0) }, 0)}>
                <div className={`bx--modal-container ${this.props.large ? "bx--modal-container-lg" : ""}`}>
                    <div className="bx--modal-header">
                        <p className="bx--modal-header__heading">{this.props.title}</p>
                        <button className="bx--modal-close" type="button" title="close the modal" onClick={ev => this.handleClose()}>
                            <svg className="bx--modal-close__icon" fillRule="evenodd" height="10" role="img" viewBox="0 0 10 10" width="10" aria-label="close the modal" alt="close the modal">
                                <title>close the modal</title>
                                <path d="M6.32 5L10 8.68 8.68 10 5 6.32 1.32 10 0 8.68 3.68 5 0 1.32 1.32 0 5 3.68 8.68 0 10 1.32 6.32 5z"></path>
                            </svg>
                        </button>
                    </div>
                    <div className="bx--modal-content">
                        {this.state.open && this.props.children}
                    </div>
                    {!this.props.passive && <div className="bx--modal-footer">
                        <button onClick={ev => this.handleClose()} tabIndex="0" className="bx--btn bx--btn--secondary" type="button">{this.props.secondaryButtonText}</button>
                        <button onClick={ev => this.props.handleSubmit()} tabIndex="0" className="bx--btn bx--btn--primary" type="button">{this.props.primaryButtonText}</button>
                    </div>}
                </div>
            </div>
        </>)
    }


    handleOpen() {
        this.setState({ open: true })
    }

    handleClose() {
        this.setState({ open: false })
    }
}

Modal.defaultProps = {
    primaryButtonText: "Save",
    secondaryButtonText: "Cancel"
}

export default Modal
