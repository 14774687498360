import React, { Component } from 'react';
import { UnorderedList, Loading, Checkbox } from 'carbon-components-react';
import { updateRecommendation } from '../../../services/corpus'
import { withRouter } from 'react-router';

class RecommendationDetail extends Component {

    state = {
        recommendation: {
            metadata: {
                examples: []
            }
        },
        loading: false
    }

    render() {
        return (<>
            <Loading active={this.state.loading} />
            <p>Para seguir a recomendação, selecionar mais: {this.state.recommendation.metadata.delete_count - this.state.recommendation.metadata.examples.filter(e => !e.keep).length} exemplos</p>
            <UnorderedList>
                {this.state.recommendation.metadata.examples.map(e =>
                    <div onClick={ev => this.toggleExample(e)}>
                        <Checkbox checked={!e.keep} labelText={e.text} />
                    </div>
                )}
            </UnorderedList>
        </>)
    }

    componentDidMount() {
        this.setState({ recommendation: this.props.value })
    }

    toggleExample(example) {
        if (this.props.disabled) return
        this.setState({ loading: true })
        let newRecommendation = {
            ...this.state.recommendation,
            metadata: {
                ...this.state.recommendation.metadata,
                examples: this.state.recommendation.metadata.examples
                    .map(e => example.id === e.id ? { ...e, keep: !e.keep } : e)
            }
        }
        updateRecommendation(this.props.match.params.dataset_id, this.props.match.params.evaluation_id, this.state.recommendation.id, newRecommendation)
            .then(data => this.setState({ recommendation: newRecommendation, loading: false }))
    }

}

export default withRouter(RecommendationDetail)