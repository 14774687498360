import React, { Component } from 'react';
import { TextInput, Button, Icon, Tile, Tabs, Tab, Toggle, Checkbox, Accordion, AccordionItem } from 'carbon-components-react';
import { iconClose, iconSettings } from 'carbon-icons';
import Modal from '../../../../../../components/modal';

class NodeHeaderEditor extends Component {

    state = {
        setMultiResponse: false,
        setSlots: false,
        setPromptEverything: false,
        setDigressIn: undefined,
        setDigressOut: undefined,
        setDigressOutSlots: undefined,
    }

    customizeNodeModalRef = React.createRef()

    render() {
        return (<>
            <Tile style={{ display: "flex", width: "100%" }}>
                <TextInput
                    id="node-title"
                    value={this.props.selectedNode.title}
                    onChange={ev => this.props.onChange(this.props.selectedNode, { title: ev.target.value || undefined })}
                />
                <Button kind="secondary" style={{ border: 0, marginLeft: "10px" }} onClick={ev => this.handleOpenModal()}>{window.translate("CUSTOMIZE")} <Icon icon={iconSettings} /></Button>
                <Button kind="secondary" style={{ border: 0, marginLeft: "10px" }} onClick={ev => this.props.onClose(ev)}><Icon icon={iconClose} style={{ margin: 0 }} /></Button>
            </Tile>

            <Modal
                title={`${window.translate("CUSTOMIZE_NODE")} "${this.props.selectedNode.title}"`}
                ref={this.customizeNodeModalRef}
                hideTrigger={true}
                handleSubmit={ev => this.handleSubmitCustomizationForm()}
            >
                <Tabs>
                    {this.props.selectedNode.type !== "folder" && <Tab label={window.translate("CUSTOMIZE_NODE")}>
                        <div style={{ display: "flex" }}>
                            <h3 style={{ flex: "1", paddingTop: "10px" }}>{window.translate("SLOTS")}</h3>
                            <div style={{ flex: "0" }}>
                                <Toggle toggled={this.state.setSlots} onToggle={state => this.setState({ setSlots: state, setPromptEverything: state && this.state.setPromptEverything })} />
                            </div>
                        </div>
                        <br />
                        <p>{window.translate("SLOTS_ENABLE_DESCRIPTION")}</p>
                        <div onClick={ev => this.setState({ setPromptEverything: this.state.setSlots && !this.state.setPromptEverything })} style={{ margin: "15px", color: this.state.setSlots ? 'inherit' : 'lightgray', cursor: 'pointer' }}>
                            <Checkbox labelText={window.translate("PROMPT_FOR_EVERYTHING")} disabled={!this.state.setSlots} checked={this.state.setPromptEverything} />
                            <br /><br />
                            <p>{window.translate("PROMPT_EVERYTHING_DESCRIPTION")}</p>
                        </div>
                        <hr />
                        <div style={{ display: "flex" }}>
                            <h3 style={{ flex: "1", paddingTop: "10px" }}>{window.translate("MULTI_CONDITIONED_RESPONSES")}</h3>
                            <div style={{ flex: "0" }}>
                                <Toggle toggled={this.state.setMultiResponse} onToggle={state => this.setState({ setMultiResponse: state })} />
                            </div>
                        </div>
                        <br />
                        <p>{window.translate("MULTI_RESPONSE_DESCRIPTION")}</p>
                    </Tab>}
                    <Tab label={window.translate("DIGRESSIONS")}>
                        <Accordion>
                            {this.props.selectedNode.type !== "folder" && <AccordionItem title={!this.hasNoChildren() && !this.hasJump() && this.state.setDigressOut === 'allow_all' ? window.translate("DIGRESSIONS_CAN_GO_AWAY") : window.translate("DIGRESSIONS_CANNOT_GO_AWAY")}>
                                {this.renderDigressOut()}
                            </AccordionItem>}
                            <AccordionItem title={window.translate("DIGRESSIONS_CANNOT_COME_IN")}>
                                {this.renderDigressIn()}
                            </AccordionItem>
                        </Accordion>
                    </Tab>
                </Tabs>
            </Modal>
        </>)
    }

    renderDigressOut() {
        if (this.hasNoChildren()) return <Tile>
            <h3>{window.translate("DIGRESSION_NO_CHILDREN_TITLE")}</h3>
            <p>{window.translate("DIGRESSION_NO_CHILDREN_TEXT")}</p>
        </Tile>
        if (this.hasJump()) return <Tile>
            <h3>{window.translate("DIGRESSION_JUMP_TITLE")}</h3>
            <p>{window.translate("DIGRESSION_JUMP_TEXT")}</p>
        </Tile>
        if (this.state.setSlots) return <div style={{ display: "flex" }}>
            <img alt="Digress out slots diagram" style={{ marginRight: "1rem" }} src={this.getDigressOutSlotsImg()} />
            <div style={{ flex: "1", padding: "10px" }}>
                <h3>{window.translate("DIGRESSION_OUT_SLOTS_TITLE")}</h3>
                <p>{window.translate("DIGRESSION_OUT_SLOTS_TEXT")}</p>
                <br />
                <div onClick={ev => this.setState({ setDigressOutSlots: this.state.setDigressOut === 'allow_all' ? this.state.setDigressOutSlots === 'allow_returning' ? 'allow_all' : 'allow_returning' : 'not_allowed' })}>
                    <Checkbox checked={this.state.setDigressOutSlots === 'allow_returning'} labelText={window.translate("DIGRESSION_OUT_SLOTS_RETURN_LABEL")} disabled={this.state.setDigressOut === 'allow_all_never_return'} />
                </div>
                <br />
                <br />
                <p>{window.translate("DIGRESSION_OUT_SLOTS_RETURN_TEXT")}</p>
            </div>
            <div style={{ flex: "0" }}>
                <Toggle
                    toggled={this.state.setDigressOut === 'allow_all'}
                    onToggle={state => this.setState({
                        setDigressOut: state ? 'allow_all' : 'allow_all_never_return',
                        setDigressOutSlots: state ? "allow_all" : "not_allowed"
                    })}
                />
            </div>
        </div>
        return <div style={{ display: "flex" }}>
            <img alt="Digress out diagram" style={{ marginRight: "1rem" }} src={this.getDigressOutImg()} />
            <div style={{ flex: "1", padding: "10px" }}>
                <p>{window.translate("DIGRESSION_OUT_RETURN_TEXT")}</p>
            </div>
            <div style={{ flex: "0" }}>
                <Toggle
                    toggled={this.state.setDigressOut === 'allow_all'}
                    onToggle={state => this.setState({
                        setDigressOut: state ? 'allow_all' : 'allow_all_never_return'
                    })}
                />
            </div>
        </div>

    }

    renderDigressIn() {
        return <Tile>
            <h3>{window.translate("DIGRESSION_IN_TITLE")}</h3>
            <p>{window.translate("DIGRESSION_IN_TEXT")}</p>
        </Tile>
    }

    handleOpenModal() {
        this.customizeNodeModalRef.current.handleOpen()
        this.setState({
            setSlots: this.isSlots(),
            setMultiResponse: this.isMCR(),
            setPromptEverything: this.isPromptEverything(),
            setDigressIn: this.props.selectedNode.digress_in,
            setDigressOut: this.props.selectedNode.digress_out || "allow_all",
            setDigressOutSlots: this.props.selectedNode.digress_out_slots || "allow_all",
        })
    }

    handleSubmitCustomizationForm() {
        let newNode = {}
        if (!this.isMCR() && this.state.setMultiResponse) {
            this.props.onAdd(this.props.selectedNode, "child", {
                type: "response_condition",
                output: this.props.selectedNode.output,
                context: this.props.selectedNode.context,
                actions: this.props.selectedNode.actions,
            })
            newNode.metadata = { _customization: { mcr: this.state.setMultiResponse } }
            newNode.output = {}
            newNode.context = {}
            newNode.actions = null
        }
        if (this.isMCR() && !this.state.setMultiResponse) {
            let firstResponseCondition = this.props.children.find(child => child.type === "response_condition")
            let nodesToDelete = this.props.children.filter(child => child.type === 'response_condition')
            nodesToDelete.map((node, i) => setTimeout(() => {
                this.props.onDelete(this.props.dialogNodes.find(n => n.dialog_node === node.dialog_node))
            }, (i + 1) * 100))
            newNode.metadata = { _customization: { mcr: this.state.setMultiResponse } }
            newNode.output = firstResponseCondition.output
            newNode.context = firstResponseCondition.context
            newNode.actions = firstResponseCondition.actions
        }
        if (!this.isSlots() && this.state.setSlots)
            newNode.type = "frame"
        if (this.isSlots() && !this.state.setSlots) {
            let nodesToDelete = this.props.children.filter(child => ['slot', 'event_handler'].includes(child.type))
            nodesToDelete.map((node, i) => setTimeout(() => {
                this.props.onDelete(this.props.dialogNodes.find(n => n.dialog_node === node.dialog_node))
            }, (i + 1) * 100))
            newNode.type = "standard"
        }
        if (this.state.setSlots && !this.isPromptEverything() && this.state.setPromptEverything) {
            this.props.onAdd(this.props.selectedNode, "child", { type: "event_handler", event_name: "focus" })
        }
        if (this.isPromptEverything() && !this.state.setPromptEverything) {
            this.props.onDelete(this.props.children.find(n => n.type === "event_handler"))
        }
        // Digressions
        newNode.digress_in = this.state.setDigressIn
        newNode.digress_out = this.state.setDigressOut
        newNode.digress_out_slots = this.state.setDigressOutSlots

        this.props.onChange(this.props.selectedNode, newNode)

        this.customizeNodeModalRef.current.handleClose()
    }

    isMCR() {
        return this.props.selectedNode.metadata && this.props.selectedNode.metadata._customization && this.props.selectedNode.metadata._customization.mcr
    }

    isSlots() {
        return this.props.selectedNode.type === "frame"
    }

    isPromptEverything() {
        return !!this.props.children.find(child => child.event_name === "focus")
    }

    getDigressIn() {
        return this.props.selectedNode.digress_in || "does_not_return"
    }

    getDigressInImg() {
        switch (this.state.setDigressIn) {
            case "does_not_return": return "/images/digress-into-does-not-return.svg"
            case "returns": return "/images/digress-into-returns.svg"
            case "not_available": return "/images/digress-into-not-available.svg"
            default: break
        }
    }

    getDigressOutImg() {
        switch (this.state.setDigressOut) {
            case "allow_all": return "/images/digress-away-allow-all.svg"
            case "allow_all_never_return": return "/images/digress-away-allow-all-never-return.svg"
            default: break
        }
    }

    getDigressOutSlotsImg() {
        switch (this.state.setDigressOut + '_' + this.state.setDigressOutSlots) {
            case "allow_all_allow_all": return "/images/digress-away-slots-allow-all.svg"
            case "allow_all_allow_returning": return "/images/digress-away-slots-allow-returning.svg"
            case "allow_all_never_return_not_allowed": return "/images/digress-away-slots-allow-all-never-return.svg"
            default: break
        }
    }

    hasNoChildren() {
        return !this.state.setSlots && this.props.dialogNodes.filter(n => n.parent === this.props.selectedNode.dialog_node).length === 0
    }

    hasJump() {
        return this.props.selectedNode.go_to || (this.props.selectedNode.next_step && this.props.selectedNode.next_step.behavior !== this.props.selectedNode.next_step && this.props.selectedNode.next_step.behavior !== 'get_user_input')
    }

}

export default NodeHeaderEditor