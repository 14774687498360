import React, { Component } from 'react';
import { Tabs, Tab, UnorderedList, ListItem } from 'carbon-components-react'

class EvaluationHelp extends Component {

    render() {
        return (<Tabs>
            <Tab label={window.translate("EVALUATIONS")}>
                <p>{window.translate("EVALUATION_HELP_0")}</p>
                <p>{window.translate("EVALUATION_HELP_1")}</p>
                <p>{window.translate("EVALUATION_HELP_2")}</p>
                <UnorderedList>
                    <ListItem>{window.translate("REDUNDANCY_DETECTION")}: {window.translate("REDUNDANCY_DETECTION_DESCRIPTION")}</ListItem>
                    <ListItem>{window.translate("UNDERSAMPLING")}: {window.translate("UNDERSAMPLING_DESCRIPTION")}</ListItem>
                    <ListItem>{window.translate("OUTLIER_DETECTION")}: {window.translate("OUTLIER_DETECTION_DESCRIPTION")}</ListItem>
                </UnorderedList>
            </Tab>
            <Tab label={window.translate("RECOMMENDATIONS")}>
                <p>{window.translate("RECOMMENDATION_HELP_0")}</p>
                <p>{window.translate("RECOMMENDATION_HELP_1")}</p>
                <p>{window.translate("RECOMMENDATION_HELP_2")}</p>
                <p>{window.translate("RECOMMENDATION_HELP_3")}</p>
                <img alt={window.translate("RECOMMENDATIONS")} style={{ width: "100%" }} src={`${process.env.PUBLIC_URL}/images/help/evaluation.png`} />
            </Tab>
        </Tabs>)
    }

}


export default EvaluationHelp
