import React, { Component } from 'react';
import { Button, TextInput, Icon, FormGroup } from 'carbon-components-react'
import { iconDelete } from 'carbon-icons'
import { Editor } from '@tinymce/tinymce-react';
import tinymce from '../../../utils/tinymce';

class MultipleResponseEditor extends Component {

    render() {
        return (<>
            <h6>{this.props.labelText}</h6>
            <br /> <br />
            {
                (Array.isArray(this.props.value) ? this.props.value : []).map((response, i) =>
                    <FormGroup key={`response-${i}`} style={{ margin: 0 }} legendText="">
                        {this.props.html && <Editor
                            apiKey={tinymce.apiKey}
                            init={{
                                plugins: 'link image code emoticons wordcount table',
                                toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code | emoticons',
                                language: navigator.language.replace('-', '_'),
                                language_url: `${(process.env.REACT_APP_PATH || "")}/languages/${navigator.language.replace('-', '_')}.js`,
                            }}
                            value={response}
                            onEditorChange={value => this.handleEditResponse(i, value)}
                            disabled={this.props.disabled}
                        />}
                        {!this.props.html && <TextInput style={{}}
                            value={response}
                            onChange={ev => this.handleEditResponse(i, ev.target.value)}
                            onKeyPress={ev => ev.key === 'Enter' ? ev.target.blur() : null}
                        />}
                        <Button kind="secondary" className="btn-icon-only" onClick={ev => this.handleRemoveResponse(i)} style={{ flexDirection: "column-reverse" }}><Icon icon={iconDelete} /></Button>
                    </FormGroup>
                )
            }
            {<Button onClick={ev => this.handleAddResponse(this.props.value.length)}> {window.translate("ADD_RESPONSE")} </Button>}
            <br /> <br />
        </>)
    }

    isValid() {
        return Array.isArray(this.props.value) && this.props.value.reduce((allStrings, element) => allStrings && typeof element === 'string', true)
    }

    componentDidMount() {
        if (!this.isValid()) {
            this.props.onChange([])
        }
    }

    handleAddResponse(i) {
        this.props.onChange([...this.props.value.slice(0, i + 1), "", ...this.props.value.slice(i + 1)])
    }

    handleEditResponse(i, value) {
        this.props.onChange([...this.props.value.slice(0, i), value, ...this.props.value.slice(i + 1)])
    }

    handleRemoveResponse(i) {
        this.props.onChange([...this.props.value.slice(0, i), ...this.props.value.slice(i + 1)])
    }
}

MultipleResponseEditor.defaultProps = {
    html: true,
    onChange: () => { },
    value: []
}

export default MultipleResponseEditor