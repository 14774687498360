module.exports = {
    "MOVE_ENTITY": "",
    "COPY_ENTITY": "",
    "ENTITY_TRANSFER": "",
    "CREATIONS": "",
    "DELETIONS": "",
    "EDITIONS": "",
    "MAPPINGS": "",
    "REMAPPINGS": "",
    "INCOMING_DISTRIBUTION": "",
    "LATEST_EXPERIMENT_RESULTS": "",
    "OVERVIEW": "Overview",
    "MERGE": "Agregar",
    "COUNT": "Cuenta",
    "PAIRWISE_CLASS_ERRORS": "Errores por par de classes",
    "AUDIT_FACTS": "Hechos de auditoria",
    "SUBJECT": "Sujeto",
    "ENTITY_VALUE": "Valor de entidad",
    "EXAMPLE": "Ejemplo",
    "REVIEW_EXAMPLE": "Ejemplo para revisión",
    "REVIEWEXAMPLE": "Ejemplo para revisión",
    "TYPE": "Tipo",
    "INTENT_ID": "ID de intención",
    "ENTITY": "Entidad",
    "SYNONYMS": "Sinónimos",
    "FUZZY_MATCH": "Fuzzy match",
    "PARENT_ID": "ID de padre",
    "AUTHOR": "Autor",
    "PT": "Portugués",
    "PT-BR": "Portugués (Brasil)",
    "EN": "Inglés",
    "EN-US": "Inglés (EEUU)",
    "ES": "Español",
    "FR": "Francés",
    "RESTORE": "Restaurar",
    "NEW_SNAPSHOTS": "Nuevo snapshot",
    "SNAPSHOTS": "Snapshots",
    "API_USAGE": "Uso de la API",
    "SEND": "Enviar",
    "ACCEPT": "Aceptar",
    "ACCURACY": "Exactitud",
    "ACCURACY_VS_CLASS_SIZE": "Exactitud VS tamaño de la clase",
    "ADD_CHILD": "Adicionar hijo",
    "ADD_CHILD_NODE": "Adicionar nodo hijo",
    "ADD_FILE": "Adicionar archivo",
    "ADD_FOLDER": "Adicionar carpeta",
    "ADD_NODE": "Adicionar nodo",
    "ADD_NODE_ABOVE": "Adicionar nodo arriba",
    "ADD_NODE_BELOW": "Adicionar nodo abajo",
    "ADD_RESPONSE": "Adicionar respuesta",
    "ADD_VARIABLE": "Adicionar variable",
    "ADMIN": "Admin",
    "ADVANCED_SEARCH": "Búsqueda avanzada",
    "ALL_EXAMPLES_REVIEW_MAPPING": "Todos los ejemplos revisión/mapeo",
    "THEN_ASSISTANT_SHOULD": "Y finalmente",
    "ANSWER": "Respuesta",
    "ANSWER_UNITS": "Unidades de respuesta",
    "ANYTHING_ELSE_TEXT": "Texto de intención no capturada",
    "API_KEYS": "Llaves de API",
    "APIKEY": "Llave de API",
    "APIKEYS": "Llaves de API",
    "APP_TITLE": "Q&A Manager 2.0",
    "ARE_YOU_SURE": "¿Estás seguro?",
    "ASSISTANT": "Assistant",
    "ASSISTANT_INSTANCE": "Instancia de Assistant",
    "ASSISTANT_INSTANCE_PERMISSIONS": "Permisos de instancia de Assistant",
    "ASSISTANT_INSTANCES": "Instancias de Assistant",
    "ASSISTANT_INSTANCES_DESCRIPTION": "Agregue instancias de servicio de Watson Assistant para responder preguntas por dialogo.",
    "ASSISTANT_SKILL": "Skill de Assistant",
    "ASSISTANT_SKILL_PERMISSIONS": "Permisos de skill de Assistant",
    "ASSISTANT_SKILLS": "Skills de Assistant",
    "AUDIT": "Auditoria",
    "AUDIT_DESCRIPTION": "Ver acciones realizadas en el servicio.",
    "AUDITOR": "Auditor",
    "AUTO_RETRAIN": "Entrenamiento automático",
    "BOUND_WORKSPACE": "Workspace asociado",
    "CANONICAL": "Pregunta canónica",
    "CHANNEL": "Canal",
    "CHANNELS": "Canales",
    "CLASS": "Clase",
    "CLASS_DISTRIBUTION": "Distribuición de clases",
    "CLASS_SIZE": "Tamaño de la clase",
    "CLASSES": "Clases",
    "COMPLETE": "Completo",
    "COMPLETE_RETRAIN": "Entrenamiento completo",
    "CONDITION": "Condición",
    "CONFIDENCE": "Confianza",
    "CONFIDENCE_VS_CLASS_SIZE": "Confianza VS tamaño de la clase",
    "CONFIRM_PASSWORD": "Por favor, confirme tu contraseña",
    "CONNECT_TO_HUMAN_AGENT": "Conectar con agente humano",
    "CONTENT": "Contenido",
    "CONTEXT_SET": "Contexto configurado",
    "CONVERSATION_CHAT": "Conversation Chat",
    "CORPUS": "Corpus",
    "CREATE": "Criar",
    "CREATED_AFTER": "Creado desde",
    "CREATED_AT": "Creado en",
    "CREATED_BEFORE": "Creado hasta",
    "DATASET": "Conjunto de datos",
    "DATASET_PERMISSIONS": "Permisos de conjunto de datos",
    "DATASETS": "Conjuntos de datos",
    "DATASETS_DESCRIPTION": "Configure las respuestas directas de tu chatbot.",
    "DEINDEX": "Desindexar",
    "DELETE": "Borrar",
    "DIALOG_CONFIGS": "Configuración de dialogo",
    "DISAMBIGUATION": "Desambiguación",
    "DISAMBIGUATION_QUESTION_LIMIT": "Límite de preguntas de desambiguación",
    "DISCOVERY_COLLECTION": "Colección de Discovery",
    "DISCOVERY_COLLECTION_PERMISSIONS": "Permisos de colección de Discovery",
    "DISCOVERY_COLLECTIONS": "Colecciones de Discovery",
    "DISCOVERY_INSTANCE": "Instancia de Discovery",
    "DISCOVERY_INSTANCE_PERMISSIONS": "Permisos de instancia de Discovery",
    "DISCOVERY_INSTANCES": "Instancias de Discovery",
    "DISCOVERY_INSTANCES_DESCRIPTION": "Associe instancias de servicio de Watson Discovery para agregar búsqueda de documentos a tu chatbot.",
    "DISTRIBUTION": "Distribuición",
    "DOCUMENTS": "Documentos",
    "DOCUMENTS_DESCRIPTION": "Indexe documentos para búsqueda utilizando lenguaje natural.",
    "DOES_NOT_RETURN": "Não regresa",
    "DOWNLOAD": "Descarga",
    "DOWNLOAD_CLEAN": "Descarga (Limpia)",
    "DUPLICATE": "Duplicar",
    "EDIT": "Editar",
    "EDITOR": "Editor",
    "ENTER_EXTERNAL_NODE_NAME": "Ingresa el nombre externo del nodo",
    "ENTER_RESPONSE_TEXT": "Ingresa el texto de respuesta",
    "ENTITIES": "Entidades",
    "ENTITY_VALUES": "Valores de entidad",
    "ENVIRONMENT_ID": "Environment ID",
    "ERROR_CREATING_RESOURCE": "Error al agregar recurso",
    "ERROR_DELETING_RESOURCE": "Error al borrar recurso",
    "ERROR_LOADING_RESOURCES": "Error al cargar recursos",
    "EXAMPLES": "Ejemplos",
    "EXAMPLES_CLASS_DISTRIBUTION": "Ejemplos e distribuición de clases",
    "EXPERIMENTS": "Experimentos",
    "EXPORT": "Exportar",
    "EXPORT_CLEAN": "Exportar (limpio)",
    "EXPORT_JSON": "Exportar JSON",
    "FEEDBACK": "Feedback",
    "FINAL": "Final",
    "FINISHED_AT": "Terminado en",
    "GO_TO": "Ir para",
    "HITS": "Acertos",
    "HOME": "Inicio",
    "HTML": "HTML",
    "IF_ASSISTANT_NEEDS_TO_REPRESENT_NODE_USE": "Caso el asistente virtual necesite representar el nodo para usuarios, usar",
    "IF_ASSISTANT_RECOGNIZES": "Caso el asistente reconozca",
    "IMAGE": "Imagen",
    "INCORRECT_PASSWORD": "Contraseña incorrecta",
    "INDEX": "Indexar",
    "INDEXED_AT": "Indexado en",
    "INSTANCE": "Instancia",
    "INTENT": "Intención",
    "INTENTS": "Intenciones",
    "INVITE": "Invitar",
    "JSON_EDITOR": "Editor de JSON",
    "JUMP_TO": "Saltar para",
    "KEY": "Llave",
    "KNOWLEDGE_BASE": "Base de conocimiento",
    "KNOWLEDGE_BASE_PERMISSIONS": "Permisos de base de conocimiento",
    "KNOWLEDGE_BASES": "Bases de conocimiento",
    "LANGUAGE": "Idioma",
    "LOGIN": "Login",
    "LOGIN_ATTEMPTS": "Intentos de login",
    "LOGIN_FAILED": "Login falló. Usuario o contraseña están incorrectos.",
    "MAP_TO_SELECTED": "Mapear para la clase seleccionada",
    "MAPPING": "Mapeo",
    "MERGE_SELECTED_CLASSES": "Agregar clases seleccionadas",
    "MERGER": "Agregador",
    "MISSES": "Errores",
    "MOVE": "Mover",
    "NAME": "Nombre",
    "NEW_ANSWER_UNITS": "Nueva unidad de respuesta",
    "NEW_APIKEYS": "Nueva llave de API",
    "NEW_ASSISTANT_INSTANCES": "Nueva instancia de Assistant",
    "NEW_ASSISTANT_SKILLS": "Nuevo skill de Assistant",
    "NEW_CHANNELS": "Nuevo canal",
    "NEW_DATASETS": "Nuevo conjunto de datos",
    "NEW_DISCOVERY_COLLECTIONS": "Nueva colección de Discovery",
    "NEW_DISCOVERY_INSTANCE": "Nueva instancia de Discovery",
    "NEW_DOCUMENTS": "Nuevo documento",
    "NEW_ENTITIES": "Nueva entidad",
    "NEW_ENTITY_VALUES": "Nuevo valor de entidad",
    "NEW_EXAMPLES": "Nuevo ejemplo",
    "NEW_EXPERIMENTS": "Nuevo experimento",
    "NEW_INTENTS": "Nueva intención",
    "NEW_KNOWLEDGE_BASES": "Nueva base de conocimiento",
    "NEW_NAME": "Nuevo nombre",
    "NEW_ORCHESTRATORS": "Nuevo orquestrador",
    "NEW_PASSWORD": "Nueva contraseña",
    "NEW_PERMISSIONS": "Nuevo permiso",
    "NEW_REPORTS": "Nuevo reporte",
    "NEW_REVIEW_EXAMPLES": "Nuevo ejemplo para mapeo",
    "NEW_USERS": "Nuevo usuario",
    "NO": "No",
    "NO_CONDITION_SET": "Ninguna condición configurada",
    "NORMAL": "Normal",
    "NUMBER_OF_CLASSES": "Número de clases",
    "NUMBER_OF_EXAMPLES": "Número de ejemplos",
    "OPTION": "Opción",
    "OPTIONS": "Opciones",
    "ORCHESTRATOR": "Orquestrador",
    "ORCHESTRATOR_PERMISSIONS": "Permisos de orquestrador",
    "ORCHESTRATORS": "Orquestradores",
    "ORCHESTRATORS_DESCRIPTION": "Unir habilidades de diálogo y búsqueda para construir un chatbot.",
    "OUT_OF_CONTEXT": "Fuera de contexto",
    "OUT_OF_TRAINING": "Fuera de treinamento",
    "OUTPUT_VARIABLES": "Variables de saída",
    "PAGE_NOT_FOUND": "Página no encontrada",
    "PARENT": "Padre",
    "PASSWORD": "Contraseña",
    "PAUSE": "Pausa",
    "PERMISSIONS": "Permisos",
    "PIVOT_DIMENSION": "Dimensión pivote",
    "PRECISION": "Precisión",
    "PRECISION_AT_K": "Precisión en K",
    "PREDICTED_INTENT": "Intención predicha",
    "PREDICTIONS": "Prediciones",
    "PROCFLOW": "Procflow",
    "QUERY": "Consulta",
    "RANDOM": "Aleatório",
    "REJECT": "Rechazar",
    "RELATED_CLASSES": "Clases relacionadas",
    "REMAP": "Remapear",
    "REMAP_EXAMPLES": "Remapear preguntas",
    "REMOVE_CHILD": "Remover hijo",
    "REMOVE_RESPONSE": "Remover respuesta",
    "REPLICATION": "Replicador",
    "REPLICATOR": "Replicador",
    "REPORT": "Reporte",
    "RESOURCE_SUBTYPE": "Subtipo de recurso",
    "RESOURCE_TYPE": "Tipo de recurso",
    "RESPONSE": "Respuesta",
    "RESPONSE_VARIATION": "Variación de respuestas",
    "RESPONSES": "Respuestas",
    "RETRAIN": "Entrenar",
    "REVIEW": "Revisión",
    "REVIEW_EXAMPLES": "Ejemplos para revisión",
    "ROLE": "Papel",
    "ROUTER": "Roteador",
    "SEARCH": "Buscar",
    "SEARCH_SKILL": "Skill de búsqueda",
    "SEARCHERS": "Buscadores",
    "SEQUENTIAL": "Sequencial",
    "SIGN_IN": "Haz login",
    "SIMPLE_RETRAIN": "Entrenamiento simple",
    "SIMPLIFIED_EDITOR": "Editor simplificado",
    "SOURCE_DATASET": "Conjunto de datos fuente",
    "STATUS": "Status",
    "SUBMIT": "Enviar",
    "SUCCESS_CREATING_RESOURCE": "Recurso agregado com éxito",
    "SUCCESS_DELETING_RESOURCE": "Recurso apagado com éxito",
    "TARGET_DATASET": "Conjunto de datos de destino",
    "TARGET_FACT": "Hecho alvo",
    "TEST": "Probar",
    "TEXT": "Texto",
    "ASSISTANT_RESPONDS": "Entonces contestar con",
    "TRAINED_AT": "Entrenado en",
    "TRAINING_DATA": "Conjuntos de Entrenamiento",
    "TRUE_INTENT": "Intención correcta",
    "UNBIND": "Desatar",
    "UPDATE": "Actualizar",
    "UPDATED_AFTER": "Actualizado desde",
    "UPDATED_AT": "Actualizado en",
    "UPDATED_BEFORE": "Actualizado hasta",
    "UPLOAD": "Enviar",
    "URL": "URL",
    "USER": "Usuario",
    "USER_IS_BLOCKED": "Usuario bloqueado",
    "USER_NOT_FOUND": "Usuario no encontrado",
    "USERNAME": "Nombre de usuario",
    "USERS": "Usuarios",
    "USERS_DESCRIPTION": "Administre usuarios del Q&A Manager.",
    "VALUE": "Valor",
    "VARIABLE": "Variable",
    "VIEW": "Ver",
    "VIEWER": "Lector",
    "WAIT_FOR_USER_INPUT": "Esperar entrada del usuario",
    "WELCOME_TEXT": "Texto de bienvenida",
    "WORKSPACE_ID": "Workspace ID",
    "YES": "Sí",
    "FIXED": "Arreglado",
    "NOT_FIXED": "No arreglado",
    "INFERENCE": "Inferencia"
}