import React, { Component } from 'react';
import { CrudTable } from '../../components/carbon-react-crud';


class AssistantSkillIntentsPage extends Component {

    render() {
        return (<>
            <h3>{window.translate("INTENTS")}</h3>
            <br />
            <CrudTable
                url={`/orchestrator/assistant_skills/${this.props.match.params.assistant_skill_id}/intents`}
                headers={[{ key: "name", header: window.translate("INTENT"), sortable: true }]}
                links={[{ text: window.translate("EXAMPLES"), onClick: row => this.props.history.push(`${process.env.REACT_APP_PATH ? window.location.pathname.split(process.env.REACT_APP_PATH)[1] : window.location.pathname}/${row.id}/examples`) }]}
                clientPagination searchable
                disableCreate disableUpdate={row => true} disableDelete={row => true}
            />
        </>)
    }
}

export default AssistantSkillIntentsPage