import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbSkeleton } from 'carbon-components-react';
import { Link, withRouter } from "react-router-dom";
import axios from 'axios'

class CustomBreadcrumb extends Component {

    state = {
        loading: false,
        names: []
    }

    routeMap = {
        "assistant_instances": "orchestrator",
        "assistant_skills": "orchestrator",
        "discovery_instances": "orchestrator",
        "discovery_projects": "orchestrator",
        "discovery_collections": "orchestrator",
        "orchestrators": "orchestrator",
        "datasets": "corpus",
        "knowledge_bases": "documents",
        "users": "auth",
        "audit_facts": "audit",
    }

    render() {
        let pathParts = this.props.location.pathname.split('/')
        return <>
            {this.state.loading ?
                <BreadcrumbSkeleton /> :
                <Breadcrumb>
                    {pathParts.map((part, i) => i % 2 ?
                        <BreadcrumbItem key={`breadcrumb-${i}`}>
                            <Link to={pathParts.slice(0, i + 1).join('/')}>{this.state.names[i] || window.translate(part)}</Link>
                        </BreadcrumbItem> :
                        i === 0 ?
                            <BreadcrumbItem key={`breadcrumb-home`}>
                                <Link to="/">{window.translate("HOME")}</Link>
                            </BreadcrumbItem> :
                            <BreadcrumbItem key={`breadcrumb-${i}`}>
                                <Link to=".">{this.state.names[i] || window.translate(part)}</Link>
                            </BreadcrumbItem>

                    )}
                </Breadcrumb>
            }
            <br /> <br />
        </>
    }

    componentDidMount() {
        this.componentWillReceiveProps(this.props)
    }

    componentWillReceiveProps(props) {
        this.setState({ names: [] })
        let parts = props.location.pathname.split('/')
        Promise.all(
            parts
                .map((part, i) => {
                    if (i > 0 && i % 2 === 0) {
                        let path = `${process.env.REACT_APP_PATH || ""}/${this.routeMap[parts[1]]}${parts.slice(0, i + 1).join('/')}`
                        return axios(path).then(response => response.data.name).catch(err => part)
                    }
                    return null
                }))
            .then(names => this.setState({ names }))
    }

}

export default withRouter(CustomBreadcrumb)