import React, { Component } from 'react';
import { CrudTableProps, CrudTableState } from '..'
import {
    DataTable, DataTableSkeleton, Pagination,
    OverflowMenu, OverflowMenuItem, Modal, ToastNotification, Icon, Button, Link
} from 'carbon-components-react';
import axios from "axios"
import { iconSettings, iconDelete } from "carbon-icons"
import ExportButton from './export-button';
import AdvancedSearch from './advanced-search';
import Form from './form';
import translate from '../i18n/index'
import patterns from '../../../utils/patterns'

const {
    TableContainer, Table, TableHead, TableRow, TableBody,
    TableCell, TableHeader, TableSelectRow, TableSelectAll, TableToolbar, TableToolbarSearch, TableExpandHeader,
    TableExpandRow, TableExpandedRow, TableBatchActions, TableToolbarContent, TableBatchAction
} = DataTable


/**
 * @extends {Component<CrudTableProps, CrudTableState>}}
 * @property {CrudTableState} state
 */
class CrudTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            limit: this.props.pageSizes[0],
            offset: 0,
            rows: [],
            search: {},
            formData: {},

            blinkForm: false,
            loading: false,
            notification: null,
        }
    }

    render() {
        return (<div style={this.props.style}>
            {this.anyModalOpen() && setTimeout(() => window.document.body.scrollTo(0, 0), 10) && setTimeout(() => window.scrollTo(0, 0), 20) && <style dangerouslySetInnerHTML={{ __html: `html, body {margin: 0; height: 100%; overflow: hidden}` }}></style>}
            {this.state.notification && <ToastNotification
                style={{ position: 'absolute', top: 0, right: 0, margin: "1rem", zIndex: 9999 }}
                timeout={5000}
                kind={this.state.notification.kind}
                onCloseButtonClick={ev => this.setState({ notification: null })}
                title={this.state.notification.title || ""}
                subtitle={""}
                caption={this.state.notification.caption || ""}
            />}
            {this.props.advancedSearchOptions && <AdvancedSearch {...this.props.advancedSearchOptions} onChange={search => this.handleSearchChange(search)} value={this.state.search} />}
            {!this.state.loading ?
                <DataTable
                    rows={this.props.rows || (this.props.clientPagination) ? this.state.rows.map(this.props.mapFn)
                        .sort((row1, row2) => {
                            if (!this.state.sortHeader) return null
                            let a = row1[this.state.sortHeader]
                            let b = row2[this.state.sortHeader]
                            if (isNaN(a) || isNaN(b)) return 2 * Number(this.state.order === 'asc' ? a > b : a < b) - 1
                            else return this.state.order === 'asc' ? a - b : b - a
                        })
                        .filter(row => this.state.search.search ? JSON.stringify(row).includes(this.state.search.search) : true)
                        .filter((_, i) => i >= this.state.offset && i < this.state.offset + this.state.limit) :
                        this.state.rows.map(this.props.mapFn)
                    }
                    headers={this.props.headers}
                    radio={this.props.radio}
                    render={props => (
                        <TableContainer style={this.props.style}>
                            {this.renderTableToolbar(props)}
                            <Table>
                                {this.renderTableHead(props)}
                                {this.renderTableBody(props)}
                            </Table>
                        </TableContainer>
                    )}
                /> :
                <DataTableSkeleton style={this.props.style} />
            }
            <Pagination
                pagesUnknown={isNaN(this.state.rowCount)}
                pageInputDisabled={this.state.rowCount > 10000}
                totalItems={this.props.clientPagination ? this.state.rows.filter(row => this.state.search.search ? JSON.stringify(row).includes(this.state.search.search) : true).length : this.state.rowCount}
                pageSizes={this.props.pageSizes}
                onChange={({ page, pageSize }) => this.handlePaginationChange({ limit: pageSize, offset: pageSize * (page - 1) })}
            />
            <Modal
                open={this.state.detailModalOpen}
                className="carbon--custom-form"
                focusTrap={false}
                passiveModal
                onRequestClose={ev => this.setState({ detailModalOpen: false })}
            >
                {this.state.detailModalOpen && this.state.detailModalContent}
            </Modal>
            <Modal
                open={this.state.dangerModalOpen}
                primaryButtonText={this.props.confirmButtonText}
                secondaryButtonText={this.props.cancelButtonText}
                danger={true}
                focusTrap={false}
                modalHeading={this.props.areYouSureText}
                onRequestSubmit={ev => {
                    this.setState({ loading: true })
                    this.state.dangerFunc()
                        .finally(() => this.setState({ loading: false }))
                }}
                onRequestClose={ev => this.setState({ dangerModalOpen: false })}
            >
                {this.props.dangerModalContent && this.props.dangerModalContent(this)}
            </Modal>
            {this.state.modalOpen && setTimeout(() => window.document.body.scrollTo(0, 0), 10) && setTimeout(() => window.scrollTo(0, 0), 20) && <style dangerouslySetInnerHTML={{ __html: `html, body {margin: 0; height: 100%; overflow: hidden}` }}></style>}
            <div className={`bx--modal bx--modal-tall ${this.state.modalOpen && "is-visible"}`} {...this.props} onClick={ev => setTimeout(() => { window.scrollTo(0, 0); window.document.body.scrollTo(0, 0) }, 0)}>
                <div className={`bx--modal-container ${this.props.fields.find(f => f.type === 'custom') ? "bx--modal-container-lg" : ""}`}>
                    <div className="bx--modal-header">
                        <button className="bx--modal-close" type="button" title="close the modal" onClick={ev => this.setState({ modalOpen: false })}>
                            <svg className="bx--modal-close__icon" fillRule="evenodd" height="10" role="img" viewBox="0 0 10 10" width="10" aria-label="close the modal" alt="close the modal">
                                <title>close the modal</title>
                                <path d="M6.32 5L10 8.68 8.68 10 5 6.32 1.32 10 0 8.68 3.68 5 0 1.32 1.32 0 5 3.68 8.68 0 10 1.32 6.32 5z"></path>
                            </svg>
                        </button>
                    </div>
                    <div className="bx--modal-content">
                        {this.state.modalOpen && !this.state.blinkForm && <Form
                            fields={this.props.fields}
                            value={this.state.formData}
                            onChange={formData => this.setState({ formData })}
                        />}
                    </div>
                    {!this.state.loading && <div className="bx--modal-footer">
                        <button onClick={ev => this.setState({ modalOpen: false })} tabIndex="0" className="bx--btn bx--btn--secondary" type="button">{this.props.cancelButtonText}</button>
                        <button onClick={async () => {
                            for (let field of this.props.fields)
                                if (field.invalid && field.invalid(this.state.formData[field.key])) return
                            this.setState({ loading: true })
                            let formattedData = this.validateFields(this.state.formData)
                            await (this.state.formData.id ? this.props.updateResource(this.props.url, formattedData) : this.props.createResource(this.props.url, formattedData))
                                .then(data => {
                                    this.setState({ notification: { kind: 'success', title: this.props.submitSuccessText, caption: "" }, modalOpen: false, formData: {} })
                                    this.loadResources()
                                })
                                .catch(err => this.setState({ notification: { kind: 'error', title: this.props.submitErrorText, caption: this.props.formatErrorMessage(err) } }))
                                .finally(() => this.setState({ loading: false }))
                        }} tabIndex="0" className="bx--btn bx--btn--primary" type="button">{this.props.submitButtonText}</button>
                    </div>}
                </div>
            </div>
        </div>)
    }

    renderTableToolbar(props) {
        return <TableToolbar>
            {this.props.searchable && <TableToolbarSearch onKeyPress={ev => ev.key === "Enter" && this.handleSearchChange({ ...this.state.search, search: this.state.tempSearch })} placeHolderText={window.translate("SEARCH_ALL_FIELDS")} persistant value={this.state.tempSearch} onChange={ev => this.setState({ tempSearch: ev.target.value })} />}
            <TableToolbarContent>
                {this.props.toolbarContent(this.props)}
                {this.props.exportable && <ExportButton {...this.props} />}
                {!this.props.disableCreate && <Button onClick={ev => this.setState({ modalOpen: true, blinkForm: true, formData: {} }, () => this.setState({ blinkForm: false }))}>{this.props.addButtonText}</Button>}
            </TableToolbarContent>
            <TableBatchActions {...props.getBatchActionProps()}>
                {this.props.links
                    .filter((link, i) => !link.condition || props.selectedRows.reduce((allAllowed, row) => allAllowed && link.condition(this.state.rows.find(r => r.id === row.id)), true))
                    .filter(link => link.batch)
                    .map(link =>
                        <TableBatchAction
                            onClick={ev => this.setState({
                                dangerModalOpen: true,
                                dangerFunc: () => Promise.all(props.selectedRows.map(row => link.onClick(this.state.rows.find(r => r.id === row.id))))
                                    .catch(err => this.setState({ notification: { kind: 'error', title: this.props.submitErrorText, caption: this.props.formatErrorMessage(err) } }))
                                    .then(() => this.setState({ dangerModalOpen: false }))
                                    .then(() => this.loadResources())
                            })}
                            renderIcon={() => <Icon style={{ marginLeft: "0.5rem" }} fill="white" icon={iconSettings} />}
                        >
                            {link.text}
                        </TableBatchAction>
                    )}
                {props.selectedRows.reduce((allAllowed, row) => allAllowed && !this.props.disableDelete(this.state.rows.find(r => r.id === row.id)), true) && <TableBatchAction
                    onClick={ev => this.setState({
                        dangerModalOpen: true,
                        dangerFunc: () => Promise.all(props.selectedRows.map(row => this.props.deleteResource(this.props.url, row)))
                            .then(data => this.setState({ notification: { kind: 'success', title: this.props.deleteSuccessText, caption: "" } }))
                            .catch(err => this.setState({ notification: { kind: 'error', title: this.props.deleteErrorText, caption: this.props.formatErrorMessage(err) } }))
                            .then(() => this.setState({ dangerModalOpen: false }))
                            .then(() => this.loadResources())
                    })}
                    renderIcon={() => <Icon style={{ marginLeft: "0.5rem" }} fill="white" icon={iconDelete} />}
                >
                    {this.props.deleteButtonText}
                </TableBatchAction>}
                {this.props.batchActions && this.props.batchActions(props.selectedRows.map(s => this.state.rows.find(r => r.id === s.id)))}
            </TableBatchActions>
        </TableToolbar>

    }

    renderTableHead(props) {
        return <TableHead>
            <TableRow>
                {this.props.renderDetail && <TableExpandHeader />}
                {this.props.selectable && (this.props.radio ? <TableExpandHeader /> : <TableSelectAll {...props.getSelectionProps()} />)}
                {this.props.headers.map((header, i) => (
                    <TableHeader
                        key={`header-${i}`}
                        {...props.getHeaderProps({ header })}
                        isSortHeader={header.key === this.state.sortHeader}
                        sortDirection={this.state.order ? this.state.order.toUpperCase() : 'NONE'}
                        isSortable={header.sortable}
                        onClick={ev => { }}
                        onMouseDown={ev => {
                            if (ev.target.tagName !== "INPUT")
                                this.handleSortChange(header.key, { asc: 'desc', desc: 'none', none: 'asc', }[this.state.order || 'none'])
                        }}
                    >
                        {!header.searchable && header.header}
                        {header.searchable && <>
                            <Form
                                fields={[{ ...header, type: header.type === "id" ? "number" : header.type === "custom" ? "text" : header.type, label: " ", placeholder: `${translate("SEARCH")} ${header.header}` }]}
                                value={this.state.search}
                                onChange={change => this.setState({ search: { ...this.state.search, ...change } })}
                                onKeyPress={ev => ev.key === "Enter" && this.handleSearchChange(this.state.search)}
                            />
                        </>}
                    </TableHeader>
                ))}
                <TableHeader />
            </TableRow>
        </TableHead >

    }

    renderTableBody(props) {
        let RowType = this.props.renderDetail ? TableExpandRow : TableRow
        return <TableBody>
            {props.rows.map((row, i) => <>
                <RowType {...props.getRowProps({ row })}>
                    {this.props.selectable && <TableSelectRow {...props.getSelectionProps({ row })} onSelect={ev => { props.getSelectionProps({ row }).onSelect(ev); setTimeout(() => this.props.onSelectRow(row), 0) }} />}
                    {row.cells.map((cell, j) => <TableCell
                        key={`cell-${j}`}>
                        {this.props.cellDetail[j] ? <Link style={{ cursor: 'pointer' }} onClick={ev => this.setState({ detailModalContent: this.props.cellDetail[j](this.state.rows.find(r => r.id === row.id)), detailModalOpen: true })}>{this.props.headers[j].parse ? this.props.headers[j].parse(cell.value) : cell.value}</Link> : this.props.headers[j].parse ? this.props.headers[j].parse(cell.value) : cell.value}
                    </TableCell>)}
                    <TableCell>
                        {this.hasOverflowOptions() && <OverflowMenu floatingMenu flipped>
                            {this.props.links
                                .filter((link, i) => !link.condition || link.condition(this.state.rows.find(r => r.id === row.id)))
                                .map((link, i) => <OverflowMenuItem
                                    key={`link-${i}`}
                                    itemText={link.text}
                                    onClick={async () => {
                                        if (link.confirm)
                                            this.setState({
                                                dangerModalOpen: true,
                                                dangerFunc: async () => {
                                                    this.setState({ loading: true, dangerModalOpen: false })
                                                    try {
                                                        await link.onClick(this.state.rows.find(r => r.id === row.id))
                                                    }
                                                    catch (err) {
                                                        this.setState({ notification: { kind: 'error', title: this.props.submitErrorText, caption: this.props.formatErrorMessage(err) } })
                                                    }
                                                    this.loadResources()
                                                }
                                            })
                                        else {
                                            this.setState({ loading: true })
                                            try {
                                                await link.onClick(this.state.rows.find(r => r.id === row.id))
                                            }
                                            catch (err) {
                                                this.setState({ notification: { kind: 'error', title: this.props.submitErrorText, caption: this.props.formatErrorMessage(err) } })
                                            }
                                            this.loadResources()
                                        }
                                    }}
                                />
                                )}
                            {!this.props.disableUpdate(this.state.rows.find(r => r.id === row.id)) && <OverflowMenuItem
                                itemText={translate("EDIT")}
                                onClick={() => {
                                    this.setState({
                                        formData: this.state.formData.id !== row.id ? this.state.rows.find(i => i.id === row.id) : this.state.formData,
                                        modalOpen: true, blinkForm: true
                                    }, () => this.setState({ blinkForm: false }))
                                }} />}
                            {!this.props.disableDelete(this.state.rows.find(r => r.id === row.id)) && <OverflowMenuItem
                                itemText={this.props.deleteButtonText}
                                hasDivider={true}
                                isDelete={true}
                                onClick={() => {
                                    this.setState({
                                        dangerModalOpen: true,
                                        dangerFunc: async () => {
                                            await this.props.deleteResource(this.props.url, row)
                                                .then(data => this.setState({ notification: { kind: 'success', title: this.props.deleteSuccessText, caption: "" } }))
                                                .catch(err => this.setState({ notification: { kind: 'error', title: this.props.deleteErrorText, caption: this.props.formatErrorMessage(err) } }))
                                            this.loadResources()
                                            this.setState({ dangerModalOpen: false })
                                        }
                                    })
                                }} />}
                        </OverflowMenu>}
                    </TableCell>
                </RowType>
                {row.isExpanded && this.props.renderDetail && (
                    <TableExpandedRow>
                        <TableCell style={{ maxWidth: "100px" }} colSpan={this.props.headers.length + 2 + !!this.hasOverflowOptions()}>{this.props.renderDetail(this.state.rows[i])}</TableCell>
                    </TableExpandedRow>
                )}
            </>)}
        </TableBody>
    }

    componentDidMount() {
        this.loadResources()
        if (this.props.liveReload) this.interval = setInterval(() => this.loadResources(false), 60000)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    loadResources(setLoading = true) {
        setLoading && this.setState({ loading: true })
        this.props.listResources(this.props.url, {
            limit: this.state.limit,
            offset: this.state.offset,
            export: this.state.export,
            order_by: this.state.order_by,
            order: this.state.order,
            ...this.state.search
        })
            .then(data => this.setState({ rows: data.rows, rowCount: data.count, loading: false }))
            .catch(err => this.setState({ notification: { kind: 'error', title: this.props.listErrorText, caption: this.props.formatErrorMessage(err) }, loading: false }))
    }

    handlePaginationChange(change) {
        this.setState(change, () => this.props.clientPagination ? null : this.loadResources())
    }

    handleSortChange(header, sortDirection) {
        this.setState({ order_by: header, order: sortDirection === 'none' ? null : sortDirection, sortHeader: header },
            () => this.props.clientPagination ? null : this.loadResources()
        )
    }

    handleSearchChange(search) {
        this.setState({ tempSearch: search.search })
        this.setState({
            search,
            offset: 0
        })
        this.props.advancedSearchOptions && this.props.advancedSearchOptions.onChange && this.props.advancedSearchOptions.onChange(search)
        if (this.props.clientPagination) return
        setTimeout(() => this.loadResources(), 0)
    }

    hasOverflowOptions() {
        // return true
        return this.props.links.length > 0 ||
            this.state.rows.reduce((hasOptions, row) => hasOptions || !this.props.disableDelete(row) || !this.props.disableUpdate(row), false)
    }

    anyModalOpen() {
        return this.state.detailModalOpen || this.state.dangerModalOpen || this.state.modalOpen
    }

    validateFields(fields) {
        try {
            return JSON.parse(JSON.stringify(fields).replace(patterns.zeroWidth, ''))
        } catch(err) {
            return fields
        }
    }
}


CrudTable.defaultProps = {
    headers: [],
    links: [],
    fields: [],
    cellDetail: [],
    pageSizes: [10, 50, 100],
    onSelectRow: () => { },
    mapFn: row => row,
    clientPagination: false,
    listResources: (url, params) => axios({ method: 'get', url, params }).then(response => response.data),
    createResource: (url, row) => axios({ url: url, method: 'post', data: row }).then(response => response.data),
    updateResource: (url, row) => axios({ url: url.includes('?') ? url.split('?').join(`/${row.id}?`) : `${url}/${row.id}`, method: 'put', data: row }).then(response => response.data),
    deleteResource: (url, row) => axios({ url: url.includes('?') ? url.split('?').join(`/${row.id}?`) : `${url}/${row.id}`, method: 'delete' }).then(response => response.data),
    disableCreate: false,
    disableUpdate: (row) => false,
    disableDelete: (row) => false,
    formatErrorMessage: error => translate(error.response ? error.response.data.message : error.message),
    addButtonText: translate("ADD"),
    cancelButtonText: translate("CANCEL"),
    confirmButtonText: translate("CONFIRM"),
    submitButtonText: translate("SUBMIT"),
    deleteButtonText: translate("DELETE"),
    areYouSureText: translate("ARE_YOU_SURE"),
    listErrorText: translate("ERROR_LOADING_RESOURCES"),
    submitErrorText: translate("ERROR_CREATING_RESOURCE"),
    submitSuccessText: translate("SUCCESS_CREATING_RESOURCE"),
    deleteErrorText: translate("ERROR_DELETING_RESOURCE"),
    deleteSuccessText: translate("SUCCESS_DELETING_RESOURCE"),
    toolbarContent: props => <></>
}

export default CrudTable