const { basicServiceRequest } = require('./_basic')

let authRequest = basicServiceRequest('/auth')

let login = (data) => authRequest({ method: 'POST', url: '/login', data })
let logout = () => authRequest({ method: 'POST', url: '/logout' })
let listUsers = (params) => authRequest({ method: 'GET', url: '/users', params })
let createUser = (data) => authRequest({ method: 'POST', url: '/users', data})
let getUser = (id, data) => authRequest({ method: 'GET', url: `/users/${id}`})
let getOwnUser = () => authRequest({ method: 'GET', url: `/users/me`})
let updateUser = (id, data) => authRequest({ method: 'PUT', url: `/users/${id}`, data})
let deleteUser = (id) => authRequest({ method: 'DELETE', url: `/users/${id}` })
let listAPIKeys = (user_id, params) => authRequest({ method: 'GET', url: `/users/${user_id}/apikeys`, params })
let createAPIKey = (user_id, data) => authRequest({ method: 'POST', url: `/users/${user_id}/apikeys`, data})
let updateAPIKey = (id, user_id, data) => authRequest({ method: 'PUT', url: `/users/${user_id}/apikeys/${id}`, data})
let deleteAPIKey = (id, user_id) => authRequest({ method: 'DELETE', url: `/users/${user_id}/apikeys/${id}` })

module.exports = {
    login,
    logout,
    listUsers,
    createUser,
    getUser,
    getOwnUser,
    updateUser,
    deleteUser,
    listAPIKeys,
    createAPIKey,
    updateAPIKey,
    deleteAPIKey
}