const renderHTML = require('react-render-html')
let moment = require('moment')
try { require('moment/locale/' + navigator.language.slice(0, 2)) } catch { }

module.exports = {
    KnowledgeBase: {
        fields: [
            { key: "name", type: "text", header: true },
            { key: "document", type: "file", label: window.translate("ADD_FILE") },
            { key: "created_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
            { key: "updated_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
        ],

        actions: [
            { path: "documents", text: "DOCUMENTS", link: true },
            { path: "permissions", text: "PERMISSIONS", link: true },
        ]
    },

    Document: {
        fields: [
            { key: "name", type: "text", header: true },
            { key: "created_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
            { key: "updated_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
        ],

        actions: [
            { path: "answer_units", text: "ANSWER_UNITS", link: true },
        ]
    },

    AnswerUnit: {
        fields: [
            { key: "title", type: "text", header: true },
            { key: "created_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
            { key: "updated_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
        ],

        renderDetail: row => renderHTML(row.content)
    }
}