import React, { Component } from 'react';
import QueryInterface from './components/query-interface';

class OrchestratorQueryPage extends Component {

    render() {
        return <>
            <QueryInterface orchestrator_id={this.props.match.params.orchestrator_id} />
        </>
    }
}

export default OrchestratorQueryPage