const queryString = require('query-string')
const { basicServiceRequest } = require('./_basic')

let corpusRequest = basicServiceRequest('/corpus')

let listDatasets = (params) => corpusRequest({ method: 'GET', url: '/datasets', params })
let createDataset = (data) => corpusRequest({ method: 'POST', url: '/datasets', data })
let getDataset = (id) => corpusRequest({ method: 'GET', url: `/datasets/${id}` })
let updateDataset = (id, data) => corpusRequest({ method: 'PUT', url: `/datasets/${id}`, data })
let deleteDataset = (id) => corpusRequest({ method: 'DELETE', url: `/datasets/${id}` })
let getDatasetClassDistibution = (dataset_id, params) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/class_distribution`, params })
let exportDataset = (dataset_id, filename, params) => {
    var link = document.createElement("a")
    link.download = filename
    link.href = `${process.env.REACT_APP_PATH || ""}/corpus/datasets/${dataset_id}/export_spreadsheet?${queryString.stringify(params)}`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}
let importDataset = (id, data) => corpusRequest({ method: 'POST', url: `/datasets/${id}/import_spreadsheet`, data })
let getDatasetWordCloud = (id) => corpusRequest({ method: 'GET', url: `/datasets/${id}/word_cloud` })
let getDatasetStats = (id) => corpusRequest({ method: 'GET', url: `/datasets/${id}/stats` })
let getDatasetAccuracyData = (id, params) => corpusRequest({ method: 'GET', url: `/datasets/${id}/accuracy`, params })
let getDatasetTaxonomies = (id, params) => corpusRequest({ method: 'GET', url: `/datasets/${id}/taxonomies`, params })
let getDatasetOwners = (id, params) => corpusRequest({ method: 'GET', url: `/datasets/${id}/owners`, params })
let auditDatasetImport = (id, data) => corpusRequest({ method: 'POST', url: `/datasets/${id}/audit_import`, data })
let importDatasetConfig = (id, data) => corpusRequest({ method: 'PUT', url: `/datasets/${id}/import`, data })

let listDatasetPermissions = (dataset_id, params) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/permissions`, params })
let createDatasetPermission = (dataset_id, data) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/permissions`, data })
let updateDatasetPermission = (dataset_id, id, data) => corpusRequest({ method: 'PUT', url: `/datasets/${dataset_id}/permissions/${id}`, data })
let deleteDatasetPermission = (dataset_id, id) => corpusRequest({ method: 'DELETE', url: `/datasets/${dataset_id}/permissions/${id}` })

let listExperiments = (dataset_id, params) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/experiments`, params })
let createExperiment = (dataset_id, data) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/experiments`, data })
let getExperiment = (dataset_id, id) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/experiments/${id}` })
let updateExperiment = (dataset_id, id, data) => corpusRequest({ method: 'PUT', url: `/datasets/${dataset_id}/experiments/${id}`, data })
let deleteExperiment = (dataset_id, id) => corpusRequest({ method: 'DELETE', url: `/datasets/${dataset_id}/experiments/${id}` })
let getAccuracyReport = (dataset_id, id, params) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/experiments/${id}/accuracy_report`, params })
let getAccuracyVSClassSize = (dataset_id, id, params) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/experiments/${id}/accuracy_vs_class_size`, params })
let getAccuracyVSCoverage = (dataset_id, id, params) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/experiments/${id}/accuracy_vs_coverage`, params })
let getConfidenceVSClassSize = (dataset_id, id, params) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/experiments/${id}/confidence_vs_class_size`, params })
let getPairwiseClassErrors = (dataset_id, id, params) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/experiments/${id}/pairwise_class_errors`, params })

let listPredictions = (dataset_id, experiment_id, params) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/experiments/${experiment_id}/predictions`, params })
let createPrediction = (dataset_id, experiment_id, data) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/experiments/${experiment_id}/predictions`, data })
let getPrediction = (dataset_id, experiment_id, id) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/experiments/${experiment_id}/predictions/${id}` })
let updatePrediction = (dataset_id, experiment_id, id, data) => corpusRequest({ method: 'PUT', url: `/datasets/${dataset_id}/experiments/${experiment_id}/predictions/${id}`, data })
let deletePrediction = (dataset_id, experiment_id, id) => corpusRequest({ method: 'DELETE', url: `/datasets/${dataset_id}/experiments/${experiment_id}/predictions/${id}` })
let remapPrediction = (dataset_id, experiment_id, id) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/experiments/${experiment_id}/predictions/${id}/remap` })

let listEvaluations = (dataset_id, params) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/evaluations`, params })
let getEvaluation = (dataset_id, id) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/evaluations/${id}` })

let listRecommendations = (dataset_id, evaluation_id, params) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/evaluations/${evaluation_id}/recommendations`, params })
let createRecommendation = (dataset_id, evaluation_id, data) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/evaluations/${evaluation_id}/recommendations`, data })
let getRecommendation = (dataset_id, evaluation_id, id) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/evaluations/${evaluation_id}/recommendations/${id}` })
let updateRecommendation = (dataset_id, evaluation_id, id, data) => corpusRequest({ method: 'PUT', url: `/datasets/${dataset_id}/evaluations/${evaluation_id}/recommendations/${id}`, data })
let deleteRecommendation = (dataset_id, evaluation_id, id) => corpusRequest({ method: 'DELETE', url: `/datasets/${dataset_id}/evaluations/${evaluation_id}/recommendations/${id}` })
let executeRecommendation = (dataset_id, evaluation_id, id) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/evaluations/${evaluation_id}/recommendations/${id}/execute` })

let listAllIntents = (params) => corpusRequest({ method: 'GET', url: `/intents`, params })
let listIntents = (dataset_id, params) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/intents`, params })
let createIntent = (dataset_id, data) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/intents`, data })
let getIntent = (dataset_id, id) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/intents/${id}` })
let updateIntent = (dataset_id, id, data) => corpusRequest({ method: 'PUT', url: `/datasets/${dataset_id}/intents/${id}`, data })
let deleteIntent = (dataset_id, id) => corpusRequest({ method: 'DELETE', url: `/datasets/${dataset_id}/intents/${id}` })
let getRelatedIntents = (dataset_id, id) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/intents/${id}/related_intents` })
let getIntentWordCloud = (dataset_id, id) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/intents/${id}/word_cloud` })
let mergeIntents = (dataset_id, id, targetIntentID) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/intents/${id}/merge`, data: { intent_id: targetIntentID } })
let importIntent = (dataset_id, data) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/intents/import`, data })

let listExamples = (dataset_id, intent_id, params) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/intents/${intent_id}/examples`, params })
let listAllExamples = (dataset_id, params) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/examples`, params })
let createExample = (dataset_id, intent_id, data) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/intents/${intent_id}/examples`, data })
let updateExample = (dataset_id, intent_id, id, data) => corpusRequest({ method: 'PUT', url: `/datasets/${dataset_id}/intents/${intent_id}/examples/${id}`, data })
let deleteExample = (dataset_id, intent_id, id) => corpusRequest({ method: 'DELETE', url: `/datasets/${dataset_id}/intents/${intent_id}/examples/${id}` })
let remapExample = (dataset_id, intent_id, id, data) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/intents/${intent_id}/examples/${id}/remap`, data })
let importExample = (dataset_id, data) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/examples/import`, data })

let listReviewExamples = (dataset_id, params) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/review_examples`, params })
let createReviewExample = (dataset_id, data) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/review_examples`, data })
let updateReviewExample = (dataset_id, id, data) => corpusRequest({ method: 'PUT', url: `/datasets/${dataset_id}/review_examples/${id}`, data })
let deleteReviewExample = (dataset_id, id) => corpusRequest({ method: 'DELETE', url: `/datasets/${dataset_id}/review_examples/${id}` })
let mapReviewExample = (dataset_id, id, data) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/review_examples/${id}/map`, data })
let acceptReviewExample = (dataset_id, id) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/review_examples/${id}/accept` })
let rejectReviewExample = (dataset_id, id) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/review_examples/${id}/reject` })

let listEntities = (dataset_id, params) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/entities`, params })
let createEntity = (dataset_id, data) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/entities`, data })
let getEntity = (dataset_id, id) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/entities/${id}` })
let updateEntity = (dataset_id, id, data) => corpusRequest({ method: 'PUT', url: `/datasets/${dataset_id}/entities/${id}`, data })
let deleteEntity = (dataset_id, id) => corpusRequest({ method: 'DELETE', url: `/datasets/${dataset_id}/entities/${id}` })
let transferEntity = (dataset_id, id, other_dataset_id, copy) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/entities/${id}/transfer`, data: { dataset_id: other_dataset_id }, params: { copy } })
let importEntity = (dataset_id, data) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/entities/import`, data })

let listEntityValues = (dataset_id, entity_id, params) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/entities/${entity_id}/entity_values`, params })
let createEntityValue = (dataset_id, entity_id, data) => corpusRequest({ method: 'POST', url: `/datasets/${dataset_id}/entities/${entity_id}/entity_values`, data })
let getEntityValue = (dataset_id, entity_id, id) => corpusRequest({ method: 'GET', url: `/datasets/${dataset_id}/entities/${entity_id}/entity_values/${id}` })
let updateEntityValue = (dataset_id, entity_id, id, data) => corpusRequest({ method: 'PUT', url: `/datasets/${dataset_id}/entities/${entity_id}/entity_values/${id}`, data })
let deleteEntityValue = (dataset_id, entity_id, id) => corpusRequest({ method: 'DELETE', url: `/datasets/${dataset_id}/entities/${entity_id}/entity_values/${id}` })

module.exports = {
    listDatasets,
    createDataset,
    getDataset,
    updateDataset,
    deleteDataset,
    getDatasetClassDistibution,
    exportDataset,
    importDataset,
    getDatasetWordCloud,
    getDatasetStats,
    getDatasetAccuracyData,
    getDatasetTaxonomies,
    getDatasetOwners,

    listDatasetPermissions,
    createDatasetPermission,
    updateDatasetPermission,
    deleteDatasetPermission,

    listExperiments,
    createExperiment,
    updateExperiment,
    getExperiment,
    deleteExperiment,
    getAccuracyReport,
    getAccuracyVSClassSize,
    getAccuracyVSCoverage,
    getConfidenceVSClassSize,
    getPairwiseClassErrors,

    listPredictions,
    createPrediction,
    getPrediction,
    updatePrediction,
    deletePrediction,
    remapPrediction,

    listEvaluations,
    getEvaluation,

    listRecommendations,
    createRecommendation,
    getRecommendation,
    updateRecommendation,
    deleteRecommendation,
    executeRecommendation,

    listAllIntents,
    listIntents,
    createIntent,
    getIntent,
    updateIntent,
    deleteIntent,
    getRelatedIntents,
    getIntentWordCloud,
    mergeIntents,

    listExamples,
    listAllExamples,
    createExample,
    updateExample,
    deleteExample,
    remapExample,

    listReviewExamples,
    createReviewExample,
    updateReviewExample,
    deleteReviewExample,
    mapReviewExample,
    acceptReviewExample,
    rejectReviewExample,

    listEntities,
    createEntity,
    getEntity,
    updateEntity,
    deleteEntity,
    transferEntity,

    listEntityValues,
    createEntityValue,
    getEntityValue,
    updateEntityValue,
    deleteEntityValue,

    importEntity,
    importExample,
    importIntent,
    importDatasetConfig,
    auditDatasetImport
}