import React, { Component } from 'react';
import ReactWordcloud from 'react-wordcloud';
import { UnorderedList, ListItem, Loading } from 'carbon-components-react'
import { getDatasetWordCloud, getDatasetClassDistibution, getDatasetStats } from '../../services/corpus'
import DatasetHeader from './components/dataset-header';
import BarGraph from '../../components/bar-graph';
import AccuracyGraph from './components/accuracy-graph';


class DatasetOverviewPage extends Component {

    options = {
        fontFamily: 'impact',
        rotations: 3,
        rotationAngles: [0, 0],
        enableTooltip: false,
    };


    state = {
        classDistribution: [],
        wordCloud: [],
        loading: true
    }

    render() {
        return (<>
            <DatasetHeader {...this.props} />
            <div className="bx--grid">
                <div className="bx--row">
                    <div className="bx--col-xs-6">
                        <h3>{window.translate("CLASS_DISTRIBUTION")}</h3>
                        <BarGraph
                            id='class-distribution'
                            width={window.innerWidth / 2}
                            data={this.state.classDistribution}
                            xLabel='Intent'
                            yLabel='# Examples'
                        />
                    </div>
                    <div className="bx--col-xs-6">
                        <div className="bx--row">
                        <div className="bx--col-xs-4">
                                <h3>{window.translate("INTENTS")}</h3>
                                <UnorderedList>
                                    <ListItem style={{ color: "green" }}>+ {this.state.intentCreations} {window.translate("CREATIONS")}</ListItem>
                                    <ListItem style={{ color: "red" }}>- {this.state.intentDeletions} {window.translate("DELETIONS")}</ListItem>
                                    <ListItem style={{ color: "blue" }}> {this.state.intentUpdates} {window.translate("EDITIONS")}</ListItem>
                                </UnorderedList>
                            </div>
                            <div className="bx--col-xs-4">
                                <h3>{window.translate("ENTITIES")}</h3>
                                <UnorderedList>
                                    <ListItem style={{ color: "green" }}>+ {this.state.entityCreations} {window.translate("CREATIONS")}</ListItem>
                                    <ListItem style={{ color: "red" }}>- {this.state.entityDeletions} {window.translate("DELETIONS")}</ListItem>
                                    <ListItem style={{ color: "blue" }}> {this.state.entityUpdates} {window.translate("EDITIONS")}</ListItem>
                                </UnorderedList>
                            </div>
                            <div className="bx--col-xs-4">
                                <h3>{window.translate("EXAMPLES")}</h3>
                                <UnorderedList>
                                    <ListItem style={{ color: "green" }}>+ {this.state.exampleCreations} {window.translate("MAPPINGS")}</ListItem>
                                    <ListItem style={{ color: "red" }}>- {this.state.exampleDeletions} {window.translate("DELETIONS")}</ListItem>
                                    <ListItem style={{ color: "blue" }}> {this.state.exampleUpdates} {window.translate("REMAPPINGS")}</ListItem>
                                </UnorderedList>
                            </div>
                        </div>
                        <div className="bx--row">
                            <h3>{window.translate("WORD_CLOUD")}</h3>
                            <div className="bx--col-xs-12">
                                <ReactWordcloud options={this.options} words={this.state.wordCloud} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bx--row">
                    <div className="bx--col-xs-6">
                    </div>
                    <div className="bx--col-xs-6">
                        <h3>{window.translate("LATEST_EXPERIMENT_RESULTS")}</h3>
                        <AccuracyGraph
                            dataset_id={this.props.match.params.dataset_id}
                        />
                    </div>
                </div>
            </div>
            <Loading active={this.state.loading} />
        </>)
    }

    async componentDidMount() {
        Promise.all([
            getDatasetWordCloud(this.props.match.params.dataset_id),
            getDatasetClassDistibution(this.props.match.params.dataset_id),
            getDatasetStats(this.props.match.params.dataset_id),
        ])
        .then((
            [wordCloud, classDistribution, stats]) => {
            this.setState({
                loading: false,
                wordCloud: wordCloud.map(w => ({ text: w.word, value: Math.log(w.count) })),
                classDistribution: classDistribution.map(d => ({ x: d.name, y: d.count, group: window.translate("EXAMPLES") })),
                intentCreations: stats.intentCreations, intentDeletions: stats.intentDeletions, intentUpdates: stats.intentUpdates,
                entityCreations: stats.entityCreations, entityDeletions: stats.entityDeletions, entityUpdates: stats.entityUpdates,
                exampleCreations: stats.exampleCreations, exampleDeletions: stats.exampleDeletions, exampleUpdates: stats.exampleUpdates,
            })
        })
    }

}

export default DatasetOverviewPage