import React, { Component } from 'react';
import { Accordion, AccordionItem, Button } from 'carbon-components-react'

import Form from './form'
import translate from '../i18n';

class AdvancedSearch extends Component {

    state = {}

    render() {
        return (
            <Accordion onKeyPress={ev => ev.key === "Enter" && this.props.onChange(this.state.value)}>
                <AccordionItem title={this.props.title}>
                    <div className="bx--grid">
                        <div className="bx--row">
                            <div className="bx--col-lg-6 bx--col-md-6 bx--col-sm-12 bx--col-xs-12">
                                <Form
                                    fields={this.props.filters}
                                    value={this.state.value}
                                    onChange={search => this.setState({ value: search })}
                                />
                                <Button style={{ margin: "1rem" }} onClick={ev => this.props.onChange(this.state.value)}>{this.props.searchButtonText}</Button>
                                <Button style={{ margin: "1rem" }} kind="secondary" onClick={ev => this.setState({ value: {} }, () => this.props.onChange({}))}>{this.props.clearButtonText}</Button>
                            </div>
                        </div>
                    </div>
                </AccordionItem>
            </Accordion>
        )
    }
}

AdvancedSearch.defaultProps = {
    filters: [],
    title: translate("ADVANCED_SEARCH"),
    clearButtonText: translate("CLEAR"),
    searchButtonText: translate("SEARCH")
}

export default AdvancedSearch