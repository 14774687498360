import React, { Component } from 'react';
import { remapPrediction, listPredictions, getIntentWordCloud } from '../../../services/corpus'
import { CrudTable } from '../../../components/carbon-react-crud';
import IntentDetail from './intent-detail';
import renderHTML from 'react-render-html';

class PairwiseDetail extends Component {

    state = {
        predictedWords: [],
        trueWords: [],
    }

    render() {
        return (<>
            <h3>{window.translate("MISSES")}</h3>
            <CrudTable
                headers={[
                    { key: "text", header: window.translate("TEXT"), sortable: true, parse: text => this.highlightConflicts(text, this.state.predictedWords) },
                    { key: "confidence", header: window.translate("CONFIDENCE"), sortable: true },
                ]}
                links={[
                    { onClick: row => remapPrediction(this.props.dataset_id, this.props.experiment_id, row.id), text: window.translate("REMAP"), confirm: true, batch: true }
                ]}
                listResources={(url, params) => listPredictions(this.props.dataset_id, this.props.experiment_id, { ...params, predicted_intent_id: this.props.prediction.predicted_intent_id, true_intent_id: this.props.prediction.true_intent_id })}
                selectable
                disableUpdate={row => true} disableDelete={row => true} disableCreate searchable
            />
            <div style={{ display: 'flex' }}>
                <div style={{ width: "50%", padding: "1rem" }}>
                    <h3>{window.translate("TRUE_INTENT")}</h3>
                    <IntentDetail
                        intentID={this.props.prediction.true_intent_id}
                        exampleParser={text => this.highlightConflicts(text, this.state.predictedWords)}
                    />
                </div>
                <div style={{ width: "50%", padding: "1rem" }}>
                    <h3>{window.translate("PREDICTED_INTENT")}</h3>
                    <IntentDetail
                        intentID={this.props.prediction.predicted_intent_id}
                        exampleParser={text => this.highlightConflicts(text, this.state.trueWords)}
                    />
                </div>
            </div>
        </>)
    }

    componentDidMount() {
        Promise.all([
            getIntentWordCloud(this.props.dataset_id, this.props.prediction.predicted_intent_id),
            getIntentWordCloud(this.props.dataset_id, this.props.prediction.true_intent_id)
        ])
            .then(([w1, w2]) => {
                this.setState({
                    predictedWords: w1.map(w => w.word),
                    trueWords: w2.map(w => w.word),
                })
            })

    }

    highlightConflicts(text, conflicts) {
        return renderHTML(text.replace(new RegExp(`\\b(${conflicts.join('|')})\\b`, 'g'), '<strong>$1</strong>'))
    }

}


export default PairwiseDetail