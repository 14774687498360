import React, { Component } from 'react';
import { Tile, TextInput } from 'carbon-components-react';

class DisambiguationEditor extends Component {

    render() {
        return (<Tile>
            <h3>{window.translate("IF_ASSISTANT_NEEDS_TO_REPRESENT_NODE_USE")}:</h3>
            <TextInput placeholder={window.translate("ENTER_EXTERNAL_NODE_NAME")} value={this.props.selectedNode.user_label} onChange={ev => this.props.onChange(this.props.selectedNode, { user_label: ev.target.value })} />
        </Tile>)
    }
}

export default DisambiguationEditor