import React, { Component } from 'react';
import { Button } from 'carbon-components-react'
import { json2csv } from 'json-2-csv'
import axios from "axios"
import translate from '../i18n'
import YAML from 'json-to-pretty-yaml'

class ExportButton extends Component {

    state = { loading: false }

    render() {
        return (<Button kind='secondary' {...this.props} disabled={this.state.loading} onClick={async () => {
            this.setState({ loading: true })
            try {
                let data = await this.props.listResources(this.props.url, { export: true })
                json2csv(data.rows
                    .map(row => Object.keys(row)
                        .reduce((acc, key) => ({
                            ...acc,
                            [this.props.translate(key)]: typeof row[key] === 'object' ? YAML.stringify(row[key]) : row[key]
                        }), {})),
                    (err, rows) => {
                        var blob = new Blob([rows], { type: 'text/csv;charset=utf-8;' });
                        var link = document.createElement("a");
                        var url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", 'export.csv');
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        this.setState({ loading: false })
                    },
                    {...this.props.csvOptions, sortHeader: this.props.sortHeader})
            } catch (err) {
                console.log(err)
                this.setState({ loading: false })
                throw err
            }
        }}>{this.props.label}
        </Button>)
    }
}

ExportButton.defaultProps = {
    label: translate("Export"),
    translate: translate,
    listResources: (url, params) => axios({ method: 'get', url, params }).then(response => response.data),
    csvOptions: { delimiter: { field: ';' }, emptyFieldValue: "", sortHeader: true, excelBOM: true }
}

export default ExportButton