import React, { Component } from 'react';
import { Tile } from 'carbon-components-react'
import { Link } from "react-router-dom";

class ServiceTile extends Component {
    render() {
        return (
            <Link to={this.props.link} {...this.props}>
                <Tile>
                    <h2> {this.props.name} </h2>
                    <br />
                    <p> {this.props.description} </p>
                    {this.props.icon &&
                        <img alt="service-icon" src={`${process.env.PUBLIC_URL}${this.props.icon}`} />
                    }
                </Tile>
            </Link>
        )
    }
}

export default ServiceTile