const { default: Axios } = require('axios')

module.exports = {
    actions: {
        retrain: (skill) => {
            return Axios.post('/orchestrator/assistant_skills/{assistant_skill_id}/retrain'.replace("{assistant_skill_id}", skill.id))
                .then(response => {
                    let data = {
                        id: response.data.id,
                        name: response.data.name,
                        status: "TRAINING"
                    }
                    let skills = sessionStorage.getItem('skills') ? JSON.parse(sessionStorage.getItem('skills')) : []
                    skills.push(data)
                    sessionStorage.setItem('skills', JSON.stringify(skills))
                })
                .catch(error => console.error(error))
            }
    }
}