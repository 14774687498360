import React, { Component } from 'react';
import { Tooltip, Tabs, Tab, UnorderedList, ListItem, Icon } from 'carbon-components-react';
import { iconMaximize, iconMinimize } from 'carbon-icons';
import ResourcePreview from './resource-previews';

class ResourceCompare extends Component {

    state = {
        maximized: false
    }

    maximizedStyle = {
        position: 'fixed',
        inset: 0,
        padding: '1rem',
        background: 'white',
        zIndex: 1
    }

    render() {
        let diffKeys = Object.keys(this.props.before || this.props.after || {})
            .filter(k => this.props.showAllFields || JSON.stringify((this.props.after || {})[k]) !== JSON.stringify((this.props.before || {})[k]))
        diffKeys.push("id", "name", "serial", "text")
        return (
            <Tabs selected={Number(!this.props.before && !this.props.after)}>
                {this.state.maximized && !!setTimeout(() => window.document.body.scrollTo(0, 0), 10) && !!setTimeout(() => window.scrollTo(0, 0), 20)}
                <Tab label={window.translate("COMPARISON")}>
                    <div style={this.state.maximized ? this.maximizedStyle : {}}>
                        {this.props.before && this.props.after && <Icon onClick={() => this.setState({ maximized: this.state.maximized ? false : 1 })} style={{ fill: "gray", cursor: "pointer" }} icon={this.state.maximized ? iconMinimize : iconMaximize} />}
                        {this.props.before && this.props.after && this.state.maximized && <Tabs autofocus onSelectionChange={index => this.setState({ maximized: index + 1 })}><Tab label={window.translate("BEFORE")} ></Tab><Tab label={window.translate("AFTER")} ></Tab></Tabs>}
                        <div style={{ display: "flex" }}>
                            {this.props.before && <div style={{ flex: 1, display: this.state.maximized && this.state.maximized !== 1 ? "none" : "block" }}>
                                <ResourcePreview {...this.props} value={this.getResourceBefore()} keys={diffKeys} />
                            </div>}
                            {!this.state.maximized && this.props.before && this.props.after && <div style={{ margin: "1rem", whiteSpace: "nowrap", alignSelf: "center", textAlign: "center", flex: 0.1 }}>
                                <Tooltip triggerText="" iconName="icon--arrow--right"></Tooltip>
                            </div>}
                            {this.props.after && <div style={{ flex: 1, display: this.state.maximized && this.state.maximized !== 2 ? "none" : "block" }}>
                                <ResourcePreview {...this.props} value={this.getResourceAfter()} keys={diffKeys} />
                            </div>}
                        </div>
                    </div>
                </Tab>
                <Tab label={window.translate("RAW")}>
                    <UnorderedList>
                        {Object.keys(this.props.value)
                            .map(key => !["resource_before", "resource_after"].includes(key) && <ListItem>
                                {window.translate(key)}: {this.props.value[key]}
                            </ListItem>)}
                    </UnorderedList>

                </Tab>
            </Tabs>
        )
    }

    getResourceBefore() {
        if (this.props.resource_type !== 'INTENT' || !(this.props.before && this.props.after)) return this.props.before
        else if (this.props.before.type === 3 && this.props.after.type === 3 && Array.isArray(this.props.before.content) && Array.isArray(this.props.after.content)) return {
            ...this.props.before,
            content: this.props.before.content.map(beforeNode => {
                let status = this._dialogNodeStatus(beforeNode, this.props.before.content, this.props.after.content)
                return {
                    ...beforeNode,
                    color: status === "deleted" ? "lightpink" :
                        status === "modified" ? "lightyellow" : "white"
                }
            })
        }
        else if (this.props.before.type === 2) return {
            ...this.props.before,
            content: this._getProcflowBefore(this.props.before.content, this.props.after.content)
        }
        else return this.props.before
    }
    getResourceAfter() {
        if (this.props.resource_type !== 'INTENT' || !(this.props.before && this.props.after)) return this.props.after
        else if ((this.props.before.type === 3 || this.props.before.type === 4) && (this.props.after.type === 3 || this.props.after.type === 4) && Array.isArray(this.props.before.content) && Array.isArray(this.props.after.content)) return {
            ...this.props.after,
            content: this.props.after.content.map(afterNode => {
                let status = this._dialogNodeStatus(afterNode, this.props.before.content, this.props.after.content)
                return {
                    ...afterNode,
                    color: status === 'created' ? "lightgreen" :
                        status === 'modified' ? "lightyellow" : "white"
                }
            })
        }
        else if (this.props.after.type === 2) return {
            ...this.props.after,
            content: this._getProcflowAfter(this.props.after.content, this.props.before.content)
        }
        else return this.props.after
    }

    _getProcflowBefore(beforeContent, afterContent, deletedParent = false) {
        let isDeleted = deletedParent || !afterContent
        let isModified = afterContent && JSON.stringify(beforeContent) !== JSON.stringify(afterContent)
        return {
            ...beforeContent,
            color: isDeleted ? "lightpink" : isModified ? "lightyellow" : "white",
            childs: (beforeContent.childs || []).map((ac, i) => this._getProcflowBefore(ac, !isDeleted && afterContent.childs && afterContent.childs.find(bc => bc.id === ac.id), isDeleted))
        }
    }

    _getProcflowAfter(afterContent, beforeContent, newParent = false) {
        let isNew = newParent || !beforeContent
        let isModified = beforeContent && JSON.stringify(afterContent) !== JSON.stringify(beforeContent)
        return {
            ...afterContent,
            color: isNew ? "lightgreen" : isModified ? "lightyellow" : "white",
            childs: (afterContent.childs || []).map((ac, i) => this._getProcflowAfter(ac, !isNew && beforeContent.childs && beforeContent.childs.find(bc => bc.id === ac.id), isNew))
        }
    }

    _dialogNodeStatus(node, before, after) {
        if (!before.map(n => n.dialog_node).includes(node.dialog_node)) return 'created'
        if (!after.map(n => n.dialog_node).includes(node.dialog_node)) return 'deleted'
        let beforeNode = before.find(n => n.dialog_node === node.dialog_node)
        let afterNode = after.find(n => n.dialog_node === node.dialog_node)
        let beforeChildren = before.filter(n => n.parent === node.dialog_node)
        let afterChildren = after.filter(n => n.parent === node.dialog_node)
        if (JSON.stringify(afterNode) !== JSON.stringify(beforeNode)) return 'modified'
        if ([...beforeChildren, ...afterChildren].find(child => ['slot', 'event_handler', 'response_condition'].includes(child.type) && this._dialogNodeStatus(child, before, after) !== 'unchanged')) return 'modified'
        return 'unchanged'
    }

}

ResourceCompare.defaultProps = {
    value: {}
}


export default ResourceCompare