import React, { Component } from 'react';
import { CrudTable } from '../../components/carbon-react-crud';


class AssistantSkillIntentExamplesPage extends Component {

    render() {
        return (<>
            <h3>{window.translate("EXAMPLES")}</h3>
            <br />
            <CrudTable
                url={`/orchestrator/assistant_skills/${this.props.match.params.assistant_skill_id}/intents/${this.props.match.params.intent_id}/examples`}
                headers={[{ key: "id", header: window.translate("EXAMPLE"), sortable: true }]}
                clientPagination searchable
                disableCreate disableUpdate={row => true} disableDelete={row => true}
            />
        </>)
    }
}

export default AssistantSkillIntentExamplesPage