const axios = require('axios')

function basicServiceRequest(servicePath) {
    return function (options) {
        return axios({
            ...options,
            url: (process.env.REACT_APP_PATH || "") + servicePath + options.url,
        })
            .then(response => response.data)
            .catch(err => {
                if (err.response) {
                    if (err.response.status === 401) setTimeout(() => window.location.replace((process.env.REACT_APP_PATH || "") + '/login'), 2000)
                    throw err.response.data ? err.response.data : err.response
                }
                throw err
            })
    }
}

export { basicServiceRequest }