import React from 'react';
import { Tooltip } from 'carbon-components-react'
const { listUsers } = require('../services/auth')

let Permission = {
    title: window.translate("PERMISSIONS"),
    fields: [
        {
            key: "user_id",
            label: window.translate("USER"),
            type: "recordpicker",
            fetchOptions: (url, params) => listUsers(params).then(data => ({ rows: data.rows.map(r => ({ name: r.username, id: r.username })) })),
            header: true,
            sortable: true,
        },
        {
            key: "role",
            type: "select",
            options: [
                { value: "", label: "" },
                { value: "admin", label: window.translate("ADMIN") },
                { value: "editor", label: window.translate("EDITOR") },
                { value: "auditor", label: window.translate("AUDITOR") },
                { value: "viewer", label: window.translate("VIEWER") },
            ],
            header: true,
            sortable: true,
            tooltip: props => <Tooltip triggerText={window.translate("MEANING_OF_ROLES")}>
                {props.value.role === 'admin' && <p>{window.translate("ADMIN_DESCRIPTION")}</p>}
                {props.value.role === 'auditor' && <p>{window.translate("AUDITOR_DESCRIPTION")}</p>}
                {props.value.role === 'editor' && <p>{window.translate("EDITOR_DESCRIPTION")}</p>}
                {props.value.role === 'viewer' && <p>{window.translate("VIEWER_DESCRIPTION")}</p>}
                {!props.value.role && <p>{window.translate("NO_ROLE_DESCRIPTION")}</p>}
            </Tooltip>,
            parse: role => window.translate(role)
        },
    ],
}

export default Permission