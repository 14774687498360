import React, { Component } from 'react';
import { Tile, StructuredListWrapper, StructuredListHead, StructuredListRow, StructuredListCell, StructuredListBody, Button, Icon } from 'carbon-components-react';
import { iconLink } from 'carbon-icons'

class FolderChildrenViewer extends Component {

    render() {
        return (<Tile>
            <h3>{window.translate("NODES_IN_THIS_FOLDER")}:</h3>
            <StructuredListWrapper>
                <StructuredListHead>
                    <StructuredListRow head>
                        <StructuredListCell head>{window.translate("NAME")}</StructuredListCell>
                        <StructuredListCell head>{window.translate("IF_ASSISTANT_RECOGNIZES")}</StructuredListCell>
                        <StructuredListCell head>{window.translate("CHILDREN")}</StructuredListCell>
                        <StructuredListCell head></StructuredListCell>
                    </StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                    {this.props.children.map(child => <StructuredListRow>
                        <StructuredListCell>{child.title || child.dialog_node}</StructuredListCell>
                        <StructuredListCell>{child.conditions}</StructuredListCell>
                        <StructuredListCell>{this.props.dialogNodes.filter(n => n.parent === child.dialog_node).length}</StructuredListCell>
                        <StructuredListCell><Button onClick={ev => this.props.onSelect(child, ev)} kind="secondary" className="btn-icon-only"><Icon icon={iconLink} /></Button></StructuredListCell>
                    </StructuredListRow>)}
                </StructuredListBody>
            </StructuredListWrapper>
        </Tile>)
    }
}

export default FolderChildrenViewer