import React, { Component } from 'react';
import { Tile } from 'carbon-components-react';
import OutputEditor from './fields/output-editor';
import NextStepEditor from './fields/next-step-editor';
import DisambiguationEditor from './fields/disambiguation-editor';
import MultiOutputEditor from './fields/multi-output-editor';
import SlotHandlerEditor from './fields/slot-handler-editor';
import SlotConditionVariableEditor from './fields/slot-condition-variable-editor';
import NodeHeaderEditor from './fields/node-header-editor';
import NodeConditionEditor from './fields/node-condition-editor';
import SlotFilledNomatchEditor from './fields/slot-filled-nomatch-editor';
import FolderChildrenViewer from './fields/folder-children-viewer';

/**
 * DialogNodeForm is the form you see when editing pretty much any node, handler, slot, etc.
 * 
 * It gets the following props:
 * - selectedNode: Node - node under edition
 * - onChange: (node, change) => undefined - function to update "node" by applying "change" to it
 * - dialogNodes: Node[] - the current configuration of dialog nodes
 * 
 * All Fields under it should use the same selectedNode, onChange and dialogNodes props
 * That way the methods will work the same way no matter where
 */
class DialogNodeForm extends Component {

    render() {
        return (
            <Tile style={{ padding: 0 }}>
                {this.headerEditorEnabled() && <NodeHeaderEditor {...this.props} />}
                <Tile style={{ padding: 0, height: "calc(85vh - 220px)", overflow: "scroll" }}>
                    {this.conditionEditorEnabled() && <NodeConditionEditor {...this.props} />}
                    {this.slotConditionVariableEditorEnabled() && <SlotConditionVariableEditor {...this.props} />}
                    {this.slotHandlerEditorEnabled() && <SlotHandlerEditor {...this.props} />}
                    {this.multiOutputEditorEnabled() && <MultiOutputEditor {...this.props} />}
                    {this.singleOutputEditorEnabled() && <OutputEditor {...this.props} />}
                    {this.nextStepEditorEnabled() && <NextStepEditor {...this.props} />}
                    {this.disambiguationEditorEnabled() && <DisambiguationEditor {...this.props} />}
                    {this.slotFilledNomatchEditorEnabled() && <SlotFilledNomatchEditor {...this.props} />}
                    {this.folderChildrenViewerEnabled() && <FolderChildrenViewer {...this.props} />}
                </Tile>
            </Tile>)
    }

    isType(...types) {
        return this.props.selectedNode && types.includes(this.props.selectedNode.type)
    }

    headerEditorEnabled() { return this.isType("standard", "frame", "folder") }
    conditionEditorEnabled() { return this.props.selectedNode.type !== "event_handler" }
    slotConditionVariableEditorEnabled() { return this.props.selectedNode.type === "event_handler" && this.props.selectedNode.event_name === 'focus' }
    slotHandlerEditorEnabled() { return this.props.selectedNode.type === "frame" }
    singleOutputEditorEnabled() { return !this.isType("folder") && (!this.props.selectedNode.metadata || !this.props.selectedNode.metadata._customization || !this.props.selectedNode.metadata._customization.mcr) }
    multiOutputEditorEnabled() { return !this.isType("folder") && !this.singleOutputEditorEnabled() }
    nextStepEditorEnabled() { return !this.isType("event_handler", "folder") || (this.props.selectedNode.event_name && this.props.selectedNode.event_name !== 'focus') }
    disambiguationEditorEnabled() { return this.isType("standard", "frame") }
    slotFilledNomatchEditorEnabled() { return this.isType("event_handler") && this.props.selectedNode.event_name === "focus" }
    folderChildrenViewerEnabled() { return this.isType("folder") }

}

export default DialogNodeForm