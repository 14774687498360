import React, { Component } from 'react';
import { Tile } from 'carbon-components-react'
import { Form } from '../../../../components/carbon-react-crud';


class CommonResourceDetail extends Component {

    render() {
        return <Tile>
            <Form
                {...this.props}
                fields={this.props.resource.fields
                    .filter(f => this.props.keys.includes(f.key))
                    .map(f => ({ ...f, label: window.translate(f.label || f.key) }))
                }
                disabled />
        </Tile>
    }

}

export default CommonResourceDetail