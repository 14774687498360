import React, { Component } from 'react';
import { Tabs, Tab, Accordion, AccordionItem } from 'carbon-components-react';
import { JSONEditor } from '../../../components/carbon-react-crud';
import ContextEditor from './context-editor';

class OutputVariablesEditor extends Component {

    state = {
        jsonEditorOn: true
    }

    render() {
        return (<>
            <Accordion>
                <AccordionItem title={this.props.title || window.translate("OUTPUT_VARIABLES")}>
                    <Tabs>
                        <Tab label={window.translate("SIMPLIFIED_EDITOR")} onClick={ev => this.setState({ jsonEditorOn: true })}>
                            {this.state.jsonEditorOn && <ContextEditor
                                value={this.props.value}
                                onChange={payload => this.props.onChange(payload)}
                                keyLabel={this.props.keyLabel}
                                addLabel={this.props.addLabel}
                                editionMode={this.props.editionMode}
                            />}
                        </Tab>
                        <Tab label={window.translate("JSON_EDITOR")} onClick={ev => this.setState({ jsonEditorOn: false })}>
                            {!this.state.jsonEditorOn && <JSONEditor
                                value={this.props.value}
                                onChange={payload => this.props.onChange(payload)}
                            />}
                        </Tab>
                    </Tabs>
                </AccordionItem>
            </Accordion>
        </>)
    }

}

OutputVariablesEditor.defaultProps = {
    value: {},
    editionMode: true
}

export default OutputVariablesEditor