import React, { Component } from 'react';
import { v4 } from 'uuid'
import { Tabs, Tab } from 'carbon-components-react';

import BarGraph from '../../components/bar-graph';
import Scatterplot from '../../components/scatterplot';

import { CrudTable } from '../../components/carbon-react-crud'
import { getAccuracyReport, listPredictions, getAccuracyVSClassSize, getAccuracyVSCoverage, getConfidenceVSClassSize, getPairwiseClassErrors, remapPrediction } from '../../services/corpus'
import DatasetHeader from './components/dataset-header';
import IntentDetail from './components/intent-detail';
import PairwiseDetail from './components/pairwise-detail';
import { LineChart } from '@carbon/charts-react';


class ExperimentReportPage extends Component {

    state = {
        accuracyData: [],
        accuracyReport: [],

        confidenceVSClassSize: [],
        accuracyVSClassSize: [],
        confidenceVSClassSizeData: [],
        accuracyVSClassSizeData: [],
        accuracyVSCoverageData: [],

        pairwiseClassErrors: [],
    }

    render() {
        return (<>
            <DatasetHeader {...this.props} />
            <Tabs>
                <Tab label={window.translate("ACCURACY")}>
                    <Tabs>
                        <Tab label={window.translate("PRECISION_AT_K")}>
                            <BarGraph
                                id='accuracy-graph'
                                data={this.state.accuracyReport}
                                xLabel="K"
                                yLabel={window.translate("PRECISION")}
                            />
                            {this.state.accuracyData.length && <CrudTable
                                style={{ maxWidth: "800px", margin: "auto" }}
                                headers={[{ key: "k", header: "K", sortable: true }, { key: "precision", header: window.translate("PRECISION"), sortable: true }]}
                                listResources={(url, params) => Promise.resolve({ rows: this.state.accuracyData, count: this.state.accuracyData.length })}
                                disableUpdate={row => true} disableDelete={row => true} disableCreate clientPagination
                            />}
                        </Tab>
                        <Tab label={window.translate("ACCURACY_VS_CLASS_SIZE")}>
                            <Scatterplot
                                id="accuracy-vs-class-size-graph"
                                data={this.state.accuracyVSClassSize}
                                xLabel="Class size"
                                yLabel="Accuracy"
                            />
                            {this.state.accuracyVSClassSizeData.length && <CrudTable
                                style={{ maxWidth: "800px", margin: "auto" }}
                                headers={[{ key: "name", header: window.translate("NAME"), sortable: true }, { key: "accuracy", header: window.translate("ACCURACY"), sortable: true }, { key: "class_size", header: window.translate("CLASS_SIZE"), sortable: true }]}
                                listResources={(url, params) => Promise.resolve({ rows: this.state.accuracyVSClassSizeData, count: this.state.accuracyVSClassSizeData.length })}
                                exportable
                                translate={window.translate}
                                csvOptions={{ keys: [window.translate("NAME"), window.translate("ACCURACY"), window.translate("CLASS_SIZE")], emptyFieldValue: "", sortHeader: false, excelBOM: true, delimiter: { field: ';' } }}
                                disableUpdate={row => true} disableDelete={row => true} disableCreate clientPagination searchable
                                cellDetail={[row => <IntentDetail intentID={row.id} />]}
                            />}
                        </Tab>
                        <Tab label={window.translate("ACCURACY_VS_COVERAGE")}>
                            <LineChart
                                data={this.state.accuracyVSCoverageData.reduce((acc, d) => [...acc, { x: d.threshold, y: d.accuracy, group: window.translate('ACCURACY') }, { x: d.threshold, y: d.coverage, group: window.translate('COVERAGE') }], [])}
                                a={console.log(this.state.accuracyVSCoverageData)}
                                options={{
                                    animations: false,
                                    height: "300px",
                                    axes: {
                                        left: { mapsTo: "y", scaleType: "linear" },
                                        bottom: { mapsTo: "x", scaleType: "linear" }
                                    },
                                    tooltip: {
                                        showTotal: false,
                                        truncation: { numCharacter: 1024 },
                                    },
                                    legend: {
                                        truncation: { numCharacter: 1024 },
                                    }
                                }}
                            />
                        </Tab>
                    </Tabs>
                </Tab>
                <Tab label={window.translate("CONFIDENCE")}>
                    <h3>{window.translate("CONFIDENCE_VS_CLASS_SIZE")}</h3>
                    <Scatterplot
                        id="confidence-vs-class-size-graph"
                        data={this.state.confidenceVSClassSize}
                        xLabel="Class size"
                        yLabel="Confidence"
                    />
                    {this.state.confidenceVSClassSizeData.length && <CrudTable
                        style={{ maxWidth: "800px", margin: "auto" }}
                        headers={[{ key: "name", header: window.translate("NAME"), sortable: true }, { key: "confidence", header: window.translate("CONFIDENCE"), sortable: true }, { key: "class_size", header: window.translate("CLASS_SIZE"), sortable: true }]}
                        listResources={(url, params) => Promise.resolve({ rows: this.state.confidenceVSClassSizeData, count: this.state.confidenceVSClassSizeData.length })}
                        exportable
                        translate={window.translate}
                        csvOptions={{ keys: [window.translate("NAME"), window.translate("CONFIDENCE"), window.translate("CLASS_SIZE")], emptyFieldValue: "", sortHeader: false, excelBOM: true, delimiter: { field: ';' } }}
                        disableUpdate={row => true} disableDelete={row => true} disableCreate clientPagination searchable
                        cellDetail={[row => <IntentDetail intentID={row.id} />]}
                    />}
                </Tab>
                <Tab label={window.translate("PREDICTIONS")}>
                    <Tabs>
                        <Tab label={window.translate("PREDICTIONS")}>
                            <CrudTable
                                headers={[
                                    { key: "text", header: window.translate("TEXT"), sortable: true },
                                    { key: "predicted_intent_name", header: window.translate("PREDICTED_INTENT"), sortable: true },
                                    { key: "true_intent_name", header: window.translate("TRUE_INTENT"), sortable: true },
                                    { key: "confidence", header: window.translate("CONFIDENCE"), sortable: true },
                                ]}
                                listResources={(url, params) => listPredictions(this.props.match.params.dataset_id, this.props.match.params.experiment_id, params)}
                                cellDetail={[
                                    null,
                                    row => <IntentDetail intentID={row.predicted_intent_id} />,
                                    row => <IntentDetail intentID={row.true_intent_id} />
                                ]}
                                exportable
                                translate={window.translate}
                                csvOptions={{ keys: [window.translate("TEXT"), window.translate("TRUE_INTENT_NAME"), window.translate("PREDICTED_INTENT_NAME"), window.translate("CONFIDENCE"), window.translate("HIT_INDEX")], delimiter: { field: ';' }, excelBOM: true }}
                                disableUpdate={row => true} disableDelete={row => true} disableCreate searchable
                            />
                        </Tab>
                        <Tab label={window.translate("HITS")}>
                            <CrudTable
                                headers={[
                                    { key: "text", header: window.translate("TEXT"), sortable: true },
                                    { key: "true_intent_name", header: window.translate("INTENT"), sortable: true },
                                    { key: "confidence", header: window.translate("CONFIDENCE"), sortable: true },
                                ]}
                                listResources={(url, params) => listPredictions(this.props.match.params.dataset_id, this.props.match.params.experiment_id, { ...params, hit: true })}
                                cellDetail={[null, row => <IntentDetail intentID={row.true_intent_id} />]}
                                exportable
                                translate={window.translate}
                                csvOptions={{ keys: [window.translate("TEXT"), window.translate("TRUE_INTENT_NAME"), window.translate("PREDICTED_INTENT_NAME"), window.translate("CONFIDENCE"), window.translate("HIT_INDEX")], delimiter: { field: ';' }, excelBOM: true }}
                                disableUpdate={row => true} disableDelete={row => true} disableCreate searchable
                            />
                        </Tab>
                        <Tab label={window.translate("MISSES")}>
                            <CrudTable
                                headers={[
                                    { key: "text", header: window.translate("TEXT"), sortable: true },
                                    { key: "predicted_intent_name", header: window.translate("PREDICTED_INTENT"), sortable: true },
                                    { key: "true_intent_name", header: window.translate("TRUE_INTENT"), sortable: true },
                                    { key: "confidence", header: window.translate("CONFIDENCE"), sortable: true },
                                ]}
                                links={[
                                    { onClick: row => remapPrediction(this.props.match.params.dataset_id, this.props.match.params.experiment_id, row.id), text: window.translate("REMAP"), confirm: true, batch: true }
                                ]}
                                listResources={(url, params) => listPredictions(this.props.match.params.dataset_id, this.props.match.params.experiment_id, { ...params, hit: false })}
                                cellDetail={[
                                    null,
                                    row => <IntentDetail intentID={row.predicted_intent_id} />,
                                    row => <IntentDetail intentID={row.true_intent_id} />
                                ]}
                                selectable
                                exportable
                                translate={window.translate}
                                csvOptions={{ keys: [window.translate("TEXT"), window.translate("TRUE_INTENT_NAME"), window.translate("PREDICTED_INTENT_NAME"), window.translate("CONFIDENCE"), window.translate("HIT_INDEX")], delimiter: { field: ';' }, excelBOM: true }}
                                disableUpdate={row => true} disableDelete={row => true} disableCreate searchable
                            />
                        </Tab>
                        <Tab label={window.translate("PAIRWISE_CLASS_ERRORS")}>
                            <CrudTable
                                headers={[
                                    { key: "predicted_intent_name", header: window.translate("PREDICTED_INTENT"), sortable: true },
                                    { key: "true_intent_name", header: window.translate("TRUE_INTENT"), sortable: true },
                                    { key: "count", header: window.translate("COUNT"), sortable: true },
                                ]}
                                listResources={(url, params) => getPairwiseClassErrors(this.props.match.params.dataset_id, this.props.match.params.experiment_id, params)}
                                cellDetail={[
                                    row => <IntentDetail intentID={row.predicted_intent_id} />,
                                    row => <IntentDetail intentID={row.true_intent_id} />,
                                    row => <PairwiseDetail dataset_id={this.props.match.params.dataset_id} experiment_id={this.props.match.params.experiment_id} prediction={row} />
                                ]}
                                exportable
                                translate={window.translate}
                                csvOptions={{ keys: [window.translate("TRUE_INTENT_NAME"), window.translate("PREDICTED_INTENT_NAME"), window.translate("COUNT")], delimiter: { field: ';' }, excelBOM: true }}
                                disableUpdate={row => true} disableDelete={row => true} disableCreate searchable
                            />
                        </Tab>
                    </Tabs>
                </Tab>
            </Tabs>
        </>)
    }

    componentDidMount() {
        getAccuracyReport(this.props.match.params.dataset_id, this.props.match.params.experiment_id)
            .then(data => {
                this.setState({
                    accuracyData: data.map(d => ({ ...d, id: v4() })),
                    accuracyReport: data.map(point => ({ x: point.k, y: point.precision })),
                }, () => this.forceUpdate())
            })
        getAccuracyVSClassSize(this.props.match.params.dataset_id, this.props.match.params.experiment_id)
            .then(data => {
                this.setState({
                    accuracyVSClassSizeData: data,
                    accuracyVSClassSize: data.map(point => ({
                        id: point.id,
                        x: point.class_size,
                        y: point.accuracy,
                        label: point.name
                    }))
                })
            })
        getConfidenceVSClassSize(this.props.match.params.dataset_id, this.props.match.params.experiment_id)
            .then(data => {
                this.setState({
                    confidenceVSClassSizeData: data,
                    confidenceVSClassSize: data.map(point => ({
                        id: point.id,
                        x: point.class_size,
                        y: point.confidence,
                        label: point.name
                    }))
                })
            })
        getAccuracyVSCoverage(this.props.match.params.dataset_id, this.props.match.params.experiment_id)
            .then(data => {
                this.setState({
                    accuracyVSCoverageData: data
                })
            })

    }

}

export default ExperimentReportPage