import React from 'react';
import HomePage from './pages/home-page';
import LoginPage from './pages/auth/login-page';
import DatasetOverviewPage from './pages/corpus/dataset-overview-page';
import DatasetExamplesDistributionPage from './pages/corpus/dataset-examples-distribution-page';
import ExperimentReportPage from './pages/corpus/experiment-report-page';
import AssistantSkillTestPage from './pages/orchestrator/assistant-skill-test-page';
import AssistantSkillQueryPage from './pages/orchestrator/assistant-skill-query-page';
import OrchestratorTestPage from './pages/orchestrator/orchestrator-test-page';
import OrchestratorQueryPage from './pages/orchestrator/orchestrator-query-page';
import AuditPage from './pages/audit/audit-page';
import Page404 from './pages/page-404';
import CommonPage from './pages/common-page';
import DualPage from './pages/dual-page';

import { Dataset, Intent, Entity, EntityValue, Example, ReviewExample, Experiment, Evaluation, Recommendation } from './models/corpus';
import { AssistantInstance, AssistantSkill, Snapshot, DiscoveryInstance, DiscoveryProject, DiscoveryCollection, Orchestrator } from './models/orchestrator';
import { KnowledgeBase, Document, AnswerUnit } from './models/documents';
import { User, APIKey } from './models/auth';
import Permission from './models/permission';
import DatasetHeader from './pages/corpus/components/dataset-header';

import { remapExample, mapReviewExample, transferEntity, mergeIntents } from './services/corpus'
import IntentDetail from './pages/corpus/components/intent-detail';
import EvaluationHelp from './help-modals/evaluation-help';
import { UnorderedList, ListItem } from 'carbon-components-react';
import AssistantSkillIntentsPage from './pages/orchestrator/assistant-skill-intents-page';
import AssistantSkillIntentExamplesPage from './pages/orchestrator/assistant-skill-intent-examples-page';

let routes = [
    { path: "/login", component: LoginPage, props: {} },
    { path: "/", component: HomePage, props: {} },

    { path: "/datasets", component: CommonPage, props: { model: Dataset, pageSizes: [25, 50, 100] } },
    { path: "/datasets/:dataset_id", component: DatasetOverviewPage, props: {} },
    { path: "/datasets/:dataset_id/permissions", component: CommonPage, props: { model: Permission, header: DatasetHeader } },
    { path: "/datasets/:dataset_id/intents", component: CommonPage, props: { model: Intent, header: DatasetHeader } },
    { path: "/datasets/:dataset_id/intents/:intent_id/examples", component: CommonPage, props: { model: Example, header: DatasetHeader } },
    {
        path: "/datasets/:dataset_id/intents/:intent_id/remap", component: DualPage, props: {
            header: DatasetHeader,
            initialSelection: params => params.dataset_id,
            model1: { ...Example, actions: null }, path1: (params) => `/datasets/${params.dataset_id}/intents/${params.intent_id}/examples`,
            model2: { ...Intent, cellDetail: [row => <IntentDetail intentID={row.id} />], fields: Intent.fields.map(f => ({ ...f, header: f.key === "name" })), actions: null }, path2: (params, selection) => `/datasets/${selection}/intents`,
            actions: [{
                label: "MAP_TO_SELECTED",
                func: (params, example, intent) => remapExample(params.dataset_id, params.intent_id, example.id, { intent_id: intent.id })
            }]
        }
    },
    { path: "/datasets/:dataset_id/review_examples", component: CommonPage, props: { model: ReviewExample, header: DatasetHeader } },
    { path: "/datasets/:dataset_id/entities", component: CommonPage, props: { model: Entity, header: DatasetHeader } },
    { path: "/datasets/:dataset_id/entities/:entity_id/entity_values", component: CommonPage, props: { model: EntityValue, header: DatasetHeader } },
    { path: "/datasets/:dataset_id/examples_class_distribution", component: DatasetExamplesDistributionPage, props: {} },
    { path: "/datasets/:dataset_id/review", component: CommonPage, props: { url: params => `/corpus/datasets/${params.dataset_id}/review_examples?status=1`, model: { ...ReviewExample, fields: ReviewExample.fields.filter(f => ["text", "intent_name", "metadata.canal_cliente"].includes(f.key)), title: window.translate("REVIEW") }, header: DatasetHeader } },
    {
        path: "/datasets/:dataset_id/mapping", component: DualPage, props: {
            header: DatasetHeader,
            initialSelection: params => params.dataset_id,
            model1: { ...ReviewExample, url: params => `/corpus/datasets/${params.dataset_id}/review_examples?status=0`, title: window.translate("MAPPING"), fields: ReviewExample.fields.map(f => ({ ...f, header: f.key === "text" || f.key === "metadata.canal_cliente" })), params: { status: 0 }, cellDetail: [] },
            path1: (params) => `/datasets/${params.dataset_id}/review_examples`,
            model2: { ...Intent, cellDetail: [row => <IntentDetail intentID={row.id} />], fields: Intent.fields.map(f => ({ ...f, header: f.key === "name" })), actions: null },
            path2: (params, selection) => `/datasets/${selection}/intents`,
            actions: [{
                label: "MAP_TO_SELECTED",
                func: (params, reviewExample, intent) => mapReviewExample(params.dataset_id, reviewExample.id, { intent_id: intent.id })
            }],
            dangerModalContent: thisArg => thisArg.state.targetRow && thisArg.state.selectedRows && <>
                <p>{window.translate("MAPPING_EXAMPLES")}:</p><br />
                <UnorderedList>
                    {thisArg.state.selectedRows.map((row, i) => <ListItem key={`row-${i}`}>{row.text}</ListItem>)}
                </UnorderedList>
                <p>{window.translate("INTO_INTENT")}:</p><br />
                <IntentDetail {...thisArg.props} intentID={thisArg.state.targetRow.id} />
            </>,
            customMessage: {
                "Review example not found": window.translate("EXAMPLE MAPPED OR DELETED. RELOAD THE PAGE.")
            }
        }
    },
    {
        path: "/datasets/:dataset_id/merger", component: DualPage, props: {
            header: DatasetHeader,
            radio: true,
            initialSelection: params => params.dataset_id,
            model1: { ...Intent, cellDetail: [row => <IntentDetail intentID={row.id} />], fields: Intent.fields.map(f => ({ ...f, header: f.key === "name" })), actions: null }, path1: (params) => `/datasets/${params.dataset_id}/intents`,
            model2: { ...Intent, cellDetail: [row => <IntentDetail intentID={row.id} />], fields: Intent.fields.map(f => ({ ...f, header: f.key === "name" })), actions: null }, path2: (params, selection) => `/datasets/${selection}/intents`,
            actions: [{
                label: "MERGE_LEFT",
                func: (params, intent, targetIntent) => mergeIntents(params.dataset_id, intent.id, targetIntent.id)
            }],
            dangerModalContent: thisArg => thisArg.state.sourceRow && <>
                <h3>{window.translate("INTENT_TO_KEEP")}:</h3><br />
                <IntentDetail {...thisArg.props} intentID={thisArg.state.sourceRow.id} includeExamplesFrom={thisArg.state.targetRow && thisArg.state.targetRow.id} />
            </>
        }
    },
    {
        path: "/datasets/:dataset_id/entity_transfer", component: DualPage, props: {
            header: DatasetHeader,
            initialSelection: params => params.dataset_id,
            model1: { ...Entity, actions: null }, path1: (params) => `/datasets/${params.dataset_id}/entities`,
            model2: { ...Dataset, fields: Dataset.fields.map(f => ({ ...f, header: f.key === "name" })), actions: null }, path2: (params, selection) => `/datasets`,
            actions: [{
                label: "COPY_ENTITY",
                func: (params, entity, dataset) => transferEntity(params.dataset_id, entity.id, dataset.id, true)
            }, {
                label: "MOVE_ENTITY",
                func: (params, entity, dataset) => transferEntity(params.dataset_id, entity.id, dataset.id, false)
            }]
        }
    },
    { path: "/datasets/:dataset_id/experiments", component: CommonPage, props: { model: Experiment, header: DatasetHeader } },
    { path: "/datasets/:dataset_id/experiments/:experiment_id/report", component: ExperimentReportPage, props: {} },
    { path: "/datasets/:dataset_id/evaluations", component: CommonPage, props: { model: Evaluation, header: DatasetHeader }, help: EvaluationHelp },
    { path: "/datasets/:dataset_id/evaluations/:evaluation_id/recommendations", component: CommonPage, props: { model: Recommendation, header: DatasetHeader }, help: EvaluationHelp },

    { path: "/assistant_instances", component: CommonPage, props: { model: AssistantInstance } },
    { path: "/assistant_instances/:assistant_instance_id/permissions", component: CommonPage, props: { model: Permission } },
    { path: "/assistant_instances/:assistant_instance_id/assistant_skills", component: CommonPage, props: { model: AssistantSkill, pageSizes: [25, 50, 100] } },
    { path: "/assistant_instances/:assistant_instance_id/assistant_skills/:assistant_skill_id/permissions", component: CommonPage, props: { model: Permission } },
    { path: "/assistant_instances/:assistant_instance_id/assistant_skills/:assistant_skill_id/test", component: AssistantSkillTestPage, props: {} },
    { path: "/assistant_instances/:assistant_instance_id/assistant_skills/:assistant_skill_id/query", component: AssistantSkillQueryPage, props: {} },
    { path: "/assistant_instances/:assistant_instance_id/assistant_skills/:assistant_skill_id/intents", component: AssistantSkillIntentsPage },
    { path: "/assistant_instances/:assistant_instance_id/assistant_skills/:assistant_skill_id/intents/:intent_id/examples", component: AssistantSkillIntentExamplesPage },
    { path: "/assistant_instances/:assistant_instance_id/assistant_skills/:assistant_skill_id/snapshots", component: CommonPage, props: { model: Snapshot } },

    { path: "/knowledge_bases", component: CommonPage, props: { model: KnowledgeBase } },
    { path: "/knowledge_bases/:knowledge_base_id/permissions", component: CommonPage, props: { model: Permission } },
    { path: "/knowledge_bases/:knowledge_base_id/documents", component: CommonPage, props: { model: Document } },
    { path: "/knowledge_bases/:knowledge_base_id/documents/:document_id/answer_units", component: CommonPage, props: { model: AnswerUnit } },

    { path: "/discovery_instances", component: CommonPage, props: { model: DiscoveryInstance } },
    { path: "/discovery_instances/:discovery_instance_id/permissions", component: CommonPage, props: { model: Permission } },
    { path: "/discovery_instances/:discovery_instance_id/discovery_projects", component: CommonPage, props: { model: DiscoveryProject } },
    { path: "/discovery_instances/:discovery_instance_id/discovery_projects/:discovery_project_id/permissions", component: CommonPage, props: { model: Permission } },
    { path: "/discovery_instances/:discovery_instance_id/discovery_projects/:discovery_project_id/discovery_collections", component: CommonPage, props: { model: DiscoveryCollection } },
    { path: "/discovery_instances/:discovery_instance_id/discovery_projects/:discovery_project_id/discovery_collections/:discovery_collection_id/permissions", component: CommonPage, props: { model: Permission } },

    { path: "/orchestrators", component: CommonPage, props: { model: Orchestrator } },
    { path: "/orchestrators/:orchestrator_id/permissions", component: CommonPage, props: { model: Permission } },
    { path: "/orchestrators/:orchestrator_id/query", component: OrchestratorQueryPage, props: {} },
    { path: "/orchestrators/:orchestrator_id/test", component: OrchestratorTestPage, props: {} },

    { path: "/users", component: CommonPage, props: { model: User } },
    { path: "/users/:user_id/apikeys", component: CommonPage, props: { model: APIKey } },

    { path: "/audit_facts", component: AuditPage, props: {} },

    { component: Page404, props: {} },
]

export default routes