import React, { Component } from 'react';
import NodeChildrenEditor from './node-children-editor';

class SlotFilledNomatchEditor extends Component {

    render() {
        return (<>
            <h3>{window.translate("WHEN_USER_RESPONDS_IF")}</h3>
            <h4>{window.translate("FOUND")}</h4>
            <NodeChildrenEditor
                {...this.props}
                template={{ type: "event_handler", event_name: "filled" }}
                selectedNode={this.props.dialogNodes.find(node => node.dialog_node === this.props.selectedNode.parent)}
            />
            <br /><br />
            <h4>{window.translate("NOT_FOUND")}</h4>
            <NodeChildrenEditor
                {...this.props}
                template={{ type: "event_handler", event_name: "nomatch" }}
                selectedNode={this.props.dialogNodes.find(node => node.dialog_node === this.props.selectedNode.parent)}
            />
            <br /><br />
        </>)
    }

}

export default SlotFilledNomatchEditor