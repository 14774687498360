import React, { Component } from 'react';
import ServiceTile from '../components/service-tile'

class HomePage extends Component {
    render() {
        return (
            <>
                <br />
                <h3> {window.translate("HOME")} </h3>
                <br />
                <div className="bx--grid">
                    <div className="bx--row">
                        <div className="bx--col-lg-3 bx--col-md-4 bx--col-sm-6 bx--col-xs-12">
                            <ServiceTile id="service-tile-datasets" className="ServiceTile" icon="/images/data.svg" link={'/datasets'} name={window.translate("DATASETS")} description={window.translate("DATASETS_DESCRIPTION")} />
                        </div>
                        <div className="bx--col-lg-3 bx--col-md-4 bx--col-sm-6 bx--col-xs-12">
                            <ServiceTile id="service-tile-assistant-instances" className="ServiceTile" icon="/images/assistant.svg" link={'/assistant_instances'} name={window.translate("ASSISTANT_INSTANCES")} description={window.translate("ASSISTANT_INSTANCES_DESCRIPTION")} />
                        </div>
                        <div className="bx--col-lg-3 bx--col-md-4 bx--col-sm-6 bx--col-xs-12">
                            <ServiceTile id="service-tile-knowledge-bases" className="ServiceTile" icon="/images/documents.svg" link={'/knowledge_bases'} name={window.translate("DOCUMENTS")} description={window.translate("DOCUMENTS_DESCRIPTION")} />
                        </div>
                        <div className="bx--col-lg-3 bx--col-md-4 bx--col-sm-6 bx--col-xs-12">
                            <ServiceTile id="service-tile-discovery-instances" className="ServiceTile" icon="/images/discovery.svg" link={'/discovery_instances'} name={window.translate("DISCOVERY_INSTANCES")} description={window.translate("DISCOVERY_INSTANCES_DESCRIPTION")} />
                        </div>
                        <div className="bx--col-lg-3 bx--col-md-4 bx--col-sm-6 bx--col-xs-12">
                            <ServiceTile id="service-tile-orchestrators" className="ServiceTile" icon="/images/orchestrator.svg" link={'/orchestrators'} name={window.translate("ORCHESTRATORS")} description={window.translate("ORCHESTRATORS_DESCRIPTION")} />
                        </div>
                        <div className="bx--col-lg-3 bx--col-md-4 bx--col-sm-6 bx--col-xs-12">
                            <ServiceTile id="service-tile-users" className="ServiceTile" icon="/images/users.svg" link={'/users'} name={window.translate("USERS")} description={window.translate("USERS_DESCRIPTION")} />
                        </div>
                        <div className="bx--col-lg-3 bx--col-md-4 bx--col-sm-6 bx--col-xs-12">
                            <ServiceTile id="service-tile-audit-facts" className="ServiceTile" icon="/images/audit.svg" link={'/audit_facts'} name={window.translate("AUDIT")} description={window.translate("AUDIT_DESCRIPTION")} />
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default HomePage