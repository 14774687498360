import React, { Component } from 'react';
import { Tile } from 'carbon-components-react';
import CommonResourceDetail from './common-resource-detail';
import { Dataset, Intent, Example, Entity, EntityValue, Evaluation, Experiment, ReviewExample } from '../../../../models/corpus';
import { AssistantInstance, AssistantSkill, Snapshot, Orchestrator } from '../../../../models/orchestrator';
import { User } from '../../../../models/auth';
import RecommendationDetail from '../../../corpus/components/recommendation-detail';
import ResourceFinder from './resource-finder';
import IntentDetail from '../../../corpus/components/intent-detail';

class ResourcePreview extends Component {

    render() {
        if (!this.props.value) return <Tile style={{ margin: "2rem" }}><p>({window.translate("RESOURCE_MISSING")})</p></Tile>
        Intent.fields.find(f => f.key === "name").type = 'text'
        return <>
            <ResourceFinder {...this.props} />
            {(this.props.resource_type === 'EXAMPLE') && <CommonResourceDetail {...this.props} value={this.props.value} resource={Example} />}
            {(this.props.resource_type === 'INTENT') && <Tile {...this.props}><IntentDetail {...this.props} intent={this.props.value}/></Tile>}
            {(this.props.resource_type === 'DATASET') && <CommonResourceDetail {...this.props} value={this.props.value} resource={Dataset} />}
            {(this.props.resource_type === 'ENTITY') && <CommonResourceDetail {...this.props} value={this.props.value} resource={Entity} />}
            {(this.props.resource_type === 'ENTITY_VALUE') && <CommonResourceDetail {...this.props} value={this.props.value} resource={EntityValue} />}
            {(this.props.resource_type === 'EVALUATION') && <CommonResourceDetail {...this.props} value={this.props.value} resource={Evaluation} />}
            {(this.props.resource_type === 'EXPERIMENT') && <CommonResourceDetail {...this.props} value={this.props.value} resource={Experiment} />}
            {(this.props.resource_type === 'RECOMMENDATION') && <RecommendationDetail value={this.props.value} disabled />}
            {(this.props.resource_type === 'REVIEW_EXAMPLE') && <CommonResourceDetail {...this.props} value={this.props.value} resource={ReviewExample} />}
            {(this.props.resource_type === 'ASSISTANT_INSTANCE') && <CommonResourceDetail {...this.props} value={this.props.value} resource={AssistantInstance} />}
            {(this.props.resource_type === 'ASSISTANT_SKILL') && <CommonResourceDetail {...this.props} value={this.props.value} resource={AssistantSkill} />}
            {(this.props.resource_type === 'SNAPSHOT') && <CommonResourceDetail {...this.props} value={this.props.value} resource={Snapshot} />}
            {(this.props.resource_type === 'ORCHESTRATOR') && <CommonResourceDetail {...this.props} value={this.props.value} resource={Orchestrator} />}
            {(this.props.resource_type === 'USER') && <CommonResourceDetail {...this.props} value={this.props.value} resource={User} />}
        </>
    }

}

export default ResourcePreview