import React, { Component } from 'react';
import Chat from './components/chat';
import APISnippet from '../../components/api-snippet';

class AssistantSkillTestPage extends Component {

    render() {
        let url = `${window.location.protocol}//${window.location.hostname}/orchestrator${this.props.location.pathname.slice(0, -5)}/message?apikey=$APIKEY`
        return <>
            <div className="bx--grid">
                <div className="bx--row">
                    <div className="bx--col-lg-6 bx--col-md-6 bx--col-sm-12 bx--col-xs-12">
                        <Chat assistant_skill_id={this.props.match.params.assistant_skill_id} />
                    </div>
                    <div className="bx--col-lg-6 bx--col-md-6 bx--col-sm-12 bx--col-xs-12">
                        <APISnippet
                           
                            title={window.translate("API_USAGE")}
                            method="POST"
                            url={url}
                            body={{}}
                        />
                    </div>
                </div>
            </div>
        </>
    }
}

export default AssistantSkillTestPage