import React, { Component } from 'react';
import { ComboBox, FormLabel } from 'carbon-components-react';
import axios from "axios"

const LOAD_TIMEOUT = 600

class RecordPicker extends Component {

    state = {
        records: [],
        value: "",
        blink: false
    }

    timeout = null

    render() {
        let selectedRecord = this.props.allowCreate ?
            { id: this.state.value, name: this.state.value } :
            this.state.records.find(r => [this.state.value].includes(r.id))
        return (<div onBlur={ev => this.blink()}>
            {this.props.label && <FormLabel>{this.props.label}</FormLabel>}
            {!this.state.blink && <ComboBox
                placeholder=""
                initialSelectedItem={selectedRecord && selectedRecord.name}
                selectedItem={selectedRecord && selectedRecord.name || ""}
                items={this.state.records.map(r => ({ label: r.name, value: r.id }))}
                onInputChange={value => this.handleInputChange(value)}
                onChange={ev => this.handleChange(ev.selectedItem && ev.selectedItem.value, !this.props.allowCreate)}
                onFocus={ev => this.loadComboOptions("")}
                invalid={this.props.invalid}
                invalidText={this.props.invalidText}
                disabled={this.props.disabled}
            />}
        </div>)
    }

    componentDidMount() {
        this.handleInputChange(this.props.value)
    }

    async handleInputChange(value) {
        this.setState({ value })
        this.loadComboOptions(value)
    }

    async loadComboOptions(value) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            this.props.fetchOptions(this.props.url, { search: value })
                .then(data => {
                    let showUnused = this.props.allowCreate && !data.rows.find(r => r.name === (value && value.trim()))
                    this.setState({
                        records: [{ id: showUnused ? value : null, name: showUnused ? value : "" }, ...data.rows]
                    }, () => {
                        let selectedRecord = this.state.records.find(r => r.id === (value && value.trim()))
                        if (selectedRecord && selectedRecord.id && selectedRecord.id !== this.props.value) this.handleChange(selectedRecord.id)
                    })
                })
        }, LOAD_TIMEOUT);
    }

    handleChange(value, updateState = true) {
        if (updateState) this.setState({ value })
        return this.props.onChange(value)
    }

    blink() {
        this.setState({ blink: true, value: this.props.value }, () => this.setState({ blink: false }))
    }

}

RecordPicker.defaultProps = {
    fetchOptions: (url, params) => axios({ method: 'get', url, params }).then(response => response.data)
}

export default RecordPicker