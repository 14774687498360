import React, { Component } from 'react';
import { TextInput, TextArea, Select, SelectItem, RadioButtonGroup, RadioButton, Checkbox, Toggle, FileUploaderButton, DatePicker, DatePickerInput, Accordion, AccordionItem, Tooltip, FormLabel } from 'carbon-components-react'
import JSONEditor from './json-editor';
import RecordPicker from './record-picker'
import NumberInput from './number-input'

class Form extends Component {

    state = {
        options: {}
    }

    render() {
        return (<span onKeyPress={this.props.onKeyPress}>
            {this.props.fields.map((field, i) => <div key={`field-${i}`}>
                {(!field.visible || field.visible(this.props.value)) && <>
                    {(field.type === "text" || field.type === "password") && <TextInput
                        {...field}
                        id={`field-${Math.random()}`}
                        labelText={this.getFieldLabel(field)}
                        name={field.key}
                        type={field.type}
                        value={this.props.value[field.key] || ""}
                        onChange={ev => this.handleFormUpdate(field.key, ev.target.value)}
                        invalid={field.invalid && field.invalid(this.props.value[field.key] || "")}
                    />}
                    {field.type === "textarea" && <TextArea
                        id={`field-${Math.random()}`}
                        name={field.key}
                        labelText={this.getFieldLabel(field)}
                        value={this.props.value[field.key]}
                        onChange={ev => this.handleFormUpdate(field.key, ev.target.value)}
                        invalid={field.invalid && field.invalid(this.props.value[field.key])}
                    />}
                    {field.type === "json" && <JSONEditor
                        id={`field-${Math.random()}`}
                        name={field.key}
                        labelText={this.getFieldLabel(field)}
                        value={this.props.value[field.key]}
                        onChange={json => this.handleFormUpdate(field.key, json)}
                        invalid={field.invalid && field.invalid(this.props.value[field.key])}
                    />}
                    {field.type === "select" && <Select
                        id={`field-${Math.random()}`}
                        name={field.key}
                        labelText={this.getFieldLabel(field)}
                        value={this.props.value[field.key] || field.options[0].value}
                        onChange={ev => this.handleFormUpdate(field.key, ev.target.value)}
                        invalid={field.invalid && field.invalid(this.props.value[field.key])}
                    >
                        {(this.state.options[field.key] || field.options || []).map((option, j) =>
                            <SelectItem
                                key={`option-${j}`}
                                value={option.value}
                                text={option.label}
                            />
                        )}
                    </Select>}
                    {field.type === "radio" && <RadioButtonGroup
                        id={`field-${Math.random()}`}
                        name={`field-${Math.random()}`}
                        valueSelected={String(this.props.value[field.key])}
                        onChange={selected => this.handleFormUpdate(field.key, selected)}
                        invalid={field.invalid && field.invalid(this.props.value[field.key])}
                    >
                        {(this.state.options[field.key] || field.options || []).map((option, j) =>
                            <RadioButton
                                key={`option-${j}`}
                                value={option.value}
                                labelText={this.getFieldLabel(option)}
                            />
                        )}
                    </RadioButtonGroup>}
                    {field.type === "toggle" && <Toggle
                        id={`field-${Math.random()}`}
                        name={field.key}
                        labelText={this.getFieldLabel(field)}
                        toggled={this.props.value[field.key]}
                        onToggle={state => this.handleFormUpdate(field.key, state)}
                        invalid={field.invalid && field.invalid(this.props.value[field.key])}
                    />}
                    {field.type === "checkbox" && <Checkbox
                        id={`field-${Math.random()}`}
                        name={field.key}
                        labelText={this.getFieldLabel(field)}
                        checked={this.props.value[field.key] || false}
                        onChange={checked => this.handleFormUpdate(field.key, checked)}
                        invalid={field.invalid && field.invalid(this.props.value[field.key])}
                    />}
                    {field.type === "number" &&
                        <NumberInput
                            {...field}
                            labelText={this.getFieldLabel(field)}
                            value={this.props.value[field.key]}
                            onChange={value => this.handleFormUpdate(field.key, value)}
                            max={field.max}
                            min={field.min}
                            invalid={field.invalid && field.invalid(this.props.value[field.key])}
                        />}
                    {field.type === "file" && <FileUploaderButton
                        name={field.key}
                        labelText={this.getFieldLabel(field)}
                        onChange={ev => this.handleFormUpdate(field.key, ev.target.files[0])}
                    />}
                    {field.type === "recordpicker" && <RecordPicker
                        id={`field-${Math.random()}`}
                        label={this.getFieldLabel(field)}
                        name={field.key}
                        url={field.url}
                        value={this.props.value[field.key]}
                        fetchOptions={field.fetchOptions}
                        onChange={value => this.handleFormUpdate(field.key, value)} />}
                    {field.type === "date" &&
                        <DatePicker
                            name={field.key}
                            datePickerType="single"
                            dateFormat="Y-m-d"
                            onChange={(_, date) => this.handleFormUpdate(field.key, date)}
                            locale={navigator.language.slice(0, 2)}
                            value={this.props.value[field.key]}
                            invalid={field.invalid && field.invalid(this.props.value[field.key])}
                        >
                            <DatePickerInput
                                id={`field-${Math.random()}`}
                                labelText={this.getFieldLabel(field)}
                                value={this.props.value[field.key]}
                            />
                        </DatePicker>}
                    {field.type === "daterange" && <>
                        <FormLabel>{field.label}</FormLabel>
                        <DatePicker
                            datePickerType="range"
                            dateFormat='Y-m-d'
                            onChange={ch => {
                                this.handleFormUpdate(field.key1, ch[0].toISOString().slice(0, 10))
                                if (!ch[1]) return
                                setTimeout(() => this.handleFormUpdate(field.key2, (new Date(Number(ch[1]) + 8.64e+7)).toISOString().slice(0, 10)), 100);
                            }}
                            locale={navigator.language.slice(0, 2)}
                        >
                            <DatePickerInput
                                id="date-picker-input-id-start"
                                value={this.props.value[field.key1] || ""}
                            />
                            <DatePickerInput
                                id="date-picker-input-id-end"
                                value={this.props.value[field.key2] || ""}
                            />
                        </DatePicker>
                    </>}

                    {field.type === "readonly" && field.component({
                        id: `field-${Math.random()}`,
                        ...(field.props || {}),
                    })}
                    {field.type === "form" && <Accordion>
                        <AccordionItem title={this.getFieldLabel(field)}>
                            <Form
                                {...this.props}
                                fields={field.fields}
                                value={this.props.value[field.key]}
                                onChange={value => this.handleFormUpdate(field.key, value)} />
                        </AccordionItem>
                    </Accordion>}
                    {field.type === "id" && this.props.value[field.key] && <span style={{ color: "gray", fontSize: "small" }}>
                        {this.getFieldLabel(field)}: {this.props.value[field.key]}
                    </span>}
                    {field.type === "custom" && <div className="custom-field">
                        {field.component({
                            id: `field-${Math.random()}`,
                            name: field.key,
                            labelText: this.getFieldLabel(field),
                            value: this.props.value[field.key],
                            current: this.props.value,
                            onChange: state => this.handleFormUpdate(field.key, state),
                            ...(field.props || {}),
                            invalid: field.invalid && field.invalid(this.props.value[field.key]),
                        })}
                    </div>}
                    {field.type !== "none" && this.props.fields.length > i + 1 && <><br /><br /></>}
                    {field.tooltip && field.tooltip(this.props)}
                </>}
            </div>)}
        </span>)
    }

    getFieldLabel(field) {
        let label = field.label || field.key
        return field.description ?
            <Tooltip triggerText={label}>{field.description}</Tooltip> :
            label
    }

    componentDidMount() {
        if (this.isMultipart()) this.props.onChange(new FormData())
        setTimeout(() => this.props.fields.map((field, i) => field.default !== undefined && this.props.value[field.value] === undefined && this.handleFormUpdate(field.key, field.default)), 0);
    }

    handleFormUpdate(key, value) {
        if (!key || this.props.disabled) return
        if (this.isMultipart()) {
            let formData = new FormData()
            if (this.props.value.forEach)
                this.props.value.forEach((v, k) => {
                    if (k !== key)
                        formData.append(k, v)
                })
            else
                Object.keys(this.props.value).forEach(k => {
                    if (k !== key)
                        formData.append(k, this.props.value[k])
                })
            formData.append(key, value)
            formData.id = formData.get('id')
            this.props.onChange(formData)
        }
        else {
            this.props.onChange({ ...this.props.value, [key]: value })
        }
    }

    isMultipart() {
        return this.props.fields.reduce((acc, field) => field.type === "file" || acc, false)
    }

    clear() {
        this.props.value = {}
        return this.props.onChange({ search: "", created_after: undefined })
    }

}

Form.defaultProps = {
    value: {}
}

export default Form