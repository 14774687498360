import { v1 } from 'uuid'
import React, { Component } from 'react';
import { ToastNotification } from 'carbon-components-react';

class NotificationOverlay extends Component {

    state = {
        notifications: []
    }

    constructor(props) {
        super(props)
        this.handleNotification = this.handleNotification.bind(this)
    }

    render() {
        return (
            <div style={{ position: "fixed", top: "3rem", zIndex: 9999, right: 0, width: 0 }}>
                {this.state.notifications.map(notification =>
                    <ToastNotification
                        key={`notification_${notification.key}`}
                        kind={notification.kind}
                        title={notification.title}
                        subtitle={notification.subtitle}
                        caption={notification.caption}
                        onCloseButtonClick={ev => this.handleCloseNotification(notification.key)}
                        style={{ float: "right" }}
                    />
                )}
            </div>
        )
    }

    handleNotification(kind, title, subtitle) {
        let key = v1()
        this.setState({
            notifications: this.state.notifications.concat({ kind, title, subtitle, caption: new Date(Date.now()).toLocaleTimeString(), key })
        })
        setTimeout(() => this.handleCloseNotification(key), 5000);
    }

    handleCloseNotification(key) {
        this.setState({ notifications: this.state.notifications.filter(n => n.key !== key) })
    }
}

export default NotificationOverlay