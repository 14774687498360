import React, { Component } from 'react';
import AuditExplorer from './components/audit-explorer';

class AuditPage extends Component {

    render() {
        return (<>
            <h3> {window.translate("AUDIT")} </h3>
            <br /><br />
            <AuditExplorer
                {...this.props}
            />
        </>)
    }
}

export default AuditPage