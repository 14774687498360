import React, { Component } from 'react';
import { Tabs, Tab } from 'carbon-components-react';

import BarGraph from '../../components/bar-graph';

import { getDatasetClassDistibution } from '../../services/corpus'
import DatasetHeader from './components/dataset-header';
import IntentDetail from './components/intent-detail';
import { CrudTable } from '../../components/carbon-react-crud';
import { Example } from '../../models/corpus';

class DatasetExamplesDistributionPage extends Component {

    state = {
        distributionData: [],
        distributionReport: []
    }

    render() {
        return (<>
            <DatasetHeader {...this.props} />

            <Tabs>
                <Tab label={window.translate("EXAMPLES")}>
                    <h3>{window.translate("EXAMPLE")}</h3>
                    <br />
                    <CrudTable
                        url={`/corpus/datasets/${this.props.match.params.dataset_id}/examples`}
                        headers={[
                            { key: "text", header: window.translate("TEXT"), sortable: true },
                            { key: "intent_name", header: window.translate("INTENT"), sortable: true },
                            { key: "status", header: window.translate("STATUS"), sortable: true, parse: status => status ? "F" : "NF" },
                            { key: "metadata.canal_cliente", header: window.translate("CHANNEL"), sortable: true, parse: channel => channel || "N/A" },
                        ]}
                        mapFn={example => ({ ...example, text: example.text, intent_name: example.intent_name, "metadata.canal_cliente": example.metadata.canal_cliente })}
                        cellDetail={[null, row => <IntentDetail intentID={row.intent_id} />]}
                        advancedSearchOptions={{ filters: Example.filters }}
                        searchable disableCreate disableUpdate={row => true} disableDelete={row => true}
                    />
                </Tab>
                <Tab label={window.translate("CLASS_DISTRIBUTION")}>
                    <BarGraph
                        id='distribution-graph'
                        data={this.state.distributionReport}
                        xLabel={window.translate("CLASS")}
                        yLabel={window.translate("NUMBER_OF_EXAMPLES")}
                    />
                    <CrudTable
                        url={`/corpus/datasets/${this.props.match.params.dataset_id}/class_distribution`}
                        listResources={(url, params) => getDatasetClassDistibution(this.props.match.params.dataset_id).then(data => ({ rows: data, count: data.length }))}
                        headers={[
                            { key: "name", header: window.translate("NAME"), sortable: true },
                            { key: "count", header: window.translate("NUMBER_OF_EXAMPLES"), sortable: true }
                        ]}
                        cellDetail={[row => <IntentDetail intentID={row.id} />]}
                        disableCreate disableUpdate={row => true} disableDelete={row => true}
                        searchable clientPagination
                    />
                </Tab>
            </Tabs>
        </>)
    }

    componentDidMount() {
        getDatasetClassDistibution(this.props.match.params.dataset_id)
            .then(data => {
                this.setState({
                    distributionData: data,
                    distributionReport: data.map(point => ({ x: point.name, y: Number(point.count) })),
                })
            })
    }

}

export default DatasetExamplesDistributionPage