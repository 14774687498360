import React, { Component } from 'react';
import { TextInput, Button, Icon, StructuredListWrapper, StructuredListHead, StructuredListBody, StructuredListRow, StructuredListCell, } from 'carbon-components-react';
import { iconAddOutline, iconDelete } from 'carbon-icons'

class ContextEditor extends Component {

    state = {
        context: this.props.value,
        editionIndex: null,
        fieldUnderEdition: null,
        valueUnderEdition: null
    }

    render() {
        return (<>
            <StructuredListWrapper style={{ marginBottom: 0 }}>
                <StructuredListHead>
                    <StructuredListRow head>
                        <StructuredListCell head>{this.props.keyLabel || window.translate("VARIABLE")}</StructuredListCell>
                        <StructuredListCell head>{this.props.valueLabel || window.translate("VALUE")}</StructuredListCell>
                        <StructuredListCell head></StructuredListCell>
                    </StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                    {Object.keys(this.props.value || {}).map((field, index) => <StructuredListRow>
                        <StructuredListCell noWrap>
                            <TextInput
                                onFocus={() => this.handleFocusField(field, index)}
                                onBlur={() => this.handleBlurField(field)}
                                onChange={ev => this.handleEditContextVariableField(field, ev.target.value)}
                                value={this.state.editionIndex === index ? this.state.fieldUnderEdition : field}
                                onKeyPress={ev => ev.key === 'Enter' ? ev.target.blur() : null}
                                invalid={this.state.editionIndex === index && this.state.fieldUnderEdition !== field && this.state.fieldUnderEdition in this.props.value}
                                invalidText={window.translate("DUPLICATE_CONTEXT_VARIABLE_NOT_SAVED")}
                            />
                        </StructuredListCell>
                        <StructuredListCell>
                            <TextInput
                                onFocus={() => this.handleFocusValue(field, index)}
                                onBlur={() => this.handleBlurValue(field)}
                                onChange={ev => this.handleEditContextVariableValue(field, ev.target.value)}
                                value={this.state.editionIndex === index ? this.state.valueUnderEdition : typeof this.props.value[field] === 'string' ? this.props.value[field] : JSON.stringify(this.props.value[field])}
                                onKeyPress={ev => ev.key === 'Enter' ? ev.target.blur() : null} />
                        </StructuredListCell>
                        <StructuredListCell>
                            {this.props.editionMode && <Button kind="secondary" className="btn-icon-only" onClick={() => this.handleRemoveContextVariable(field)}><Icon icon={iconDelete} /></Button>}
                        </StructuredListCell>
                    </StructuredListRow>)}
                </StructuredListBody>
            </StructuredListWrapper>
            {this.props.editionMode && <Button kind="secondary" disabled={(this.props.svalue || {})[""] !== undefined} style={{ borderWidth: 0 }} onClick={() => this.handleAddContextVariable()}>{this.props.addLabel || window.translate("ADD_VARIABLE")}<Icon icon={iconAddOutline} /></Button>}
        </>)
    }

    handleFocusField(field, index) {
        this.setState({ editionIndex: index, fieldUnderEdition: field })
    }

    handleFocusValue(field, index) {
        this.setState({ editionIndex: index, valueUnderEdition: typeof this.props.value[field] === 'string' ? this.props.value[field] : JSON.stringify(this.props.value[field]) })
    }

    handleBlurField(field) {
        if (this.props.value[this.state.fieldUnderEdition]) return
        let newContext = JSON.parse(JSON.stringify({
            ...this.props.value,
            [field]: undefined,
            [this.state.fieldUnderEdition]: this.props.value[field]
        }))
        this.props.onChange(newContext)
        this.setState({ context: newContext, fieldUnderEdition: null })
    }

    handleBlurValue(field) {
        let valueUnderEdition = this.state.valueUnderEdition
        try { valueUnderEdition = JSON.parse(this.state.valueUnderEdition) }
        catch (err) { console.log(err) }
        let newContext = {
            ...this.props.value,
            [field]: valueUnderEdition
        }
        this.props.onChange(newContext)
        this.setState({ context: newContext, valueUnderEdition: null })
    }

    handleAddContextVariable() {
        let newContext = { ...this.props.value, "": "" }
        this.props.onChange(newContext)
        this.setState({ context: newContext })
    }

    handleEditContextVariableField(field, newField) {
        this.setState({ fieldUnderEdition: newField })
    }

    handleEditContextVariableValue(field, value) {
        this.setState({ valueUnderEdition: value })
    }

    handleRemoveContextVariable(field) {
        let newContext = JSON.parse(JSON.stringify({
            ...this.props.value,
            [field]: undefined
        }))
        this.props.onChange(newContext)
        this.setState({ context: newContext })
    }

}

ContextEditor.defaultProps = {
    editionMode: true
}

export default ContextEditor
