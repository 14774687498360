import React, { Component } from 'react';
import * as d3 from 'd3'

class Scatterplot extends Component {

    state = {
        width: window.innerWidth,
        height: window.innerHeight,
    }

    render() {
        let data = this.props.data

        // what are these and are they things that someone should edit
        const margin = { top: 30, right: 20, bottom: 60, left: 65 };
        const width = this.state.width - 65 - (margin.left + margin.right);
        const height = 300 - (margin.top + margin.bottom);
        const labelOffset = 55;
        const axisOffset = 16;

        // Set the scales
        const x = d3.scaleBand()
            .rangeRound([0, width])
            .domain(data.map((d) => d.x))
            .padding(0.1);

        const y = d3.scaleLinear()
            .range([height, 0])
            .domain([0, d3.max(data, (d) => d.y * 1.1)]);

        // Set the axes
        const xAxis = d3.axisBottom()
            .scale(x)
            .tickSize(0)

        const yAxis = d3.axisLeft()
            .ticks(4)
            .tickSize(-width)
            .scale(y.nice());

        // Set up SVG with initial transform to avoid repeat positioning
        const svg = d3.select(`#${this.props.id}`)
            .attr('class', 'graph')
            .attr('width', width + (margin.left + margin.right))
            .attr('height', height + (margin.top + margin.bottom))
            .append('g')
            .attr('class', 'group-container')
            .attr('transform', `translate(${margin.left}, ${margin.top})`);

        // Add Y axis
        svg.append('g')
            .attr('class', 'axis y')
            .attr('stroke-dasharray', '4')
            .call(yAxis)
            .selectAll('text')
            .attr("x", -axisOffset)
            .attr('font-family', 'ibm-plex-sans');

        // Add Y axis label
        svg.select('.y')
            .append('text')
            .text(this.props.yLabel)
            .attr('class', 'label')
            .attr('transform', `translate(${-labelOffset}, ${height / 2}) rotate(-90)`)
            .attr('font-family', 'ibm-plex-sans');

        // Add X axis
        svg.append('g')
            .attr('class', 'axis x')
            .attr('transform', `translate(0, ${height})`)
            .call(xAxis)
            .selectAll('text')
            .attr("y", axisOffset)
            .attr('font-family', 'ibm-plex-sans');

        // Add X axis label
        svg.select('.x')
            .append('text')
            .text(this.props.xLabel)
            .attr('class', 'label')
            .attr('transform', `translate(${width / 2}, ${labelOffset})`)
            .attr('font-family', 'ibm-plex-sans');

        svg.append('g')
            .attr('class', 'scatter-container')
            .selectAll('circle')
            .data(data)
            .enter().append('circle')
            .attr('class', 'circle')
            .attr('cx', (d) => x(d.x))
            .attr('cy', (d) => y(d.y))
            .attr('fill', '#00a68f')
            .attr('r', 0)
            .transition()
            // .delay((d, i) => i * 35)
            .attr('r', 4)

        // Select Tooltip
        const tooltip = d3.select(`.tooltip-${this.props.id}`);

        svg.selectAll('.circle')
            .on('mouseover', function (d) {
                const circleData = {
                    x: x(d.x),
                    y: y(d.y),
                }

                d3.select(this)
                    .style('cursor', 'pointer');

                svg.select('.scatter-container')
                    .append('circle')
                    .attr('class', 'temp-circle')
                    .lower()
                    .attr('cx', circleData.x)
                    .attr('cy', circleData.y)
                    .attr('r', 4)
                    .transition()
                    .attr('r', 10)
                    .style('opacity', .5)
                    .attr('fill', '#00a68f')

                tooltip
                    .style('display', 'inherit')
                    .text(`${d.label || d.y}`)
                    .style('top', `${y(d.y) + axisOffset - 25}px`)
                    // .transition()
                    .style('opacity', 1);

                let tooltipWidth = tooltip.nodes()[0].getBoundingClientRect().width;
                let offset = tooltipWidth / 2;

                tooltip
                    .style('left', `${x(d.x) + margin.left - offset}px`)
            })
            .on('mouseout', function (d) {
                d3.select('.temp-circle')
                    .transition()
                    .attr('r', 0)
                    .remove()

                tooltip
                    .transition()
                    .style('opacity', 0)
                    .transition()
                    .duration(0)
                    .style('display', 'none')
            })
            .on('click', this.props.onCircleClick)
        return (
            <div class="graph-container">
                <svg id={this.props.id}></svg>
                <div class={`tooltip tooltip-${this.props.id}`}></div>
            </div>
        )
    }

    updateDimensions = () => {
        d3.select(`#${this.props.id}`).selectAll("*").remove()
        this.setState({ width: window.innerWidth, height: window.innerHeight })
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions)
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }
}

export default Scatterplot