import React, { Component } from 'react';
import { TextInput, Tile, Button } from 'carbon-components-react';
import { listIntents, listEntities } from '../../../services/corpus'
import normalize from '../../../utils/normalize-intent-name'

const LOAD_TIMEOUT = 300
const COMMIT_TIMEOUT = 0

class ConditionEditor extends Component {

    state = {
        intents: [],
        entities: [],
        focused: false,
        conditionUnderEdition: null
    }

    inputRef = React.createRef()
    lastValue = null
    timeout = null
    commitTimeout = null

    render() {
        return (<>
            <div style={{ display: "flex" }}>
                <TextInput
                    {...this.props}
                    value={this.state.conditionUnderEdition === null ? this.props.value : this.state.conditionUnderEdition}
                    onChange={ev => this.onChange(ev.target.value)}
                    onFocus={ev => this.handleFocus(ev)}
                    onBlur={ev => this.handleBlur(ev)}
                    onKeyPress={ev => ev.key === 'Enter' ? ev.target.blur() : null}
                    ref={this.inputRef}
                >
                </TextInput>
                <Button
                    kind="secondary"
                    className="btn-icon-only"
                    onClick={ev => this.props.onChange("", true)}
                >
                    -
                </Button>
            </div>

            <br />
            {this.state.focused && this.state.conditionUnderEdition !== undefined && !this.state.conditionUnderEdition.endsWith(')') && <div style={{ position: "absolute", zIndex: 9000, right: 0, left: 0 }}>
                {!this.state.conditionUnderEdition && <div>
                    <Tile>
                        <h5>Filter by</h5>
                        <div className="bx--overflow-menu-options__option" style={{ maxWidth: "unset", padding: "0.5rem 1rem" }} onMouseDown={() => this.onChange("#")}># {window.translate("INTENTS")}</div>
                        <div className="bx--overflow-menu-options__option" style={{ maxWidth: "unset", padding: "0.5rem 1rem" }} onMouseDown={() => this.onChange("@")}>@ {window.translate("ENTITIES")}</div>
                        <div className="bx--overflow-menu-options__option" style={{ maxWidth: "unset", padding: "0.5rem 1rem" }} onMouseDown={() => this.onChange("$")}>$ {window.translate("CONTEXT_VARIABLES")}</div>
                    </Tile>
                    <Tile>
                        <h5>Common conditions</h5>
                        <div className="bx--overflow-menu-options__option" style={{ maxWidth: "unset", padding: "0.5rem 1rem" }} onMouseDown={() => this.onChange("welcome")}>welcome: {window.translate("WELCOME_CONDITION_DESCRIPTION")}</div>
                        <div className="bx--overflow-menu-options__option" style={{ maxWidth: "unset", padding: "0.5rem 1rem" }} onMouseDown={() => this.onChange("anything_else")}>anything_else: {window.translate("ANYTHING_ELSE_CONDITION_DESCRIPTION")}</div>
                    </Tile>
                </div>}
                {this.state.conditionUnderEdition.startsWith('#') && this.state.conditionUnderEdition.slice(1) !== normalize((this.state.intents[0] || { name: "" }).name) && <div>
                    <Tile>
                        <h5>{window.translate("INTENTS")}</h5>
                        {this.state.intents.map((intent, i) =>
                            <div className="bx--overflow-menu-options__option"
                                key={`intent-${i}`}
                                style={{ maxWidth: "unset", padding: "0.5rem 1rem" }}
                                onMouseDown={() => this.onChange(`#${normalize(intent.name)}`)}
                            >{intent.name}</div>
                        )}
                    </Tile>
                </div>}
                {this.state.conditionUnderEdition.startsWith('@') && <div>
                    {!this.state.conditionUnderEdition.slice(1).startsWith(this.getEntityName(this.state.entities[0])) && <Tile>
                        <h5>{window.translate("ENTITIES")}</h5>
                        {this.state.entities.map((entity, i) =>
                            <div className="bx--overflow-menu-options__option"
                                key={`entity-${i}`}
                                style={{ maxWidth: "unset", padding: "0.5rem 1rem" }}
                                onMouseDown={() => this.onChange(`@${this.getEntityName(entity)}`)}
                            >{entity.name}</div>
                        )}
                    </Tile>}
                    {this.state.entities.length > 0 && this.state.conditionUnderEdition.slice(1) === this.getEntityName(this.state.entities[0]) && <Tile>
                        <h5>{window.translate("ENTITY_VALUES")}</h5>
                        {this.state.entities[0].values.map((value, i) =>
                            <div className="bx--overflow-menu-options__option"
                                key={`entity-value-${i}`}
                                style={{ maxWidth: "unset", padding: "0.5rem 1rem" }}
                                onMouseDown={() => this.onChange(`@${this.getEntityName(this.state.entities[0])}:(${this.getEntityValueName(value)})`)}
                            >{value.name}</div>
                        )}
                    </Tile>}
                </div>}
            </div>}
        </>
        )
    }


    getEntityName(entity) {
        return entity ? (this.props.isProcflow ? entity.name : entity.name.toLowerCase()) : {}
    }

    getEntityValueName(entityValue) {
        return this.props.isProcflow ? entityValue.name : entityValue.name.toLowerCase()
    }

    onChange(condition) {
        this.setState({ conditionUnderEdition: condition }, () => setTimeout(() => this.inputRef.current && this.inputRef.current.focus(), 0))
        if (condition === undefined) return
        clearTimeout(this.timeout)
        setTimeout(() => {
            this.loadIntents(this.props.dataset_id, condition)
            this.loadEntities(this.props.dataset_id, condition)
        }, LOAD_TIMEOUT);
    }

    loadIntents(dataset_id, value) {
        listIntents(dataset_id, { search: value.slice(1) })
            .then(data => this.setState({ intents: data.rows }))
    }

    loadEntities(dataset_id, value) {
        listEntities(dataset_id, { search: value.slice(1) })
            .then(data => this.setState({ entities: data.rows }))
    }

    handleFocus() {
        clearTimeout(this.commitTimeout)
        this.setState({ focused: true, conditionUnderEdition: this.state.conditionUnderEdition === null ? this.props.value : this.state.conditionUnderEdition })
    }

    handleBlur() {
        this.commitTimeout = setTimeout(() => {
            this.props.onChange(this.state.conditionUnderEdition)
            this.setState({ focused: false, conditionUnderEdition: null })
        }, COMMIT_TIMEOUT)
    }
}

ConditionEditor.defaultProps = {
    value: ""
}

export default ConditionEditor