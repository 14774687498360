import React, { Component } from 'react';
import { Tile, TextInput, OverflowMenu, OverflowMenuItem } from 'carbon-components-react';
import ConditionEditor from '../../../condition-editor';
import { JSONEditor } from '../../../../../../components/carbon-react-crud';
import validateDialogNodeJSON from '../../../../../../utils/validate-dialog-node-json';


class SlotConditionVariableEditor extends Component {

    state = {
        selectedNode: null,
        jsonEditor: false
    }

    modalRef = React.createRef()

    render() {
        let focusHandler = this.props.selectedNode
        let inputHandler = this.props.dialogNodes.find(node => node.parent === focusHandler.parent && node.event_name === 'input')
        let slotNode = this.props.dialogNodes.find(node => node.dialog_node === focusHandler.parent)
        if (!slotNode || !inputHandler || !focusHandler) return <></>
        return (<>
            <Tile>
                <div style={{ display: "flex" }}>
                    {<h3 style={{ flex: "1 1", margin: "10px" }}>{slotNode.conditions !== undefined && `${window.translate("ENABLE_THIS_SLOT_IF")}:`}</h3>}
                    <OverflowMenu flipped={true} >
                        {slotNode.conditions === undefined && <OverflowMenuItem itemText="Enable condition" onClick={ev => this.props.onChange(slotNode, { conditions: "true" })} />}
                        {slotNode.conditions !== undefined && <OverflowMenuItem itemText="Disable condition" onClick={ev => this.props.onChange(slotNode, { conditions: undefined })} />}
                        {!this.state.jsonEditor && <OverflowMenuItem itemText="Open JSON editor" onClick={ev => this.setState({ jsonEditor: true })} />}
                        {this.state.jsonEditor && <OverflowMenuItem itemText="Close JSON editor" onClick={ev => this.setState({ jsonEditor: false })} />}
                    </OverflowMenu>
                </div>
                <br />
                {slotNode.conditions !== undefined && <ConditionEditor

                    value={slotNode.conditions}
                    onChange={conditions => this.props.onChange(slotNode, { conditions })}
                    dataset_id={this.props.dataset_id}
                />}
                {this.state.jsonEditor && <JSONEditor validate={validateDialogNodeJSON} value={{ context: inputHandler.context, output: inputHandler.output, actions: inputHandler.actions }} onChange={payload => this.props.onChange(inputHandler, payload)} />}
                {!this.state.jsonEditor && <div style={{ display: "flex" }}>
                    <div style={{ margin: "0.5rem" }}>
                        <h3>{window.translate("CHECK_FOR")}</h3>
                        <ConditionEditor

                            value={inputHandler.conditions}
                            onChange={conditions => {
                                let varName = slotNode.variable ? slotNode.variable.replace(/^\$/, '') : null
                                let inputHandlerChange = {
                                    conditions,
                                    context: {
                                        ...inputHandler.context,
                                        [varName]: inputHandler.context[varName] === inputHandler.conditions ? conditions : inputHandler.context[varName]
                                    }
                                }
                                this.props.onChange(inputHandler, inputHandlerChange)
                                let match = conditions.match(/^@[0-9A-zÀ-ÿ-]{1,64}(:\([^\n\r\t]{0,63}\))?$/)
                                if (!varName && match) {
                                    let value = match[0].slice(1).replace(/[(|)]/g, "").replace(/[ |:]/g, '_')
                                    this.props.onChange(slotNode, { variable: `$${value}` });
                                    inputHandlerChange.context[value] = conditions
                                    setTimeout(() => this.props.onChange(inputHandler, inputHandlerChange), 0);
                                }
                            }}
                            dataset_id={this.props.dataset_id}
                        />
                    </div>
                    <div style={{ margin: "0.5rem" }}>
                        <h3>{window.translate("SAVE_IT_AS")}</h3>
                        <TextInput
                            value={slotNode.variable}
                            onChange={ev => {
                                let value = ev.target.value
                                this.props.onChange(slotNode, { variable: value });
                                setTimeout(() => this.props.onChange(inputHandler, { context: { ...inputHandler.context, [slotNode.variable.replace(/^\$/, '')]: undefined, [value.replace(/^\$/, '')]: inputHandler.conditions } }), 0);
                            }}
                            onKeyPress={ev => ev.key === 'Enter' ? ev.target.blur() : null}
                        />
                    </div>
                </div>}
            </Tile>
        </>)
    }
}

export default SlotConditionVariableEditor