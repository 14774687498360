import React, { Component } from 'react'

import withRouterAndRef from '../utils/with-router-and-ref'
import axios from 'axios'
import { CrudTable } from '../components/carbon-react-crud'
import { Modal } from 'carbon-components-react'
import AuditExplorer from './audit/components/audit-explorer'

class CommonPage extends Component {

    routeMap = {
        "assistant_instances": "orchestrator",
        "assistant_skills": "orchestrator",
        "discovery_instances": "orchestrator",
        "discovery_projects": "orchestrator",
        "discovery_collections": "orchestrator",
        "orchestrators": "orchestrator",
        "datasets": "corpus",
        "knowledge_bases": "documents",
        "users": "auth",
        "audit_facts": "audit",
    }

    permissionLoadTimeout = null

    state = {}

    crudTableRef = React.createRef()

    render() {
        return <>
            {this.state.auditModalOpen && setTimeout(() => window.document.body.scrollTo(0, 0), 10) && setTimeout(() => window.scrollTo(0, 0), 20) && <style dangerouslySetInnerHTML={{ __html: `html, body {margin: 0; height: 100%; overflow: hidden}` }}></style>}
            {this.props.header && <this.props.header {...this.props} />}
            <h3>{this.props.model.title}</h3>
            <br />
            <CrudTable
                disableUpdate={row => !this.isAllowed(row, ['admin', 'auditor', 'editor'])}
                disableDelete={row => !this.isAllowed(row, ['admin'])}
                {...this.props}
                {...this.props.model}
                url={this.props.url ? this.props.url(this.props.match.params) : this.getResourceURL()}
                headers={this.props.model.fields.filter(field => field.header).map(field => ({ ...field, header: field.label || window.translate(field.key) }))}
                fields={this._model2Fields(this.props.model)}
                advancedSearchOptions={this.props.model.filters && { filters: this._model2Filters(this.props.model) }}
                addButtonText={window.translate(`NEW_${this.getResourceName()}`)}
                links={this._model2Links(this.props.model)}
                selectable searchable
                formatErrorMessage={error => window.translate(error.response ? error.response.data.message : error.message)}
                ref={this.crudTableRef}
                translate={window.translate}
            />
            <Modal
                open={this.state.auditModalOpen}
                onRequestClose={ev => this.setState({ auditModalOpen: false })}
                passiveModal
                title={"Últimas operações"}
                className="carbon--custom-form"
            >
                {this.state.auditModalOpen && <AuditExplorer resource_id={this.state.auditResourceID} resource_type={this.props.model.audit} />}
            </Modal>
            {this.props.model.footer && this.props.model.footer.bind(this)(this)}
        </>
    }

    _model2Fields(model) {
        let fields = model.fields
            .map(field => {
                let newField = { ...field }
                if (newField.type === "fkey") {
                    newField.type = 'recordpicker'
                    newField.label = window.translate(field.label) || window.translate(field.key.slice(0, -3))
                    newField.fetchOptions = (url, params) => this.sendRequest({ url: field.path, params })
                }
                newField.label = newField.label || window.translate(newField.key)
                newField.description = window.translate(newField.description)
                return newField
            })
        return fields
    }

    _model2Links(model) {
        let links = (model.actions || [])
            .map(action => {
                if (!action.link) {
                    if (action.download) {
                        action.onClick = row => {
                            let link = document.createElement("a")
                            link.download = `${row.name}-${(new Date()).toISOString().replace(/-|:/g, '').replace('T', '-').slice(0, 15)}${action.download}`
                            link.href = `${this.getResourceURL()}/${row.id}/${action.path}`
                            document.body.appendChild(link)
                            setTimeout(() => {
                                link.click()
                                document.body.removeChild(link)
                            }, 0)
                        }
                    }

                    else if (action.path)
                        action.onClick = row => this.sendRequest({ url: `./${row.id}/${action.path}`, method: action.method || "POST" })
                }
                else {
                    action.onClick = row => this.props.history.push(`${this.props.location.pathname}/${row.id}/${action.path}`)
                }
                if (action.roles) {
                    let oldCondition = action.condition || (() => true)
                    action.condition = (row) => oldCondition(row) && this.isAllowed(row, action.roles)
                }
                return { ...action, text: window.translate(action.text) }
            })
        if (model.audit && this.props.user && this.props.user.auditor)
            links.push({ text: window.translate("AUDIT"), onClick: row => this.setState({ auditModalOpen: true, auditResourceID: row.id }) })
        return links
    }

    _model2Filters(model) {
        return model.filters.map(filter => ({ ...filter, label: filter.label || window.translate(filter.key) }))
    }

    sendRequest(options) {
        if (options.url.startsWith('.'))
            options.url = options.url.replace('.', (this.props.url && this.props.url(this.props.match.params).split('?')[0]) || this.getResourceURL())
        else
            options.url = `${process.env.REACT_APP_PATH || ""}${options.url}`
        return axios(options)
            .then(response => response.data)
            .catch(err => {
                if (err.response) {
                    if (err.response.status === 401) setTimeout(() => window.location.replace((process.env.REACT_APP_PATH || "") + '/login'), 2000)
                    throw err.response.data ? err.response.data : err.response
                }
                throw err
            })
    }

    getResourceURL() {
        let parts = (this.props.pathname || this.props.location.pathname).split('/')
        let path = `${process.env.REACT_APP_PATH || ""}/${this.routeMap[parts[1]]}${parts.join('/')}`
        return path
    }

    getResourceName() {
        let parts = (this.props.pathname || this.props.location.pathname).split('/')
        let lastPart = parts[parts.length - 1].toUpperCase()
        return lastPart
    }

    isAllowed(row, roles) {
        return (
            (!this.props.user || this.props.user.admin) ||
            !row.permissions ||
            row.permissions.map(p => p.role).reduce((allowed, p) => allowed || roles.includes(p), false)
        )
    }

    handleRefresh() {
        this.crudTableRef.current.loadResources()
    }
}


export default withRouterAndRef(CommonPage)