import React from 'react';
import { Icon } from 'carbon-components-react'
import { iconCheckmark, iconClose } from 'carbon-icons'
import { Modal } from 'carbon-components-react';
let moment = require('moment')
try { require('moment/locale/' + navigator.language.slice(0, 2)) } catch { }

let User = {
    title: window.translate("Users"),
    fields: [
        { key: "name", type: "text", header: true, description: "USER_NAME_DESCRIPTION" },
        { key: "username", type: "text", header: true, description: "USER_USERNAME_DESCRIPTION" },
        { key: "new_password", type: "password", description: "USER_PASSWORD_DESCRIPTION" },
        { key: "active", type: "toggle", header: true, default: true, parse: state => <Icon style={{ fill: "black" }} icon={state ? iconCheckmark : iconClose} />, description: "USER_ACTIVE_DESCRIPTION" },
        { key: "admin", type: "toggle", header: true, parse: state => <Icon style={{ fill: "black" }} icon={state ? iconCheckmark : iconClose} />, description: "USER_ADMIN_DESCRIPTION" },
        { key: "auditor", type: "toggle", header: true, parse: state => <Icon style={{ fill: "black" }} icon={state ? iconCheckmark : iconClose} />, description: "USER_AUDITOR_DESCRIPTION" },
        { key: "login_attempts", type: "number", description: "USER_LOGIN_ATTEMPTS_DESCRIPTION" },
        { key: "password", type: "password", label: window.translate("CONFIRM_PASSWORD"), description: "USER_CONFIRM_PASSWORD_DESCRIPTION" },
        { key: "created_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
    ],
    filters: [
        { type: "toggle", key: "active" },
        { type: "toggle", key: "admin" },
        { type: "toggle", key: "auditor" },
    ],
    actions: [
        { path: "apikeys", text: "APIKEYS", link: true },
    ],
    exportable: true,
    audit: "USER",
}

let APIKey = {
    title: window.translate("APIKeys"),
    fields: [
        { key: "name", type: "text", header: true },
        { key: "password", type: "password", label: window.translate("CONFIRM_PASSWORD") },
        { key: "created_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
        { key: "updated_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
    ],
    footer: function () {
        return <Modal
            modalHeading="API key created successfully!"
            open={this.state.modalOpen}
            onRequestClose={ev => this.setState({ modalOpen: false, apikey: "" })}
            passiveModal={true}
        >
            <p>Keep it safe! This is the only time we'll show it to you.</p>
            <br />
            <p>{this.state.apikey}</p>
        </Modal>
    },
    onCreateSuccess: function (ok) { this.setState({ modalOpen: true, apikey: ok.apikey }) },

    actions: [],
    audit: "APIKEY",

}

export { User, APIKey }
