import React, { Component } from 'react';
import QueryInterface from './components/query-interface';

class AssistantSkillQueryPage extends Component {

    render() {
        return <>
            <QueryInterface assistant_skill_id={this.props.match.params.assistant_skill_id} />
        </>
    }
}

export default AssistantSkillQueryPage