module.exports = {
    "ACCURACY_VS_COVERAGE": "Acurácia VS cobertura",
    "COVERAGE": "Cobertura",
    "DIGRESSION_RETURN_TEXT": "Texto de retorno da digressão",
    "SEARCH_ALL_FIELDS": "Buscar em todos os campos",
    "AFTER": "Depois",
    "BEFORE": "Antes",
    "FLAGS": "Configurações",
    "PROCFLOW REQUIRES AT LEAST ONE CHILD": "Procflow requer ao menos um filho",
    "PROCFLOW OPTIONS SHOULD HAVE A SINGLE CHILD": "Opções de procflow deve ter apenas um filho",
    "RESPONSE SHOULD BE A NON-EMPTY LIST": "Resposta deve ser uma lista não vazia",
    "INVALID GO-TO DETECTED": "Salto inválido detectado",
    "PROCFLOW QUESTION SHOULD BE A NON-EMPTY LIST": "Pergunta de desambiguação deve ser uma lista não vazia",
    "DIGRESSION_RETURN": "Retornar de digressões",
    "NO_QUESTION": "Sem pergunta de desambiguação",
    "NEGATIVE": "Negativo",
    "POSITIVE": "Positivo",
    "WORD_CLOUD": "Nuvem de palavras",
    "AVERAGE": "Média",
    "REORDER": "Reordenar",
    "SNAPSHOT": "Snapshot",
    "AUDIT_MAX_RANGE_MESSAGE": "Selecione uma janela de até 90 dias para buscar e exportar",
    "TRAINING": "Treinando",
    "FINISHED": "Finalizado",
    "FAILED": "Falhou",
    "INDEXED": "Indexado",
    "DEINDEXED": "Desindexado",
    "ORCHESTRATOR_ID": "Orquestrador",
    "CREATED_BETWEEN": "Criado entre",
    "UPDATED_BETWEEN": "Atualizado entre",
    "CONFIRM": "Confirmar",
    "TRANSFER": "Transferir",
    "COPY": "Copiar",
    "EXPERIMENT_ID": "ID de Experimento",
    "PREDICTION_ID": "ID de Predição",
    "INCLUDE_INTENTS": "Incluiu intenções",
    "INCLUDE_ENTITIES": "Incluiu entidades",
    "INCLUDE_DIALOG": "Incluiu diálogo",
    "TOTAL_BY": "Total por",
    "DATE": "Data",
    "COMPARISON": "Comparação",
    "RAW": "Puro",
    "WHEN_USER_RESPONDS_IF": "Quando o usuário responder, se a intenção ou entidade for",
    "FOUND": "Encontrado",
    "NOT_FOUND": "Não encontrado",
    "IMPORT": "Importar",
    "EXAMPLE_ID": "ID de Exemplo",
    "SHOW_ALL_FIELDS": "Mostrar todos os campos",
    "RESOURCE_MISSING": "Recurso faltando",
    "REVIEW_EXAMPLE_ID": "ID de Exemplo de revisão",
    "ALL_EVENTS": "Todos os eventos",
    "VIEW_ALL_EVENTS": "Ver todos os eventos",
    "EVENTS_IN": "Eventos dentro de",
    "EVENTS_FOR": "Eventos sobre",
    "VIEW_EVENTS_IN_THIS_RESOURCE": "Visualizar eventos dentro desse recurso",
    "VIEW_EVENTS_TARGETING": "Visualizar eventos sobre esse recurso",
    "VIEW_EVENTS_IN": "Ver eventos para este",
    "RESOURCE": "Recurso",
    "RESOURCE_BEFORE": "Recurso antes",
    "RESOURCE_AFTER": "Recurso depois",
    " FACT": " Fato",
    " DATE": " Data",
    "ACTION": "Ação",
    "INTENT CREATE": "Criar Intenção",
    "INTENT UPDATE": "Editar Intenção",
    "CREATE ENTITY_VALUE": "Criar Valor de entidade",
    "EXAMPLE_STATUS": "Exemplo Status",
    "MAP REVIEW_EXAMPLE": "Mapear Exemplo para revisão",
    "ACCEPT REVIEW_EXAMPLE": "Aceitar Exemplo para revisão",
    "ID_INTENT_BEFORE": "ID da Intenção anterior",
    "ID_INTENT_ACTUAL": "ID da Intenção atual",
    "ID_INTENT": "ID da Intenção",
    "NAME_AFTER": "Nome depois",
    "EXAMPLE_STATUS_AFTER": "Exemplo Status depois",
    "ANSWER_AFTER": "Resposta depois",
    "EXAMPLE_AFTER": "Exemplo depois",
    "TAXONOMY_AFTER": "Taxonomia depois",
    "TAXONOMY_MERGED": "Taxonomia da intenção agregada",
    "TAXONOMY_KEEPED": "Taxonomia da intenção mantida",
    "FINAL_AFTER": "Final depois",
    "OUT_OF_CONTEXT_AFTER": "Fora de contexto depois",
    "OUT_OF_TRAINING_AFTER": "Fora de treinamento depois",
    "CANONICAL_AFTER": "Pergunta canônica depois",
    "TYPE_AFTER": "Tipo depois",
    "CONTEXT_VARIABLES_AFTER": "Variáveis de contexto depois",
    "OUTPUT_VARIABLES_AFTER": "Variáveis de saída depois",
    "TAXONOMY": "Taxonomia",
    "TAXONOMY_ACTUAL": "Taxonomia atual",
    "TAXONOMY_BEFORE": "Taxonomia anterior",
    "ASSISTANT_INSTANCE_ID": "ID de Instância de Assistant",
    "ASSISTANT_SKILL_ID": "ID de Skill de Assistant",
    "RECOMMENDATION_ID": "ID de Recommendação",
    "EVALUATION_ID": "ID de Avaliação",
    "ENTITY_VALUE_ID": "ID de Valor de entidade",
    "ENTITY_ID": "ID de Entidade",
    "DATASET_ID": "ID de Conjunto de treinamento",
    "START_DATE": "Data inicial",
    "END_DATE": "Data final",
    "ACTION_TYPE": "Tipo de ação",
    "ENABLE_FILTERS": "Habilitar filtros",
    "RETRAIN_DIALOG": "Retreinar diálogo",
    "DIALOG_RETRAIN": "Retreino de diálogo",
    "NODES_IN_THIS_FOLDER":"Nós nesta pasta",
    "CHILDREN": "Filhos",
    "TARGET": "Alvo",
    "DIGRESSIONS_CAN_GO_AWAY": "Digressões podem sair deste nó",
    "DIGRESSIONS_CAN_COME_IN": "Digressões podem entrar neste nó",
    "DIGRESSIONS_CANNOT_GO_AWAY": "Digressões não podem sair deste nó",
    "DIGRESSIONS_CANNOT_COME_IN": "Digressões não podem entrar neste nó",
    "DIGRESSION_NO_CHILDREN_TITLE": "Esse nó não tem filhos.",
    "DIGRESSION_NO_CHILDREN_TEXT": "Digressões não são possíveis se um nó não tem filhos, pois o fluxo já terminou.",
    "DIGRESSION_JUMP_TITLE": "Salto bloqueia digressões depois da resposta desse nó.",
    "DIGRESSION_JUMP_TEXT": "Este nó está configurado para saltar para outro ou pular a entrada do usuário depois de ser processado. Isto sempre disparará antes que a digressão ocorra.",
    "DIGRESSION_OUT_SLOTS_TITLE": "Permitir digressões para fora durante preenchimento de slots.",
    "DIGRESSION_OUT_SLOTS_TEXT": "Usuários podem desviar a conversa para fora deste nó enquanto slots são processados.",
    "DIGRESSION_OUT_SLOTS_RETURN_LABEL": "Só permitir digressão de slots para nós que permitem retorno.",
    "DIGRESSION_OUT_SLOTS_RETURN_TEXT": "Se um usuário sair do assunto, apenas nós com digressões que permitem retorno serão considerados.",
    "DIGRESSION_OUT_RETURN_TEXT": "Permitir retorne de digressões após a resposta deste fluxo.",
    "DIGRESSION_IN_TITLE": "Este nó não pode ser acessado diretamente por digressão.",
    "DIGRESSION_IN_TEXT": "Digressões para um nó só são permitidas para nós na raiz do diálogo. Este nó ainda pode ser alcançado como parte de uma digressão; apenas é necessário passar por um nó pai na raiz, que tenha recebido a digressão.",
    "INVALID_TARGET": "Alvo inválido",
    "RECOMMENDATION": "Recomendação",
    "EVALUATION": "Avaliação",
    "DELETE EVALUATION": "Apagar Avaliação",
    "DUPLICATE_CONTEXT_VARIABLE_NOT_SAVED": "Variável de contexto duplicada não salva",
    "MANDATORY_DISAMBIGUATION": "Desambiguação obrigatória",
    "INFERENCE": "Inferência",
    "INFERENCE_OPT": "Compor Inferência",
    "IF_NO_SLOTS_ARE_PREFILLED_ASK": "Se nenhum slot for preenchido, perguntar primeiro:",
    "ADD_SLOT": "Adicionar slot",
    "EVALUATION_HELP_0": "Avaliações são ferramentas de análise de conjuntos de treinamento que permitem otimizar o conteúdo da base.",
    "EVALUATION_HELP_1": "Ao executar uma avaliação, são geradas recomendações de deletar exemplos, com base em uma análise estatística dos dados.",
    "EVALUATION_HELP_2": "Estão disponíveis os seguintes tipos de avaliação:",
    "REDUNDANCY_DETECTION_DESCRIPTION": "Detecta e sugere a remoção de exemplos muito parecidos entre si.",
    "UNDERSAMPLING_DESCRIPTION": "Detecta classes com número elevado de exemplos, e sugere remoções.",
    "OUTLIER_DETECTION_DESCRIPTION": "Detecta e sugere a remoção de exemplos de tamanho muito diferente da média.",
    "RECOMMENDATION_HELP_0": "Recomendações são o resultado de uma avaliação, e representam ações que podem ser tomadas com base na análise.",
    "RECOMMENDATION_HELP_1": "Abrindo os detalhes de cada recomendação, é possível visualizar e editar as modificações propostas.",
    "RECOMMENDATION_HELP_2": "Quando uma recomendação for julgada pertinente, clique em \"Executar\" para aplicá-la ao conjunto de treinamento.",
    "RECOMMENDATION_HELP_3": "Caso contrário, clique em \"Apagar\" para removê-la da lista.",
    "EVALUATION_NAME_DESCRIPTION": "O nome da avaliação.",
    "EVALUATION_LANGUAGE_DESCRIPTION": "O idioma a ser usado nesta avaliação.",
    "EVALUATION_USE_REVIEW_DESCRIPTION": "Se esta avaliação deve utilizar exemplos em revisão em vez de examplos mapeados.",
    "EVALUATION_MIN_DISTANCE_DESCRIPTION": "Distância mínima entre dois exemplos para que sejam considerados diferentes. Valores maiores farão com que mais exemplos sejam considerados redundantes.",
    "EVALUATION_MAX_SAMPLES_DESCRIPTION": "Número máximo de exemplos por classe considerado razoável para este conjunto de treinamento.",
    "EVALUATION_DEVIATIONS_DESCRIPTION": "Número de desvios padrão em relação à média para que um exemplo seja considerado um outlier. Valores maiores farão com que menos exemplos sejam selecionados.",
    "HELP": "Ajuda",
    "USER_NAME_DESCRIPTION": "O nome do usuário.",
    "USER_USERNAME_DESCRIPTION": "O nome de usuário a ser usado para login.",
    "USER_PASSWORD_DESCRIPTION": "A senha do usuário.",
    "USER_ACTIVE_DESCRIPTION": "Se este usuário está ativo. Usuários inativos não podem fazer login.",
    "USER_ADMIN_DESCRIPTION": "Se este usuário é um administrador da plataforma. Administradores têm acesso irrestrito a todos os recursos.",
    "USER_AUDITOR_DESCRIPTION": "Se este usuário é um auditor da plataforma. Auditores podem gerar relatórios de uso de recursos aos quais têm acesso de leitura.",
    "USER_LOGIN_ATTEMPTS_DESCRIPTION": "Quantas tentativas falhas de login este usuário realizou. Se estiver em 3, o usuário está bloqueado.",
    "USER_CONFIRM_PASSWORD_DESCRIPTION": "Confirme a sua senha para executar esta ação, por motivos de segurança.",
    "REMAINING_EXAMPLES_TO_DELETE": "Exemplos para deletar",
    "DELETE_REVIEW_EXAMPLES": "Deletar exemplos para revisão",
    "USE_REVIEW": "Usar exemplos para revisão",
    "OUTLIER_DETECTION": "Detecção de outliers",
    "DEVIATIONS": "Desvios padrão",
    "MAX_SAMPLES": "Número máximo de amostras",
    "DELETE_EXAMPLES": "Apagar exemplos",
    "UNDERSAMPLING": "Subamostragem",
    "KEEP_ONE_EXAMPLE": "Manter um exemplo",
    "EXECUTE": "Executar",
    "EVALUATIONS": "Avaliações",
    "NEW_EVALUATIONS": "Nova avaliação",
    "RECOMMENDATIONS": "Recomendações",
    "MIN_DISTANCE": "Distância mínima",
    "REDUNDANCY_DETECTION": "Detecção de redundâncias",
    "RESPONSE_VARIATIONS_SET_TO": "Variação de respostas configurada para",
    "SET_TO": "Configurar para",
    "MULTILINE": "Multilinha",
    "MANAGE_CONTEXT": "Gerenciar contexto",
    "VIEW_OUTPUT": "Ver saída",
    "MANAGE_HANDLERS_FOR": "Gerenciar manipuladores para",
    "MANAGE_HANDLERS": "Gerenciar manipuladores",
    "HANDLERS_DESCRIPTION_0": "Manipuladores são como seu assistente responderá quando a resposta do usuário a uma pergunta não é encontrada. Esses manipuladores serão checados antes de tentar as respostas de \"Não encontrado\" em um slot.",
    "HANDLERS_DESCRIPTION_1": "Se uma resposta para qualquer pergunta não é encontrada e",
    "WELCOME_CONDITION_DESCRIPTION": "dispara quando uma conversa é iniciada pelo sistema",
    "ANYTHING_ELSE_CONDITION_DESCRIPTION": "dispara quando a entrada do usuário não satisfaz outras condições",
    "CUSTOMIZE": "Customizar",
    "PROMPT_FOR_EVERYTHING": "Perguntar por tudo",
    "CUSTOMIZE_NODE": "Customizar nó",
    "SLOTS": "Slots",
    "MULTI_CONDITIONED_RESPONSES": "Múltiplas respostas condicionais",
    "DIGRESSIONS": "Digressões",
    "SLOTS_ENABLE_DESCRIPTION": "Habilite esta opção para coletar a informação que o assistente necessita para responder o usuário em um único nó.",
    "PROMPT_EVERYTHING_DESCRIPTION": "Habilite esta opção para perguntar por múltiplas informações em uma única pergunta, para que o seu usuário possa provê-las todas de uma vez.",
    "MULTI_RESPONSE_DESCRIPTION": "Habilite múltiplas respostas para que seu assistante possa prover diferentes respostas para uma mesma entrada, baseado em outras condições.",
    "DEFAULT": "Padrão",
    "PROMPT_AGAIN": "Perguntar novamente",
    "SKIP_CURRENT_SLOT": "Pular slot atual",
    "SKIP_TO_RESPONSE": "Pular para a resposta",
    "CLEAR_SLOT_AND_PROMPT_AGAIN": "Limpar slot e perguntar novamente",
    "MOVE_ON": "Continuar",
    "ENABLE_THIS_SLOT_IF" : "Habilitar slot se",
    "CONFIGURE_SLOT" : "Configurar slot",
    "CHECK_FOR" : "Checar por",
    "SAVE_IT_AS" : "Salvar como",
    "IF_NOT_PRESENT_ASK" : "Se ausente, perguntar",
    "THEN_CHECK_FOR": "Então checar",
    "CONFIGURE_RESPONSE": "Configurar resposta",
    "EVALUATE_CONDITION": "Avaliar condição",
    "EVALUATE_RESPONSES": "Avaliar resposta",
    "SKIP_USER_INPUT": "Pular entrada do usuário",
    "ADMIN_DESCRIPTION": "Administradores podem executar qualquer ação sobre o recurso, inclusive apagá-lo, ou conceder novas permissões.",
    "AUDITOR_DESCRIPTION": "Auditores podem, além das atribuições de Editor, gerar e visualizar reportes de performance e uso do recurso.",
    "EDITOR_DESCRIPTION": "Editores podem editar detalhes do recurso, bem como criar, editar e apagar recursos dependentes dele.",
    "VIEWER_DESCRIPTION": "Leitores podem apenas visualizar o recurso.",
    "NO_ROLE_DESCRIPTION": "Selecione um papel para ver suas atribuições.",
    "MEANING_OF_ROLES": "O que significa esse papel?",
    "MAPPING_EXAMPLES": "Mapeando exemplos",
    "INTO_INTENT": "Para a intenção",
    "INTENT_NAME": "Nome da intenção",
    "INTENT_NOT_FOUND": "Intenção não encontrada",
    "EXPERIMENT": "Experimento",
    "CREATE EXPERIMENT": "Criar Experimento",
    "INTENT_TO_KEEP": "Intenção a ser mantida",
    "ALL": "Todos",
    "CORRECT": "Correto",
    "INCORRECT": "Incorreto",
    "NONE": "Nenhum",
    "FEEDBACK_NOT_FOUND": "Feedback não encontrado",
    "EXAMPLE ALREADY EXISTS IN THIS DATASET": "Exemplo já existe neste conjunto de treinamento",
    "DISCOVERY_COLLECTION_ID": "ID de Coleção do Discovery",
    "DISCOVERY_PROJECT_ID": "ID do Projeto do Discovery",
    "TRAINING_DATA_ID": "ID do conjunto de treinamento",
    "HISTORY_DISAMBIGUATION_FLOW": "Fluxo de histórico de desambiguação",
    "ASSISTANTSKILL": "Skill de Assistant",
    "ASSISTANTINSTANCE": "Instância de Assistant",
    "NO_EVENTS_FOR_RESOURCE_TYPE": "Nenhum evento encontrado para esta combinação de recurso e subtipo de recurso.",
    "NO_EVENTS_FOR_RESOURCE": "Nenhum evento encontrado para este recurso.",
    "GROUP_BY": "Agrupar resultados por",
    "IN_DATE_RANGE": "No período de",
    "EVENTS_OF_TYPE": "Limitados a eventos do tipo",
    "AUDIT_INSTRUCTIONS": "Selecione os parâmetros da sua busca. O texto abaixo refletirá o seu significado.",
    "SEARCH_EVENTS_RELATED_TO": "Buscar eventos relativos a",
    "INPUT": "Entrada",
    "CONVERSATION_ID": "ID da conversa",
    "MESSAGE": "Mensagem",
    "GENERATE": "Gerar",
    "CONFIG": "Configuração",
    "ACTIVE": "Ativo",
    "DISAMBIGUATION_OR_PROCFLOW_INTENT": "(Intenção de disambiguação ou procflow)",
    "NO_AUDIT_FACTS_FOUND": "Desculpe! Nenhum evento de auditoria encontrado para esta busca.",
    "WELCOME": "Bem vindo(a)",
    "LOGOUT": "Sair",
    "TRAIN_SIZE": "Tamanho do conjunto de treino",
    "THEN_SET_CONTEXT": "Então colocar contexto",
    "UPLOAD_INSTRUCTIONS": "Instruções de upload",
    "UPLOAD_INSTRUCTIONS_0": "A planilha é composta de quatro folhas: \"Classes\" (intenções), \"Examples\" (exemplos), \"Entities\" (entidades) e \"Config\" (configurações). Verifique o formato baixando um template no link abaixo.",
    "UPLOAD_INSTRUCTIONS_1": "As folhas são identificadas pelo nome, e as colunas pela ordem. Portanto, não altere essas informações. A ausência de uma folha não impacta no processo.",
    "UPLOAD_INSTRUCTIONS_2": "Consulte as abas seguintes para informações específicas sobre o formato de cada planilha.",
    "INTENTS_UPLOAD_INSTRUCTIONS_0": "A folha de intenções deve conter as seguintes colunas:",
    "INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_0": "Um nome descritivo.",
    "INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_1": "A pergunta canônica.",
    "INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_2": "A resposta. Para classes tipo normal ou desambiguação, deve ser um array de strings. Outros tipos de classe devem ser tipo JSON, mas sua edição direta não é suportada.",
    "INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_3": "Se a classe é fora de contexto. Deve ser 0 ou 1.",
    "INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_4": "Se a classe é fora de treinamento. Deve ser 0 ou 1.",
    "INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_5": "Se a classe é apenas resposta. Deve ser 0 ou 1.",
    "INTENTS_UPLOAD_INSTRUCTIONS_COLUMN_6": "Tipo da classe. Deve ser 0 (normal), 1 (desambiguação) ou 2 (procflow)",
    "EXAMPLES_UPLOAD_INSTRUCTIONS_0": "A folha de exemplos deve conter as seguintes colunas:",
    "EXAMPLES_UPLOAD_INSTRUCTIONS_COLUMN_0": "O exemplo a ser cadastrado.",
    "EXAMPLES_UPLOAD_INSTRUCTIONS_COLUMN_1": "O nome da classe à qual o exemplo pertence",
    "ENTITIES_UPLOAD_INSTRUCTIONS_0": "A folha de entidades deve conter as seguintes colunas:",
    "ENTITIES_UPLOAD_INSTRUCTIONS_COLUMN_0": "O nome da entidade.",
    "ENTITIES_UPLOAD_INSTRUCTIONS_COLUMN_1": "O nome do valor.",
    "ENTITIES_UPLOAD_INSTRUCTIONS_COLUMN_2": "Os sinônimos do valor. Deve ser um array de strings.",
    "ENTITIES_UPLOAD_INSTRUCTIONS_COLUMN_3": "O tipo do valor (Sinônimos ou Padrões).",
    "CONFIG_UPLOAD_INSTRUCTIONS_0": "A folha de configurações deve conter as seguintes colunas:",
    "CONFIG_UPLOAD_INSTRUCTIONS_COLUMN_0": "Texto a ser apresentado no início da conversa.",
    "CONFIG_UPLOAD_INSTRUCTIONS_COLUMN_1": "Texto a ser apresentado caso o assistente não entenda a pergunta.",
    "CONFIG_UPLOAD_INSTRUCTIONS_COLUMN_2": "O tipo de variação das respostas. Deve ser \"sequential\" (sequencial) ou \"random\" (aleatório).",
    "CONFIG_UPLOAD_INSTRUCTIONS_COLUMN_3": "Número máximo de perguntas de desambiguação que o assistente fará antes de dizer que não entendeu a pergunta.",
    "CANCEL": "Cancelar",
    "SAVE": "Salvar",
    "CLOSE": "Fechar",
    "AT_ROW": "na linha",
    "ERROR_UPLOADING_FILE": "Erro no upload do arquivo",
    "TOP_INTENTS": "Intenções mais prováveis",
    "MERGE_LEFT": "Agregar à esquerda",
    "MERGE_RIGHT": "Agregar à direita",
    "SERIAL": "ID",
    "ID_MERGED": "ID da intenção agregada",
    "ID_KEEPED": "ID da intenção mantida",
    "EXAMPLE_KEEPED": "Exemplo excluído",
    "TOTAL_SUGGESTED_EXAMPLES": "Total de exemplos indicados",
    "TOTAL_DELETED_EXAMPLES": "Total de exemplos excluídos",
    "PROVIDED PARAMETER 'NAME' IS INVALID": "Parâmetro fornecido 'nome' é inválido",
    "REQUIRED PARAMETER 'NAME' IS MISSING": "Parâmetro obrigatório 'nome' faltando",
    "PROVIDED PARAMETER 'OWNER' IS INVALID": "Parâmetro fornecido 'gestor' é inválido",
    "REQUIRED PARAMETER 'OWNER' IS MISSING": "Parâmetro obrigatório 'gestor' faltando",
    "PROVIDED PARAMETER 'USERNAME' IS INVALID": "Parâmetro fornecido 'nome de usuário' é inválido",
    "REQUIRED PARAMETER 'USERNAME' IS MISSING": "Parâmetro obrigatório 'nome de usuário' faltando",
    "PROVIDED PARAMETER 'PASSWORD' IS INVALID": "Parâmetro fornecido 'senha' é inválido",
    "REQUIRED PARAMETER 'PASSWORD' IS MISSING": "Parâmetro obrigatório 'senha' faltando",
    "PROVIDED PARAMETER 'NEW_PASSWORD' IS INVALID": "Parâmetro fornecido 'nova senha' é inválido",
    "REQUIRED PARAMETER 'NEW_PASSWORD' IS MISSING": "Parâmetro obrigatório 'nova senha' faltando",
    "PROVIDED PARAMETER 'URL' IS INVALID": "Parâmetro fornecido 'url' é inválido",
    "REQUIRED PARAMETER 'URL' IS MISSING": "Parâmetro obrigatório 'url' faltando",
    "ASSISTANT INSTANCE IS NOT VALID, CHECK YOUR CREDENTIALS": "Instância de Assistant inválida, verifique suas credenciais",
    "DISCOVERY INSTANCE IS NOT VALID, CHECK YOUR CREDENTIALS": "Instância de Discovery inválida, verifique suas credenciais",
    "PROVIDED PARAMETER 'ENVIRONMENT_ID' IS INVALID": "Parâmetro fornecido 'environment ID' é inválido",
    "REQUIRED PARAMETER 'ENVIRONMENT_ID' IS MISSING": "Parâmetro obrigatório 'environment ID' faltando",
    "PROVIDED PARAMETER 'LANGUAGE' IS INVALID": "Parâmetro fornecido 'idioma' é inválido",
    "REQUIRED PARAMETER 'LANGUAGE' IS MISSING": "Parâmetro obrigatório 'idioma' faltando",
    "PROVIDED PARAMETER 'KNOWLEDGE_BASE_ID' IS INVALID": "Parâmetro fornecido 'base de conhecimento' é inválido",
    "REQUIRED PARAMETER 'KNOWLEDGE_BASE_ID' IS MISSING": "Parâmetro obrigatório 'base de conhecimento' faltando",
    "PROVIDED PARAMETER 'DATASET_ID' IS INVALID": "Parâmetro fornecido 'conjunto de treinamento' é inválido",
    "REQUIRED PARAMETER 'DATASET_ID' IS MISSING": "Parâmetro obrigatório 'conjunto de treinamento' faltando",
    "PROVIDED PARAMETER 'WORKSPACE_ID' IS INVALID": "Parâmetro fornecido 'workspace ID' é inválido",
    "REQUIRED PARAMETER 'WORKSPACE_ID' IS MISSING": "Parâmetro obrigatório 'workspace ID' faltando",
    "PROVIDED PARAMETER 'CANONICAL' IS INVALID": "Parâmetro fornecido 'pergunta canônica' é inválido",
    "REQUIRED PARAMETER 'CANONICAL' IS MISSING": "Parâmetro obrigatório 'pergunta canônica' faltando",
    "PROVIDED PARAMETER 'CONTENT' IS INVALID": "Parâmetro fornecido 'conteúdo' é inválido",
    "REQUIRED PARAMETER 'CONTENT' IS MISSING": "Parâmetro obrigatório 'conteúdo' faltando",
    "PROVIDED PARAMETER 'OUT_OF_TRAINING' IS INVALID": "Parâmetro fornecido 'fora de treinamento' é inválido",
    "REQUIRED PARAMETER 'OUT_OF_TRAINING' IS MISSING": "Parâmetro obrigatório 'fora de treinamento' faltando",
    "PROVIDED PARAMETER 'OUT_OF_CONTEXT' IS INVALID": "Parâmetro fornecido 'fora de contexto' é inválido",
    "REQUIRED PARAMETER 'OUT_OF_CONTEXT' IS MISSING": "Parâmetro obrigatório 'fora de contexto' faltando",
    "PROVIDED PARAMETER 'FINAL' IS INVALID": "Parâmetro fornecido 'final' é inválido",
    "REQUIRED PARAMETER 'FINAL' IS MISSING": "Parâmetro obrigatório 'final' faltando",
    "PROVIDED PARAMETER 'TYPE' IS INVALID": "Parâmetro fornecido 'tipo' é inválido",
    "REQUIRED PARAMETER 'TYPE' IS MISSING": "Parâmetro obrigatório 'tipo' faltando",
    "PROVIDED PARAMETER 'INTENT' IS INVALID": "Parâmetro fornecido 'intenção' é inválido",
    "REQUIRED PARAMETER 'INTENT' IS MISSING": "Parâmetro obrigatório 'intenção' faltando",
    "PROVIDED PARAMETER 'SYNONYMS' IS INVALID": "Parâmetro fornecido 'sinônimos' é inválido",
    "REQUIRED PARAMETER 'SYNONYMS' IS MISSING": "Parâmetro obrigatório 'sinônimos' faltando",
    "PROVIDED PARAMETER 'TEXT' IS INVALID": "Parâmetro fornecido 'texto' é inválido",
    "REQUIRED PARAMETER 'TEXT' IS MISSING": "Parâmetro obrigatório 'texto' faltando",
    "PROVIDED PARAMETER 'ASSISTANT_INSTANCE_ID' IS INVALID": "Parâmetro fornecido 'instância de Assistant' é inválido",
    "REQUIRED PARAMETER 'ASSISTANT_INSTANCE_ID' IS MISSING": "Parâmetro obrigatório 'instância de Assistant' faltando",
    "PROVIDED PARAMETER 'USER_ID' IS INVALID": "Parâmetro fornecido 'usuário' é inválido",
    "REQUIRED PARAMETER 'USER_ID' IS MISSING": "Parâmetro obrigatório 'usuário' faltando",
    "PROVIDED PARAMETER 'ROLE' IS INVALID": "Parâmetro fornecido 'papel' é inválido",
    "REQUIRED PARAMETER 'ROLE' IS MISSING": "Parâmetro obrigatório 'papel' faltando",
    "PROVIDED PARAMETER 'CREATED_AFTER' IS INVALID": "Parâmetro fornecido 'criado desde' é inválido",
    "REQUIRED PARAMETER 'CREATED_AFTER' IS MISSING": "Parâmetro obrigatório 'criado desde' faltando",
    "PROVIDED PARAMETER 'CREATED_BEFORE' IS INVALID": "Parâmetro fornecido 'criado até' é inválido",
    "REQUIRED PARAMETER 'CREATED_BEFORE' IS MISSING": "Parâmetro obrigatório 'criado até' faltando",
    "PROVIDED PARAMETER 'RESOURCE_ID' IS INVALID": "Parâmetro fornecido 'ID de recurso' é inválido",
    "REQUIRED PARAMETER 'RESOURCE_ID' IS MISSING": "Parâmetro obrigatório 'ID de recurso' faltando",
    "PROVIDED PARAMETER 'WELCOME_TEXT' IS INVALID": "Parâmetro fornecido 'texto de boas vindas' é inválido",
    "REQUIRED PARAMETER 'WELCOME_TEXT' IS MISSING": "Parâmetro obrigatório 'texto de boas vindas' faltando",
    "PROVIDED PARAMETER 'ANYTHING_ELSE_TEXT' IS INVALID": "Parâmetro fornecido 'texto de intenção não capturada' é inválido",
    "REQUIRED PARAMETER 'ANYTHING_ELSE_TEXT' IS MISSING": "Parâmetro obrigatório 'texto de intenção não capturada' faltando",
    "PROVIDED PARAMETER 'CONTEXT_VARIABLES' IS INVALID": "Parâmetro fornecido 'variáveis de contexto' é inválido",
    "PROVIDED PARAMETER 'OUTPUT_VARIABLES' IS INVALID": "Parâmetro fornecido 'variáveis de saída' é inválido",
    "PROVIDED PARAMETER 'METADATA' IS INVALID": "Parâmetro fornecido 'metadata' é inválido",
    "PROVIDED PARAMETER 'RESPONSE_VARIATION' IS INVALID": "Parâmetro fornecido 'variação de respostas' é inválido",
    "PROVIDED PARAMETER 'DISAMBIGUATION_QUESTION_LIMIT' IS INVALID": "Parâmetro fornecido 'limite de perguntas de desambiguação' é inválido",
    "PROVIDED PARAMETER 'VALUE' IS INVALID": "Parâmetro fornecido 'valor' é inválido",
    "PROVIDED PARAMETER 'ENTITY' IS INVALID": "Parâmetro fornecido 'entidade' é inválido",
    "INVALID_SYNONYMS_DETECTED": "Sinônimo inválido detectado",
    "INVALID PATTERNS DETECTED": "Padrão inválido detectado",
    "PATTERN TYPE LIMIT EXCEDED": "Tipo Padrão não pode ter mais do que 5 sinônimos",
    "UNAUTHORIZED": "Não autorizado",
    "FORBIDDEN. YOU DO NOT HAVE THE REQUIRED PRIVILEGES FOR THIS ACTION": "Proibido. Você não tem os privilégios necessários para executar esta ação",
    "FORBIDDEN. YOU ARE NOT AN ADMIN": "Proibido. Você não é um administrador",
    "FORBIDDEN. YOU ARE NOT AN AUDITOR": "Proibido. Você não é um auditor",
    "MOVE_ENTITY": "Mover entidade",
    "COPY_ENTITY": "Copiar entidade",
    "ENTITY_TRANSFER": "Transferência de entidades",
    "SYSTEM": "Sistema",
    "CREATIONS": "Criações",
    "DELETIONS": "Deleções",
    "EDITIONS": "Edições",
    "MAPPINGS": "Mapeamentos",
    "REMAPPINGS": "Remapeamentos",
    "INCOMING_DISTRIBUTION": "Distribuição incidente",
    "LATEST_EXPERIMENT_RESULTS": "Últimos resultados de experimento",
    "CLEAR": "Limpar",
    "SERVICES": "Serviços",
    "OVERVIEW": "Informações gerais",
    "MERGE": "Agregar",
    "MERGE INTENT": "Agregar Intenção",
    "COUNT": "Contagem",
    "PAIRWISE_CLASS_ERRORS": "Erros por par de classes",
    "AUDIT_FACTS": "Fatos de auditoria",
    "SUBJECT": "Sujeito",
    "ENTITY_VALUE": "Valor de entidade",
    "EXAMPLE": "Exemplo",
    "EXAMPLE_HISTORY_DISAMBIGUATION": "Exemplo - Opções selecionadas no fluxo",
    "REVIEW_EXAMPLE": "Exemplo para revisão",
    "REVIEWEXAMPLE": "Exemplo para revisão",
    "TITLE": "Título",
    "TYPE": "Tipo",
    "TYPE_MERGED": "Tipo da intenção agregada",
    "TYPE_KEEPED": "Tipo da intenção mantida",
    "INTENT_ID": "ID de Intenção",
    "ENTITY": "Entidade",
    "ENTITY_VALUE_NAME": "Nome do valor",
    "ENTITY_VALUE_NAME_AFTER": "Nome do valor depois",
    "UPDATE ENTITY_VALUE": "Editar Valor de entidade",
    "SYNONYMS": "Sinônimos",
    "PATTERNS": "Padrões",
    "PATTERNS_TOOLTIP": "Padrões são complexos e podem impactar o retreino. Utilize padrões validados no Watson Assistant",
    "SYNONYMS_AFTER": "Sinônimos depois",
    "FUZZY_MATCH": "Fuzzy match",
    "PARENT_ID": "ID do roteador",
    "AUTHOR": "Autor",
    "PT": "Português",
    "PT-BR": "Português (Brasil)",
    "EN": "Inglês",
    "EN-US": "Inglês (EUA)",
    "ES": "Espanhol",
    "FR": "Francês",
    "RESTORE": "Restaurar",
    "NEW_SNAPSHOTS": "Novo snapshot",
    "SNAPSHOTS": "Snapshots",
    "API_USAGE": "Uso da API",
    "SEND": "Enviar",
    "ACCEPT": "Aceitar",
    "ACCURACY": "Acurácia",
    "ACCURACY_VS_CLASS_SIZE": "Acurácia VS tamanho da classe",
    "ADD_CHILD": "Adicionar filho",
    "ADD_CHILD_NODE": "Adicionar nó filho",
    "ADD_FILE": "Adicionar arquivo",
    "ADD_FOLDER": "Adicionar pasta",
    "ADD_NODE": "Adicionar nó",
    "ADD_NODE_ABOVE": "Adicionar nó acima",
    "ADD_NODE_BELOW": "Adicionar nó abaixo",
    "ADD_RESPONSE": "Adicionar resposta",
    "ADD_VARIABLE": "Adicionar variável",
    "ADD_FLAG": "Adicionar flag",
    "METADATA": "Metadados",
    "ADMIN": "Admin",
    "ADVANCED_SEARCH": "Busca avançada",
    "ALL_EXAMPLES_REVIEW_MAPPING": "Todos os exemplos revisão/mapeamento",
    "THEN_ASSISTANT_SHOULD": "Então o assistente deve",
    "ANSWER": "Resposta",
    "ANSWER_MERGED": "Resposta da intenção agregada",
    "ANSWER_KEEPED": "Resposta da intenção mantida",
    "ANSWER_UNITS": "Unidades de resposta",
    "ANYTHING_ELSE_TEXT": "Texto de intenção não capturada",
    "API_KEYS": "Chaves de API",
    "APIKEY": "Chave de API",
    "APIKEYS": "Chaves de API",
    "APP_TITLE": "Q&A Manager 2.0",
    "ARE_YOU_SURE": "Você tem certeza?",
    "ASSISTANT": "Assistant",
    "TEMPLATE": "Template",
    "LOAD_TEMPLATE": "Carregar Template",
    "ASSISTANT_INSTANCE": "Instância do Assistant",
    "ASSISTANT_INSTANCE_PERMISSIONS": "Permissões de instância do Assistant",
    "ASSISTANT_INSTANCES": "Instâncias do Assistant",
    "ASSISTANT_INSTANCES_DESCRIPTION": "Associe instâncias de serviço do Watson Assistant para responder perguntas via diálogo.",
    "ASSISTANT_SKILL": "Skill do Assistant",
    "ASSISTANT_SKILL_PERMISSIONS": "Permissões de skill do Assistant",
    "ASSISTANT_SKILLS": "Skills do Assistant",
    "ASSISTANT_MISSING_NODES_MSG": "O nó {dialog_node} salta para o nó inexistente {jump_dialog_node}",
    "AUDIT": "Auditoria",
    "AUDIT_DESCRIPTION": "Ver ações realizadas no serviço.",
    "AUDITOR": "Auditor",
    "AUTO_RETRAIN": "Retreino automático",
    "BOUND_WORKSPACE": "Workspace atrelado",
    "CANONICAL": "Pergunta canônica",
    "CANONICAL_MERGED": "Pergunta canônica da intenção agregada",
    "CANONICAL_KEEPED": "Pergunta canônica da intenção mantida",
    "OWNER": "Gestor",
    "CHANNEL": "Canal",
    "INPUT_CHANNEL": "Canal de entrada",
    "CHANNELS": "Canais",
    "CLASS": "Classe",
    "CLASS_DISTRIBUTION": "Distribuição de classes",
    "CLASS_SIZE": "Tamanho da classe",
    "CLASSES": "Classes",
    "COMPLETE": "Completo",
    "COMPLETE_RETRAIN": "Retreino completo",
    "CONDITION": "Condição",
    "CONFIDENCE": "Confiança",
    "CONFIDENCE_VS_CLASS_SIZE": "Confiança VS tamanho da classe",
    "CONFIRM_PASSWORD": "Por favor, confirme sua senha anterior",
    "CONNECT_TO_HUMAN_AGENT": "Conectar com agente humano",
    "CONTENT": "Conteúdo",
    "CONTEXT_SET": "Contexto configurado",
    "CONVERSATION_CHAT": "Conversation Chat",
    "CORPUS": "Corpus",
    "CREATE": "Criar",
    "CREATED_AFTER": "Criado desde",
    "CREATED_AT": "Criado em",
    "CREATED_BEFORE": "Criado até",
    "DATASET": "Conjunto de treinamento",
    "DATASET_MERGED": "Conjunto de treinamento da intenção agregada",
    "DATASET_KEEPED": "Conjunto de treinamento da intenção mantida",
    "DATASET_PERMISSIONS": "Permissões de conjunto de treinamento",
    "DATASETS": "Conjuntos de treinamento",
    "DATASETS_DESCRIPTION": "Configure as respostas diretas do seu chatbot.",
    "DEINDEX": "Desindexar",
    "DELETE": "Apagar",
    "DELETE EXPERIMENT": "Apagar Experimento",
    "DELETE ENTITY_VALUE": "Apagar Valor de entidade",
    "DELETE EXAMPLE": "Apagar Exemplo",
    "DIALOG_CONFIGS": "Configurações de diálogo",
    "DISAMBIGUATION": "Desambiguação",
    "DISAMBIGUATION_QUESTION_LIMIT": "Limite de perguntas de desambiguação",
    "DISCOVERY_COLLECTION": "Coleção do Discovery",
    "DISCOVERY_COLLECTION_PERMISSIONS": "Permissões de coleção do Discovery",
    "DISCOVERY_COLLECTIONS": "Coleções do Discovery",
    "DISCOVERY_PROJECT": "Projeto do Discovery",
    "DISCOVERY_PROJECT_PERMISSIONS": "Permissões de projeto do Discovery",
    "DISCOVERY_PROJECTS": "Projetos do Discovery",
    "DISCOVERY_INSTANCE": "Instância do Discovery",
    "DISCOVERY_INSTANCE_PERMISSIONS": "Permissões de instância do Discovery",
    "DISCOVERY_INSTANCES": "Instâncias do Discovery",
    "DISCOVERY_INSTANCES_DESCRIPTION": "Associe instâncias de serviço do Watson Discovery para agregar busca de documentos ao seu chatbot.",
    "CUSTOM": "Customizado",
    "DOCUMENT_RETRIEVAL": "Recuperação de documentos",
    "CONVERSATIONAL_SEARCH": "Busca conversacional",
    "DISTRIBUTION": "Distribuição",
    "DOCUMENTS": "Documentos",
    "DOCUMENTS_DESCRIPTION": "Indexe documentos para busca usando linguagem natural.",
    "DOES_NOT_RETURN": "Não retorna",
    "DOWNLOAD": "Download",
    "DOWNLOAD_CLEAN": "Download (Limpo)",
    "DUPLICATE": "Duplicar",
    "EDIT": "Editar",
    "EDITOR": "Editor",
    "ENTER_EXTERNAL_NODE_NAME": "Insira o nome externo do nó",
    "ENTER_RESPONSE_TEXT": "Insira o texto de resposta",
    "ENTITIES": "Entidades",
    "ENTITY_VALUES": "Valores de entidade",
    "ENVIRONMENT_ID": "Environment ID",
    "ERROR_CREATING_RESOURCE": "Erro ao associar recurso",
    "ERROR_DELETING_RESOURCE": "Erro ao apagar recurso",
    "ERROR_LOADING_RESOURCES": "Erro ao carregar recursos",
    "EXAMPLES": "Exemplos",
    "EXAMPLES_CLASS_DISTRIBUTION": "Exemplos e distribuição de classes",
    "EXPERIMENTS": "Experimentos",
    "EXPORT": "Exportar",
    "EXPORT_CLEAN": "Exportar (limpo)",
    "EXPORT_JSON": "Exportar JSON",
    "FEEDBACK": "Feedback",
    "FINAL": "Final",
    "FINAL_MERGED": "Final da intenção agregada",
    "FINAL_KEEPED": "Final da intenção mantida",
    "FINISHED_AT": "Terminado em",
    "GO_TO": "Ir para",
    "HITS": "Acertos",
    "HIT_INDEX": "Índice de acertos",
    "HOME": "Início",
    "HTML": "HTML",
    "IF_ASSISTANT_NEEDS_TO_REPRESENT_NODE_USE": "Se o assistente virtual precisar representar o nó para usuários, usar",
    "IF_ASSISTANT_RECOGNIZES": "Se o assistente reconhecer",
    "IMAGE": "Imagem",
    "INCORRECT_PASSWORD": "Senha incorreta",
    "INDEX": "Indexar",
    "INDEXED_AT": "Indexado em",
    "INSTANCE": "Instância",
    "INTENT": "Intenção",
    "INTENTS": "Intenções",
    "INVITE": "Convidar",
    "JSON_EDITOR": "Editor de JSON",
    "JUMP_TO": "Saltar para",
    "KEY": "Chave",
    "KNOWLEDGE_BASE": "Base de conhecimento",
    "KNOWLEDGE_BASE_PERMISSIONS": "Permissões de base de conhecimento",
    "KNOWLEDGE_BASES": "Bases de conhecimento",
    "LANGUAGE": "Idioma",
    "LOGIN": "Login",
    "LOGIN_ATTEMPTS": "Tentativas de login",
    "LOGIN_FAILED": "Login falhou. Usuário ou senha estão incorretos.",
    "MAP_TO_SELECTED": "Mapear para a classe selecionada",
    "MAPPING": "Mapeamento",
    "MERGE_SELECTED_CLASSES": "Agregar classes selecionadas",
    "MERGER": "Agregador",
    "MISSES": "Erros",
    "MOVE": "Mover",
    "NAME": "Nome",
    "DESCRPTION": "Descrição",
    "NEW_ANSWER_UNITS": "Nova unidade de resposta",
    "NEW_APIKEYS": "Nova Chave de API",
    "NEW_ASSISTANT_INSTANCES": "Nova instância de Assistant",
    "NEW_ASSISTANT_SKILLS": "Nova skill do Assistant",
    "NEW_CHANNELS": "Novo canal",
    "NEW_DATASETS": "Novo conjunto de treinamento",
    "NEW_DISCOVERY_COLLECTIONS": "Nova coleção do Discovery",
    "NEW_DISCOVERY_PROJECTS": "Novo projeto do Discovery",
    "NEW_DISCOVERY_INSTANCES": "Nova instância do Discovery",
    "NEW_DOCUMENTS": "Novo documento",
    "NEW_ENTITIES": "Nova entidade",
    "NEW_ENTITY_VALUES": "Novo valor de entidade",
    "NEW_EXAMPLES": "Novo exemplo",
    "NEW_EXPERIMENTS": "Novo experimento",
    "NEW_INTENTS": "Nova intenção",
    "NEW_KNOWLEDGE_BASES": "Nova base de conhecimento",
    "NEW_NAME": "Novo nome",
    "NEW_ORCHESTRATORS": "Novo orquestrador",
    "NEW_PASSWORD": "Nova senha",
    "NEW_PERMISSIONS": "Nova Permissão",
    "NEW_REPORTS": "Novo relatório",
    "NEW_REVIEW_EXAMPLES": "Novo exemplo para mapeamento",
    "NEW_USERS": "Novo Usuário",
    "NO": "Não",
    "NO_CONDITION_SET": "Nenhuma condição configurada",
    "NORMAL": "Normal",
    "NUMBER_OF_CLASSES": "Número de classes",
    "NUMBER_OF_EXAMPLES": "Número de exemplos",
    "OPTION": "Opção",
    "OPTIONS": "Opções",
    "ORCHESTRATOR": "Orquestrador",
    "ORCHESTRATOR_PERMISSIONS": "Permissões de orquestrador",
    "ORCHESTRATORS": "Orquestradores",
    "ORCHESTRATORS_DESCRIPTION": "Junte habilidades de diálogo e busca para construir um chatbot.",
    "OUT_OF_CONTEXT": "Fora de contexto",
    "OUT_OF_CONTEXT_MERGED": "Fora de contexto da intenção agregada",
    "OUT_OF_CONTEXT_KEEPED": "Fora de contexto da intenção mantida",
    "OUT_OF_TRAINING": "Fora de treinamento",
    "OUT_OF_TRAINING_MERGED": "Fora de treinamento da intenção agregada",
    "OUT_OF_TRAINING_KEEPED": "Fora de treinamento da intenção mantida",
    "OUTPUT_VARIABLES": "Variáveis de saída",
    "OUTPUT_VARIABLES_MERGED": "Variáveis de saída da intenção agregada",
    "OUTPUT_VARIABLES_KEEPED": "Variáveis de saída da intenção mantida",
    "CONTEXT_VARIABLES": "Variáveis de contexto",
    "CONTEXT_VARIABLES_MERGED": "Variáveis de contexto da intenção agregada",
    "CONTEXT_VARIABLES_KEEPED": "Variáveis de contexto da intenção mantida",
    "PAGE_NOT_FOUND": "Página não encontrada",
    "PARENT": "Pai",
    "PASSWORD": "Senha",
    "PAUSE": "Pausa",
    "PERMISSIONS": "Permissões",
    "PIVOT_DIMENSION": "Dimensão pivô",
    "PRECISION": "Precisão",
    "PRECISION_AT_K": "Precisão em K",
    "PREDICTED_INTENT": "Intenção predita",
    "PREDICTED_INTENT_NAME": "Nome da intenção predita",
    "PREDICTIONS": "Predições",
    "PROCFLOW": "Fluxo de procedimento",
    "QUERY": "Consulta",
    "RANDOM": "Randômica",
    "REJECT": "Rejeitar",
    "RELATED_CLASSES": "Classes relacionadas",
    "REMAP": "Remapear",
    "MAP": "Mapear",
    "REMAP_EXAMPLES": "Remapear perguntas",
    "REMOVE_CHILD": "Remover filho",
    "REMOVE_RESPONSE": "Remover resposta",
    "REPLICATION": "Replicador",
    "REPLICATOR": "Replicador",
    "REPORT": "Relatório",
    "RESOURCE_SUBTYPE": "Subtipo de recurso",
    "RESOURCE_TYPE": "Tipo de recurso",
    "RESPONSE": "Resposta",
    "RESPONSE_VARIATION": "Variação de respostas",
    "RESPONSES": "Respostas",
    "RETRAIN": "Retreinar",
    "RETRAIN ASSISTANT_SKILL": "Retreinar Skill do Assistant",
    "FEEDBACK ORCHESTRATOR": "Feedback Orquestrador",
    "FEEDBACK_TYPE": "Tipo de feedback",
    "REVIEW": "Revisão",
    "REVIEW_EXAMPLES": "Todos os exemplos para revisão e mapeamento",
    "ROLE": "Papel",
    "ROUTER": "Roteador",
    "SEARCH": "Buscar",
    "SEARCH_SKILL": "Skill de busca",
    "SEARCHERS": "Buscadores",
    "SEQUENTIAL": "Sequencial",
    "SIGN_IN": "Por favor, conecte-se",
    "SIMPLE_RETRAIN": "Retreino simples",
    "SIMPLIFIED_EDITOR": "Editor simplificado",
    "SOURCE_DATASET": "Conjunto de treinamento fonte",
    "STATUS": "Status",
    "SUBMIT": "Enviar",
    "SUCCESS_CREATING_RESOURCE": "Recurso associado com sucesso",
    "SUCCESS_DELETING_RESOURCE": "Recurso apagado com sucesso",
    "TARGET_DATASET": "Conjunto de treinamento de destino",
    "TARGET_FACT": "Fato alvo",
    "TEST": "Testar",
    "TEXT": "Texto",
    "SKILL_RETRAINED_EVENT_MESSAGE": "Skill {name} foi atualizado com status {status}",
    "ASSISTANT_RESPONDS": "Assistente responde",
    "TRAINED_AT": "Treinado em",
    "TRAINING_DATA": "Conjuntos de Treinamento",
    "TRUE_INTENT": "Intenção correta",
    "TRUE_INTENT_NAME": "Nome da intenção correta",
    "UNBIND": "Desvincular",
    "UPDATE": "Editar",
    "UPDATED_AFTER": "Atualizado desde",
    "UPDATED_AT": "Atualizado em",
    "UPDATED_BEFORE": "Atualizado até",
    "UPLOAD": "Enviar",
    "URL": "URL",
    "USER": "Usuário",
    "USER_IS_BLOCKED": "Usuário bloqueado",
    "USER_NOT_FOUND": "Usuário não encontrado",
    "USERNAME": "Nome de usuário",
    "USERS": "Usuários",
    "USERS_DESCRIPTION": "Administre usuários do Q&A Manager.",
    "VALUE": "Valor",
    "VARIABLE": "Variável",
    "VIEW": "Ver",
    "VIEWER": "Leitor",
    "WAIT_FOR_USER_INPUT": "Esperar entrada do usuário",
    "WELCOME_TEXT": "Texto de boas vindas",
    "WORKSPACE_ID": "Workspace ID",
    "YES": "Sim",
    "FIXED": "Fixo",
    "NOT_FIXED": "Não fixo",
    "STANDARD": "Padrão",
    "RESPONSE_CONDITION": "Condicão de Resposta",
    "SLOT": "Slot",
    "EVENT_HANDLER": "Configurador de Eventos",
    "FRAME": "Frame",
    "UPLOAD_SUCCESS": "Upload finalizado com sucesso",
    "UPLOAD_WARNING": "Upload finalizado com erros encontrados",
    "UPLOADING": "Upload em andamento...",
    "UPLOADING_WARNING": "Upload em andamento com erros encontrados",
    "CONFIG SPREADSHEET COULDN'T BE LOADED": "Planilha de Configuração não pôde ser carregada",
    "CONFIG SPREADSHEET SHOULD HAVE 2 ROWS": "Planilha de Configuração deve ter 2 linhas",
    "CONFIG SPREADSHEET SHOULD HAVE 4 COLUMNS": "Planilha de Configuração deve ter 4 colunas",
    "CLASSES SPREADSHEET COULDN'T BE LOADED": "Planilha de Classes não pôde ser carregada",
    "CLASSES SPREADSHEET SHOULD HAVE AT LEAST 7 COLUMNS": "Planilha de Classes deve ter pelo menos 7 colunas",
    "EXAMPLES SPREADSHEET COULDN'T BE LOADED": "Planilha de Exemplos não pôde ser carregada",
    "EXAMPLES SPREADSHEET SHOULD HAVE AT LEAST 2 COLUMNS": "Planilha de Exemplos deve ter pelo menos 2 colunas",
    "ENTITIES SPREADSHEET COULDN'T BE LOADED": "Planilha de Entidades não pôde ser carregada",
    "ENTITIES SPREADSHEET SHOULD HAVE 3 COLUMNS": "Planilha de Entidades deve ter 3 colunas",
    "UPLOAD_DETAILS": "Detalhes do upload da planilha",
    "INVALID SYNONYMS DETECTED": "Sinônimos inválidos detectados",
    "DUPLICATE SYNONYMS DETECTED": "Sinônimos duplicados detectados",
    "EXAMPLE ALREADY EXISTS IN":  "Exemplo já existe em",
    "AND_FINALLY": "E finalmente",
    "EXAMPLE MAPPED OR DELETED. RELOAD THE PAGE.": "Exemplo mapeado ou apagado. Recarregue a página."
}
