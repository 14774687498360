import React, { Component } from 'react';
import * as d3 from 'd3'
import { Link } from 'carbon-components-react';

class BarGraph extends Component {

    state = {
        width: window.innerWidth / 2,
        height: window.innerHeight,
        order: null
    }

    render() {
        let self = this
        let data = this.props.data.sort((a, b) =>
            this.state.order === 'asc' ? Number(a.y) - Number(b.y) :
                this.state.order === 'desc' ? Number(b.y) - Number(a.y) : false
        )

        // what are these and are they things that someone should edit
        const margin = { top: 30, right: 20, bottom: 60, left: 65 };
        const width = d3.max([this.state.width - 65 - (margin.left + margin.right), 15 * data.length]);
        const height = 300 - (margin.top + margin.bottom);
        const labelOffset = 50;
        const axisOffset = 16;

        // Set the scales
        const x = d3.scaleBand()
            .rangeRound([0, width])
            .domain(data.map((d) => d.x))
            .padding(0);

        const y = d3.scaleLinear()
            .range([height, 0])
            .domain([0, d3.max(data, (d) => d.y * 1.1)]);

        // Set the axes
        d3.axisBottom()
            .scale(x)
            .tickSize(0)

        const yAxis = d3.axisLeft()
            .ticks(4)
            .tickSize(-width)
            .scale(y.nice());

        // Set up SVG with initial transform to avoid repeat positioning
        const svg = d3.select(`#${this.props.id}`)
            .attr('class', 'graph')
            .attr('width', width + (margin.left + margin.right))
            .attr('height', height + (margin.top + margin.bottom))
            .append('g')
            .attr('class', 'group-container')
            .attr('transform', `translate(${margin.left}, ${margin.top})`)
            .attr('font-family', 'ibm-plex-sans');

        // // Add Y axis
        svg.append('g')
            .attr('class', 'axis y')
            .attr('stroke-dasharray', '4')
            .call(yAxis)
            .selectAll('text')
            .attr("x", -axisOffset)
            .attr('font-family', 'ibm-plex-sans');

        // // Add Y axis label
        svg.select('.y')
            .append('text')
            .text(this.props.yLabel)
            .attr('class', 'label')
            .attr('transform', `translate(${-labelOffset}, ${height / 2}) rotate(-90)`)
            .attr('font-family', 'ibm-plex-sans');

        // // Add X axis
        svg.append('g')
            .attr('class', 'axis x')
            .attr('transform', `translate(0, ${height})`)
            // .call(xAxis)
            .selectAll('text')
            // .attr("y", axisOffset)
            .attr('font-family', 'ibm-plex-sans')

        // // Add X axis label
        svg.select('.x')
            .append('text')
            .text(this.props.xLabel)
            .attr('class', 'label')
            .attr('transform', `translate(${this.state.width / 2}, ${labelOffset})`)
            .attr('font-family', 'ibm-plex-sans');

        svg.append('g')
            .attr('class', 'bar-container')
            .selectAll('rect')
            .data(data)
            .enter().append('rect')
            .attr('class', 'bar')
            .attr('x', (d) => x(d.x))
            .attr('y', (d) => height)
            .attr('height', 0)
            .attr('width', x.bandwidth() / 1.2)
            .attr('fill', self.props.color)
            .transition()
            .duration(500)
            // .delay((d, i) => i * 50)
            .attr('height', (d) => height - y(d.y))
            .attr('y', (d) => y(d.y));

        // Select Tooltip
        const tooltip = d3.select(`.tooltip-${this.props.id}`);

        svg.selectAll('.bar')
            .on('mouseover', function (d) {
                let color = d3.color(self.props.color).darker()
                d3.select(this)
                    .attr('fill', color)
                tooltip
                    .style('display', 'inherit')
                    .text(`${d.x}: ${d.y}`)
                    .style('top', `${y(d.y) - axisOffset}px`);

                let bandwidth = x.bandwidth();
                let tooltipWidth = tooltip.nodes()[0].getBoundingClientRect().width;
                let offset = (tooltipWidth - bandwidth) / 2;

                tooltip
                    .style('left', `${x(d.x) + margin.left - offset}px`)
            })
            .on('mouseout', function (d) {
                d3.select(this)
                    .transition()
                    .duration(250)
                    .attr('fill', self.props.color)
                tooltip
                    .style('display', 'none')
            })

        return (
            <div class="graph-container">
                <Link
                    onClick={ev => this.setState({ order: this.state.order === 'desc' ? 'asc' : 'desc' }, () => this.updateDimensions())}
                    style={{ cursor: 'pointer' }}
                >
                    {window.translate("REORDER")}
                </Link>
                <svg id={this.props.id}></svg>
                <div class={`tooltip tooltip-${this.props.id}`}></div>
            </div >
        )
    }

    updateDimensions = () => {
        d3.select(`#${this.props.id}`).selectAll("*").remove()
        this.setState({ width: window.innerWidth, height: window.innerHeight })
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions)
        this.updateDimensions()
    }
    componentWillReceiveProps(props) {
        if (JSON.stringify(this.props.data) !== JSON.stringify(props.data))
            this.updateDimensions()
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }
}

BarGraph.defaultProps = {
    id: "bar-graph",
    data: [],
    color: '#00A78F'
}

export default BarGraph