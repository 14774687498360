module.exports = {
    "ADD": "Add",
    "CANCEL": "Cancel",
    "CONFIRM": "Confirm",
    "SUBMIT": "Submit",
    "EDIT": "Edit",
    "DELETE": "Delete",
    "ARE_YOU_SURE": "Are you sure?",
    "ERROR_LOADING_RESOURCES": "Error loading resources",
    "ERROR_CREATING_RESOURCE": "Error creating resource",
    "SUCCESS_CREATING_RESOURCE": "Resource created successfully",
    "ERROR_DELETING_RESOURCE": "Error creating resource",
    "SUCCESS_DELETING_RESOURCE": "Resource deleted successfully",
    "ADVANCED_SEARCH": "Advanced search",
    "SEARCH": "Search",
    "CLEAR": "Clear",
    "EXPORT": "Export",
}