import React, { Component } from 'react';
import { Button, Icon, Tile, TextInput, StructuredListWrapper, StructuredListHead, StructuredListRow, StructuredListCell, StructuredListBody } from 'carbon-components-react';
import { iconAddOutline, iconDelete, iconChevronUp, iconChevronDown, iconSettings } from 'carbon-icons'
import Modal from '../../../../../../components/modal';
import DialogNodeForm from './../index';
import ConditionEditor from '../../../condition-editor';
import NodeChildrenEditor from './node-children-editor';
import OutputEditor from './output-editor';


class SlotHandlerEditor extends Component {

    state = {
        selectedNode: null
    }

    modalRef = React.createRef()
    handlersModalRef = React.createRef()

    render() {
        let slotNodes = this.props.children.filter(node => node.type === 'slot')
        let genericHandlers = this.props.children.filter(node => node.type === 'event_handler' && node.event_name === "generic")
        let focusHandler = this.props.children.find(node => node.type === 'event_handler' && node.event_name === "focus")
        return (<>
            <Tile>
                <div>
                    <h3 style={{ float: "left" }}>{window.translate("THEN_CHECK_FOR")}:</h3>
                    <div style={{ float: "right" }}>
                        <div style={{ display: "inline-block", backgroundColor: "#047cc0", color: "white", borderRadius: "10rem" }}>{genericHandlers.length}</div>
                        <Button kind="secondary" style={{ border: 0 }} onClick={() => this.handlersModalRef.current.handleOpen()}>{window.translate("MANAGE_HANDLERS")}</Button>
                    </div>
                </div>
                <StructuredListWrapper style={{ marginBottom: 0 }}>
                    <StructuredListHead>
                        <StructuredListRow head>
                            <StructuredListCell head></StructuredListCell>
                            <StructuredListCell head>{window.translate("CHECK_FOR")}</StructuredListCell>
                            <StructuredListCell head>{window.translate("SAVE_IT_AS")}</StructuredListCell>
                            <StructuredListCell head>{window.translate("IF_NOT_PRESENT_ASK")}</StructuredListCell>
                            <StructuredListCell head></StructuredListCell>
                            <StructuredListCell head></StructuredListCell>
                        </StructuredListRow>
                    </StructuredListHead>
                    <StructuredListBody>
                        {slotNodes.map((child, index) => {
                            let inputHandler = this.props.dialogNodes.find(node => node.parent === child.dialog_node && node.event_name === 'input')
                            let focusHandler = this.props.dialogNodes.find(node => node.parent === child.dialog_node && node.event_name === 'focus')
                            if (!inputHandler || !focusHandler) return <></>
                            return <StructuredListRow>
                                <StructuredListCell style={{ textAlign: "center" }}>
                                    {<Button disabled={index === 0} kind="secondary" className="btn-icon-only" onClick={() => this.props.onMove(child, child.parent, slotNodes[index - 1].previous_sibling, "slot")}><Icon icon={iconChevronUp} /></Button>}
                                    <br />{index + 1}<br />
                                    {<Button disabled={index === slotNodes.length - 1} kind="secondary" className="btn-icon-only" onClick={() => this.props.onMove(child, child.parent, slotNodes[index + 1].dialog_node, "slot")}><Icon icon={iconChevronDown} /></Button>}
                                </StructuredListCell>
                                <StructuredListCell style={{ position: "static" }}>
                                    <br />
                                    <ConditionEditor
                                       
                                        value={inputHandler.conditions}
                                        onChange={conditions => {
                                            let varName = child.variable ? child.variable.replace(/^\$/, '') : null
                                            let inputHandlerChange = {
                                                conditions,
                                                context: {
                                                    ...inputHandler.context,
                                                    [varName]: inputHandler.context[varName] === inputHandler.conditions ? conditions : inputHandler.context[varName]
                                                }
                                            }
                                            this.props.onChange(inputHandler, inputHandlerChange)
                                            let match = conditions.match(/^@[0-9A-zÀ-ÿ-]{1,64}(:\([^\n\r\t]{0,63}\))?$/)
                                            if (!varName && match) {
                                                let value = match[0].slice(1).replace(/[(|)]/g, "").replace(/[ |:]/g, '_')
                                                this.props.onChange(child, { variable: `$${value}` });
                                                inputHandlerChange.context[value] = conditions
                                                setTimeout(() => this.props.onChange(inputHandler, inputHandlerChange), 0);
                                            }
                                        }}
                                        dataset_id={this.props.dataset_id} />

                                </StructuredListCell>
                                <StructuredListCell>
                                    <br /><TextInput onChange={ev => {
                                        let value = ev.target.value
                                        this.props.onChange(child, { variable: value || null });
                                        setTimeout(() => this.props.onChange(inputHandler, { context: { ...inputHandler.context, [child.variable.replace(/^\$/, '')]: undefined, [value.replace(/^\$/, '')]: inputHandler.conditions } }), 0);
                                    }}
                                        value={child.variable ? child.variable : ""}
                                        onKeyPress={ev => ev.key === 'Enter' ? ev.target.blur() : null}
                                    />
                                </StructuredListCell>
                                <StructuredListCell>
                                    <br />
                                    {focusHandler.output.generic && focusHandler.output.generic[0] ?
                                        <TextInput
                                            onChange={ev => this.props.onChange(focusHandler, { output: { ...focusHandler.output, generic: [{ response_type: "text", selection_policy: "sequential", values: [{ text: ev.target.value }] }] } })}
                                            value={focusHandler.output.generic[0].values[0] ? focusHandler.output.generic[0].values[0].text : ""}
                                            onKeyPress={ev => ev.key === 'Enter' ? ev.target.blur() : null}
                                        /> :
                                        <TextInput disabled value="" />
                                    }
                                </StructuredListCell>
                                <StructuredListCell>
                                    <br />
                                    <Button kind="secondary" className="btn-icon-only" onClick={() => { this.modalRef.current.handleOpen(); this.setState({ selectedNode: child, inputHandler, focusHandler }) }}><Icon icon={iconSettings} /></Button>
                                </StructuredListCell>
                                <StructuredListCell>
                                    <br />
                                    <Button kind="secondary" className="btn-icon-only" onClick={() => this.props.onDelete(child)}><Icon icon={iconDelete} /></Button>
                                </StructuredListCell>
                            </StructuredListRow>
                        })}
                    </StructuredListBody>
                </StructuredListWrapper>
                <Button kind="secondary" style={{ borderWidth: 0 }} onClick={() => {
                    let lastChild = slotNodes.slice(-1)[0]
                    let slotNode = this.props.onAdd(lastChild || this.props.selectedNode, lastChild ? "below" : "child", { type: "slot" })
                    setTimeout(() => this.props.onAdd(slotNode, "child", { type: "event_handler", event_name: "focus", parent: slotNode.dialog_node }), 10);
                    setTimeout(() => this.props.onAdd(slotNode, "child", { type: "event_handler", event_name: "input", parent: slotNode.dialog_node }), 20);
                }}>{window.translate("ADD_SLOT")}<Icon icon={iconAddOutline} /></Button>
            </Tile>
            {focusHandler && <>
                <h3>{window.translate("IF_NO_SLOTS_ARE_PREFILLED_ASK")}</h3>
                <OutputEditor outputOnly {...this.props} selectedNode={focusHandler} />
            </>}
            <Modal
                hideTrigger
                ref={this.modalRef}
                title={`${window.translate("CONFIGURE_SLOT")} ${slotNodes.map(n => n.dialog_node).indexOf((this.state.selectedNode || {}).dialog_node) + 1}`}
                handleSubmit={() => { ; this.modalRef.current.handleClose() }}
            >
                {this.state.selectedNode && <DialogNodeForm
                    {...this.props}
                    selectedNode={this.state.focusHandler}
                    onChange={(node, change) => {
                        if (node.dialog_node === this.state.focusHandler.dialog_node)
                            this.setState({ focusHandler: { ...node, ...change } }, () => this.props.onChange(this.state.focusHandler, this.state.focusHandler))
                        else
                            this.props.onChange(node, change)
                    }}
                />}
            </Modal>
            <Modal
                hideTrigger
                ref={this.handlersModalRef}
                title={`${window.translate("MANAGE_HANDLERS_FOR")} "${this.props.selectedNode.title}"`}
                handleSubmit={() => this.handlersModalRef.current.handleClose()}
            >
                <p>{window.translate("HANDLERS_DESCRIPTION_0")}</p>
                <p>{window.translate("HANDLERS_DESCRIPTION_1")}</p>
                <NodeChildrenEditor
                    {...this.props}
                    template={{ type: "event_handler", event_name: "generic" }}
                />
            </Modal>
        </>)
    }
}

export default SlotHandlerEditor