import React, { Component } from 'react';
import { Tile, Button, Loading, Toggle } from 'carbon-components-react';
import { withRouter } from 'react-router-dom';
import { v4 } from 'uuid'
import DialogNodeForm from './dialog-node-form';
import DialogNode from './dialog-node';
import { listAllIntents } from '../../../../services/corpus'
import { Form } from '../../../../components/carbon-react-crud';
import Modal from '../../../../components/modal';
import DialogToolbar from './dialog-toolbar';
import { listAllAssistantSkillDialogNodes } from '../../../../services/orchestrator';

class AssistantEditor extends Component {

    state = {
        selectedNode: null,
        sourceNode: null,
        contextEditorOn: true,
        jsonEditorOn: true,
        inferenceOpt: null,

        mouseX: 0,
        mouseY: 0,
        menuX: 0,
        menuY: 0,

        otherIntent: {}
    }

    modalRef = React.createRef()

    render() {
        if (!this.isValid()) return <></>
        return (<>
            {this.state.loading && <Loading />}
            <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 50 }}>
                    <Toggle
                        labelText={window.translate("INFERENCE_OPT")}
                        toggled={this.state.inferenceOpt}
                        onToggle={state => this.handleValueChange({ inferenceOpt: state })}
                        disabled={this.props.disabled}
                    />
                </div>
            </div>
            <br />
            <h5>{this.props.labelText}</h5>
            <br />
            <div style={{ display: "flex", transition: 'position .3s', ...(this.state.maximized ? this.maximizedStyle : {}) }}>
                <div style={{ minHeight: "500px", flex: "1 1" }}>
                    {!this.props.disabled && <DialogToolbar
                        dataset_id={this.props.dataset_id}
                        intent_id={this.props.intent_id}
                        intent_type={this.props.current.type}
                        selectedNode={this.state.selectedNode}
                        nodePickerOn={this.state.sourceNode}
                        onCancel={() => this.stopNodePicker()}
                        onChooseIntent={() => this.modalRef.current.handleOpen()}
                        onAddNode={this.handleAddNode.bind(this)}
                        onCopyFromWA={this.handleCopyFromWA.bind(this)}
                        onCopyFromTemplate={this.handleCopyFromTemplate.bind(this)}
                    />}
                    <br />
                    <img src="/images/watson.svg" alt=""></img> {this.props.title}
                    <br />
                    <br />
                    <DialogNode
                        dialogNodes={this.orderNodes(this.props.value)}
                        hasChildren={true}
                        onSelect={this.handleSelectNode.bind(this)}
                        onAdd={this.handleAddNode.bind(this)}
                        onDelete={this.handleDeleteNode.bind(this)}
                        onBind={this.startNodePicker.bind(this)}
                        onDuplicate={this.handleDuplicateNode.bind(this)}
                        onExpand={node => this.handleNodeEdition(node, { expanded: !node.expanded })}
                        selectedNode={this.state.selectedNode}
                        sourceNode={this.state.sourceNode}
                        nodePickerOn={this.props.disabled}
                        showVirtualNodes={this.props.showVirtualNodes}
                    />
                </div>
                <div style={{ position: "absolute", top: "10%", right: 0, width: "50%" }} >
                    {this.state.selectedNode && !this.state.sourceNode && <DialogNodeForm
                        onChange={this.handleNodeEdition.bind(this)}
                        onAdd={this.handleAddNode.bind(this)}
                        onDelete={this.handleDeleteNode.bind(this)}
                        onMove={this.handleMoveNode.bind(this)}
                        onBind={this.startNodePicker.bind(this)}
                        onClose={ev => this.setState({ selectedNode: null })}
                        onSelect={this.handleSelectNode.bind(this)}
                        dialogNodes={this.orderNodes(this.props.value)}
                        children={this.orderChildren(this.props.value, this.state.selectedNode)}
                        selectedNode={this.state.selectedNode}
                        dataset_id={this.props.dataset_id || this.props.match.params.dataset_id}
                    />}
                </div>
            </div>
            <Modal hideTrigger large ref={this.modalRef}>
                <h3>Selecione a intenção para a qual deseja saltar:</h3>
                <Form
                    fields={[{ key: "id", type: "recordpicker", label: window.translate("INTENT"), fetchOptions: (url, params) => listAllIntents({ ...params, siblings_of: this.props.dataset_id, limit: 10 }) }]}
                    onChange={intent => listAllIntents({ id: intent.id })
                        .then(data => this.setState({ otherIntent: data.rows[0] }))}
                />
                <br /><br />
                <DialogNode
                    dialogNodes={this.state.otherIntent.type <= 2 ? [{ ...EmptyNode(), title: window.translate("INTENT"), conditions: `#${this.state.otherIntent.name}`, dialog_node: this.state.otherIntent.id }] : this.state.otherIntent.content}
                    hasChildren
                    onSelect={this.handleSelectNode.bind(this)}
                    onAdd={this.handleAddNode.bind(this)}
                    onDelete={this.handleDeleteNode.bind(this)}
                    onBind={this.startNodePicker.bind(this)}
                    onExpand={node => this.handleNodeEdition(node, { expanded: !node.expanded })}
                />
            </Modal>
            {this.state.jumpOptions && <Tile style={{ zIndex: 2000, position: "fixed", left: this.state.menuX, top: this.state.menuY }}>
                <h5>Jump to and...</h5>
                <Button kind="secondary" className="btn-icon-only" onClick={() => this.handleSetJump(this.state.selectedNode, "user_input")} >Wait for user input</Button><br />
                <Button kind="secondary" className="btn-icon-only" onClick={() => this.handleSetJump(this.state.selectedNode, "condition")} >If assistant recognizes (condition)</Button><br />
                <Button kind="secondary" className="btn-icon-only" onClick={() => this.handleSetJump(this.state.selectedNode, "body")} >Respond</Button>
            </Tile>}
            {this.state.moveOptions && <Tile style={{ zIndex: 2000, position: "fixed", left: this.state.menuX, top: this.state.menuY }}>
                <h5>Move to...</h5>
                <Button kind="secondary" className="btn-icon-only" onClick={() => this.handleMoveNode(this.state.sourceNode, this.state.selectedNode.dialog_node, null)} >As child node</Button><br />
                <Button kind="secondary" className="btn-icon-only" onClick={() => this.handleMoveNode(this.state.sourceNode, this.state.selectedNode.parent, this.state.selectedNode.previous_sibling, "node")} >Above node</Button><br />
                <Button kind="secondary" className="btn-icon-only" onClick={() => this.handleMoveNode(this.state.sourceNode, this.state.selectedNode.parent, this.state.selectedNode.dialog_node, "node")} >Below node</Button>
            </Tile>}
        </>)
    }

    isValid() {
        return Array.isArray(this.props.value) && this.props.value.reduce((allNodes, node) => allNodes && node.dialog_node, true)
    }

    componentDidMount() {
        if (!this.isValid()) {
            this.handleChange([])
        }
        this.handleValueChange({ inferenceOpt: this.handleToggled(this.props.value) })
    }

    handleValueChange(value) {
        this.setState(value, () => this.handleChange(this.props.value))
    }

    handleToggled(nodes) {
        return !Array.isArray(nodes) ? true : (nodes.length === 0 || nodes.some((node, i, array) => { return node.inference_opt !== false }))
    }

    handleNodeEdition(node, change) {
        if (change.next_step && change.next_step.behavior === "jump_to" && (!node.next_step || change.next_step.behavior !== node.next_step.behavior)) return this.startNodePicker(node, "jump")
        let otherNodes = this.props.value.filter(otherNode => otherNode.dialog_node !== node.dialog_node)
        let newNode = { ...node, ...change }
        let newNodes = [newNode, ...otherNodes]
        this.handleChange(newNodes)
        if (this.state.selectedNode && node.dialog_node === this.state.selectedNode.dialog_node) this.setState({ selectedNode: newNode })
    }

    startNodePicker(sourceNode, reason) {
        this.setState({ selectedNode: sourceNode, nodePickerReason: reason, sourceNode: sourceNode })
    }

    stopNodePicker() {
        this.setState({ selectedNode: null, sourceNode: null, jumpOptions: false, moveOptions: false })
    }

    handleSelectNode(targetNode, ev) {
        if (this.state.sourceNode) {
            switch (this.state.nodePickerReason) {
                case "move": this.setState({ moveOptions: true, menuX: ev.clientX, menuY: ev.clientY, selectedNode: targetNode }); break;
                case "jump": this.setState({ jumpOptions: true, menuX: ev.clientX, menuY: ev.clientY, selectedNode: targetNode }); break;
                default: break;
            }
        }
        else
            this.setState({ selectedNode: null }, () => this.setState({ selectedNode: targetNode }))
    }

    handleAddNode(dialogNode, option, template = {}) {
        let [parent, previousSibling] =
            option === "child" ? [dialogNode.dialog_node, null] :
                option === "above" ? [dialogNode.parent, dialogNode.previous_sibling] :
                    option === "below" ? [dialogNode.parent, dialogNode.dialog_node] : []
        let newNodeID = v4()
        let newNode = { ...EmptyNode(), dialog_node: newNodeID, previous_sibling: previousSibling, parent: parent, ...template }
        let replacedNode = this.props.value.find(node => node.parent == parent && node.previous_sibling == previousSibling)
        let otherNodes = this.props.value.filter(node => node.dialog_node !== (replacedNode || {}).dialog_node)
        let newNodes = [
            newNode,
            ...(replacedNode ? [{ ...replacedNode, previous_sibling: newNode.dialog_node }] : []),
            ...otherNodes
        ]
        let parentNode = newNodes.find(node => node.dialog_node === parent)
        if (parentNode) parentNode.expanded = true
        this.handleChange(newNodes)
        return newNode
    }

    handleDeleteNode(dialogNode) {
        let replaceeNode = this.props.value.find(node => node.parent === dialogNode.parent && node.previous_sibling === dialogNode.dialog_node)
        let otherNodes = this.props.value.filter(node => ![(replaceeNode || {}).dialog_node, dialogNode.dialog_node].includes(node.dialog_node))
        let newNodes = [
            ...(replaceeNode ? [{ ...replaceeNode, previous_sibling: dialogNode.previous_sibling, parent: dialogNode.parent }] : []),
            ...otherNodes
        ]
        let filteredNodes = newNodes.reduce((nodes) => {
            return nodes.filter(node => !node.parent || nodes.map(n => n.dialog_node).includes(node.parent))
        }, newNodes)
        if (this.state.selectedNode && dialogNode.dialog_node === this.state.selectedNode.dialog_node)
            this.setState({ selectedNode: null }, this.handleChange(filteredNodes))
        else
            this.handleChange(filteredNodes)
    }

    handleMoveNode(dialogNode, parent, previousSibling, componentType = "none") {
        if (this.state.sourceNode) this.stopNodePicker()
        if (this.isDescendant(this.state.selectedNode, dialogNode.dialog_node)) return

        let oldNextSibling = this.props.value.find(node => node.previous_sibling === dialogNode.dialog_node)
        let newNextSibling = this.props.value.find(node => node.parent === parent && node.previous_sibling === previousSibling)
        let oldLastSibling = this.orderNodes(this.props.value, parent).reduce((acc, node) => node.parent === parent ? node : acc, {})

        if (newNextSibling && oldNextSibling && newNextSibling.dialog_node === oldNextSibling.dialog_node) return
        if (oldNextSibling) oldNextSibling.previous_sibling = dialogNode.previous_sibling
        if (newNextSibling) newNextSibling.previous_sibling = dialogNode.dialog_node

        dialogNode.parent = parent

        if (componentType !== "none") dialogNode.previous_sibling = previousSibling
        else dialogNode.previous_sibling = oldLastSibling !== {} ? oldLastSibling.dialog_node : previousSibling

        let otherNodes = this.props.value.filter(node => ![dialogNode.dialog_node, (oldNextSibling || {}).dialog_node, (newNextSibling || {}).dialog_node].includes(node.dialog_node))
        let newNodes = [dialogNode, oldNextSibling, newNextSibling, ...otherNodes].filter(n => n)
        this.handleChange(newNodes)
    }

    handleSetJump(dialogNode, selector) {
        let otherNodes = this.props.value.filter(node => this.state.sourceNode.dialog_node !== node.dialog_node)
        let newNode = {
            ...this.state.sourceNode,
            next_step: { behavior: "jump_to", dialog_node: dialogNode.dialog_node, selector },
        }
        let newNodes = [newNode, ...otherNodes]
        this.handleChange(newNodes)
        this.modalRef.current.handleClose()
        this.stopNodePicker()
    }

    async handleDuplicateNode(dialogNode) {
        let nodesToDupe = this.getNodesBelow(this.props.value, dialogNode)
        // Scramble node IDs before including
        for (let node of nodesToDupe)
            nodesToDupe = JSON.parse(JSON.stringify(nodesToDupe).replace(new RegExp(node.dialog_node, 'g'), v4()))
        // Set title, parent and previous sibling of copy
        nodesToDupe[0].title = dialogNode.title + ' Copy'
        nodesToDupe[0].parent = dialogNode.parent
        nodesToDupe[0].previous_sibling = dialogNode.dialog_node
        // Set next sibling's (if any) previous sibling
        let nextSibling = this.props.value.find(node => node.previous_sibling === dialogNode.dialog_node)
        if (nextSibling) nextSibling.previous_sibling = nodesToDupe[0].dialog_node
        this.handleChange([...this.props.value, ...nodesToDupe])
    }

    isDescendant(dialogNode, parent) {
        if (!dialogNode.parent) return false
        if (dialogNode.parent === parent) return true
        let nextNode = this.props.value.find(otherNode => otherNode.dialog_node === dialogNode.parent)
        return this.isDescendant(nextNode, parent)
    }

    // Gets nodes under a given dialog node
    getNodesBelow(nodes, dialogNode) {
        // Add the node itself, and apply recursively to children
        let children = nodes.filter(n => n.parent === dialogNode.dialog_node)
        return [
            dialogNode,
            ...children.reduce((acc, child) => [...acc, ...this.getNodesBelow(nodes, child)], [])
        ]
    }

    orderChildren(children, parent) {
        return children
            .filter(node => node.parent === (parent ? parent.dialog_node : undefined))
            .reduce((orderedChildren, _1, _2, allChildren) => {
                if (orderedChildren.length === 0) return [allChildren.find(child => !child.previous_sibling)]
                let nextChild = allChildren.find(child => child.previous_sibling === orderedChildren.slice(-1)[0].dialog_node)
                if (!nextChild) return orderedChildren
                return [...orderedChildren, nextChild]
            }, [])
    }

    orderNodes(nodes, parent, current = []) {
        let children = this.orderChildren(nodes, parent)
        let childrensChildren = children.map(node => [node, ...this.orderNodes(nodes, node, current)])
        let result = childrensChildren.reduce((acc, children) => [...acc, ...children], current)
        return result
    }

    handleChange(nodes) {
        nodes = nodes.map(node => {
            let children = nodes.filter(n => n.parent === node.dialog_node)
            let newNode = {
                ...node,
                inference_opt: this.state.inferenceOpt,
                output: node.output && node.output.text ? {
                    ...node.output,
                    generic: [{
                        ...node.output.text,
                        values: (typeof node.output.text == 'string' ? [node.output.text] : (Array.isArray(node.output.text) ? node.output.text : node.output.text.values)).map(text => ({ text })),
                        response_type: "text"
                    }],
                    text: undefined
                } : node.output,
                digress_in: undefined,
                digress_out: (node.next_step && node.next_step.behavior !== 'get_user_input') || children.length === 0 ? undefined : node.digress_out,
                digress_out_slots: node.type === 'frame' ? node.digress_out_slots : undefined
            }
            if (newNode.inference_opt === false) {
                if (newNode.context) {
                    newNode.context.inference = undefined
                    newNode.context._eval = newNode.context._eval ? newNode.context._eval.filter(condition => !condition.includes("inference")) : undefined
                }
                if (newNode.output) newNode.output._eval = newNode.output._eval ? newNode.output._eval.filter(condition => !condition.includes("inference")) : undefined
            }
            return newNode
        })
        this.props.onChange(nodes)
    }

    async handleCopyFromWA(assistantSkillID) {
        this.setState({ loading: true })
        let nodes = await listAllAssistantSkillDialogNodes(assistantSkillID, { page_limit: 999999999 })
            .then(data => data.rows)
        let topNode = nodes.find(n => n.dialog_node === this.props.intent_id)
        this.setState({ loading: false })
        if (!topNode) return
        let intentNodes = this.getNodesBelow(nodes, topNode)
        if (intentNodes.length === 0) return
        let oldID = topNode.dialog_node
        let newID = v4()
        topNode.dialog_node = newID
        topNode.conditions = "true"
        delete topNode.parent
        delete topNode.previous_sibling
        intentNodes = JSON.parse(JSON.stringify(intentNodes).replace(new RegExp(oldID, 'g'), newID))
        intentNodes = JSON.parse(JSON.stringify(intentNodes).replace(new RegExp(`@${this.props.match.params.dataset_id.slice(0, 5)}_`, 'g'), "@"))
        if (topNode.title.startsWith('[A]')) intentNodes = intentNodes.slice(1).map(n => ({
            ...n,
            parent: n.parent === topNode.dialog_node ? undefined : n.parent,
            previous_sibling: n.previous_sibling === topNode.dialog_node ? undefined : n.previous_sibling,
        }))
        this.handleChange(intentNodes)
    }

    async handleCopyFromTemplate(intentID) {
        this.setState({ loading: true })
        let templateNodes = await listAllIntents({id: intentID})
        this.setState({ loading: false })
        if (templateNodes.count === 0) return
        let idDict = templateNodes.rows[0].content.reduce((acc, node) => ({ ...acc, [node.dialog_node]: v4() }), {})
        let newNodes = templateNodes.rows[0].content.map(node => ({
            ...node,
            dialog_node: idDict[node.dialog_node],
            parent: node.parent ? idDict[node.parent] : undefined,
            previous_sibling: node.previous_sibling ? idDict[node.previous_sibling] : undefined
        }))
        this.handleValueChange({ inferenceOpt: newNodes[0].inference_opt })
        this.handleChange(newNodes)
    }
}

function EmptyNode() {
    return {
        type: "standard",
        expanded: true,
        conditions: "",
        context: {},
        output: { generic: [{ response_type: "text", values: [], selection_policy: "sequential" }] },
    }
}

AssistantEditor.defaultProps = {
    value: []
}

export default withRouter(AssistantEditor)