import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import {
    Header, HeaderName, HeaderNavigation, HeaderMenuItem, HeaderMenu, HeaderGlobalBar,
} from 'carbon-components-react/lib/components/UIShell';
import { logout } from '../services/auth'
import { listAllAssistantSkills } from '../services/orchestrator'

class Navbar extends Component {

    state = {
        indexOpen: null
    }
    timer = null
    poolingTime = 60000

    render() {
        return (
            <Header>
                <HeaderName href={process.env.REACT_APP_PATH || '/'} prefix="">{window.translate("APP_TITLE")}</HeaderName>
                {this.props.user && <>
                    <HeaderNavigation>
                        <HeaderMenu aria-label={window.translate("SERVICES")}>
                            <HeaderMenuItem onClick={ev => this.props.history.push("/assistant_instances")}>{window.translate("ASSISTANT_INSTANCES")}</HeaderMenuItem>
                            <HeaderMenuItem onClick={ev => this.props.history.push("/discovery_instances")}>{window.translate("DISCOVERY_INSTANCES")}</HeaderMenuItem>
                        </HeaderMenu>
                        <HeaderMenu aria-label={window.translate("DATASETS")}>
                            <HeaderMenuItem onClick={ev => this.props.history.push("/datasets")}>{window.translate("DATASETS")}</HeaderMenuItem>
                            <HeaderMenuItem onClick={ev => this.props.history.push("/knowledge_bases")}>{window.translate("KNOWLEDGE_BASES")}</HeaderMenuItem>
                        </HeaderMenu>
                        <HeaderMenuItem onClick={ev => this.props.history.push("/orchestrators")}>{window.translate("ORCHESTRATORS")}</HeaderMenuItem>
                        {this.props.user.auditor ? <HeaderMenuItem onClick={ev => this.props.history.push("/audit_facts")}>{window.translate("AUDIT")}</HeaderMenuItem> : <></>}
                    </HeaderNavigation>
                    <HeaderGlobalBar>
                        <HeaderMenu aria-label={`${window.translate("WELCOME")}, ${this.props.user.name.split(' ')[0]}!`}>
                            <HeaderMenuItem onClick={ev => this.props.history.push("/users")}>{window.translate("USERS")}</HeaderMenuItem>
                            <HeaderMenuItem onClick={ev => logout().then(() => window.location.replace(process.env.REACT_APP_PATH || '/'))}>{window.translate("LOGOUT")}</HeaderMenuItem>
                        </HeaderMenu>
                    </HeaderGlobalBar>
                </>}
            </Header>
        )
    }

    componentDidMount() {
        this.getRetrainingAlerts()
        this.timer = setTimeout(this.pooling, this.poolingTime)
    }

    componentWillUnmount() {
        this.timer = null 
        this.pooling = null 
    }

    pooling = () => {
        this.getRetrainingAlerts()
        setTimeout(this.pooling, this.poolingTime);
    }

    getAlertMessage(skill) {
        return window.translate("SKILL_RETRAINED_EVENT_MESSAGE").replace('{name}', skill.name).replace('{status}', window.translate(skill.status))   
    }

    getRetrainingAlerts() {
        const oldSkills = sessionStorage.getItem('skills') ? JSON.parse(sessionStorage.getItem('skills')) : []
        if (this.props.user && oldSkills.length > 0) {
            listAllAssistantSkills().then((data) => {
                let skills = data.rows.map(s => ({ name: s.name, id: s.id, status: s.status }))
                let newFinished = this.checkSkillsRetraining(skills)
                if (newFinished.length > 0)
                    newFinished.forEach((finished) => { 
                        setTimeout(window.alert(this.getAlertMessage(finished)), 5000)
                    })
            })
        }
    }

    checkSkillsRetraining(skills) {
        let newFinished = []
        const oldSkills = sessionStorage.getItem('skills') ? JSON.parse(sessionStorage.getItem('skills')) : []
        if (oldSkills.length > 0) {
            oldSkills.forEach((skill, i) => { 
                let filtered = skills.filter(s => skill.id === s.id && skill.status !== s.status)
                if (filtered.length > 0) {
                    newFinished.push(filtered.pop())
                    oldSkills.splice(i, 1)
                }
            })
            sessionStorage.setItem('skills', JSON.stringify(oldSkills));
        }
        return newFinished
    }

    getAllRetrainingAlerts() {
        if (this.props.user) {
            listAllAssistantSkills().then((data) => {
                let skills = data.rows.map(s => ({ name: s.name, id: s.id, status: s.status }))
                let newFinished = this.checkAllSkillsRetraining(skills)
                if (newFinished.length > 0)
                    newFinished.forEach((finished) => { 
                        setTimeout(alert(this.getAlertMessage(finished)), 5000)
                    })
            })
        }
    }

    checkAllSkillsRetraining(skills) {
        let newFinished = []
        const oldSkills = sessionStorage.getItem('skills') ? JSON.parse(sessionStorage.getItem('skills')) : []
        if (oldSkills.length > 0) {
            skills.forEach((skill) => { 
                let filtered = oldSkills.filter(s => skill.id === s.id && skill.status !== s.status)
                if (filtered.length > 0)
                    newFinished.push(skill)
            })
        }
        sessionStorage.setItem('skills', JSON.stringify(skills));
        return newFinished
    }
}

export default withRouter(Navbar)