module.exports = {
    "MOVE_ENTITY": "",
    "COPY_ENTITY": "",
    "ENTITY_TRANSFER": "",
    "CREATIONS": "",
    "DELETIONS": "",
    "EDITIONS": "",
    "MAPPINGS": "",
    "REMAPPINGS": "",
    "INCOMING_DISTRIBUTION": "",
    "LATEST_EXPERIMENT_RESULTS": "",
    "OVERVIEW": "Overview",
    "MERGE": "",
    "COUNT": "",
    "PAIRWISE_CLASS_ERRORS": "",
    "AUDIT_FACTS": "Hechos de auditoria",
    "SUBJECT": "Sujêt",
    "ENTITY_VALUE": "Valor de entidad",
    "EXAMPLE": "Ejemplo",
    "REVIEW_EXAMPLE": "Ejemplo para revisión",
    "REVIEWEXAMPLE": "Ejemplo para revisión",
    "TYPE": "Tipo",
    "INTENT_ID": "ID de intención",
    "ENTITY": "Entidad",
    "SYNONYMS": "Sinónimos",
    "FUZZY_MATCH": "Fuzzy match",
    "PARENT_ID": "ID de padre",
    "AUTHOR": "Autor",
    "PT": "Portuguais",
    "PT-BR": "Portuguais (Brésil)",
    "EN": "Anglais",
    "EN-US": "Anglais (EUA)",
    "ES": "Espagnol",
    "FR": "Français",
    "RESTORE": "Restaurer",
    "NEW_SNAPSHOTS": "Neuf snapshot",
    "SNAPSHOTS": "Snapshots",
    "API_USAGE": "Usage de l'API",
    "SEND": "Envoyer",
    "ACCEPT": "Acepter",
    "ACCURACY": "Exactitude",
    "ACCURACY_VS_CLASS_SIZE": "Exactitude VS taille de la classe",
    "ADD_CHILD": "Ajouter un enfant",
    "ADD_CHILD_NODE": "Ajouter un noed enfant",
    "ADD_FILE": "Ajouter un fichier",
    "ADD_FOLDER": "Ajouter un dossier",
    "ADD_NODE": "Ajouter un noed",
    "ADD_NODE_ABOVE": "Ajouter un noed ci-dessus",
    "ADD_NODE_BELOW": "Ajouter un noed ci-dessous",
    "ADD_RESPONSE": "Ajouter une réponse",
    "ADD_VARIABLE": "Ajouter une variable",
    "ADMIN": "Admin",
    "ADVANCED_SEARCH": "Recherche avancée",
    "ALL_EXAMPLES_REVIEW_MAPPING": "Tous les exemples révision/mapping",
    "THEN_ASSISTANT_SHOULD": "Et enfin",
    "ANSWER": "Réponse",
    "ANSWER_UNITS": "Unités de réponse",
    "ANYTHING_ELSE_TEXT": "Texte d'intencion pas capturé",
    "API_KEYS": "Clés d'API",
    "APIKEY": "Clé d'API",
    "APIKEYS": "Clés d'API",
    "APP_TITLE": "Q&A Manager 2.0",
    "ARE_YOU_SURE": "Êtes-vous sûr?",
    "ASSISTANT": "Assistant",
    "ASSISTANT_INSTANCE": "Instance d'Assistant",
    "ASSISTANT_INSTANCE_PERMISSIONS": "Permissions d'instance d'Assistant",
    "ASSISTANT_INSTANCES": "Instances d'Assistant",
    "ASSISTANT_INSTANCES_DESCRIPTION": "Liez les instances de service de Watson Assistant pour répondre aux questions via dialogue.",
    "ASSISTANT_SKILL": "Skill d'Assistant",
    "ASSISTANT_SKILL_PERMISSIONS": "Permissions de skill d'Assistant",
    "ASSISTANT_SKILLS": "Skills d'Assistant",
    "AUDIT": "Audit",
    "AUDIT_DESCRIPTION": "Voir actions effectuées dans le service.",
    "AUDITOR": "Auditeur",
    "AUTO_RETRAIN": "Entraînement automatique",
    "BOUND_WORKSPACE": "Workspace lié",
    "CANONICAL": "Question canonique",
    "CHANNEL": "Canal",
    "CHANNELS": "Canaux",
    "CLASS": "Classe",
    "CLASS_DISTRIBUTION": "Distribution de classes",
    "CLASS_SIZE": "Taille de la classe",
    "CLASSES": "Classes",
    "COMPLETE": "Complet",
    "COMPLETE_RETRAIN": "Entraînement complet",
    "CONDITION": "Condition",
    "CONFIDENCE": "Confiance",
    "CONFIDENCE_VS_CLASS_SIZE": "Confiance VS taille de la classe",
    "CONFIRM_PASSWORD": "S'il vous plaît confirmer votre mot de passe",
    "CONNECT_TO_HUMAN_AGENT": "Se connecter à un agent humain",
    "CONTENT": "Contenu",
    "CONTEXT_SET": "Contexte configuré",
    "CONVERSATION_CHAT": "Conversation Chat",
    "CORPUS": "Corpus",
    "CREATE": "Créer",
    "CREATED_AFTER": "Crée depuis",
    "CREATED_AT": "Crée à",
    "CREATED_BEFORE": "Crée après",
    "DATASET": "Base de données",
    "DATASET_PERMISSIONS": "Permissions de base de données",
    "DATASETS": "Bases de données",
    "DATASETS_DESCRIPTION": "Configurez les réponses directes de ton chatbot.",
    "DEINDEX": "Désindexer",
    "DELETE": "Effacer",
    "DIALOG_CONFIGS": "Configuration de dialogue",
    "DISAMBIGUATION": "Désambiguïsation",
    "DISAMBIGUATION_QUESTION_LIMIT": "Limite de questions de désambiguïsation",
    "DISCOVERY_COLLECTION": "Collection de Discovery",
    "DISCOVERY_COLLECTION_PERMISSIONS": "Permissions de collection de Discovery",
    "DISCOVERY_COLLECTIONS": "Collections de Discovery",
    "DISCOVERY_INSTANCE": "Instance de Discovery",
    "DISCOVERY_INSTANCE_PERMISSIONS": "Permissions d'instance de Discovery",
    "DISCOVERY_INSTANCES": "Instances de Discovery",
    "DISCOVERY_INSTANCES_DESCRIPTION": "Liez les instances du service Watson Discovery pour insuffler à votre chatbot récupération de documents.",
    "DISTRIBUTION": "Distribution",
    "DOCUMENTS": "Documents",
    "DOCUMENTS_DESCRIPTION": "Indexez les documents pour la recherche en langage naturel.",
    "DOES_NOT_RETURN": "Ne régresser pas",
    "DOWNLOAD": "Télecharger",
    "DOWNLOAD_CLEAN": "Descarga (Limpia)",
    "DUPLICATE": "Duplicar",
    "EDIT": "Editar",
    "EDITOR": "Editor",
    "ENTER_EXTERNAL_NODE_NAME": "Ingresa el nombre externo del noed",
    "ENTER_RESPONSE_TEXT": "Ingresa el texto de réponse",
    "ENTITIES": "Entidades",
    "ENTITY_VALUES": "Valores de entidad",
    "ENVIRONMENT_ID": "Environment ID",
    "ERROR_CREATING_RESOURCE": "Error al agregar recurso",
    "ERROR_DELETING_RESOURCE": "Error al borrar recurso",
    "ERROR_LOADING_RESOURCES": "Error al cargar recursos",
    "EXAMPLES": "Ejemplos",
    "EXAMPLES_CLASS_DISTRIBUTION": "Ejemplos e distribuición de classes",
    "EXPERIMENTS": "Experimentos",
    "EXPORT": "Exportar",
    "EXPORT_CLEAN": "Exportar (limpio)",
    "EXPORT_JSON": "Exportar JSON",
    "FEEDBACK": "Feedback",
    "FINAL": "Final",
    "FINISHED_AT": "Terminado en",
    "GO_TO": "Ir para",
    "HITS": "Acertos",
    "HOME": "Inicio",
    "HTML": "HTML",
    "IF_ASSISTANT_NEEDS_TO_REPRESENT_NODE_USE": "Caso el asistente virtual necesite representar el noed para usuarios, usar",
    "IF_ASSISTANT_RECOGNIZES": "Caso el asistente reconozca",
    "IMAGE": "Imagen",
    "INCORRECT_PASSWORD": "Contraseña incorrecta",
    "INDEX": "Indexar",
    "INDEXED_AT": "Indexado en",
    "INSTANCE": "Instance",
    "INTENT": "Intención",
    "INTENTS": "Intenciones",
    "INVITE": "Invitar",
    "JSON_EDITOR": "Editor de JSON",
    "JUMP_TO": "Saltar para",
    "KEY": "Clé",
    "KNOWLEDGE_BASE": "Base de conocimiento",
    "KNOWLEDGE_BASE_PERMISSIONS": "Permissions de base de conocimiento",
    "KNOWLEDGE_BASES": "Bases de conocimiento",
    "LANGUAGE": "Idioma",
    "LOGIN": "Login",
    "LOGIN_ATTEMPTS": "Intentos de login",
    "LOGIN_FAILED": "Login falló. Usuario o contraseña están incorrectos.",
    "MAP_TO_SELECTED": "Mapear para la classe seleccionada",
    "MAPPING": "Mapeo",
    "MERGE_SELECTED_CLASSES": "Agregar classes seleccionadas",
    "MERGER": "Agregador",
    "MISSES": "Errores",
    "MOVE": "Mover",
    "NAME": "Nombre",
    "NEW_ANSWER_UNITS": "Nueva unidad de réponse",
    "NEW_APIKEYS": "Nueva llave d'API",
    "NEW_ASSISTANT_INSTANCES": "Nueva instance d'Assistant",
    "NEW_ASSISTANT_SKILLS": "Nuevo skill d'Assistant",
    "NEW_CHANNELS": "Nuevo canal",
    "NEW_DATASETS": "Nuevo base de données",
    "NEW_DISCOVERY_COLLECTIONS": "Nueva collection de Discovery",
    "NEW_DISCOVERY_INSTANCE": "Nueva instance de Discovery",
    "NEW_DOCUMENTS": "Nuevo documento",
    "NEW_ENTITIES": "Nueva entidad",
    "NEW_ENTITY_VALUES": "Nuevo valor de entidad",
    "NEW_EXAMPLES": "Nuevo ejemplo",
    "NEW_EXPERIMENTS": "Nuevo experimento",
    "NEW_INTENTS": "Nueva intención",
    "NEW_KNOWLEDGE_BASES": "Nueva base de conocimiento",
    "NEW_NAME": "Nuevo nombre",
    "NEW_ORCHESTRATORS": "Nuevo orquestrador",
    "NEW_PASSWORD": "Nueva contraseña",
    "NEW_PERMISSIONS": "Nuevo permiso",
    "NEW_REPORTS": "Nuevo reporte",
    "NEW_REVIEW_EXAMPLES": "Nuevo ejemplo para mapeo",
    "NEW_USERS": "Nuevo usuario",
    "NO": "No",
    "NO_CONDITION_SET": "Ninguna condición configurada",
    "NORMAL": "Normal",
    "NUMBER_OF_CLASSES": "Número de classes",
    "NUMBER_OF_EXAMPLES": "Número de ejemplos",
    "OPTION": "Opción",
    "OPTIONS": "Opciones",
    "ORCHESTRATOR": "Orquestrador",
    "ORCHESTRATOR_PERMISSIONS": "Permissions de orquestrador",
    "ORCHESTRATORS": "Orquestradores",
    "ORCHESTRATORS_DESCRIPTION": "Unir habilidades de diálogo y búsqueda para construir un chatbot.",
    "OUT_OF_CONTEXT": "Fuera de contexto",
    "OUT_OF_TRAINING": "Fuera de treinamento",
    "OUTPUT_VARIABLES": "Variables de saída",
    "PAGE_NOT_FOUND": "Página no encontrada",
    "PARENT": "Padre",
    "PASSWORD": "Contraseña",
    "PAUSE": "Pausa",
    "PERMISSIONS": "Permissions",
    "PIVOT_DIMENSION": "Dimensión pivote",
    "PRECISION": "Precisión",
    "PRECISION_AT_K": "Precisión en K",
    "PREDICTED_INTENT": "Intención predicha",
    "PREDICTIONS": "Prediciones",
    "PROCFLOW": "Procflow",
    "QUERY": "Consulta",
    "RANDOM": "Aleatório",
    "REJECT": "Rechazar",
    "RELATED_CLASSES": "Classes relacionadas",
    "REMAP": "Remapear",
    "REMAP_EXAMPLES": "Remapear questions",
    "REMOVE_CHILD": "Remover hijo",
    "REMOVE_RESPONSE": "Remover réponse",
    "REPLICATION": "Replicador",
    "REPLICATOR": "Replicador",
    "REPORT": "Reporte",
    "RESOURCE_SUBTYPE": "Subtipo de recurso",
    "RESOURCE_TYPE": "Tipo de recurso",
    "RESPONSE": "Réponse",
    "RESPONSE_VARIATION": "Variación de réponses",
    "RESPONSES": "Réponses",
    "RETRAIN": "Entrenar",
    "REVIEW": "Revisión",
    "REVIEW_EXAMPLES": "Ejemplos para révision",
    "ROLE": "Papel",
    "ROUTER": "Roteador",
    "SEARCH": "Buscar",
    "SEARCH_SKILL": "Skill de búsqueda",
    "SEARCHERS": "Buscadores",
    "SEQUENTIAL": "Sequencial",
    "SIGN_IN": "Haz login",
    "SIMPLE_RETRAIN": "Entraînement simple",
    "SIMPLIFIED_EDITOR": "Editor simplificado",
    "SOURCE_DATASET": "Base de données fuente",
    "STATUS": "Status",
    "SUBMIT": "Enviar",
    "SUCCESS_CREATING_RESOURCE": "Recurso agregado com éxito",
    "SUCCESS_DELETING_RESOURCE": "Recurso apagado com éxito",
    "TARGET_DATASET": "Base de données de destino",
    "TARGET_FACT": "Hecho alvo",
    "TEST": "Probar",
    "TEXT": "Texto",
    "ASSISTANT_RESPONDS": "Entonces contestar con",
    "TRAINED_AT": "Entrenado en",
    "TRAINING_DATA": "Bases de Entraînement",
    "TRUE_INTENT": "Intención correcta",
    "UNBIND": "Desatar",
    "UPDATE": "Actualizar",
    "UPDATED_AFTER": "Actualizado depuis",
    "UPDATED_AT": "Actualizado en",
    "UPDATED_BEFORE": "Actualizado après",
    "UPLOAD": "Enviar",
    "URL": "URL",
    "USER": "Usuario",
    "USER_IS_BLOCKED": "Usuario bloqueado",
    "USER_NOT_FOUND": "Usuario no encontrado",
    "USERNAME": "Nombre de usuario",
    "USERS": "Usuarios",
    "USERS_DESCRIPTION": "Administre usuarios del Q&A Manager.",
    "VALUE": "Valor",
    "VARIABLE": "Variable",
    "VIEW": "Ver",
    "VIEWER": "Lector",
    "WAIT_FOR_USER_INPUT": "Esperar entrada del usuario",
    "WELCOME_TEXT": "Texto de bienvenida",
    "WORKSPACE_ID": "Workspace ID",
    "YES": "Sí"
}