import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from './components/navbar';
import Breadcrumb from './components/breadcrumb'
import Content from './components/content';
import NotificationOverlay from './components/notification-overlay';
import './App.css';
import './foo.scss';
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/dracula.css'
import 'carbon-components/css/carbon-components.min.css';
import routes from './routes'
import { Button, Modal } from 'carbon-components-react';
import { getOwnUser } from './services/auth';

class App extends Component {

    state = {
        helpOpen: false,
        notifications: [],
        user: null
    }

    constructor(props) {
        super(props)
        this.notificationOverlay = React.createRef()
    }

    routerRef = React.createRef()

    render() {
        return (
            <Router basename={(process.env.REACT_APP_PATH || "")} ref={this.routerRef}>
                <>
                    <Navbar user={this.state.user} />
                    <NotificationOverlay ref={this.notificationOverlay} notifications={this.state.notifications} />
                    <Content>
                        <Breadcrumb />
                        <Switch>
                            {routes.map((r, i) =>
                                <Route key={`route-${i}`} path={r.path} exact render={(props) => <>
                                    {r.help && <>
                                        <Button style={{ position: "absolute", top: "10px", right: "10px", borderRadius: "10rem" }} onMouseDown={ev => this.setState({ helpOpen: true })} >?</Button>
                                        <Modal modalHeading={window.translate("HELP")} passiveModal open={this.state.helpOpen} onRequestClose={ev => this.setState({ helpOpen: false })}><r.help {...this.props} /></Modal>
                                    </>}
                                    <r.component handleNotification={this.handleNotification.bind(this)} user={this.state.user} {...r.props} {...props} />
                                </>} />)}
                        </Switch>
                    </Content>
                </>
            </Router >
        );
    }

    componentDidMount() {
        if (window.location.pathname !== "/login") this.loadUser()
    }

    loadUser() {
        getOwnUser()
            .then(user => this.setState({ user }))
            .catch(err => window.location.replace(`/login?next_url=${window.location.pathname}`))
    }

    handleNotification(...args) {
        this.notificationOverlay.current.handleNotification(...args)
    }

}

export default App;
