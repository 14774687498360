import React from 'react'
import { UnorderedList, ListItem, Button, DataTable, Icon, Modal, TextInput } from 'carbon-components-react'
import { iconDownload } from 'carbon-icons'
import MultipleResponseEditor from '../pages/corpus/components/multiple-response-editor'
import ProcflowEditor from '../pages/corpus/components/procflow-editor'
import AssistantEditor from '../pages/corpus/components/assistant-editor'
import OutputVariablesEditor from '../pages/corpus/components/output-variables-editor'
import IntentDetail from '../pages/corpus/components/intent-detail'
import RecommendationDetail from '../pages/corpus/components/recommendation-detail'
import { deleteExample, executeRecommendation } from '../services/corpus'
import TextBuilder from '../components/text-builder'
let moment = require('moment')
try { require('moment/locale/' + navigator.language.slice(0, 2)) } catch { }

let Dataset = {
    title: window.translate("Datasets"),
    fields: [
        { key: "serial", type: "id", header: false },
        { key: "name", type: "text", header: true },
        { key: "created_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
        { key: "status", type: "none", header: true },
        {
            key: "language", type: "select", header: false, options: [
                { label: "", value: "" }, { label: window.translate("en"), value: "en" }, { label: window.translate("pt-BR"), value: "pt-BR" }, { label: window.translate("es"), value: "es" }, { label: window.translate("fr"), value: "fr" }
            ]
        },
        { key: "router", type: "toggle" },
        { key: "parent_id", type: "fkey", path: "./?router=true" },
        { key: "disambiguation_question_limit", type: "number", min: 0 },
        {
            key: "response_variation", type: "select", options: [
                { value: "sequential", label: window.translate("SEQUENTIAL") }, { value: "random", label: window.translate("RANDOM") }
            ]
        },
        { key: "welcome_text", type: "custom", component: props => <AssistantEditor {...props} dataset_id={props.current.dataset_id || props.current.id} /> },
        { key: "anything_else_text", type: "custom", component: props => <AssistantEditor {...props} dataset_id={props.current.dataset_id || props.current.id} /> },
    ],

    actions: [
        { path: "", text: "OVERVIEW", link: true },
        { path: "examples_class_distribution", text: "EXAMPLES", link: true },
        { path: "review_examples", text: "REVIEW_EXAMPLES", link: true, roles: ["admin", "auditor", "editor"] },
        { path: "review", text: "REVIEW", link: true, roles: ["admin", "auditor", "editor"] },
        { path: "mapping", text: "MAPPING", link: true, roles: ["admin", "auditor", "editor"] },
        { path: "merger", text: "MERGER", link: true, roles: ["admin", "auditor", "editor"] },
        { path: "intents", text: "INTENTS", link: true, roles: ["admin", "auditor", "editor"] },
        { path: "entities", text: "ENTITIES", link: true, roles: ["admin", "auditor", "editor"] },
        { path: "entity_transfer", text: "ENTITY_TRANSFER", link: true, roles: ["admin", "auditor", "editor"] },
        { path: "experiments", text: "EXPERIMENTS", link: true, roles: ["admin", "auditor", "editor"] },
        { path: "evaluations", text: "EVALUATIONS", link: true, roles: ["admin", "auditor", "editor"] },
        { path: "export_spreadsheet", text: "EXPORT", link: false, batch: true, method: "get", download: ".xlsx" },
        { path: "export_spreadsheet?clean=true", text: "EXPORT_CLEAN", link: false, batch: true, method: "get", download: ".xlsx" },
        { path: "permissions", text: "PERMISSIONS", link: true, roles: ["admin"] },
    ],

    permissionsPath: id => `/corpus/datasets/${id}/permissions/me`,

    audit: "DATASET",

    liveReload: true,

}

let Intent = {
    title: window.translate("Intents"),
    fields: [
        { key: "serial", type: "id", header: true, sortable: true, searchable: true, min: 1, style: { width: "7rem" } },
        { key: "dataset_id", type: "fkey", path: "/corpus/datasets", visible: formData => formData.id },
        { key: "name", type: "custom", component: props => <TextBuilder {...props} />, header: true, sortable: true, searchable: true },
        { key: "canonical", type: "text", header: true, sortable: true, searchable: true, visible: formData => ![4, "4"].includes(formData.type) },
        { key: "owner", type: "custom", component: props => <TextBuilder {...props} type="owner" separator="," />, header: true, sortable: true, searchable: true, visible: formData => ![4, "4"].includes(formData.type) },
        { key: "created_at", type: "none", header: true, parse: date => moment(date).format('LLL'), sortable: true },
        { key: "updated_at", type: "none", header: true, parse: date => moment(date).format('LLL'), sortable: true },
        { key: "out_of_training", type: "checkbox", sortable: true, visible: formData => ![4, "4"].includes(formData.type) },
        { key: "out_of_context", type: "checkbox", sortable: true, visible: formData => ![4, "4"].includes(formData.type) },
        { key: "final", type: "checkbox", sortable: true, visible: formData => ![4, "4"].includes(formData.type) },
        {
            key: "type", type: "radio",
            options: [{ value: "0", label: window.translate("NORMAL") }, { value: "1", label: window.translate("DISAMBIGUATION") }, { value: "2", label: window.translate("PROCFLOW") }, { value: "3", label: window.translate("ASSISTANT") }, { value: "4", label: "Template Assistant" }]
        },
        {
            key: "response_variation", type: "select", visible: formData => [0, 1, "0", "1"].includes(formData.type), 
            options: [{ value: "sequential", label: window.translate("SEQUENTIAL") }, { value: "random", label: window.translate("RANDOM") }, { value: "multiline", label: window.translate("MULTILINE") }]
        },
        { key: "content", type: "custom", component: props => <MultipleResponseEditor {...props} />, visible: formData => [0, 1, "0", "1"].includes(formData.type) },
        { key: "content", type: "custom", component: props => <ProcflowEditor {...props} dataset_id={props.current.dataset_id || props.current.id} />, visible: formData => [2, "2"].includes(formData.type) },
        { key: "content", type: "custom", component: props => <AssistantEditor {...props} dataset_id={props.current.dataset_id || props.current.id} intent_id={props.current.id} />, visible: formData => [3, "3"].includes(formData.type) },
        { key: "content", type: "custom", component: props => <AssistantEditor {...props} dataset_id={props.current.dataset_id || props.current.id} intent_id={props.current.id} />, visible: formData => [4, "4"].includes(formData.type) },
        { key: "context_variables", type: "custom", component: props => <OutputVariablesEditor title={window.translate("CONTEXT_VARIABLES")} {...props} />, visible: formData => [0, 1, "0", "1"].includes(formData.type) },
        { key: "output_variables", type: "custom", component: props => <OutputVariablesEditor {...props} />, visible: formData => [0, 1, "0", "1"].includes(formData.type) },
        { key: "metadata", type: "custom", component: props => <OutputVariablesEditor title={window.translate("METADATA")} keyLabel="Flag" addLabel={window.translate("ADD_FLAG")} {...props} />, visible: formData => ![4, "4"].includes(formData.type) },
        { key: "content", type: "custom", component: props => <><Modal primaryButtonText={window.translate("SUBMIT")} secondaryButtonText={window.translate("CANCEL")} modalHeading={window.translate("DIGRESSION_RETURN_TEXT")} open={!props.value.value.output_variables || !props.value.value.output_variables.texto_retorno_digressao} onRequestClose={() => props.onChange({ ...props.value, value: { ...props.value.value, digression: false } })} onRequestSubmit={ev => props.onChange({ ...props.value, value: { ...props.value.value, output_variables: { ...props.value.value.output_variables, texto_retorno_digressao: document.querySelector('[name=foo]').value } } })}><TextInput name="foo" /></Modal></>, visible: formData => [2, "2"].includes(formData.type) && formData.content && formData.content.value && formData.content.value.digression },
        { key: "id", type: "id", label: "UUID", visible: intent => intent.id },
        { key: "dataset_id", type: "id", visible: intent => intent.dataset_id },
    ],

    actions: [
        { path: "examples", text: "EXAMPLES", link: true },
        { path: "remap", text: "REMAP", link: true },
    ],
    batchActions: selectedRows => <DataTable.TableBatchAction
        renderIcon={() => <Icon style={{ marginLeft: "0.5rem" }} fill="white" icon={iconDownload} />}
        onClick={ev => {
            let link = document.createElement("a")
            link.download = `export.xlsx`
            link.href = `/corpus/datasets/${selectedRows[0].dataset_id}/export_spreadsheet?intent_ids=${selectedRows.reduce((ids, row) => ids + ',' + row.id, '').slice(1)}`
            document.body.appendChild(link)
            setTimeout(() => {
                link.click()
                document.body.removeChild(link)
            }, 0)
        }}
    >{window.translate("EXPORT")}
    </DataTable.TableBatchAction>,
    filters: [
        { type: "checkbox", key: "out_of_training" },
        { type: "checkbox", key: "out_of_context" },
        { type: "checkbox", key: "final" },
        { type: "checkbox", key: "output_variables" },
        { type: "checkbox", key: "context_variables" },
        { type: "checkbox", key: "metadata" },
        {
            type: "select", key: "content", label: window.translate("FLAGS"), options: [{},
            { value: "\"mandatory\":true", label: window.translate("MANDATORY_DISAMBIGUATION") },
            { value: "\"inference\":true", label: window.translate("INFERENCE") },
            { value: "\"digression\":true", label: window.translate("DIGRESSION_RETURN") },
            ]
        },
        { type: "daterange", label: window.translate("created_between"), key1: "created_after", key2: "created_before" },
        { type: "daterange", label: window.translate("updated_between"), key1: "updated_after", key2: "updated_before" },
    ],

    cellDetail: [null, row => <IntentDetail intentID={row.id} intent={row} />],
    audit: "INTENT",

}

let Entity = {
    title: window.translate("Entities"),
    fields: [
        { key: "name", type: "text", header: true },
        { key: "created_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
        { key: "updated_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
    ],
    actions: [
        { path: "entity_values", text: "ENTITY_VALUES", link: true },
    ],
    toolbarContent: props => <Button kind='secondary' {...props} onClick={row => {
        let link = document.createElement("a")
        link.download = `entities-${(new Date()).toISOString().replace(/-|:/g, '').replace('T', '-').slice(0, 15)}.xlsx`
        link.href = `${props.url.slice(0, -9)}/export_spreadsheet?include_intents=false&include_examples=false&include_config=false`
        document.body.appendChild(link)
        setTimeout(() => {
            link.click()
            document.body.removeChild(link)
        }, 0)
    }}>{window.translate("EXPORT")}</Button>,
    batchActions: selectedRows => <DataTable.TableBatchAction
        renderIcon={() => <Icon style={{ marginLeft: "0.5rem" }} fill="white" icon={iconDownload} />}
        onClick={ev => {
            let link = document.createElement("a")
            link.download = `export.xlsx`
            link.href = `/corpus/datasets/${selectedRows[0].dataset_id}/export_spreadsheet?entity_ids=${selectedRows.reduce((ids, row) => ids + ',' + row.id, '').slice(1)}`
            document.body.appendChild(link)
            setTimeout(() => {
                link.click()
                document.body.removeChild(link)
            }, 0)
        }}
    >{window.translate("EXPORT")}
    </DataTable.TableBatchAction>,
    renderDetail: entity => <UnorderedList>{entity.values.map(v => <ListItem>{v.name}</ListItem>)}</UnorderedList>,
    audit: "ENTITY",
}

let EntityValue = {
    title: window.translate("Entity_Values"),
    fields: [
        { key: "name", type: "text", header: true },
        { key: "created_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
        { key: "updated_at", type: "none", header: true, parse: date => moment(date).format('LLL') },
        {
            key: "type", type: "radio",
            options: [{ value: "0", label: window.translate("SYNONYMS") }, { value: "1", label: window.translate("PATTERNS"), description: window.translate("PATTERNS_TOOLTIP") }],
        },
        { key: "synonyms", type: "custom", component: props => <MultipleResponseEditor {...props} />, props: { html: false } },
    ],
    audit: "ENTITY_VALUE",
}

let Example = {
    title: window.translate("Examples"),
    fields: [
        { key: "text", type: "text", header: true, sortable: true },
        { key: "status", type: "number", min: 0, max: 1, step: 1, header: true, parse: status => status ? window.translate("FIXED") : window.translate("NOT_FIXED"), sortable: true },
        { key: "metadata.canal_cliente", label: window.translate("CHANNEL"), header: true, sortable: true, parse: channel => channel || "N/A" },
    ],
    filters: [
        { type: "daterange", label: window.translate("created_between"), key1: "created_after", key2: "created_before" },
        { type: "daterange", label: window.translate("updated_between"), key1: "updated_after", key2: "updated_before" },
        { type: "select", key: "status", options: [{}, { label: window.translate("FIXED"), value: 1 }, { label: window.translate("NOT_FIXED"), value: 0 }] },
        { type: "select", key: "metadata.canal_cliente", label: window.translate("CHANNEL"), options: [{ value: null }, { value: "APPLE_BUSINESS_CHAT", label: "APPLE_BUSINESS_CHAT" }, { value: "GOOGLE_ASSISTENTE", label: "GOOGLE_ASSISTENTE" }, { value: "MOBILE_PF", label: "MOBILE_PF" }, { value: "Q&A", label: "Q&A" }, { value: "TRANSACIONAL_PF", label: "TRANSACIONAL_PF" }, { value: "WHATSAPP", label: "WHATSAPP" }, { value: "WHATSAPP-STT", label: "WHATSAPP-STT" }, { value: "PORTAL_BRADESCO", label: "PORTAL_BRADESCO" }, { value: "MOBILE_PF_LOGADO", label: "MOBILE_PF_LOGADO" } ] },
    ],
    mapFn: example => ({ ...example, text: example.text, intent_name: example.intent_name, "metadata.canal_cliente": example.metadata.canal_cliente }),
    audit: "EXAMPLE",
}

let ReviewExample = {
    title: window.translate("Review_Examples"),
    fields: [
        { key: "text", type: "text", header: true, sortable: true },
        { key: "intent_name", type: "none", header: true, sortable: true },
        { key: "status", type: "none", header: true, sortable: true, parse: status => window.translate(status ? "REVIEW" : "MAPPING") },
        { key: "updated_at", type: "none", header: true, parse: date => moment(date).format('LLL'), sortable: true },
        { key: "metadata.canal_cliente", label: window.translate("CHANNEL"), header: true, sortable: true, parse: channel => channel || "N/A" },
    ],
    actions: [
        { path: "accept", text: "ACCEPT", batch: true, condition: row => row.status === 1 },
        { path: "reject", text: "REJECT", batch: true, condition: row => row.status === 1 },
    ],
    filters: [
        { type: "daterange", label: window.translate("created_between"), key1: "created_after", key2: "created_before" },
        { type: "daterange", label: window.translate("updated_between"), key1: "updated_after", key2: "updated_before" },
        { type: "select", key: "metadata.canal_cliente", label: window.translate("CHANNEL"), options: [{ value: null }, { value: "APPLE_BUSINESS_CHAT", label: "APPLE_BUSINESS_CHAT" }, { value: "GOOGLE_ASSISTENTE", label: "GOOGLE_ASSISTENTE" }, { value: "MOBILE_PF", label: "MOBILE_PF" }, { value: "Q&A", label: "Q&A" }, { value: "TRANSACIONAL_PF", label: "TRANSACIONAL_PF" }, { value: "WHATSAPP", label: "WHATSAPP" }, { value: "WHATSAPP-STT", label: "WHATSAPP-STT" }, { value: "PORTAL_BRADESCO", label: "PORTAL_BRADESCO" }, { value: "MOBILE_PF_LOGADO", label: "MOBILE_PF_LOGADO" }] },
    ],

    mapFn: example => ({ ...example, text: example.text, intent_name: example.intent_name, "metadata.canal_cliente": example.metadata.canal_cliente }),
    disableCreate: true,
    cellDetail: [null, row => <IntentDetail intentID={row.intent_id} />],
    audit: "REVIEW_EXAMPLE",
}

let Experiment = {
    title: window.translate("Experiments"),
    fields: [
        { key: "name", type: "text", header: true, sortable: true },
        { key: "created_at", type: "none", header: true, parse: date => moment(date).format('LLL'), sortable: true },
        { key: "status", type: "none", header: true, sortable: true },
        { key: "assistant_instance_id", type: "fkey", path: "/orchestrator/assistant_instances" },
        { key: "language", type: "select", options: [{ label: "", value: "" }, { label: window.translate("en"), value: "en" }, { label: window.translate("pt-br"), value: "pt-br" }, { label: window.translate("es"), value: "es" }] },
        { key: "train_size", type: "number", step: 0.1, min: 0.1, max: 1, default: 0.8 },
        { key: "complete", type: "checkbox" },
    ],
    actions: [
        { path: "report", text: "REPORT", link: true },
    ],
    disableUpdate: row => true,
    audit: "EXPERIMENT",
}

let Evaluation = {
    title: window.translate("Evaluations"),
    fields: [
        { key: "name", type: "text", header: true, description: "EVALUATION_NAME_DESCRIPTION", sortable: true },
        { key: "created_at", type: "none", header: true, parse: date => moment(date).format('LLL'), sortable: true },
        { key: "type", type: "select", header: true, options: [{ label: "", value: "" }, { label: window.translate("REDUNDANCY_DETECTION"), value: "redundancy_detection" }, { label: window.translate("UNDERSAMPLING"), value: "undersampling" }, { label: window.translate("OUTLIER_DETECTION"), value: "outlier_detection" }], parse: type => window.translate(type), sortable: true },
        { key: "status", type: "none", header: true, sortable: true },
        { key: "language", type: "select", options: [{ label: "", value: "" }, { label: window.translate("pt"), value: "pt" }], description: "EVALUATION_LANGUAGE_DESCRIPTION" },
        // { key: "use_review", type: "toggle", description: "EVALUATION_USE_REVIEW_DESCRIPTION" },
        { key: "min_distance", type: "number", step: 1, min: 0, max: 5, default: 0, visible: formData => formData.type === 'redundancy_detection', description: "EVALUATION_MIN_DISTANCE_DESCRIPTION" },
        { key: "max_samples", type: "number", step: 1, min: 5, max: 100, default: 20, visible: formData => formData.type === 'undersampling', description: "EVALUATION_MAX_SAMPLES_DESCRIPTION" },
        { key: "deviations", type: "number", step: 1, min: 1, max: 5, default: 1, visible: formData => formData.type === 'outlier_detection', description: "EVALUATION_DEVIATIONS_DESCRIPTION" },
    ],
    actions: [
        { path: "recommendations", text: "RECOMMENDATIONS", link: true },
    ],
    disableUpdate: row => true,
    audit: "EVALUATION",
}

let Recommendation = {
    title: window.translate("Recommendations"),
    fields: [
        { key: "content", type: "none", header: true, sortable: true },
        { key: "type", type: "none", header: true, sortable: true },
    ],
    actions: [
        {
            text: "EXECUTE", link: false, batch: true, confirm: true, onClick: async recommendation => {
                let datasetID = window.location.pathname.split('datasets/')[1].split('/')[0]
                executeRecommendation(datasetID, recommendation.evaluation_id, recommendation.id)
                recommendation.metadata.examples
                    .filter(example => !example.keep)
                    .map(example => deleteExample(datasetID, example.intent_id, example.id))
            }
        },
    ],
    disableCreate: true,
    disableUpdate: row => true,
    mapFn: (recommendation, page) => {
        if (["delete_examples", "delete_review_examples"].includes(recommendation.type))
            return {
                ...recommendation,
                content: recommendation.metadata.examples[0].intent_name,
                type: window.translate(recommendation.type)
            }
    },
    renderDetail: recommendation => <RecommendationDetail value={recommendation} />,
    cellDetail: [row => <IntentDetail intentID={row.metadata.examples[0].intent_id} />],
    audit: "RECOMMENDATION",
}

export { Dataset, Intent, Entity, EntityValue, Example, ReviewExample, Experiment, Evaluation, Recommendation }