import React, { Component } from 'react';
import renderHTML from 'react-render-html'
import { Tile, TextInput, Button, UnorderedList, ListItem, Accordion, AccordionItem, Icon, Tabs, Tab, ModalWrapper } from 'carbon-components-react';
import { messageOrchestrator, feedbackOrchestrator, messageAssistantSkill } from '../../../services/orchestrator'
import { iconRestart, iconWarning } from 'carbon-icons';
import { JSONEditor } from '../../../components/carbon-react-crud';


class Chat extends Component {

    state = {
        messages: [],
        input: {
            text: ""
        },
        context: {
            canal_cliente: "Q&A"
        },

        answerOptionsOn: false,
        feedbackOn: false,
        topFiveOn: false,
        documentsOn: false,
        loading: true
    }

    messagesEndRef = React.createRef()

    render() {
        return (<>
            <Tabs onSelectionChange={() => this.setState({ loading: true }, () => this.setState({ loading: false }))}>
                <Tab label="Chat">
                    <Tile style={{ height: "75vh", maxWidth: "800px", margin: "auto", overflow: "scroll" }}>
                        {this.state.messages.map((message, index) => message.from === "user" ?
                            <UserMessage payload={message.payload} key={index} /> :
                            <BotMessage payload={message.payload} key={index} />
                        )}
                        {this.state.answerOptionsOn &&
                            <AnswerOptions
                                options={this.state.messages[this.state.messages.length - 1].payload.output.answer_options.map(o => ({ value: o, text: o }))}
                                onClick={option => this.sendMessage({ text: option })}
                            />}
                        {this.state.feedbackOn &&
                            <FeedbackControl
                                onFeedback={type => this.sendFeedback({
                                    input: this.state.messages[this.state.messages.length - 2].payload.text,
                                    type: type,
                                    intent_id: this.state.messages[this.state.messages.length - 1].payload.intents[0].id,
                                    training_data_id: this.state.messages[this.state.messages.length - 1].payload.context.training_data_id,
                                    history_disambiguation_flow: this.state.messages[this.state.messages.length - 1].payload.output.history_disambiguation_flow,
                                    context: { "canal_cliente": this.state.context.canal_cliente }
                                })} />}
                        {this.state.topFiveOn &&
                            <TopFiveControl
                                topIntents={this.state.messages[this.state.messages.length - 1].payload.intents}
                                onFeedback={(type, intentID) => this.sendFeedback({
                                    input: this.state.messages[this.state.messages.length - 2].payload.text,
                                    type: type,
                                    intent_id: intentID,
                                    training_data_id: this.state.messages[this.state.messages.length - 1].payload.context.training_data_id,
                                    history_disambiguation_flow: this.state.messages[this.state.messages.length - 1].payload.output.history_disambiguation_flow,
                                    context: { "canal_cliente": this.state.context.canal_cliente }
                                })}
                                orchestrator_id={this.props.orchestrator_id}
                            />}
                        {this.state.documentsOn &&
                            <RelevantDocumentsControl
                                relevantDocuments={this.state.messages[this.state.messages.length - 1].payload.relevant_documents}
                            />}
                        <div ref={this.messagesEndRef} />
                    </Tile>
                    <div style={{ display: "flex", maxWidth: "800px", margin: "auto" }}>
                        <TextInput
                            onChange={ev => this.setState({ input: { text: ev.target.value } })}
                            onKeyPress={ev => ev.key === "Enter" ? ev.shiftKey ? this.reset() : this.sendMessage(this.state.input) : undefined}
                            value={this.state.input.text}
                        />
                        <Button
                            onClick={ev => this.sendMessage(this.state.input)}
                        >
                            {window.translate("SEND")}
                        </Button>
                        <Button
                            className="btn-icon-only"
                            kind="secondary"
                            onClick={ev => this.reset()}
                        >
                            <Icon icon={iconRestart} />
                        </Button>
                    </div>
                </Tab>
                <Tab label={window.translate("MANAGE_CONTEXT")}>
                    {!this.state.loading && <JSONEditor value={this.state.context} onChange={context => this.setState({ context })} />}
                </Tab>
                <Tab label={window.translate("VIEW_OUTPUT")}>
                    {!this.state.loading && <JSONEditor value={this.state.messages.length ? this.state.messages[this.state.messages.length - 1].payload : {}} />}
                </Tab>
            </Tabs>
        </>)
    }

    componentDidMount() {
        this.reset()
    }

    componentDidUpdate() {
        this.scrollToBottom()
    }

    scrollToBottom = () => {
        this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    sendMessage(input) {
        if (input.text)
            this.setState({
                input: { text: "" },
                messages: this.state.messages
                    .concat({ payload: input, from: "user" }),
                answerOptionsOn: false,
                feedbackOn: false,
                topFiveOn: false,
                documentsOn: false,
                loading: true
            })
        return (this.props.orchestrator_id ?
            messageOrchestrator(this.props.orchestrator_id, { input: input, context: this.state.context }) :
            messageAssistantSkill(this.props.assistant_skill_id, { input: input, context: this.state.context })
        )
            .then(data => {
                this.setState({
                    messages: this.state.messages.concat({ payload: data, from: "bot" }),
                    context: data.context,
                    answerOptionsOn: data.output.answer_options,
                    feedbackOn: data.output.type === "target" && this.props.orchestrator_id,
                    topFiveOn: !this.props.orchestrator_id && this.state.messages.length > 1,
                })
            })
            .catch(err => {
                this.setState({
                    messages: this.state.messages.concat({ payload: { output: { text: ["Error"] } }, from: "bot" }),
                    loading: false
                })
            })
    }

    sendFeedback(payload) {
        feedbackOrchestrator(this.props.orchestrator_id, payload)
        this.setState({
            feedbackOn: false,
            topFiveOn: payload.type === "incorrect",
            documentsOn: payload.type === "none" && this.state.messages[this.state.messages.length - 1].payload.relevant_documents
        })
    }

    reset() {
        this.setState({
            input: { text: "" }, context: { canal_cliente: this.state.context.canal_cliente }, messages: [],
            answerOptionsOn: false,
            feedbackOn: false,
            topFiveOn: false,
            documentsOn: false,
        }, () => this.sendMessage(this.state.input))
    }
}


class UserMessage extends Component {
    render() {
        return (
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Tile style={{ margin: "10px", backgroundColor: "azure" }}>
                    {renderHTML(this.props.payload.text)}
                </Tile>
            </div>
        )
    }
}

class BotMessage extends Component {
    render() {
        return (<>
            {this.props.payload.output.text.map(txt =>
                <div style={{ display: "flex" }}>
                    <Tile style={{ margin: "10px" }}>
                        {renderHTML(txt)}
                    </Tile>
                </div>)}
            {this.props.payload.output.error && <ModalWrapper
                triggerButtonKind="danger--primary"
                buttonTriggerClassName="btn-icon-only"
                buttonTriggerText={<Icon fill="red" icon={iconWarning} />} passiveModal>{this.props.payload.output.error}</ModalWrapper>}
        </>)
    }
}

class AnswerOptions extends Component {
    render() {
        return (
            <UnorderedList>
                {this.props.options.map(option =>
                    <ListItem style={{ cursor: "pointer" }} onClick={ev => this.props.onClick(option.value)}>{option.text}</ListItem>
                )}
            </UnorderedList>
        )
    }
}

class FeedbackControl extends Component {
    render() {
        return (<>
            Did you like this answer?
            <br />
            <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Button style={{ width: "200px", margin: "10px" }} onClick={ev => this.props.onFeedback("correct")}>Yes</Button>
                <Button style={{ width: "200px", margin: "10px" }} onClick={ev => this.props.onFeedback("incorrect")}>No</Button>
            </div>
        </>)
    }
}

class TopFiveControl extends Component {
    render() {
        return (<>
            <Accordion>
                <AccordionItem title="Top Answers">
                    <Accordion>
                        {this.props.topIntents.map(intent =>
                            <AccordionItem title={`${intent.canonical} (${(intent.confidence * 100).toFixed(2)})`}>
                                {renderHTML(intent.response || "")}
                                {this.props.orchestrator_id && <div style={{ display: "flex", justifyContent: "space-around" }}>
                                    <Button style={{ width: "200px", margin: "10px" }} onClick={ev => this.props.onFeedback("correct", intent.id)}>Yes</Button>
                                </div>}
                            </AccordionItem>
                        )}
                    </Accordion>
                    <br /><br />
                    {this.props.orchestrator_id && <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <Button style={{ width: "200px", margin: "10px" }} onClick={ev => this.props.onFeedback("none")}>None</Button>
                    </div>}
                </AccordionItem>
            </Accordion>
        </>)
    }
}

class RelevantDocumentsControl extends Component {
    render() {
        return (<>
            Here are some documents related to your query.
            <br /><br />
            <Accordion>
                {this.props.relevantDocuments.map(document =>
                    <AccordionItem title={document.name}>
                        {renderHTML(document.content || "")}
                    </AccordionItem>
                )}
            </Accordion>
        </>)
    }
}


export default Chat