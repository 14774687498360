import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { RadioButtonGroup, RadioButton, Button, Toggle, Tile, Select, SelectItem } from 'carbon-components-react';
import { listAllIntents } from '../../../../services/corpus'
import { v4 } from 'uuid'
import MultipleResponseEditor from '../multiple-response-editor';
import OutputVariablesEditor from '../output-variables-editor';
import { RecordPicker } from '../../../../components/carbon-react-crud';
import MultiConditionEditor from '../multi-condition-editor';
import { values } from 'carbon-icons';


class ProcflowEditor extends Component {

    render() {
        return (<div>
            <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 50 }}>
                    <Toggle
                        labelText={window.translate("MANDATORY_DISAMBIGUATION")}
                        toggled={this.props.value && this.props.value.value && this.props.value.value.mandatory}
                        onToggle={state => this.handleValueChange({ mandatory: state })}
                        disabled={this.props.disabled}
                    />
                </div>
                <div style={{ marginRight: 50 }}>
                    <Toggle
                        labelText={window.translate("INFERENCE")}
                        toggled={this.props.value && this.props.value.value && this.props.value.value.inference}
                        onToggle={state => this.handleValueChange({ inference: state })}
                        disabled={this.props.disabled}
                    />
                </div>
                <div style={{ marginRight: 50 }}>
                    <Toggle
                        labelText={window.translate("DIGRESSION_RETURN")}
                        toggled={this.props.value && this.props.value.value && this.props.value.value.digression}
                        onToggle={state => this.handleValueChange({ digression: state })}
                        disabled={this.props.disabled}
                    />
                </div>
                <div style={{ marginRight: 50 }}>
                    <Toggle
                        labelText={window.translate("NO_QUESTION")}
                        toggled={!(this.props.value && this.props.value.value && this.props.value.value.text)}
                        onToggle={state => this.handleValueChange({ text: state ? undefined : [] })}
                        disabled={this.props.disabled}
                    />
                </div>

            </div>
            <br />
            <Select 
                value={this.props.value && this.props.value.value && this.props.value.value.response_variation}
                labelText={window.translate("RESPONSE_VARIATION")}
                onChange={response_variation => this.handleVariationChange(response_variation.target.value, undefined)}
            >
                <SelectItem value="sequential" text={window.translate("SEQUENTIAL")} />
                <SelectItem value="random" text={window.translate("RANDOM")} />
                <SelectItem value="multiline" text={window.translate("MULTILINE")} />
            </Select>
            <MultipleResponseEditor
                value={this.props.value && this.props.value.value && this.props.value.value.text}
                onChange={value => this.handleValueChange({ text: value })}
                disabled={this.props.disabled}
            />
            <br /><br />
            <OutputVariablesEditor
                title={window.translate("CONTEXT_VARIABLES")}
                value={(this.props.value && this.props.value.value && this.props.value.value.context_variables) || {}}
                onChange={contextVariables => this.handleContextVariablesChange(contextVariables)}
            />
            <OutputVariablesEditor
                title={window.translate("OUTPUT_VARIABLES")}
                value={(this.props.value && this.props.value.value && this.props.value.value.output_variables) || {}}
                onChange={outputVariables => this.handleOutputVariablesChange(outputVariables)}
            />
            <br /><br />
            <Button onClick={ev => this.handleAddChild(0)}>{window.translate("ADD_CHILD")}</Button>
            {this.props.value && this.props.value.childs && this.props.value.childs.map((child, i) => <div key={child.id} style={{ marginLeft: "3rem" }}>
                <div className="Node">
                    <Tile
                    >
                        <h3>{window.translate("IF_ASSISTANT_RECOGNIZES")}:</h3>
                        <br />
                        <MultiConditionEditor
                            dataset_id={this.props.dataset_id || this.props.match.params.dataset_id}
                            value={child.value.text[0]}
                            onChange={value => this.handleConditionChange(value, i)}
                            isProcflow={true}
                        />
                    </Tile>
                    <br /><br />
                    <RadioButtonGroup
                        valueSelected={child.childs[0].subtype}
                        onChange={type => this.handleTypeChange(type, i)}
                    >
                        <RadioButton value="straight" labelText={window.translate("NORMAL")} disabled={this.props.disabled} />
                        <RadioButton value="goTo" labelText={window.translate("GO_TO")} disabled={this.props.disabled} />
                        <RadioButton value="disambiguation" labelText={window.translate("PROCFLOW")} disabled={this.props.disabled} />
                    </RadioButtonGroup> <br /><br />
                    {child.childs[0].subtype === "straight" && <>
                        <RadioButtonGroup
                            valueSelected={child.childs[0].value.type}
                            onChange={type => this.handleValueChange({ type }, i)}
                        >
                            <RadioButton value="target" labelText={window.translate("TARGET")} disabled={this.props.disabled} />
                            <RadioButton value="out_of_context" labelText={window.translate("OUT_OF_CONTEXT")} disabled={this.props.disabled} />
                        </RadioButtonGroup> 
                        <br /><br />
                        <Select 
                            value={child.childs[0].value.response_variation}
                            labelText={window.translate("RESPONSE_VARIATION")}
                            onChange={response_variation => this.handleVariationChange(response_variation.target.value, i)}
                        >
                            <SelectItem value="sequential" text={window.translate("SEQUENTIAL")} />
                            <SelectItem value="random" text={window.translate("RANDOM")} />
                            <SelectItem value="multiline" text={window.translate("MULTILINE")} />
                        </Select>
                        <MultipleResponseEditor
                            value={child.childs[0].value.text}
                            onChange={value => this.handleValueChange({ text: value }, i)}
                            disabled={this.props.disabled}
                        />
                        <OutputVariablesEditor
                            title={window.translate("CONTEXT_VARIABLES")}
                            value={child.childs[0].value.context_variables || {}}
                            onChange={contextVariables => this.handleContextVariablesChange(contextVariables, i)}
                        />
                        <OutputVariablesEditor
                            title={window.translate("OUTPUT_VARIABLES")}
                            value={child.childs[0].value.output_variables || {}}
                            onChange={outputVariables => this.handleOutputVariablesChange(outputVariables, i)}
                        />
                    </>}
                    {child.childs[0].subtype === "goTo" && <>
                        {window.translate("GO_TO")}
                        <RecordPicker
                            fetchOptions={(url, params) => listAllIntents({ ...params, siblings_of: this.props.dataset_id })}
                            value={child.childs[0].value.goTo.value}
                            onChange={id => this.handleGoToChange(id, i)}
                            invalid={!child.childs[0].value.goTo.value}
                            invalidText={window.translate("INVALID_TARGET")}
                            disabled={this.props.disabled}
                        />
                        <br /><br />
                    </>}
                    {child.childs[0].subtype === "disambiguation" &&
                        <ProcflowEditor
                            value={child.childs[0]}
                            onChange={value => this.handleEditChild(value, i)}
                            dataset_id={this.props.dataset_id || this.props.match.params.dataset_id}
                        />
                    }
                    <br /> <br />
                    <Button kind='danger--primary' onClick={ev => this.handleRemoveChild(i)}>{window.translate("REMOVE_CHILD")}</Button>
                </div>
                <Button onClick={ev => this.handleAddChild(i + 1)}>{window.translate("ADD_CHILD")}</Button>
            </div>)
            }
        </div >)
    }

    isValid() {
        return typeof this.props.value === 'object' && this.props.value.subtype === "disambiguation"
    }

    componentDidMount() {
        if (!this.isValid()) {
            this.props.onChange({ subtype: "disambiguation", value: { text: [] }, childs: [] })
        }
    }

    handleTypeChange(type, childIndex) {
        let target = this.props.value.childs[childIndex].childs[0]
        target.subtype = type
        if (type === "straight") target.value = { text: [""] }
        if (type === "goTo") target.value = { goTo: { value: "" }, text: [""] }
        if (type === "disambiguation") this.props.value.childs[childIndex].childs[0] = { subtype: "disambiguation", value: { text: "" }, childs: [] }
        target.childs = []
        this.props.onChange(this.props.value)
    }

    handleVariationChange(response_variation, childIndex) {
        if (childIndex !== undefined) this.props.value.childs[childIndex].childs[0].value.response_variation = response_variation
        else this.props.value.value.response_variation = response_variation
        this.props.onChange(this.props.value)
    }

    handleValueChange(value, childIndex) {
        if (childIndex !== undefined)
            this.props.value.childs[childIndex].childs[0].value = { ...this.props.value.childs[childIndex].childs[0].value, ...value }
        else
            this.props.value.value = { ...this.props.value.value, ...value }
        this.props.onChange(this.props.value)
    }

    handleConditionChange(value, childIndex) {
        this.props.value.childs[childIndex].value.text = [value]
        this.props.onChange(this.props.value)
    }

    handleOutputVariablesChange(value, childIndex) {
        if (childIndex !== undefined) this.props.value.childs[childIndex].childs[0].value.output_variables = value
        else this.props.value.value.output_variables = value
        this.props.onChange(this.props.value)
    }

    handleContextVariablesChange(value, childIndex) {
        if (childIndex !== undefined) this.props.value.childs[childIndex].childs[0].value.context_variables = value
        this.props.value.value.context_variables = value
        this.props.onChange(this.props.value)
    }

    handleGoToChange(intentID, childIndex) {
        this.props.value.childs[childIndex].childs[0].value.goTo.value = intentID
        this.props.onChange(this.props.value)
    }

    handleAddChild(childIndex) {
        let target = this.props.value
        target.childs = [
            ...target.childs.slice(0, childIndex),
            ProcflowOption(),
            ...target.childs.slice(childIndex)
        ]
        this.props.onChange(this.props.value)
    }

    handleEditChild(value, childIndex) {
        this.props.value.childs[childIndex].childs[0] = value
        this.props.onChange(this.props.value)
    }

    handleRemoveChild(childIndex) {
        let target = this.props.value
        target.childs = [
            ...target.childs.slice(0, childIndex),
            ...target.childs.slice(childIndex + 1)
        ]
        this.props.onChange(this.props.value)
    }

}

ProcflowEditor.defaultProps = {
    position: []
}

let ProcflowOption = () => ({
    "id": v4(),
    "subtype": "options", "childs": [{
        "subtype": "straight", "childs": [],
        "value": { "text": [""], "response_variation": "sequential" }
    }],
    "value": { "text": [""] }
})

export default withRouter(ProcflowEditor)
